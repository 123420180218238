<script setup>
defineProps({
  paymentInformation: {
    type: [Boolean, Object],
    default: false,
  },
})

const { t } = useI18n()

const openPaymentMethodModal = ref(false)
</script>

<template>
  <template v-if="paymentInformation">
    <div
      v-if="paymentInformation.type === 'card'"
      class="mb-4"
    >
      <span class="font-bold">{{ paymentInformation.brand }}</span>
      {{ t('payment.endingWith') }} {{ paymentInformation.last4 }}
      <br>
      {{ t('payment.expires') }} {{ paymentInformation.expMonth }} /
      {{ paymentInformation.expYear }}
    </div>

    <div
      v-else-if="paymentInformation.type === 'sepa_debit'"
      class="mb-4"
    >
      <span class="font-bold">{{ paymentInformation.bankName }}</span>
      {{ t('payment.endingWith') }} {{ paymentInformation.last4 }}
    </div>
  </template>
  <template v-else>
    {{ t('payment.noPaymentMethod') }}
  </template>

  <p>
    <DButton
      class="mt-4"
      variant="gradient-primary"
      @click="openPaymentMethodModal = true"
    >
      {{ t('payment.update') }}
    </DButton>
  </p>

  <PaymentMethodModal
    v-if="openPaymentMethodModal"
    @close="openPaymentMethodModal = false"
    @updated="openPaymentMethodModal = false"
  />
</template>
