import { defineStore } from 'pinia'

export const useLoginModalsStore = defineStore('auth', {
  state: () => {
    return {
      currentModal: null, // 'signin', 'oauth', 'forgot-password', 'change-organization'
      oAuthProvider: null, // 'google', 'microsoft', 'linkedin', 'github'
      forgotEmail: null,
    }
  },
  actions: {
    openSignin() {
      const userStore = useUserStore()

      if (userStore.user && !userStore.organization) {
        // user is already connected and has no organization
        return this.openChangeOrganization()
      }

      this.currentModal = 'signin'
    },
    openOAuth(provider) {
      this.currentModal = 'oauth'
      this.oAuthProvider = provider
    },
    openForgotPassword(email = null) {
      this.currentModal = 'forgot-password'
      this.forgotEmail = email
    },
    openChangeOrganization() {
      this.currentModal = 'change-organization'
    },
    closeModal() {
      this.currentModal = null
    },
  },
})

// make sure to pass the right store definition, `useLoginModalsStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoginModalsStore, import.meta.hot))
}
