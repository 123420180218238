export function validatorButtonsVariants(value: string): boolean {
  return [
    'fill-primary',
    'fill-secondary',
    'gradient-primary',
    'gradient-secondary',
    'outline-primary',
    'outline-secondary',
    'fill-amber',
    'success',
    'alert',
    'warning',
    'none',
  ].includes(value)
}

export function validatorVariants(value: string): boolean {
  return ['default', 'info', 'success', 'alert', 'warning'].includes(value)
}

export const validatorSizes: ((value: string) => boolean) = value => ['xs', 'sm', 'md', 'lg'].includes(value)

export function validatorEmail(email: string): boolean {
  const regex
    = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}
