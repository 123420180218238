<script setup>
defineOptions({
  name: 'CityCarRentalDistribution',
})

const { t } = useI18n()

function prepareData(data) {
  return (
    data?.reduce(
      (acc, { duration, value, percent }, key) => {
        const arr = [...Array(data.length).keys()].map(() => 0)
        arr[key] = percent

        const labelArr = duration.includes('-')
          ? duration.split('-')
          : [duration]

        const label
          = labelArr.length > 1
            ? `${labelArr[0]}-${labelArr[1]}${t('units.upper_hour')}`
            : `${labelArr}${t('units.upper_hour')}+`

        acc.series.push({
          data: arr,
          name: label,
          value,
          color: PALETTE_COLORS_VEHICLES.car,
        })

        acc.labels.push(label)

        return acc
      },
      {
        series: [],
        labels: [],
      },
    ) || []
  )
}
</script>

<template>
  <MetricCard
    kpi="cityCarRentalDistribution"
    :as-mode="VEHICLE_TYPE_CAR"
    :used-filters="['dateRange', 'filterBySpecs']"
  >
    <template #title>
      {{ t('Rental period by duration') }}
    </template>

    <template #default="{ data }">
      <BarMetric
        :data="prepareData(data)"
        :options="{
          scales: {
            x: {
              stacked: true,
            },
            y: {
              suggestedMin: 0,
              ticks: {
                callback: v => formatPercent(v),
              },
            },
          },
          unstacked: true,
          isPercent: true,
        }"
        :legend="false"
      />
    </template>
  </MetricCard>
</template>
