<script setup>
import locationsQuery from '@/graphql/queries/locations.gql'

defineOptions({
  name: 'FilterBy',
})

const props = defineProps({
  vehicleTypes: { type: Array, default: () => VEHICLE_TYPES },
  locations: { type: Array, default: () => [] },
})

const emit = defineEmits(['update:vehicleTypes', 'update:locations'])

const route = useRoute()
const { t } = useI18n()
const { isFreemium } = storeToRefs(useUserStore())
const { vehicleTypes, locations } = useVModels(props, emit)

const { translateLocation } = useLabelTranslation()
const { result, loading } = useQuery(locationsQuery)

const locked = computed(() => route.name === 'Providers' && get(isFreemium))

const locationsList = computed(() => {
  if (loading.value) {
    return []
  }

  return (
    result.value?.locations?.map(location => ({
      ...location,
      locked: location.unlocked === false,
      label: translateLocation(location.name, location.type),
      value: location,
    })) || []
  )
})
</script>

<template>
  <FilterByContainer>
    <DField class="mt-2">
      <template #label>
        {{ t('Modes') }}
      </template>
      <VehiclesTypesSelector
        v-model="vehicleTypes"
        class="mt-1"
      />
    </DField>

    <div>
      <p class="mb-1 text-grey-200 text-xs">
        {{ t('Location') }}
        <DTooltip
          v-if="locked"
          class="inline"
          :content="t('locked_for_freemium')"
        >
          <DIcon
            path="cadenas"
            size="sm"
          />
        </DTooltip>
      </p>
      <DInputTags
        v-model="locations"
        :list="locationsList"
        :placeholder="t('Type a country or a city...')"
        :loading="loading"
        :locked="locked"
        :deep-values="v => v.name"
        variant="secondary"
        hide-input
        multiple
      />
    </div>
  </FilterByContainer>
</template>
