<script setup>
defineOptions({
  name: 'LoginCallback',
})

const route = useRoute()
const { login, goToUserMain, onUserLoaded, loading } = useUserStore()

onMounted(() => {
  login(route.hash.replace('#jwt=', ''))
})

onUserLoaded((user) => {
  if (user) {
    goToUserMain()
  }
})
</script>

<template>
  <div>
    <LoginHeader />
    <DLoader v-if="loading" />
  </div>
</template>
