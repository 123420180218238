<script setup>
const props = defineProps({
  city: {
    type: Object,
    default: null,
  },
  loading: Boolean,
  vehicleTypes: {
    type: Array,
    default: () => [],
  },
  vehicleTypesLoading: Boolean,
  showDetails: Boolean,
})

const { t } = useI18n()
const { user } = storeToRefs(useUserStore())
const citiesStore = useCitiesStore()
const router = useRouter()

const isLocked = computed(() => {
  return citiesStore.isCityLocked(props.city?.name)
})

watch(
  () => props.loading,
  (value) => {
    if (!value && !props.city) {
      router.push('/')
    }
  },
)
</script>

<template>
  <div class="flex flex-col overflow-y-auto bg-grey-100">
    <template v-if="!loading && city">
      <CityInfoSidebarHeader
        :city-name="city.name"
        :cover="city.cover"
      />

      <CityInfoSidebarInfo :info="city.info" />

      <CityInfoSidebarVehiclesData
        :city-name="city.name"
        :vehicle-types="vehicleTypes"
        :loading="vehicleTypesLoading"
        :show-details="
          isDefined(city.availableInCD) ? city.availableInCD : showDetails
        "
      />

      <slot>
        <div
          v-if="!user"
          class="mx-8 mt-8 text-center"
        >
          <p class="text-center md:mx-16">
            {{
              t(
                'Create a free account to see the number of vehicles in any city, including {city}.',
                { city: citiesStore.getCityNameLocalized(city.name) },
              )
            }}
          </p>

          <DButton
            class="my-4 w-1/2"
            size="lg"
            :to="{ name: 'Register' }"
          >
            {{ t('Sign up') }}
          </DButton>
        </div>
        <div
          v-else
          class="mx-8 mt-8 text-center"
        >
          <p class="md:mx-16">
            {{
              t('Explore operator-level vehicle & trip data in {city}',
                { city: citiesStore.getCityNameLocalized(city.name) })
            }}
          </p>

          <DButton
            v-if="isLocked"
            class="my-4 w-1/2"
            size="lg"
            icon-right="chevron-right"
            :to="{ name: 'Cities' }"
          >
            {{ t('Dive in') }}
          </DButton>
          <DButton
            v-else
            class="my-4 w-1/2"
            size="lg"
            icon-right="chevron-right"
            :to="{ name: 'City', params: { citySlug: city.name || '' } }"
          >
            {{ t('Dive in') }}
          </DButton>
        </div>
      </slot>
    </template>

    <template v-else>
      <div class="flex h-full flex-col items-center justify-center">
        <DLoader />
      </div>
    </template>
  </div>
</template>
