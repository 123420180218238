<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['update:modelValue'])
const viewType = useVModel(props, 'modelValue', emit)
const { setPref } = usePreferences()

watch(viewType, () => {
  setPref('boards.global.viewType', viewType.value)
})
</script>

<template>
  <div class="hidden md:block">
    <DIconButton
      path="list"
      class="text-grey-500"
      :class="{ active: viewType === 'list' }"
      @click="viewType = 'list'"
    />
    <DIconButton
      path="grid"
      class="text-grey-500"
      :class="{ active: viewType === 'grid' }"
      @click="viewType = 'grid'"
    />
  </div>
</template>
