<script setup>
const emit = defineEmits(['select'])
const { t } = useI18n()
const router = useRouter()

function loginOAuth(provider) {
  emit('select', provider)

  if (LOGIN_DISABLED) {
    router.push({ name: 'Overview' })
  } else {
    window.location.href = `${
      import.meta.env.VITE_CAPTAIN_API
    }/v1/auth/${provider}?from=citydive&returnTo=${
      import.meta.env.VITE_CITY_BASE_URL
    }/login/callback`
  }
}
</script>

<template>
  <div class="flex items-center justify-center space-x-2.5">
    <button
      class="h-12 w-12 rounded-full bg-white p-2.5 shadow-md transition-all hover:bg-blue-200"
      :title="t('Connect with {provider}', { provider: 'Google' })"
      @click="loginOAuth('google')"
    >
      <img
        class="h-full w-full"
        src="@/assets/svg/signin-platforms/google.svg"
        :alt="t('oauth_providers.google')"
      >
    </button>
    <button
      class="h-12 w-12 rounded-full bg-white p-2.5 shadow-md transition-all hover:bg-blue-200"
      :title="t('Connect with {provider}', { provider: 'Microsoft' })"
      @click="loginOAuth('microsoft')"
    >
      <img
        class="h-full w-full"
        src="@/assets/svg/signin-platforms/microsoft.svg"
        :alt="t('oauth_providers.microsoft')"
      >
    </button>
    <button
      class="h-12 w-12 rounded-full bg-white p-2.5 shadow-md transition-all hover:bg-blue-200"
      :title="t('Connect with {provider}', { provider: 'Linkedin' })"
      @click="loginOAuth('linkedin')"
    >
      <img
        class="h-full w-full"
        src="@/assets/svg/signin-platforms/linkedin.svg"
        :alt="t('oauth_providers.linkedin')"
      >
    </button>
    <button
      class="h-12 w-12 rounded-full bg-white p-2.5 shadow-md transition-all hover:bg-blue-200"
      :title="t('Connect with {provider}', { provider: 'Github' })"
      @click="loginOAuth('github')"
    >
      <img
        class="h-full w-full"
        src="@/assets/svg/signin-platforms/github.svg"
        :alt="t('oauth_providers.github')"
      >
    </button>
  </div>
</template>
