<script setup>
// news = {
//   date: String
//   id: Number
//   lang: String
//   title: String
//   url: String
// }

defineProps({
  news: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
</script>

<template>
  <DLink
    :href="news.url"
    target="_blank"
  >
    <div class="flex rounded bg-teal-100 py-2 px-4 hover:bg-teal-200">
      <div class="flex-1">
        <p class="font-bold leading-4 text-blue-500">
          {{ news.title || t('N/A') }}
        </p>
        <NewsfeedDateLang
          class="pt-1 text-xs before:m-1 before:inline-block before:w-2 before:border-t before:border-grey-300"
          :news="news"
        />
      </div>
      <DIcon
        size="sm"
        path="external-link"
        class="pl-4 text-blue-500"
      />
    </div>
  </DLink>
</template>
