<script setup>
defineOptions({
  name: 'ErrorNoDataToShow',
})

const { t } = useI18n()
const { onReset } = useResetFilters()
</script>

<template>
  <p class="text-center text-amber-500 font-bold">
    <DIcon path="warning" /><br>
    {{ t('No data to show with your current settings') }}<br>
    <I18nMd
      class="text-grey-700 font-normal text-sm"
      keypath="try_to_reset_filters"
      tag="small"
    >
      <template #link="{ translation }">
        <DLink @click="() => onReset() ">
          {{ translation }}
        </DLink>
      </template>
    </I18nMd>
  </p>
</template>
