<script setup>
import { zxcvbn } from '@zxcvbn-ts/core'

const props = defineProps({
  password: {
    type: String,
    default: null,
  },
  strengthMeterClass: {
    type: String,
    default: 'Password__strength-meter',
  },
  strengthMeterFillClass: {
    type: String,
    default: 'Password__strength-meter--fill',
  },
})

const emit = defineEmits(['score'])
const { password } = toRefs(props)

const passwordStrength = ref(0)

watchDebounced(
  password,
  async (password) => {
    if (password) {
      const res = await zxcvbn(password)

      if (res) {
        emit('score', res.score)
        set(passwordStrength, res.score)
      } else {
        emit('score', null)
        set(passwordStrength, 0)
      }
    }
  },
  { debouce: 500 },
)
</script>

<template>
  <div class="Password">
    <div :class="[strengthMeterClass]">
      <div
        :class="[strengthMeterFillClass]"
        :data-score="passwordStrength"
      />
    </div>
  </div>
</template>

<style lang="css" scoped>
[v-cloak] {
  display: none;
}
.Password {
  max-width: 400px;
  margin: 0;
}
.Password__strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 10px auto 10px;
  border-radius: 3px;
}
.Password__strength-meter:before,
.Password__strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 5px 0 5px;
  position: absolute;
  width: 20%;
  z-index: 10;
}
.Password__strength-meter:before {
  left: 20%;
}
.Password__strength-meter:after {
  right: 20%;
}
.Password__strength-meter--fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition:
    width 0.5s ease-in-out,
    background 0.25s;
}
.Password__strength-meter--fill[data-score='0'] {
  background: darkred;
  width: 20%;
}
.Password__strength-meter--fill[data-score='1'] {
  background: orangered;
  width: 40%;
}
.Password__strength-meter--fill[data-score='2'] {
  background: orange;
  width: 60%;
}
.Password__strength-meter--fill[data-score='3'] {
  background: yellowgreen;
  width: 80%;
}
.Password__strength-meter--fill[data-score='4'] {
  background: green;
  width: 100%;
}
</style>
