<script setup>
const tabs = ref([])
const activeTab = ref()

provide('tabs', tabs)
provide('activeTab', activeTab)
</script>

<template>
  <div>
    <nav class="mb-4 flex flex-col items-center md:flex-row">
      <DButton
        v-for="tab in tabs"
        :key="tab"
        :pill="false"
        class="flex-1 justify-center font-normal"
        :class="{
          active: activeTab === tab,
        }"
        :variant="activeTab !== tab ? 'fill-secondary' : 'fill-primary'"
        @click="activeTab = tab"
      >
        {{ tab }}
      </DButton>
    </nav>

    <slot />
  </div>
</template>
