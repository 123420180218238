<script setup>
const props = defineProps({
  dateRange: {
    type: Object,
    default: null,
  },
  comparativeRange: {
    type: Object,
    default: null,
  },
  withComparison: Boolean,
  minDate: Date,
  disabledDates: {
    type: Array,
    default: () => [
      /* new Date(2023, 10, 10), new Date(2023, 10, 8) */
    ],
  },
})

const emit = defineEmits([
  'update:dateRange',
  'update:comparativeRange',
  'compare',
])

const { dateRange, comparativeRange } = useVModels(props, emit)

const { t } = useI18n()
const selectedRange = ref(null)
const selectedComparative = ref(0)

const { relativeRanges, relativeComparatives } = useDateRangeFilter()

watchEffect(() => {
  if (dateRange.value) {
    const { start, end } = dateRange.value
    const defaultDateIndex = Object.values(relativeRanges).findIndex(
      ({ period }) => {
        return (
          period.start.getTime() === start.getTime()
          && period.end.getTime() === end.getTime()
        )
      },
    )

    selectedRange.value
      = Number(Object.keys(relativeRanges)[defaultDateIndex]) || null
  }
})

watchEffect(() => {
  if (props.withComparison && comparativeRange.value && dateRange.value) {
    const { start, end } = comparativeRange.value
    const defaultDateIndex = Object.values(relativeComparatives).findIndex(
      ({ period }) => {
        const compare = period(dateRange.value)
        return (
          compare.start.getTime() === start.getTime()
          && compare.end.getTime() === end.getTime()
        )
      },
    )

    selectedComparative.value
      = Number(Object.keys(relativeComparatives)[defaultDateIndex]) || null
  }
})
</script>

<template>
  <div class="date-range-picker">
    <div class="hidden md:block">
      <DatePicker
        v-model="dateRange"
        :disabled-dates="disabledDates"
        :min-date="minDate"
      />

      <div v-if="withComparison && comparativeRange">
        <p class="text-center text-sm">
          {{ t('Compared to') }}
        </p>
        <DatePicker
          v-model="comparativeRange"
          class="comparated-date"
          :disabled-dates="disabledDates"
          :min-date="minDate"
        />
      </div>
    </div>

    <div
      class="flex flex-col space-y-2 border-l border-grey-100 py-4 px-6 text-center"
    >
      <DateRangeForm v-model="dateRange" />

      <DButton
        v-for="(relative, index) in relativeRanges"
        :key="index"
        :class="{ active: selectedRange === index }"
        variant="outline-primary"
        size="sm"
        @click.prevent="
          () => {
            dateRange = relative.period
          }
        "
      >
        {{ relative.name }}
      </DButton>

      <a
        v-if="withComparison"
        class="mb-3 py-2 text-sm font-bold text-blue-500 underline"
        @click="() => emit('compare')"
      >
        {{ comparativeRange ? t('Cancel comparaison') : t('Compare to') }}
      </a>

      <template v-if="withComparison && comparativeRange">
        <DateRangeForm v-model="comparativeRange" />

        <DButton
          v-for="(relative, index) in relativeComparatives"
          :key="index"
          :class="{ active: selectedComparative === index }"
          variant="outline-primary"
          size="sm"
          @click="
            () => {
              comparativeRange = relative.period(dateRange)
            }
          "
        >
          {{ relative.name }}
        </DButton>
      </template>
    </div>
  </div>
</template>

<style lang="pcss">
.date-range-picker {
  .comparated-date.vc-container {
    --blue-200: #f1ebfc;
    --blue-600: #ab89ee;
  }

  @apply flex flex-col md:flex-row bg-white;
}
</style>
