<script setup>
defineOptions({
  name: 'ProviderTPVPerTime',
})

const { t } = useI18n()
const { mode } = useMode()
const filtersStore = useFiltersStore()
const { getColor } = useSharedChartColors()

function formatLineChartLabelWhenCountry(label) {
  const [city, type] = label.split(' - ')

  return type ? [city, t(getVehicleTypeLabel(type, true))] : city
}
</script>

<template>
  <MetricCard
    kpi="providerTPVPerTime"
    :used-filters="[
      'mode',
      'dateRange',
      'timeMode',
      'country',
      'region',
      'filterBySpecs',
    ]"
  >
    <template #title>
      {{ t('Trips per vehicle') }}
    </template>
    <template #subtitle>
      {{ t('Data from unlocked cities') }}
    </template>

    <template #default="{ data, dataUpdateId }">
      <LineMetric
        :data="data"
        :options="{
          dimension: filtersStore.country ? DimensionTypes.UNKNOWN : DimensionTypes.VEHICLETYPE,
          label: d =>
            filtersStore.country
              ? formatLineChartLabelWhenCountry(d.name)
              : d.name,
          scales: {
            y: {
              suggestedMin: 0,
            },
          },
          color: filtersStore.country
            ? d =>
              getColor(
                d.name,
                mode ? dataUpdateId : `providers:${dataUpdateId}`,
              )
            : null,
          colors: filtersStore.country
            ? null
            : getPaletteVehicleTypeBySeries(data?.series),
        }"
      />
    </template>
  </MetricCard>
</template>
