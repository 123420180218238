<script setup lang="ts">
import type { LineMetricOptions, MetricChart } from '@/types/charts.types'

defineOptions({
  name: 'LineMetric',
})

const props = withDefaults(defineProps<LineMetricProps>(), {
  options: () => ({}),
  legend: true,
})

type LineMetricProps = MetricChart<LineMetricOptions>

const filtersStore = useFiltersStore()

const series = computed(() => {
  let colorIndex = 0

  return (
    props.data.series.map((d, dataIndex) => {
      const dataset = {
        ...d,
        dataIndex,
        label: props.options.label ? props.options.label(d) : d.name,
        color:
          (props.options.color && props.options.color(d))
          || getColorFromSerie(d, colorIndex, props.options.colors),
      }

      if (d.unlocked !== false) {
        colorIndex++
      }

      return dataset
    }) || []
  )
})
const { seriesVisible, resetSeriesVisible } = useChartSeriesVisible(series)

const chartData = computed(() => {
  return {
    labels: props.data.labels || [],
    datasets: series.value.map((d) => {
      return {
        label: d.label,
        backgroundColor: d.color,
        borderColor: d.color,
        data: seriesVisible.value.includes(d.name || '')
          ? d.data.map(d =>
            Array.isArray(d) ? { x: d[0], y: d[1], estimated: d[2] } : d,
          )
          : [],
      }
    }),
  }
})

const chartOption = computed(() => {
  const { options } = props
  const timeUnit = options.timeMode || filtersStore.timeMode

  return {
    ...options,
    elements: {
      line: {
        fill: false,
      },
      ...options.elements,
    },
    scales: {
      ...options.scales,
      y: {
        ...options.scales?.y,
      },
      x: {
        grid: { display: false },
        stacked: true,
        type: 'time',
        time: {
          tooltipFormat: getTooltipFormatOfDate(timeUnit),
          isoWeekday: true,
          unit: timeUnit,
        },
        ...options.scales?.x,
      },
    },
  }
})
</script>

<template>
  <div class="flex flex-col">
    <div class="flex-1 overflow-auto">
      <LineChart
        :data="chartData"
        :options="chartOption"
      />
    </div>
    <div
      v-if="legend"
      class="p-3"
    >
      <ChartLegend
        v-model="seriesVisible"
        :dimension="chartOption.dimension"
        :series="series"
        :palette="chartOption.colors"
        @reset="resetSeriesVisible"
      />
    </div>
  </div>
</template>
