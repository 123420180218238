<script setup lang="ts">
import type { LngLatLike, MapLayerMouseEvent } from 'maplibre-gl'
import type { Expression } from '@/types/index.types'

defineOptions({
  name: 'CyclabilityMap',
})

const props = withDefaults(defineProps<CyclabilityMapProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({
    geojson: {
      type: 'FeatureCollection',
      features: [],
    },
  }),
})

interface CyclabilityMapProps {
  zoom: number
  center: LngLatLike
  data: {
    geojson: GeoJSON.FeatureCollection
  }
  legendTitle?: string
  loading?: boolean
}

const { t } = useI18n()
const featuresFlagsStore = useFeaturesFlagsStore()
const { selectedArea, onSelectArea, unselectAllAreas }
  = useSelectedAreaFromUrl()

const geojson = computed(
  () =>
    props.data?.geojson || {
      type: 'FeatureCollection',
      features: [],
    },
)

const { breaks, getColorInRange } = useClassBreaks(geojson)

const paintExpression = computed(() => {
  const breaksRef = get(breaks)

  const lineColor = [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    '#4F74EF',
  ] as Expression

  if (breaksRef && breaksRef.length > 0) {
    lineColor.push([
      'step',
      ['get', 'mean'],
      '#20757C',
    ])
    breaksRef.forEach((value) => {
      lineColor.push(value || 0)
      lineColor.push(getColorInRange(value) || 'transparent')
    })
  } else {
    lineColor.push('#C1CCCD')
  }

  return {
    'line-color': lineColor,
    'line-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.6,
      1,
    ],
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      12,
      8,
    ],
  }
})

function onFeatureClick({ event }: { event: MapLayerMouseEvent }) {
  if (!featuresFlagsStore.get('map_selected_area')) {
    return
  }

  const name = event.features?.[0].properties?.name

  onSelectArea(name)
}

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
  >
    <MapLibrePopup
      :offset="[0, -5]"
      layer-id="city-map"
    >
      <template #element="{ feature }">
        <div class="flex w-28 flex-col whitespace-normal text-xs">
          <p>{{ feature?.properties?.name }}</p>
        </div>
      </template>
    </MapLibrePopup>

    <MapLibreSourceGeojson
      v-if="!loading"
      id="city-segments"
      :layer-props="{
        type: 'line',
        paint: paintExpression,
        layout: {
          'line-cap': 'square', // round | butt | square
          'line-join': 'round', // round | bevel | miter
        },
      }"
      :data="geojson"
      :geojson-options="{
        promoteId: 'name',
      }"
      :selected-features="selectedArea || []"
      @click="onFeatureClick"
    />

    <MapLibreControl
      v-if="selectedArea.length !== 0"
      position="top-right"
      no-style
      class="rounded-full"
    >
      <DButton
        variant="fill-primary"
        icon-left="cross"
        @click="unselectAllAreas"
      >
        {{ t('Unselect all') }}
      </DButton>
    </MapLibreControl>

    <DefineTemplate>
      <DLoader v-if="loading" />
      <MapLegend
        v-else-if="breaks"
        :breaks="breaks"
        :get-color="getColorInRange"
      >
        <template
          v-if="legendTitle"
          #title
        >
          {{ legendTitle }}
        </template>
      </MapLegend>
    </DefineTemplate>

    <SidebarMobileControls>
      <template #default="{ isMobile }">
        <MapLibreControl
          v-if="!isMobile"
          position="bottom-left"
        >
          <ReuseTemplate />
        </MapLibreControl>
      </template>

      <template #mobile>
        <ReuseTemplate />
      </template>
    </SidebarMobileControls>
  </MapLibre>
</template>
