import type { City } from '@/stores/city'
import type { Provider } from '@/stores/provider'

export function useVehicleTypesFromCityOrProvider(city: Ref<City | null>, provider: Ref<Provider | null>) {
  const citiesStore = useCitiesStore()

  const vehicleTypes = computed(() => {
    const cityRef = get(city)
    const providerRef = get(provider)

    if (!cityRef && !providerRef) {
      return null
    }

    if (providerRef) {
      // is provider pages
      return filterVehicleTypes(providerRef?.vehicleTypes || [])
    } else {
      // is city pages
      return filterVehicleTypes(
        (cityRef
        && (citiesStore.citiesByName[cityRef.name]?.vehicleTypes
          ?.map(v => v?.type)
          .filter(type => DATA_VEHICLE_TYPES.includes(type))
          || []))
          || [],
      )
    }
  })

  return {
    vehicleTypes,
  }
}
