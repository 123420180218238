<script setup>
import { endOfDay, startOfDay, subDays } from 'date-fns'

const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
  row: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()
const dates = useVModel(props, 'modelValue', emit)

const startFormated = computed({
  get: () => formatDate(dates.value?.start || new Date(), DATE_FORMAT),
  set: value => onChangeDate(value, 'start'),
})

const endFormated = computed({
  get: () => formatDate(dates.value?.end || new Date(), DATE_FORMAT),
  set: value => onChangeDate(value, 'end'),
})

const min = computed(() => {
  return {
    start: MIN_DATE,
    end: formatDate(dates.value?.start || new Date(), DATE_FORMAT),
  }
})

const max = computed(() => {
  return {
    start: formatDate(dates.value?.end || new Date(), DATE_FORMAT),
    end: formatDate(subDays(now, 1), DATE_FORMAT),
  }
})

function onChangeDate(value, type) {
  const parsed = new Date(value)

  if (Number.isNaN(parsed.getTime())) {
    return
  }

  dates.value = {
    ...dates.value,
    [type]: type === 'start' ? startOfDay(parsed) : endOfDay(parsed),
  }
}

const args = computed(() => ({
  row: props.row,
  inline: !props.row,
}))
</script>

<template>
  <div :class="{ 'space-y-2': row, 'space-x-2': !row }">
    <DField v-bind="args">
      <template #label>
        <span :class="{ 'flex w-8': row }">{{ t('From') }}</span>
      </template>
      <DInputText
        v-model="startFormated"
        :size="row ? 'xs' : 'sm'"
        inline
        type="date"
        :min="min.start"
        :max="max.start"
      >
        <template
          v-if="!row"
          #left
        >
          <DIcon
            path="edit"
            size="sm"
          />
        </template>
      </DInputText>
    </DField>

    <DField v-bind="args">
      <template #label>
        <span :class="{ 'flex w-8': row }">{{ t('To') }}</span>
      </template>
      <DInputText
        v-model="endFormated"
        :size="row ? 'xs' : 'sm'"
        inline
        type="date"
        :min="min.end"
        :max="max.end"
      >
        <template
          v-if="!row"
          #left
        >
          <DIcon
            path="edit"
            size="sm"
          />
        </template>
      </DInputText>
    </DField>
  </div>
</template>
