<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: INDICATOR_VALUE,
  },
})

const emit = defineEmits(['update:modelValue'])
const indicator = useVModel(props, 'modelValue', emit)

const { t } = useI18n()
</script>

<template>
  <DSelectPicker
    id="indicator"
    v-model="indicator"
    :options="[
      { text: t('Value'), value: INDICATOR_VALUE },
      { text: t('Percent'), value: INDICATOR_PERCENT },
    ]"
    :label="t('Display')"
    align="right"
  />
</template>
