<script setup>
const itemOpen = ref()

provide('itemOpen', itemOpen)
</script>

<template>
  <div role="tablist">
    <slot />
  </div>
</template>
