<script setup>
const route = useRoute()
const providerSlug = computed(() => route.params.provider)
</script>

<template>
  <div class="flex h-full flex-col">
    <ProviderFootprintsMap :provider-slug="providerSlug" />
  </div>
</template>
