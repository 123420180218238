<script setup>
const props = defineProps({
  providers: {
    type: Array,
    default: () => [],
  },
  noBadges: Boolean,
})

const sortedProviders = computed(() => {
  return [...props.providers].sort((a, b) => {
    if (a.notMonitored !== b.notMonitored) {
      return a.notMonitored ? 1 : -1
    } else {
      return a.slug > b.slug ? 1 : -1
    }
  })
})
</script>

<template>
  <div class="providers-icons-list flex flex-wrap gap-4">
    <ProviderIconLink
      v-for="provider in sortedProviders"
      :key="provider.slug"
      :slug="provider.slug"
      :show-badge="!noBadges"
      :status="provider.status"
      :not-monitored="provider.notMonitored"
      :estimated="provider.estimated"
      :locked="provider.locked"
    />
  </div>
</template>
