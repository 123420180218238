import { defineStore } from 'pinia'

export const useNavigationStore = defineStore('navigation', {
  state: () => {
    return {
      isOpen: false,
      searchIsOpen: false,
      settingsIsOpen: false,
      sidebarIsMounted: false,
      filtersIsMounted: false,
    }
  },
  actions: {
    toggleSearch() {
      const newValue = !this.searchIsOpen
      this.hideAll()
      this.searchIsOpen = newValue
    },
    toggleSettings() {
      const newValue = !this.settingsIsOpen
      this.hideAll()
      this.settingsIsOpen = newValue
    },
    toggleMenu() {
      const newValue = !this.isOpen
      this.hideAll()
      this.isOpen = newValue
    },
    hideAll() {
      this.isOpen = false
      this.searchIsOpen = false
      this.settingsIsOpen = false
    },
  },
})

// make sure to pass the right store definition, `useNavigationStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavigationStore, import.meta.hot))
}
