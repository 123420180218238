<script setup>
defineOptions({
  name: 'VehiclesTypesSelector',
})

const props = defineProps({
  modelValue: { type: Array, default: () => VEHICLE_TYPES },
})

const emit = defineEmits(['update:modelValue'])
const vehicleTypes = useVModel(props, 'modelValue', emit)

const isMobile = useBreakpoints(breakpointsTailwind).smaller('md')

function toggleVehicleType(toggledType) {
  const vehicleTypesRef = get(vehicleTypes)

  if (vehicleTypesRef.length === VEHICLE_TYPES.length) {
    set(vehicleTypes, [toggledType])
  } else if (
    vehicleTypesRef.length === 1
    && vehicleTypesRef.includes(toggledType)
  ) {
    // reset to all types if only one type is selected
    set(vehicleTypes, VEHICLE_TYPES)
  } else {
    set(
      vehicleTypes,
      vehicleTypesRef.includes(toggledType)
        ? vehicleTypesRef.filter(type => type !== toggledType)
        : [...vehicleTypesRef, toggledType],
    )
  }
}
</script>

<template>
  <div class="space-x-2">
    <VehicleIcon
      v-for="type in VEHICLE_TYPES"
      :key="type"
      :type="type"
      :size="isMobile ? 'lg' : 'md'"
      class="cursor-pointer"
      :disabled="!vehicleTypes.includes(type)"
      @click="() => toggleVehicleType(type)"
    />
  </div>
</template>
