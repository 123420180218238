<script setup>
defineProps({
  freemiumPlan: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['explore', 'upgrade'])

const { t } = useI18n()
</script>

<template>
  <div class="w-full">
    <p class="text-center md:text-sm">
      {{
        t(
          'change_plan_to_unlock_cities_operators_and_more',
          { subscriptionPlan: t('data.plans.premium') },
        )
      }}
    </p>

    <FreemiumOnboardingPlans
      :freemium-plan="freemiumPlan"
      @explore="emit('explore')"
      @upgrade="emit('upgrade')"
    />
  </div>
</template>
