<script setup>
defineOptions({
  name: 'CityTripsWeek',
})

const { t } = useI18n()
const { mode } = useMode()
</script>

<template>
  <MetricCard
    kpi="tripsWeek"
    :variables="{
      vehicleType: ENUM_VEHICLE_TYPES[mode],
    }"
    :used-filters="['mode', 'dateRange', 'filterBySpecs']"
  >
    <template #title>
      {{ t('Trip distribution per day') }}
    </template>

    <template #default="scoped">
      <BarMetric
        :data="{
          ...scoped.data,
          categories: scoped.data?.categories?.map(d => t(d)) || [],
        }"
        :options="{
          scales: {
            y: {
              suggestedMin: 0,
              title: {
                display: true,
                text: t('percent_baseline'),
                padding: 0,
              },
              stacked: false,
            },
          },
          annotation: {
            annotations: [
              {
                drawTime: 'afterDatasetsDraw',
                id: 'vline',
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: '100',
                borderColor: 'rgba(255, 0, 0, 0.8)',
              },
            ],
          },
          color: d => PALETTE_COLORS_WEEK[d.name],
        }"
      />
    </template>
  </MetricCard>
</template>
