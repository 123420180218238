<script setup>
defineProps({
  data: {
    type: Object,
    required: true,
  },
  indicator: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
const { comparativeFormated, dateRangeFormated } = useFiltersStore()

const fields = [
  {
    text: t('Operators', 2),
    value: 'provider',
  },
  {
    text: dateRangeFormated,
    value: 'dateRange',
    sortable: true,
  },
  {
    text: comparativeFormated,
    value: 'comparativeDateRange',
    sortable: true,
  },
]
</script>

<template>
  <DSortingTable
    :fields="fields"
    :rows="
      data
        ?.filter(d => d.provider)
        .map(d => ({
          cols: {
            ...d,
          },
        }))
    "
    :find-value="v => v.slug || v.value"
    sortable
  >
    <template #default="{ field, d }">
      <div
        class="pl-6 pr-2 py-1"
        :class="{ 'text-left': field === 'provider' }"
      >
        <template v-if="field === 'provider'">
          <ProviderIconLink
            inline
            :slug="d.slug"
            :locked="d.locked"
            :status="d.status"
            :not-monitored="d.notMonitored"
            :estimated="d.estimated"
          />
        </template>

        <template v-else>
          <TrendMetric
            :data="d"
            inline
            :is-percent="indicator === INDICATOR_PERCENT"
          />
        </template>
      </div>
    </template>
  </DSortingTable>
</template>
