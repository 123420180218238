import addFreemiumSubscriptionMutation from '@/graphql/mutations/addFreemiumSubscription.gql'

export function useFreemiumAddSubscription(freemiumPlan) {
  const displayError = useDisplayErrors()
  const { loadUser, goToUserMain } = useUserStore()
  const { subscription, organization } = storeToRefs(useUserStore())

  const { mutate: addFreemium, onDone } = useMutation(
    addFreemiumSubscriptionMutation,
    {
      variables: {
        product: PRODUCT,
        organizationId: get(organization)?.id,
      },
      clientId: CAPTAIN,
    },
  )

  onDone(async ({ data }) => {
    if (data.subscription?.id) {
      await loadUser()
      goToUserMain()
    } else {
      displayError()
    }
  })

  async function onFreemium() {
    if (!get(subscription)) {
      try {
        await addFreemium({
          plans: [{ id: get(freemiumPlan).id }],
        })
      } catch (error) {
        displayError(error)
      }
    } else {
      goToUserMain()
    }
  }

  return {
    onFreemium,
  }
}
