<script setup lang="ts">
import type { TrendType } from './Trend.types'
import { TrendTypes } from './Trend.types'
import type { Direction } from '@/types/index.types'

defineOptions({
  name: 'Trend',
})

const props = withDefaults(defineProps<{
  trend: number | null
  direction?: Direction
  type?: TrendType
}>(), {
  trend: null,
  direction: Directions.UP,
  type: TrendTypes.PERCENT,
})

const { t } = useI18n()

const isZero = ref<boolean>(false)
const isMoreThanThousand = ref<boolean>(false)
const trendParsed = ref<number>(0)

watch(() => props.trend, (trend) => {
  if (trend !== null && trend !== 0) {
    isZero.value = false

    if (Math.abs(trend) >= 1000) {
      isMoreThanThousand.value = true
      trendParsed.value = 999

      return
    }

    trendParsed.value = Math.abs(trend)
  } else {
    isZero.value = true
    trendParsed.value = 0
    isMoreThanThousand.value = false
  }
}, { immediate: true })

const isUp = computed(() => props.direction === Directions.UP)
</script>

<template>
  <span
    :class="{
      'text-grey-300': isZero,
      'text-green-500': !isZero && isUp,
      'text-red-500': !isZero && !isUp,
    }"
    :title="isMoreThanThousand ? formatNumber(trend as number) : undefined"
  >
    <template v-if="isMoreThanThousand">&gt;</template>
    <template v-else>{{ isUp ? '+' : '-' }}</template>
    {{ trendParsed }}
    <span
      v-if="type === TrendTypes.POINT"
      class="text-xs"
    >
      {{ t('units.pts') }}
    </span>
    <template v-else-if="type === TrendTypes.PERCENT">%</template>
  </span>
</template>
