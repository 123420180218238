<script setup>
const { ToastPromise } = useToast()

function autoClose(resolve, time = 5000) {
  setTimeout(() => {
    resolve(false)
  }, time)
}
</script>

<template>
  <Teleport to="#teleport-toasts">
    <ToastPromise v-slot="{ resolve, args: [props, options], isResolving }">
      <div
        class="bg-variants rounded-md shadow-sm md:w-80 w-full mb-2"
        :class="[options?.variant || 'default']"
      >
        <div class="p-2">
          <div class="flex">
            <h2
              v-if="props?.title"
              class="flex-1"
            >
              {{ props.title }}
            </h2>
            <DIconButton
              path="cross"
              size="xs"
              icon-size="sm"
              @click="() => resolve(false)"
            />
          </div>
          <p
            v-if="props?.message"
            class="text-sm font-normal text-grey-500"
          >
            {{ props.message }}
          </p>
        </div>
        <div
          v-if="options?.buttons"
          class="border-t border-white/60 px-2 py-1 text-right"
        >
          <DButton
            size="sm"
            :loading="isResolving"
            class="mr-2"
            @click="() => resolve(true)"
          >
            ok
          </DButton>
          <DButton
            size="sm"
            variant="fill-secondary"
            class="mr-2"
            @click="() => resolve(false)"
          >
            cancel
          </DButton>
        </div>
        <div
          v-if="options?.autoClose && autoClose(resolve, options?.autoClose)"
        />
      </div>
    </ToastPromise>
  </Teleport>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
