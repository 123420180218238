<template>
  <div class="mt-6 mb-2 flex min-h-[36px] items-center justify-between">
    <p class="flex-1 font-title text-lg font-light capitalize text-grey-500">
      <slot name="header" />
    </p>

    <p
      class="text-right font-sans text-lg font-normal lowercase leading-4 text-grey-500"
    >
      <slot name="header-right" />
    </p>
  </div>

  <div class="flex flex-col">
    <slot />
  </div>
</template>
