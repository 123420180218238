<script>
const variants = {
  info: {
    icon: 'information',
    classes: 'text-blue-400',
    label: 'information',
  },
  success: {
    icon: 'check',
    classes: 'text-teal-400',
    label: 'success',
  },
  warning: {
    icon: 'warning',
    classes: 'text-amber-400',
    label: 'disclaimer',
  },
  alert: {
    icon: 'cross',
    classes: 'text-red-400',
    label: 'error',
  },
}
</script>

<script setup>
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
  variant: {
    type: String,
    default: 'default',
    validator: value => Object.keys(variants).includes(value),
  },
  title: {
    type: String,
    default: null,
  },
  subtitle: {
    type: String,
    default: null,
  },
  closable: Boolean,
  showLabel: Boolean,
})

const { t } = useI18n()
const { setPref, getPref } = usePreferences()

const isClose = ref(false)
const variantData = computed(() => variants[props.variant])

if (props.id && props.closable) {
  watch(
    () => getPref('alerts'),
    (alerts) => {
      if (alerts?.includes(props.id)) {
        isClose.value = true
      }
    },
    { immediate: true, deep: true },
  )
}

function onClose() {
  if (props.id) {
    const alerts = getPref('alerts')

    if (!alerts?.includes(props.id)) {
      setPref('alerts', [...alerts, props.id])
    }
  } else {
    isClose.value = true
  }
}
</script>

<template>
  <div
    v-if="!isClose"
    class="bg-variants align-center my-4 flex w-full items-center justify-between rounded-xl p-4 font-bold text-white shadow-sm"
    :class="[variant]"
    role="alert"
  >
    <div
      v-if="$slots.left || variant !== 'default'"
      class="-my-2 mr-3 flex"
    >
      <slot name="left">
        <DIcon
          :path="variantData.icon"
          size="lg"
        />
      </slot>
    </div>

    <div class="flex-1 overflow-auto">
      <p
        v-if="variant !== 'default' && showLabel"
        class="text-xs font-bold uppercase"
        :class="variantData.classes"
      >
        {{ t(variantData.label) }}
      </p>
      <p>
        <slot>
          {{ title }}
        </slot>
      </p>
      <p
        v-if="$slots.subtitle || subtitle"
        class="text-sm font-normal text-grey-500"
      >
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </p>
    </div>
    <div
      v-if="closable"
      class="-my-2"
    >
      <DIcon
        path="close"
        class="-mt-2 -mr-4 cursor-pointer"
        :title="id ? t('do not show anymore') : t('close')"
        @click="() => onClose()"
      />
    </div>
  </div>
</template>
