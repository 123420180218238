<script setup>
defineOptions({
  name: 'DSortingTable',
})

const props = defineProps({
  fields: {
    type: Array,
    required: true,
  },
  rows: {
    type: Array,
    required: true,
  },
  total: {
    type: Object,
    default: null,
  },
  findValue: {
    type: Function,
    default: v => v,
  },
  findOpenRows: {
    type: Function,
    default: () => false,
  },
  sortable: Boolean,
  closableRows: Boolean,
  multiDimensional: Boolean,
})

const { rows, fields, sortable, closableRows, multiDimensional, findValue }
  = toRefs(props)

const sortBy = ref(null)
const sortDesc = ref(true)

const options = reactive({
  fields,
  sortBy,
  sortDesc,
  sortable,
  closableRows,
  multiDimensional,
  findValue,
})

provide(PROVIDE_SORTING_TABLE_OPTIONS, options)

const sortedRows = useSorted(rows, (rowA, rowB) => {
  const sortByRef = get(sortBy)

  if (props.sortable && sortByRef) {
    return (
      (props.findValue(rowB.cols[sortByRef])
      - props.findValue(rowA.cols[sortByRef]))
      * (get(sortDesc) ? 1 : -1)
    )
  } else {
    return 0
  }
})

onMounted(() => {
  const field = fields.value.find(f => f.sortable).value
  sortBy.value = field
})

function onSort(field) {
  if (sortBy.value === field) {
    sortDesc.value = !sortDesc.value
  } else {
    sortBy.value = field
    sortDesc.value = true
  }
}

const [TemplateTotal, ReuseTotal] = createReusableTemplate()
</script>

<template>
  <div class="overflow-auto p-0">
    <TemplateTotal>
      <DSortingTableRow
        v-if="total"
        :row="total"
        is-total
      >
        <template #dimension="scope">
          <slot
            name="dimension"
            v-bind="{ ...scope }"
          />
        </template>

        <template #default="scope">
          <slot
            name="default"
            v-bind="{ ...scope }"
          />
        </template>
      </DSortingTableRow>
    </TemplateTotal>

    <table class="w-full text-left text-grey-500">
      <thead class="bg-white text-xs uppercase text-grey-400">
        <tr class="border border-grey-100">
          <th
            v-if="multiDimensional"
            scope="col"
            class="border border-grey-100 py-3 pr-6 pl-12 text-left font-normal"
          >
            {{ fields[0].text }}
          </th>
          <th
            v-for="field in fields.slice(multiDimensional ? 1 : 0)"
            :key="field.value"
            scope="col"
            class="border border-grey-100 font-normal"
          >
            <div
              class="flex items-center whitespace-nowrap p-3 py-2"
              :class="{
                'justify-end': field.sortable,
                'justify-center': !field.sortable,
              }"
            >
              <DHelpTooltip
                v-if="field.help"
                :help="field.help"
              >
                {{ field.text }}
              </DHelpTooltip>
              <span v-else>
                {{ field.text }}
              </span>
              <DIcon
                v-if="sortable && field.sortable"
                class="ml-2 cursor-pointer"
                :class="{ 'text-blue-500': sortBy === field.value }"
                :path="`sort-${
                  sortBy === field.value
                    ? sortDesc
                      ? 'descending'
                      : 'ascending'
                    : 'descending'
                }`"
                size="sm"
                @click="() => onSort(field.value)"
              />
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <ReuseTotal />

        <DSortingTableRow
          v-for="(row, index) in sortedRows"
          :key="index"
          :row="row"
          :even="index % 2 === 0"
          :is-open="sortedRows.length === 1 || findOpenRows(row)"
        >
          <template #dimension="scope">
            <slot
              name="dimension"
              v-bind="{ ...scope }"
            />
          </template>

          <template #default="scope">
            <slot
              name="default"
              v-bind="{ ...scope }"
            />
          </template>
        </DSortingTableRow>
      </tbody>

      <tfoot>
        <ReuseTotal />
      </tfoot>
    </table>
  </div>
</template>
