import { registerSW } from 'virtual:pwa-register'
import { defineStore } from 'pinia'

export const useAppRegisterStore = defineStore('appRegister', () => {
  let register: ServiceWorkerRegistration | null = null
  let swUrl: string | null = null

  const lastUpdate = ref(null)
  const isUpdatingSW = ref(false)
  const needRefresh = ref(false) // If a new version is available and ready to be used
  const apiVersion = ref(null) // The current API version
  const isRefreshing = ref(false)

  const router = useRouter()
  const focused = useWindowFocus()

  const updateSW = registerSW({
    onNeedRefresh() {
      // if refresh is needed and ready on first load
      set(needRefresh, true)
    },
    onRegisteredSW(swScriptUrl, r) {
      if (r) {
        register = r
        swUrl = swScriptUrl
      }
    },
  })

  // Will use the new available assets
  async function doRefresh() {
    if (get(isRefreshing)) {
      return
    }

    set(isRefreshing, true)

    try {
      await updateSW()
    } catch (error) {
      // ignore
    }

    set(needRefresh, false)
    set(isRefreshing, false)
  }

  // Will trigger the fetch of new assets if possible
  async function updateIfPossible() {
    if (get(isUpdatingSW) || !register || !swUrl || register.installing) {
      return
    }

    set(isUpdatingSW, true)

    try {
      const resp = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          'cache': 'no-store',
          'cache-control': 'no-cache',
        },
      })

      if (register.installing || resp?.status !== 200) {
        set(isUpdatingSW, false)
        return
      }
    } catch (error) {
      set(isUpdatingSW, false)
      return
    }

    try {
      set(lastUpdate, Date.now())
      // Trigger the fetch of new assets
      // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
      await register.update()
    } catch (error) {
      // ignore
    }

    set(isUpdatingSW, false)
  }

  // In case the user missed the update and reuse the same tab
  // we will check once per day if a new version is available
  whenever(focused, () => {
    const lastUpdateRef = get(lastUpdate)

    if (!lastUpdateRef || Date.now() - lastUpdateRef > H24_MILLISECONDS) {
      setTimeout(updateIfPossible, 2000)
    }
  })

  // refresh service worker if needed when route change
  router.afterEach(async (to, from) => {
    if (to.name !== from.name && get(needRefresh)) {
      // eslint-disable-next-line no-console
      console.info('New version of the CityDive is available. Reloading...')
      await doRefresh()
    }
  })

  return {
    lastUpdate,
    isUpdatingSW,
    needRefresh,
    apiVersion,
    isRefreshing,

    updateApiVersion: async (newApiVersion: string) => {
      const apiVersionRef = get(apiVersion)

      if (apiVersionRef && apiVersionRef !== newApiVersion) {
        set(needRefresh, true) // why ?
        await nextTick(updateIfPossible)
      }

      set(apiVersion, newApiVersion)
    },
  }
})

// make sure to pass the right store definition, `useAppRegisterStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppRegisterStore, import.meta.hot))
}
