<script setup>
defineOptions({
  name: 'CityFleetsOverview',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())
</script>

<template>
  <MetricCard kpi="cityOverview">
    <template #title>
      {{ t('Shared mobility in {city}', { city: city.nameLocalized }) }}
    </template>
    <template #subtitle="scoped">
      {{ t('AVG per day over last 15 days') }} -
      {{
        t(
          'data from {n} operators',
          { n: scoped.data?.providersCount ?? 0 },
          scoped.data?.providersCount ?? 0,
        )
      }}
    </template>

    <template #default="scoped">
      <div class="flex flex-wrap justify-between gap-4">
        <TrendMetricWithVehiclesTypes
          class="text-teal-700"
          :label="t('Vehicles', scoped.data?.vehicles.TOTAL)"
          :data="{ value: scoped.data?.vehicles.TOTAL }"
          :vehicles-types="scoped.data?.vehicles"
        />
        <TrendMetricWithVehiclesTypes
          class="text-teal-700"
          :label="t('Trips')"
          :data="{ value: scoped.data?.trips.TOTAL }"
          :vehicles-types="scoped.data?.trips"
        />
        <TrendMetricWithVehiclesTypes
          class="text-teal-700"
          :label="t('TVD')"
          :help="t('Trips per vehicle per day')"
          :data="{ value: scoped.data?.tvd.TOTAL }"
          :vehicles-types="scoped.data?.tvd"
        >
          <template #default="{ formated, value }">
            {{ value === null ? '-' : formated }}
          </template>
          <template #typeValue="{ value }">
            {{ formatAverage(value) }}
          </template>
        </TrendMetricWithVehiclesTypes>
      </div>
    </template>
  </MetricCard>
</template>
