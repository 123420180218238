<script setup>
import newsfeedWidgetQuery from '@/graphql/queries/newsfeedWidget.gql'

const props = defineProps({
  /**
   * @type {City}
   */
  city: {
    default: null,
    validator: (v) => {
      if (typeof v === 'object') {
        let valid = true

        if (!isDefined(v.nameLocalized)) {
          console.warn('City is missing nameLocalized', v)
          valid = false
        }

        if (!isDefined(v.wikidataId)) {
          console.warn('City is missing wikidataId', v)
          valid = false
        }

        return valid
      } else {
        return v === null
      }
    },
  },
  /**
   * @type {Provider}
   */
  provider: {
    default: null,
    validator: (v) => {
      if (typeof v === 'object') {
        let valid = true

        if (!isDefined(v.slug)) {
          console.warn('Provider is missing slug', v)
          valid = false
        }

        return valid
      } else {
        return v === null
      }
    },
  },
  modes: {
    type: Array, // Array of strings
    default: null,
  },
})

const { t } = useI18n()
const { isFreemium } = storeToRefs(useUserStore())
const { providerNameBySlug } = useProviderName()

const variables = computed(() => ({
  page: 1,
  perPage: 4,
  ...(props.modes
    ? { anyVehicleTypes: props.modes?.map(type => ENUM_VEHICLE_TYPES[type]) }
    : {}),
  ...(props.city ? { allCities: [props.city?.wikidataId] } : {}),
  ...(props.provider ? { anyProviders: [props.provider?.slug] } : {}),
}))
const { result, loading } = useQuery(newsfeedWidgetQuery, variables)

const news = computed(() => result.value?.newslinks?.newslinks || [])

const targetName = computed(() => {
  if (props.city) {
    return props.city?.nameLocalized
  } else if (props.provider) {
    return providerNameBySlug(props.provider?.slug)
  } else {
    return t('shared mobility')
  }
})
</script>

<template>
  <BoardCard>
    <template #head>
      <p>
        {{ t('Latest news') }}
      </p>
      <FiltersIcons
        class="mt-2 gap-2 flex"
        :filters="['mode', 'region']"
      />
    </template>
    <template
      v-if="news.length !== 0"
      #head-right
    >
      <RouterLink
        :to="{
          name: city
            ? 'CityNewsfeed'
            : provider
              ? 'ProviderNewsfeed'
              : 'Newsfeed',
        }"
        class="text-sm font-bold text-blue-500"
      >
        {{ t('More news about {target}', { target: targetName }) }}
        <DIcon
          path="chevron-right"
          size="xs"
        />
      </RouterLink>
    </template>

    <div class="flex-1">
      <DLoader v-if="loading" />

      <template v-else-if="news.length">
        <div class="flex flex-col space-y-4">
          <NewsfeedWidgetItem
            v-for="item in news"
            :key="item.id"
            :news="item"
          />
        </div>
      </template>

      <div
        v-else
        class="flex-center my-8 flex flex-1 flex-col text-center"
      >
        <p class="text-xl font-bold uppercase text-grey-400">
          {{ t('No news about {target} yet', { target: targetName }) }}
        </p>
        <p class="px-12 text-sm text-grey-400">
          {{ t('Please wait, until we find, specific news.') }}<br>
          <I18nMd
            keypath="dont_hesitate_to_discover_latest_news"
            tag="span"
          >
            <template #link="{ translation }">
              <DTooltip
                v-if="isFreemium"
                :title="t('upgrade_to_unlock')"
              >
                <DLink
                  :to="null"
                  disabled
                  class="underline"
                >
                  {{ translation }}
                </DLink>
              </DTooltip>
              <DLink
                v-else
                :to="{ name: 'Newsfeed' }"
                class="text-blue-500 underline"
              >
                {{ translation }}
              </DLink>
            </template>
          </I18nMd>
        </p>
      </div>
    </div>
  </BoardCard>
</template>
