<script setup>
const { t } = useI18n()
</script>

<template>
  <div>
    <img src="@/assets/svg/onboarding-backpacker-title.svg">
    <p class="w-80 p-3 text-center md:text-sm text-grey-400">
      {{ t('freemium_is_free_users') }}
    </p>
  </div>
</template>
