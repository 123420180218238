<script setup>
const props = defineProps({
  row: {
    type: Object,
    required: true,
  },
  isTotal: Boolean,
  isOpen: Boolean,
  even: Boolean,
})

const options = inject(PROVIDE_SORTING_TABLE_OPTIONS, {
  fields: [],
  sortBy: null,
  sortDesc: true,
  sortable: false,
  closableRows: false,
  multiDimensional: false,
  findValue: v => v,
})

const showChild = ref(!options.closableRows || props.isOpen)
const rows = computed(() => props.row?.rows || [])
const cols = computed(() => {
  if (!Array.isArray(props.row.cols)) {
    return options.fields
      .filter(f => f.value)
      .reduce((acc, { value }) => {
        acc.push({
          col: props.row.cols?.[value],
          field: value,
        })

        return acc
      }, [])
  }

  return props.row.cols.map((c, index) => ({
    col: c,
    field: index,
  }))
})

const sortedRows = useSorted(rows, (rowA, rowB) => {
  const sortByRef = options.sortBy
  if (options.sortable && sortByRef) {
    return (
      (options.findValue(rowB.cols[sortByRef])
      - options.findValue(rowA.cols[sortByRef]))
      * (options.sortDesc ? 1 : -1)
    )
  } else {
    return 0
  }
})

watch(
  () => props.isOpen,
  () => {
    set(showChild, props.isOpen)
  },
)

function onShowChild() {
  if (options.closableRows && get(rows).length) {
    showChild.value = !showChild.value
  }
}
</script>

<template>
  <tr
    class="hover:bg-blue-100"
    :class="{
      'bg-grey-50': even,
      'text-grey-300': isTotal,
      'cursor-pointer': options.closableRows && rows.length,
    }"
    @click="onShowChild"
  >
    <th
      v-if="options.multiDimensional"
      scope="row"
      class="whitespace-nowrap border border-grey-100 py-1 align-middle font-medium"
      :class="[
        options.closableRows || $slots.dimension ? 'p-0' : 'p-2',
        {
          'uppercase': row.rows || isTotal,
          '!font-bold': isTotal,
        },
      ]"
    >
      <div
        v-if="options.closableRows"
        class="flex items-center"
      >
        <DIcon
          v-if="rows.length"
          class="mx-2 text-blue-500"
          :path="`chevron-thin-${showChild ? 'up' : 'down'}`"
        />
        <div :class="{ 'pl-14': !rows.length }">
          <slot
            name="dimension"
            :label="row.dimension"
            :d="row"
          >
            {{ row.dimension }}
          </slot>
        </div>
      </div>

      <slot
        v-else
        name="dimension"
        :label="row.dimension"
        :d="row"
      >
        {{ row.dimension }}
      </slot>
    </th>
    <template
      v-for="{ col, field } in cols"
      :key="col"
    >
      <td
        v-if="col"
        class="border border-grey-100 text-right align-middle"
        :class="{
          'p-0': $slots.default,
          'p-2': !$slots.default,
        }"
        v-bind="col.attrs || null"
      >
        <slot
          :row-data="row.data"
          :d="col"
          :field="field"
          :is-total="isTotal"
        >
          {{ formatNumber(col) }}
        </slot>
      </td>
      <td v-else />
    </template>
  </tr>

  <template v-if="row.rows && showChild">
    <DSortingTableRow
      v-for="subRow in sortedRows"
      :key="subRow"
      :row="subRow"
      :even="even"
    >
      <template #dimension="scope">
        <slot
          name="dimension"
          v-bind="{ ...scope }"
        />
      </template>

      <template #default="scope">
        <slot
          name="default"
          v-bind="{ ...scope }"
        />
      </template>
    </DSortingTableRow>
  </template>
</template>
