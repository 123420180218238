<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  region: {
    type: String,
    default: REGION_CODE_EU,
    validator: v => REGIONS_CODES.includes(v),
  },
})

const { t } = useI18n()
const { translateCountry } = useLabelTranslation()

const regionData = computed(() => {
  const { region } = props
  return REGIONS.find(r => r.code === region)
})

const geojson = computed(() => {
  return {
    type: 'FeatureCollection',
    features:
      props.data?.map((country) => {
        return {
          type: 'Feature',
          properties: {
            ...country,
          },
          geometry: {
            ...country.geojson,
          },
        }
      }) || [],
  }
})
</script>

<template>
  <MapLibre
    :zoom="2"
    :center="regionData.center"
    :min-zoom="2"
    :max-zoom="2"
  >
    <MapLibrePopup
      :offset="[0, -5]"
      layer-id="countries"
    >
      <template #element="{ feature }">
        <span class="text-bold">{{
          translateCountry(feature?.properties?.countryCode)
        }}</span>
        {{
          t('with {count} cities', feature?.properties?.cities, {
            count: feature?.properties?.cities,
          })
        }}
      </template>

      <MapLibreSourceGeojson
        id="countries"
        :layer-props="{
          type: 'fill',
          paint: {
            'fill-color': options.color || '#20757C',
            'fill-outline-color': '#fff',
          },
        }"
        :data="geojson"
        :geojson-options="{
          promoteId: 'countryCode',
        }"
      />
    </MapLibrePopup>
  </MapLibre>
</template>
