export const DEFAULT_CHART_OPTIONS = {
  responsive: true,
  locale: 'en',
  maintainAspectRatio: false,
  animation: {
    duration: 300,
  },
  animations: {
    x: {
      duration: 0,
    },
    barPercentage: {
      duration: 0,
    },
    categoryPercentage: {
      duration: 0,
    },
  },
}
