<script setup>
defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  options: {
    type: Array,
    default: () => [],
  },
  size: {
    type: String,
    default: 'md',
    validation: value => ['xs', 'sm', 'md'].includes(value),
  },
  state: {
    type: Boolean,
    default: null,
  },
})

const emit = defineEmits(['update:modelValue'])
const attrs = useAttrs()
const label = inject(PROVIDE_UI_FIELD_LABEL, '')
</script>

<template>
  <select
    v-bind="attrs"
    :value="modelValue"
    class="d-select block w-full rounded-md border bg-white text-grey-500 placeholder:text-grey-300 focus:outline-none focus:ring-2 disabled:cursor-not-allowed"
    :class="{
      'py-1/2 pl-1 text-sm': size === 'xs',
      'py-2 pl-2 text-sm': size === 'sm',
      'py-2.5 pl-4 text-base': size === 'md',
      'border-grey-100 focus:border-grey-100 focus:ring-blue-200':
        state === null,
      'border-green-500 focus:border-green-500 focus:ring-green-200':
        state === true,
      'border-red-500 focus:border-red-500 focus:ring-red-200': state === false,
    }"
    :aria-label="label"
    @input="e => emit('update:modelValue', e.target.value)"
  >
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
    >
      {{ option.text }}
    </option>
  </select>
</template>
