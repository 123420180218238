<script setup>
defineProps({
  info: {
    type: Object,
    default: null,
  },
})

const { t } = useI18n()

const tableTitles = {
  area: {
    title: t('area'),
    format: value => `${formatNumber(value)}${t('km²')}`,
  },
  pop: {
    title: t('population'),
    format: formatNumber,
  },
  density_pop: {
    title: t('density'),
    format: value => `${formatNumber(value)}${t('/km²')}`,
  },
}
</script>

<template>
  <div
    v-if="info"
    class="z-hop mx-8 -mt-8 flex flex-col justify-around space-x-4 rounded-md bg-teal-100 p-4 shadow-md md:flex-row"
  >
    <p
      v-for="row in info"
      :key="row.titleKey"
      class="py-1 text-center text-lg"
    >
      <span class="text-sm capitalize text-grey-500">
        {{ tableTitles[row.titleKey]?.title || 'N/D' }} </span><br>
      <span class="gradient bg-clip-text font-bold text-transparent">
        {{ tableTitles[row.titleKey]?.format(row.value) || row.value }}
      </span>
    </p>
  </div>
</template>
