<script setup>
import { providerStatusProps } from '@/components/Provider/ProviderStatus.vue'

const props = defineProps({
  ...providerStatusProps,
  locked: Boolean,
})

const { providerNameBySlug } = useProviderName()
const providersStore = useProvidersStore()

const isLocked = computed(
  () =>
    (providersStore.isProviderLocked(props.slug) && !isDefined(props.locked))
    || props.locked,
)
</script>

<template>
  <ProviderLink
    :provider="{
      unlocked: !isLocked,
      notMonitored,
      slug,
    }"
    class="group relative inline-block"
  >
    <ProviderStatus v-bind="props">
      <ProviderIcon
        :slug="slug"
        :not-monitored="notMonitored"
        :class="{
          'mr-2 inline-block': inline,
          'group-hover:opacity-50 group-hover:transition-colors': isLocked,
          'relative': !inline,
        }"
        :size="inline ? 'xs' : 'md'"
      />

      <span
        v-if="inline"
        :class="{
          'group-hover:opacity-50 group-hover:transition-colors': isLocked,
        }"
      >
        {{ providerNameBySlug(slug) }}
      </span>

      <div
        v-if="isLocked"
        class="!absolute top-0 left-0 hidden h-full w-full group-hover:flex group-hover:items-center group-hover:justify-center"
      >
        <div class="locked-icon text-grey-700">
          <DIcon
            path="cadenas"
            size="sm"
            class=""
            style="paint-order: stroke"
          />
        </div>
      </div>
    </ProviderStatus>
  </ProviderLink>
</template>

<style lang="pcss">
.locked-icon svg {
  filter: drop-shadow(-3px 3px 4px rgba(0, 0, 0, 0.1));

  path {
    stroke: white;
    stroke-width: 3px;
    paint-order: stroke;
    fill-rule: evenodd !important;
  }
}
</style>
