<script setup>
defineOptions({
  name: 'CityMetricsSummary',
})

const { t } = useI18n()
</script>

<template>
  <MetricCard
    kpi="cityMetricsSummary"
    no-padding
    :used-filters="['mode', 'filterBySpecs']"
  >
    <template #title>
      {{ t('Market performance') }}
    </template>
    <template #subtitle>
      {{ t('AVG per day over last 30 days') }}
    </template>

    <template #default="scoped">
      <TableProvidersMetric :data="scoped.data" />
    </template>
  </MetricCard>
</template>
