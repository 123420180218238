<script>
export const providerStatusProps = {
  slug: {
    type: String,
    required: true,
  },
  notMonitored: Boolean,
  estimated: Boolean,
  inline: Boolean,
  status: {
    type: Object,
    default: () => null,
  },
  showBadge: {
    type: Boolean,
    default: true,
  },
}
</script>

<script setup>
const props = defineProps(providerStatusProps)

const { t } = useI18n()
const { providerNameBySlug } = useProviderName()

const isActive = computed(() => {
  return props.status && props.status.code === PROVIDERS_STATUS.ACTIVE
})

const isWarning = computed(() => {
  return props.status && props.status.code !== PROVIDERS_STATUS.ACTIVE
})
</script>

<template>
  <DTooltip
    class="relative"
    :class="{
      'flex items-center': inline,
      'block': !inline,
    }"
  >
    <slot />

    <template v-if="showBadge">
      <div
        v-if="isWarning"
        class="pointer-events-none"
        :class="{
          'relative ml-1.5 inline-block': inline,
          'absolute -top-2.5 -right-2.5': !inline,
        }"
      >
        <DIcon
          name="warning-colored"
          size="sm"
          class="drop-shadow-sm"
        />
      </div>
      <div
        v-else-if="notMonitored || estimated || isActive"
        class="d-badge pointer-events-none h-3.5 w-3.5 rounded-full border-2 border-white"
        :class="[
          {
            'relative ml-1.5 inline-block': inline,
            'absolute -top-1.5 -right-1.5': !inline,
            'bg-green-500': isActive,
            '!bg-grey-200': notMonitored,
            '!bg-blue-200': estimated,
          },
        ]"
      />
    </template>

    <template
      v-if="(!notMonitored && !estimated && !status) || !showBadge"
      #title
    >
      {{ providerNameBySlug(slug) }}
    </template>

    <template
      v-else
      #content
    >
      <p class="flex flex-col text-sm font-bold">
        <span class="w-24">
          {{ providerNameBySlug(slug) }}
        </span>

        <span
          v-if="notMonitored || estimated || isActive"
          class="text-xs font-normal capitalize text-grey-300"
        >
          {{
            t(
              estimated || notMonitored
                ? estimated
                  ? 'estimated'
                  : 'unmonitored'
                : 'monitored',
            )
          }}
        </span>

        <template v-if="isWarning">
          <span class="w-32 text-xs font-normal text-grey-300">----</span>
          <span class="font-normal uppercase text-amber-500">{{
            t('disclaimer')
          }}</span>
          <span class="font-bold">{{ t(`Has ${status.code}`) }}</span>
          <span v-if="status.since">
            {{
              t('since {date}', { date: formatDate(status.since, DATE_FORMAT) })
            }}
          </span>
          <span v-if="status.detail">
            {{ t('due to {detail}', { detail: status.detail }) }}
          </span>
        </template>
      </p>
    </template>
  </DTooltip>
</template>
