<script setup lang="ts">
import type { VehicleTypeObject } from '@/utils/vehicleTypes'
import type { VehicleType, VehicleTypeData } from '@/types/vehicleTypes.types'

defineOptions({
  name: 'VehiclesTypesList',
})

const props = withDefaults(defineProps<{
  vehicleTypes: VehicleTypeObject[] | VehicleTypeData[] | null
  selected?: VehicleType[]
  fixedPlaces?: boolean
}>(), {
  selected: () => VEHICLE_TYPES,
})

const { t } = useI18n()

const vehicleTypesName = computed(() => {
  const types = sortAndFilterVehicleTypes(props.vehicleTypes || []).map(d => getVehicleType(d))

  return VEHICLE_TYPES.reduce((acc: (VehicleType | false)[], vehicleType: VehicleType) => {
    if (types.includes(vehicleType)) {
      acc.push(vehicleType)
    } else if (props.fixedPlaces) {
      acc.push(false)
    }

    return acc
  }, [])
})
</script>

<template>
  <div class="w-36 space-x-2">
    <p
      v-if="vehicleTypesName.length === 0"
      class="m-4 text-xs text-grey-500"
    >
      {{ t('Vehicles modes not found') }}
    </p>

    <div
      v-for="(type, index) in vehicleTypesName"
      :key="`${type}_${index}`"
      class="inline-block w-6"
    >
      <VehicleIcon
        v-if="type"
        :type="type"
        :disabled="!selected.includes(type)"
      />
    </div>
  </div>
</template>
