<script setup>
const props = defineProps({
  draft: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
const { loadUser } = useUserStore()
const { confirmSubscription, cancelSubscription } = useSubscription()
const displayError = useDisplayErrors()

const stripe = inject(PROVIDE_STRIPE)
const stripeLoaded = inject(PROVIDE_STRIPE_LOADED, false)
const openPaymentMethodModal = ref(false)

const currentPlan = computed(() => {
  return props.draft.plans.find(p => p.isOption === false) || {}
})

async function pay() {
  const clientSecret = props.draft.paymentSecret

  if (clientSecret) {
    try {
      let res

      if (props.draft.paymentType === 'sepa_debit') {
        res = await stripe.confirmSepaDebitPayment(clientSecret, {
          setup_future_usage: 'off_session',
        })
      } else {
        res = await stripe.confirmCardPayment(clientSecret, {
          setup_future_usage: 'off_session',
        })
      }

      const stripeError = res.error
      if (stripeError) {
        throw stripeError
      }

      const paymentIntent = res.paymentIntent

      if (paymentIntent && paymentIntent.status === 'succeeded') {
        await confirmSubscription({ subscriptionId: props.draft.id })
      }
    } catch (error) {
      if (error.code === 'payment_intent_incompatible_payment_method') {
        openPaymentMethodModal.value = true
        return
      }

      displayError(error)
    }

    loadUser()
  } else {
    openPaymentMethodModal.value = true
  }
}
</script>

<template>
  <DCard>
    <template #title>
      {{ `${t('Current plan')} - ${t('Payment needed')}` }}
    </template>

    <div class="mb-3 mt-3 text-center">
      {{
        [4, 5].includes(currentPlan.id)
          ? t('City Dweller')
          : [6, 7].includes(currentPlan.id)
            ? t('data.plans.premium')
            : currentPlan.name
      }}
    </div>

    <div class="mb-3 text-center">
      <DButton
        :loading="!stripeLoaded"
        @click="pay"
      >
        {{ t('Pay my plan') }}
        <template v-if="draft.draftPrice?.total">
          {{ formatMoney(draft.draftPrice.total, { cents: true }) }}
          <template v-if="draft.draftPrice.interval">
            <template v-if="draft.draftPrice.intervalCount === 1">
              ({{ t(`subscription.interval1.${draft.draftPrice.interval}`) }})
            </template>
            <template v-else>
              ({{
                t(`subscription.intervalN.${draft.draftPrice.interval}`, {
                  interval: draft.draftPrice.intervalCount,
                })
              }})
            </template>
          </template>
        </template>
      </DButton>
      &nbsp;
      <DButton @click="cancelSubscription({ subscriptionId: props.draft.id })">
        {{ t('Cancel my subscription') }}
      </DButton>
    </div>
  </DCard>

  <PaymentMethodModal
    v-if="openPaymentMethodModal"
    :subscription="draft"
    @close="openPaymentMethodModal = false"
    @updated="openPaymentMethodModal = false"
  />
</template>
