import {
  addDays,
  areIntervalsOverlapping,
  endOfDay,
  startOfDay,
  subDays,
} from 'date-fns'
import type { City } from '@/stores/city'
import type { Provider } from '@/stores/provider'
import type { VehicleTypeData } from '@/types/vehicleTypes.types'

export interface PeriodInterval {
  start: Date
  end: Date
  withTrips?: boolean
}

export function useVehicleTypesPeriods(target: Ref<Provider | City | null>) {
  const { mode } = useMode()

  const periods = computed((): PeriodInterval[] => {
    const targetRef = get(target)
    const modeRef = get(mode)

    if (!targetRef?.vehicleTypesPeriods) {
      return []
    }

    const vehicleTypesPeriods = toRaw(targetRef.vehicleTypesPeriods) || {}
    const result = []

    if (modeRef) {
      result.push(...(vehicleTypesPeriods[ENUM_VEHICLE_TYPES[modeRef] as VehicleTypeData] || []))
    } else {
      result.push(
        ...Object.values(vehicleTypesPeriods).reduce((acc: PeriodInterval[], periods) => {
          if (Array.isArray(periods)) {
            return [...acc, ...periods]
          }

          return acc
        }, []),
      )
    }

    return result
      .map(p => ({
        start: startOfDay(new Date(p.start)),
        end: endOfDay(new Date(p.end)),
        withTrips: p.withTrips || false,
      }))
      .sort((a, b) => {
        return a.start.getTime() - b.start.getTime()
      })
      .reduce((acc: PeriodInterval[], interval) => {
        const lastInterval = acc[acc.length - 1]

        if (lastInterval && areIntervalsOverlapping(lastInterval, interval)) {
          if (lastInterval.end.getTime() < interval.end.getTime()) {
            lastInterval.end = interval.end
          }
        } else {
          acc.push(interval)
        }

        return acc
      }, [])
  })

  const periodsEmpty = computed((): PeriodInterval[] => {
    const periodsRef = get(periods)

    return periodsRef.reduce((acc: PeriodInterval[], interval, index) => {
      if (index < periodsRef.length - 1) {
        acc.push({
          start: startOfDay(addDays(interval.end, 1)),
          end: endOfDay(subDays(periodsRef[index + 1]?.start || new Date(), 1)),
        })
      } else {
        const lastInterval = acc.slice().pop() || interval

        if (lastInterval?.end !== now) {
          acc.push({
            start: startOfDay(addDays(interval.end, 1)),
            end: endOfDay(now),
          })
        }
      }

      return acc
    }, [])
  })

  return {
    periods,
    periodsEmpty,
  }
}
