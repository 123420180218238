<script setup>
import { onMounted } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  default: Boolean,
})

const tabs = inject('tabs', null)
const activeTab = inject('activeTab', null)

onMounted(() => {
  tabs.value.push(props.title)

  if (props.default) {
    activeTab.value = props.title
  }
})
</script>

<template>
  <div v-if="activeTab === title">
    <slot />
  </div>
</template>
