<script setup>
defineOptions({
  name: 'CityProvidersVehicleTypeOverview',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())

const { mode } = useMode()
</script>

<template>
  <MetricCard kpi="cityProviderVehicleTypeOverview">
    <template #title>
      {{
        t(`mode_services_in_city.${mode}`, {
          city: city.nameLocalized,
        })
      }}
    </template>
    <template #subtitle="scoped">
      {{ t('AVG Last 15 days') }} -
      {{
        t(
          'data from {n} operators',
          { n: scoped.data?.providers.length },
          scoped.data?.providers.length,
        )
      }}
    </template>

    <template #default="scoped">
      <DTable
        no-border
        no-padding
        class="text-sm"
      >
        <thead>
          <tr>
            <th class="pb-1">
              {{ t('operator(s)', scoped.data?.providers.length) }}
            </th>
            <th class="pb-1">
              {{ t('vehicles') }}
            </th>
            <th class="pb-1">
              {{ t('trips') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="{ provider, vehicles, trips } in scoped.data?.providers"
            :key="provider.slug"
          >
            <td class="py-1">
              <ProviderIconLink
                :slug="provider.slug"
                :status="provider.status"
                :estimated="provider.estimated"
                :not-monitored="provider.notMonitored"
                :locked="provider.locked"
                inline
              />
            </td>
            <td class="py-1">
              {{ vehicles !== null ? formatNumber(vehicles) : t('N/A') }}
            </td>
            <td class="py-1">
              {{ trips !== null ? formatNumber(trips) : t('N/A') }}
            </td>
          </tr>
        </tbody>
      </DTable>
    </template>
  </MetricCard>
</template>
