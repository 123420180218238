<script setup>
defineOptions({
  name: 'CityMapSettings',
})

const props = defineProps({
  kpi: {
    type: String,
    default: null,
  },
  display: {
    type: String,
    default: null,
  },
  geometryType: {
    type: String,
    default: 'hm-3',
  },
  dataScale: {
    type: Number,
    default: 5,
  },
  percent: {
    type: Number,
    default: null,
  },
  providers: {
    type: Array,
    default: () => [],
  },
  geometriesOptions: {
    type: Array,
    default: () => [],
  },
  displayOptions: {
    type: Array,
    default: () => [],
  },
  providersList: {
    type: Array,
    default: () => null,
  },
  geometriesLoading: Boolean,
})

const emit = defineEmits([
  'close',
  'update:display',
  'update:geometryType',
  'update:percent',
  'update:dataScale',
  'update:providers',
])
const { display, geometryType, percent, dataScale } = useVModels(props, emit)

const { t } = useI18n()
const gtm = useGtm()

const { providerNameBySlug } = useProviderName()

const providers = computed({
  set: (values) => {
    emit('update:providers', values)
  },
  get: () => {
    return props.providers.length === 0
      ? props.providersList?.map(p => p.name) || []
      : props.providers
  },
})

function sendGAEvent(action, category, label, value) {
  if (gtm?.enabled()) {
    gtm.trackEvent({
      event: action,
      category,
      action: 'click',
      label,
      value,
    })
  }
}
</script>

<template>
  <form
    class="flex flex-col gap-2"
    @submit.prevent
  >
    <DSelectPicker
      v-if="displayOptions.length"
      v-model="display"
      :options="displayOptions"
      :label="t('Display')"
      icon="target"
      @change="sendGAEvent('update_display', 'data', kpi)"
    />

    <DSelectPicker
      v-model="geometryType"
      icon="hexagones"
      :options="geometriesOptions"
      :label="t('Statistical area')"
      :is-loading="geometriesLoading"
      @change="sendGAEvent('update_geometry', 'data', kpi)"
    />

    <DField>
      <template #label>
        {{ t('Data scale') }}
      </template>
      <template #label-right>
        <span class="text-blue-500">{{ dataScale }}</span>
      </template>
      <DInputRange
        v-model="dataScale"
        :min="3"
        :max="7"
        @change="sendGAEvent('update_class', 'data', kpi, dataScale)"
      />
    </DField>

    <DField v-if="percent !== null">
      <template #label>
        {{ t('Percentage data extract') }}
      </template>
      <template #label-right>
        <span class="text-blue-500">{{ formatPercent(percent) }}</span>
      </template>
      <DInputRange
        v-model="percent"
        :min="5"
        :max="30"
        :step="5"
        @change="sendGAEvent('update_percent_data', 'data', kpi)"
      />
    </DField>

    <DField v-if="providersList">
      <template #label>
        {{ t('Operators', providersList.length) }}
      </template>

      <div
        v-if="providersList.length > 0"
        class="my-2 flex flex-col gap-2"
      >
        <DInputCheckbox
          v-for="provider in providersList"
          :key="provider"
          v-model="providers"
          :value="provider.name"
        >
          {{ providerNameBySlug(provider.name) }}
          <ProviderSource
            v-if="provider.source"
            :source="provider.source"
          />
        </DInputCheckbox>
      </div>

      <div v-else>
        {{ t('no providers available') }}
      </div>
    </DField>
  </form>
</template>

<style lang="pcss" scoped>
.d-field {
  @apply bg-grey-50 hover:bg-blue-100 text-slate-500 rounded-md py-1 px-2 !mb-0;
}
</style>
