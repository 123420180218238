<script setup>
const { t } = useI18n()
const route = useRoute()
const filtersStore = useFiltersStore()
const { userRegions } = useFilterRegion()
const { isFreemium } = storeToRefs(useUserStore())
const providerStore = useProviderStore()
const { provider, isLoading } = storeToRefs(providerStore)

// set filters from user preferences
useFiltersFromPreferences('providers')

// Generate the side menu for the provider
const menu = computed(() => {
  const providerRef = get(provider)
  const isNotDemo = providerRef?.slug !== DEMO
  const canBeDisable = !providerRef?.availableInCD && t('Not available yet')
  const canBeLocked = isNotDemo && providerRef?.locked && t('upgrade_to_unlock')

  return [
    {
      label: t('Overview'),
      route:
        (!canBeDisable && !canBeLocked && { name: 'ProviderOverview' }) || null,
      disabled: canBeDisable,
      locked: canBeLocked,
    },
    {
      label: t('Fleets & Trips'),
      disabled: canBeDisable,
      locked: canBeLocked,
      subMenu: [
        {
          route: { name: 'ProviderFleetsTrips', hash: '#at-a-glance' },
          label: t('At a glance'),
          isActive: route.hash === '#at-a-glance',
        },
        {
          route: { name: 'ProviderFleetsTrips', hash: '#fleets' },
          label: t('Fleets'),
          isActive: route.hash === '#fleets',
        },
        {
          route: { name: 'ProviderFleetsTrips', hash: '#trips' },
          label: t('Trips'),
          isActive: route.hash === '#trips',
        },
      ],
    },
    {
      label: t('Footprint'),
      route: { name: 'ProviderFootprint' },
    },
    {
      label: t('Newsfeed'),
      route: (!get(isFreemium) && { name: 'ProviderNewsfeed' }) || null,
      locked: get(isFreemium) && t('upgrade_to_unlock'),
    },
  ]
})

// watch the provider data to update the filters
watch([provider, userRegions], ([provider, userRegions]) => {
  const providerRegions = getRegionsFromDataFiltered(provider, get(userRegions))

  // reset the region if the provider is not in the selected region
  if (
    filtersStore.region
    && !providerRegions.map(r => r.code).includes(filtersStore.region)
  ) {
    filtersStore.setRegion(null)
  }

  // reset the country if the provider is not in the selected country
  if (
    filtersStore.country
    && !provider.countries?.all?.includes(filtersStore.country)
  ) {
    filtersStore.country = null
  }
})

onMounted(async () => {
  if (route.params.provider) {
    await providerStore.load(route.params.provider)
  }
})

onBeforeUnmount(() => {
  providerStore.unload()
})

watch(
  () => route.params.provider,
  async (nextProvider, lastProvider) => {
    if (nextProvider && nextProvider !== lastProvider) {
      await providerStore.load(nextProvider)
    }
  },
)
</script>

<template>
  <div>
    <SidebarContainer
      v-if="!isLoading && provider"
      class="flex"
      :no-padding="route.name === 'ProviderFootprint'"
      have-menu
    >
      <template #left>
        <Sidebar
          :to="{ name: 'Providers' }"
          :text="t('Back to operators list')"
          :menu="menu"
        >
          <FavoriteCard
            type="provider"
            :slug="route.params.provider"
            class="mb-4 hidden md:block"
          />
        </Sidebar>
      </template>

      <template #default="{ container }">
        <router-view :infinite-target="container" />
      </template>
    </SidebarContainer>

    <div
      v-else
      class="flex h-full items-center justify-center"
    >
      <AnimatedLoading />
    </div>
  </div>
</template>
