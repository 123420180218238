<script setup>
import { resetCache } from '@/modules/apollo'

const { t } = useI18n()

const { logout, isAdmin, goToUserMain, switchOrganization } = useUserStore()
const { role, organization, subscription, organizations } = storeToRefs(
  useUserStore(),
)
const { locale, localesOptions } = useLocaleSetting()

const organizationId = computed({
  get() {
    return organization.value.id
  },
  set(value) {
    selectOrganization(value)
  },
})

const menu = computed(() => [
  {
    route: { name: 'SettingsAccount' },
    label: t('Overview'),
  },
  {
    route: { name: 'SettingsEdit' },
    label: t('My profile'),
  },
  ...((['owner', 'billing'].includes(role.value) && [
    ...(subscription.value
      ? [
          {
            route: { name: 'SettingsSubscription' },
            label: t('My plan'),
          },
        ]
      : [
          {
            route: { name: 'SubscribePlans' },
            label: t('Upgrade my plan'),
          },
        ]),
    {
      route: { name: 'Invoices' },
      label: t('Invoices'),
    },
  ])
  || []),
])

async function selectOrganization(organizationId) {
  await resetCache()
  await switchOrganization(organizationId)
  goToUserMain()
}
</script>

<template>
  <SidebarContainer have-menu>
    <template #left>
      <Sidebar
        :menu="menu"
        :label="t('My account')"
        hide-reset
      >
        <template #filters>
          <DSelectPicker
            v-if="organizations.length > 1"
            v-model="organizationId"
            class="mt-4"
            :label="t('Organization')"
            icon="human-group"
            :options="
              organizations.map(o => ({
                text: o.name,
                value: o.id,
              }))
            "
          />
          <DSelectPicker
            v-if="isAdmin"
            v-model="locale"
            class="mt-4"
            :label="t('Language')"
            icon="translate"
            :options="localesOptions"
          />

          <DButton @click="() => logout()">
            {{ t('Log out') }}
          </DButton>
        </template>
      </Sidebar>
    </template>

    <template v-if="organization">
      <router-view class="mb-5" />
    </template>
  </SidebarContainer>
</template>
