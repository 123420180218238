import {
  differenceInDays,
  endOfDay,
  parse,
  startOfDay,
  subDays,
  subMonths,
} from 'date-fns'

export function useDateRangeFilter() {
  const gtm = useGtm()
  const { t } = useI18n()
  const filtersStore = useFiltersStore()

  const end = endOfDay(subDays(now, 1))
  const relativeRanges = {
    last7days: {
      name: t('Last 7 days'),
      period: {
        start: startOfDay(subDays(now, 7)),
        end,
      },
    },
    last30days: {
      name: t('Last 30 days'),
      period: {
        start: startOfDay(subDays(now, 30)),
        end,
      },
    },
    last2months: {
      name: t('Last 2 months'),
      period: {
        start: startOfDay(subMonths(now, 2)),
        end,
      },
    },
    last4months: {
      name: t('Last 4 months'),
      period: {
        start: startOfDay(subMonths(now, 4)),
        end,
      },
    },
    last6months: {
      name: t('Last 6 months'),
      period: {
        start: startOfDay(subMonths(now, 6)),
        end,
      },
    },
    lastYear: {
      name: t('Last 12 months'),
      period: {
        start: startOfDay(subMonths(now, 12)),
        end,
      },
    },
  }

  const relativeComparatives = [
    {
      name: t('Previous period'),
      period: (dateRange) => {
        const { start, end } = dateRange
        const nbrDays = differenceInDays(end, start)

        return {
          start: startOfDay(subDays(start, nbrDays + 1)),
          end: endOfDay(subDays(end, nbrDays + 1)),
        }
      },
    },
    {
      name: t('Same period last year'),
      period: (dateRange) => {
        const { start, end } = dateRange
        return {
          start: startOfDay(subMonths(start, 12)),
          end: endOfDay(subMonths(end, 12)),
        }
      },
    },
  ]

  const dateRange = computed({
    get: () => ({ ...filtersStore.dateRange }),
    set(value) {
      if (gtm?.enabled()) {
        gtm.trackEvent({
          event: 'update_date_range',
          category: 'ui',
          label: 'start_date',
          value: parse(value.start, DATE_FORMAT, new Date()).getTime(),
        })
        gtm.trackEvent({
          event: 'update_date_range',
          category: 'ui',
          label: 'end_date',
          value: parse(value.end, DATE_FORMAT, new Date()).getTime(),
        })
      }

      filtersStore.$patch({
        dateRange: {
          start: startOfDay(value.start),
          end: endOfDay(value.end),
        },
      })
    },
  })

  const comparativeRange = computed({
    get: () =>
      (filtersStore.comparativeRange && {
        ...filtersStore.comparativeRange,
      })
      || null,
    set(value) {
      filtersStore.comparativeRange = {
        start: startOfDay(value.start),
        end: endOfDay(value.end),
      }
    },
  })

  function toggleComparativeRange(dateRange) {
    if (!dateRange || get(comparativeRange)) {
      filtersStore.comparativeRange = null
    } else {
      filtersStore.comparativeRange = {
        ...relativeComparatives[0].period(dateRange),
      }
    }
  }

  // reset comparative range when is not used on the page
  onBeforeRouteLeave((to) => {
    const isCompare = to.meta.comparativeRange
    if (!isCompare && get(comparativeRange)) {
      toggleComparativeRange()
    }
  })

  return {
    relativeRanges,
    relativeComparatives,
    dateRange,
    comparativeRange,
    toggleComparativeRange,
  }
}

export function useFilterRegion(provider) {
  const { unlockedRegions } = storeToRefs(useUserStore())
  const filtersStore = useFiltersStore()

  const userRegions = computed(() =>
    REGIONS.filter(
      r => r.code === null || get(unlockedRegions).includes(r.code),
    ),
  )

  const providerRegions = computed(() =>
    getRegionsFromDataFiltered(get(provider), get(userRegions)),
  )

  const regionIconName = computed(
    () => `world-${(filtersStore.region || REGION_CODE_EU).toLowerCase()}`,
  )

  return {
    userRegions,
    providerRegions,
    regionIconName,
  }
}

export function useFilterHighlight(id, isOpen) {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const navigationStore = useNavigationStore()
  const { ready, start } = useTimeout(500, { controls: true })

  const isHighlighted = ref(false)

  watch(ready, (ready) => {
    set(isHighlighted, !ready)
  })

  if (get(id)) {
    globalEmitter.on(`highlight:${get(id)}`, () => {
      start()
    })
    globalEmitter.on(`open:${get(id)}`, () => {
      const isMobile = get(breakpoints.smaller('md'))
      start()

      if (isMobile) {
        navigationStore.toggleSettings()
      }

      if (isOpen) {
        set(isOpen, true)
      }
    })
  }

  // TODO check if is ok in composition api
  onBeforeUnmount(() => {
    globalEmitter.off(`highlight:${get(id)}`)
    globalEmitter.off(`open:${get(id)}`)
  })

  return {
    isHighlighted,
  }
}

export function useFiltersFromPreferences(boardId = 'cities') {
  const filtersStore = useFiltersStore()
  const { getPref, setPref } = usePreferences()
  const pathBoard = `boards.${boardId}`

  const filters = [
    'timeMode',
    'region',
    'country',
    'carPropulsion',
    'carSize',
    'carModel',
  ]

  filters.forEach((filter) => {
    // TODO add stop to unwatch if needed
    watch(
      () => filtersStore[filter],
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          updatePrefIfNotSame(filter, newValue)
        }
      },
    )
  })

  function setFilterWithPrefIfExist(filter = 'timeMode') {
    const value = getPref(`${pathBoard}.${filter}`)

    if (value) {
      filtersStore[filter] = value
    }
  }

  function updatePrefIfNotSame(filter = 'timeMode', newValue) {
    const value = getPref(`${pathBoard}.${filter}`)

    if (value !== newValue) {
      setPref(`${pathBoard}.${filter}`, newValue)
    }
  }

  function init() {
    filters.forEach((filter) => {
      setFilterWithPrefIfExist(filter)
    })
  }

  init()

  return {
    init,
  }
}

export function useResetFilters(emit) {
  const filtersStore = useFiltersStore()

  function onReset() {
    filtersStore.$reset()
    globalEmitter.emit('filters:reset')
    if (emit) {
      emit('reset')
    }
  }

  return {
    onReset,
  }
}
