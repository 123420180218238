<script setup>
import invoicesQuery from '@/graphql/queries/invoices.gql'

const testInvoice = {
  total: 1,
  page: 1,
  limit: 10,
  invoices: [
    {
      id: 1,
      status: 'paid',
      url: 'http://example.com',
      total: '12345',
      period: {
        start: '2020-01-01T00:00:00Z',
        end: '2020-02-01T00:00:00Z',
      },
    },
  ],
}

const { t } = useI18n()
const { translateDate } = useDateTranslation()
const { organizationId } = storeToRefs(useUserStore())

const { result, loading } = useQuery(
  invoicesQuery,
  {
    page: 1,
    organizationId: get(organizationId),
  },
  {
    clientId: CAPTAIN,
  },
)

const invoices = computed(() =>
  LOGIN_DISABLED
    ? testInvoice
    : result.value?.invoices || {
      total: 0,
      invoices: [],
    },
)

function getBadgeVariant(status) {
  let variant
  switch (status) {
    case 'unpaid':
      variant = 'alert'
      break
    case 'paid':
      variant = 'success'
      break
    case 'deleted':
      variant = 'default'
      break
    default:
      variant = 'default'
      break
  }

  return variant
}
</script>

<template>
  <div class="space-y-4">
    <BoardTitle hide-on-mobile>
      {{ t('Invoices') }}
    </BoardTitle>

    <DCard no-padding>
      <DLoader v-if="loading" />
      <p
        v-if="invoices.invoices.length === 0"
        class="p-8"
      >
        {{ t("You don't have invoices for the moment") }}
      </p>
      <DTable v-else>
        <thead>
          <tr>
            <th>{{ t('Status') }}</th>
            <th>{{ t('Period') }}</th>
            <th>{{ t('Amount') }}</th>
            <th />
          </tr>
        </thead>
        <tbody
          v-for="item in invoices.invoices"
          :key="item"
        >
          <tr>
            <td>
              <DBadge
                size="sm"
                :variant="getBadgeVariant(item.status.toLowerCase())"
              >
                {{ item.status.toLowerCase() }}
              </DBadge>
            </td>
            <td>
              {{ translateDate(item.period.end, {
                month: 'full',
                year: 'numeric',
              }) }}
            </td>
            <td>{{ formatMoney(Number(item.total / 100).toFixed(2)) }}</td>
            <td class="w-1 whitespace-nowrap text-center">
              <DButton
                size="sm"
                :href="item.url"
              >
                {{ t('Show') }}
              </DButton>
            </td>
          </tr>
        </tbody>
      </DTable>
    </DCard>
  </div>
</template>
