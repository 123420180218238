<script setup>
defineProps({
  news: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
const { translateFromData } = useLabelTranslation()
const { translateDate } = useDateTranslation()
</script>

<template>
  <p class="whitespace-nowrap text-grey-300">
    <template v-if="news.date">
      {{
        translateDate(news.date, HUMAN_DATE_FORMAT)
      }}
    </template>
    <template v-if="news.lang && news.date">
      |
    </template>
    <template v-if="news.lang">
      {{
        t('In {locale}', { locale: translateFromData('locales', news.lang) })
      }}
    </template>
  </p>
</template>
