<script setup>
defineProps({
  filter: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()
const { mode } = useMode()
const { provider } = storeToRefs(useProviderStore())
const { providerRegions } = useFilterRegion(provider)

const filtersTypes = {
  mode: {
    label: t('Vehicle type'),
    icon: 'wheel',
  },
  dateRange: {
    label: t('Time period'),
    icon: 'calendar',
  },
  compare: {
    label: t('Time period with comparaison'),
    icon: 'calendar-vs',
    linkedTo: 'dateRange',
  },
  timeMode: {
    label: t('Time mode'),
    icon: 'data-settings',
  },
  region: {
    label: t('Region'),
    icon: 'map-marker',
  },
  country: {
    label: t('Country'),
    icon: 'flag',
  },
  filterBy: {
    label: t('Filter by'),
    icon: 'filter',
  },
  filterBySpecs: {
    label: t('Filter by specs'),
    icon: 'filter',
  },
  sortBy: {
    label: t('Sort by'),
    icon: 'sort',
  },
  language: {
    label: t('Language'),
    icon: 'dialog',
  },
}

function onHover(filterToHover) {
  globalEmitter.emit(`highlight:${filterToHover}`)
}

function onClick(filterToOpen) {
  globalEmitter.emit(`open:${filterToOpen}`)
}
</script>

<template>
  <DTooltip
    v-if="
      filtersTypes[filter]
        && (filter !== 'region' || (provider && providerRegions.length > 1))
        && (filter !== 'filterBySpecs' || mode === VEHICLE_TYPE_CAR)
    "
  >
    <DIcon
      :path="filtersTypes[filter].icon"
      size="sm"
      class="cursor-pointer rounded-full bg-teal-100 text-slate-500 hover:bg-blue-100 hover:text-blue-500"
      @mouseenter="() => onHover(filtersTypes[filter].linkedTo || filter)"
      @click="() => onClick(filtersTypes[filter].linkedTo || filter)"
    />
    <template #content>
      <p class="w-24 text-xs">
        {{ t('Data affected by "{filter}" settings', {
          filter: filtersTypes[filter].label,
        }) }}
      </p>
    </template>
  </DTooltip>
</template>
