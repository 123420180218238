<script setup>
defineOptions({
  name: 'ProviderVehicleOverview',
})

const { t } = useI18n()
const { mode } = useMode()
const { modeClasses } = useModeClasses()
const { providerName } = useProviderName()

const { provider } = storeToRefs(useProviderStore())
</script>

<template>
  <MetricCard kpi="providerVehicleTypeOverview">
    <template #title>
      {{ providerName(provider) }} - {{ t(`mode_performance.${mode}`) }}
    </template>

    <template #subtitle="{ data }">
      {{ t('AVG Last 30 days') }} -
      {{
        t(
          'data from {n} cities on City Dive',
          { n: data?.citiesCount ?? 0 },
          data?.citiesCount ?? 0,
        )
      }}
    </template>

    <template #default="{ data }">
      <div
        class="grid grid-cols-2 gap-4 md:grid-cols-3"
        :class="modeClasses"
      >
        <TrendMetric
          :data="data?.vehicles"
          :label="t('Vehicles deployed')"
          class="mb-4"
        />
        <TrendMetric
          :data="data?.usagePercent"
          :label="t('Vehicles used')"
          class="mb-4"
        >
          <template #default="{ value }">
            {{ value !== null ? formatPercent(value) : t('N/A') }}
          </template>
        </TrendMetric>
        <TrendMetric
          :data="data?.tripDuration"
          :label="
            mode === VEHICLE_TYPE_CAR
              ? t('AVG Rental period')
              : t('AVG Trip time')
          "
          class="mb-4"
        >
          <template #default="{ value }">
            {{ value !== null ? t('{n}min', { n: value }) : t('N/A') }}
          </template>
        </TrendMetric>
        <TrendMetric
          :data="data?.trips"
          :label="t('Trips')"
          class="mb-4"
        />
        <TrendMetric
          :data="data?.tripsPerVehicles"
          :label="t('TVD')"
          :help="t('Trips per vehicle per day')"
          class="mb-4"
        >
          <template #default="{ value }">
            {{ value !== null ? formatAverage(value) : t('N/A') }}
          </template>
        </TrendMetric>
        <TrendMetric
          v-if="mode !== VEHICLE_TYPE_CAR"
          :data="data?.tripDistance"
          :label="t('AVG Trip distance')"
          class="mb-4"
        >
          <template #default="{ value }">
            {{
              value !== null
                ? formatDistance(value, { meters: true })
                : t('N/A')
            }}
          </template>
        </TrendMetric>
      </div>
    </template>
  </MetricCard>
</template>
