import { useI18n } from 'vue-i18n'
import * as Sentry from '@sentry/vue'

export function useDisplayErrors() {
  const toast = useToast()
  const { t } = useI18n()

  function displayError(error) {
    if (import.meta.env.VITE_SENTRY_DSN) {
      // before all, we send it to Sentry
      Sentry.captureException(new Error(error))
    }

    // make sure we have a message
    let message
      = error?.message || error || t('An error occurred, please retry later')

    // remove the GraphQL error prefix
    message = message.replace('GraphQL error: ', '')
    toast.push(
      {
        title: t('Error'),
        message,
      },
      { variant: 'alert' },
    )
  }

  return displayError
}
