<script setup>
const { t } = useI18n()
const filtersStore = useFiltersStore()

const { provider } = storeToRefs(useProviderStore())
const { providerRegions } = useFilterRegion(provider)

const options = computed(() => {
  return get(providerRegions).map(({ name, code }) => ({
    text: t(name),
    value: code,
    icon: getRegionIcon(code),
  }))
})
</script>

<template>
  <DSelectPicker
    v-if="options.length > 1"
    id="region"
    v-model="filtersStore.region"
    :options="[
      {
        text: t('Global'),
        value: null,
        icon: 'circle',
      },
      ...options,
    ]"
    :label="t('Geographical Area')"
    icon="map-marker"
  />
</template>
