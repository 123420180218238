<script setup>
import homeCityVehicleTypesQuery from '@/graphql/queries/homeCityVehicleTypes.gql'

const props = defineProps({
  loading: Boolean,
  cities: {
    type: Array,
    default: () => [],
  },
})

const route = useRoute()
const city = computed(() =>
  props.cities?.find(c => c.name === route.params.citySlug),
)

const variables = computed(() => ({
  city: route.params.citySlug,
}))

// retrieve city VehicleTypes
const { loading: vehicleTypesLoading, result } = useQuery(
  homeCityVehicleTypesQuery,
  variables,
)
const vehicleTypes = computed(
  () => get(result)?.homeCityVehicleTypes.data.vehicleTypes || [],
)
</script>

<template>
  <CityInfoSidebar
    class="absolute right-0 top-0 z-sidebar h-full w-full shadow-md md:w-[464px]"
    :city="city"
    :loading="loading"
    :vehicle-types="vehicleTypes"
    :vehicle-types-loading="vehicleTypesLoading"
    :show-details="false"
  />
</template>
