<script setup>
import { nanoid } from 'nanoid'

const props = defineProps({
  modelValue: Boolean,
  size: {
    type: String,
    default: 'md',
    validation: value => ['sm', 'md', 'lg'].includes(value),
  },
  inline: Boolean,
  disabled: Boolean,
})

const emit = defineEmits(['update:modelValue'])

const uid = `d-switch-${nanoid()}`

const attrs = useAttrs()

const data = useVModel(props, 'modelValue', emit)

const label = inject(PROVIDE_UI_FIELD_LABEL, '')
</script>

<template>
  <label
    class="flex cursor-pointer flex-center"
    :for="uid"
  >
    <div class="relative mr-3">
      <input
        v-bind="attrs"
        :id="uid"
        v-model="data"
        type="checkbox"
        class="peer sr-only"
        :aria-label="label"
        :disabled="disabled"
      >
      <div
        class="peer h-4 w-7 rounded-full bg-grey-100 after:absolute after:top-[2px] after:left-[2px] after:h-3 after:w-3 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-200"
        :class="{
          'cursor-not-allowed bg-grey-200 after:bg-grey-100': disabled,
        }"
      />
    </div>

    <div class="flex-1 leading-4">
      <slot />
    </div>
  </label>
</template>
