<script setup lang="ts">
import cityZoneQuery from '@/graphql/queries/cityZone.gql'

defineOptions({
  name: 'Geometry',
})

const props = withDefaults(defineProps<GeometryProps>(), {
  geometryType: 'h3_8',
  locations: () => [],
})

interface ILocation {
  id: string
}

interface GeometryProps {
  geometryType: string
  locations: ILocation[]
}

const route = useRoute()
const { result, loading } = useQuery(
  cityZoneQuery,
  computed(() => ({
    city: route.params.citySlug,
    zone: props.geometryType,
  })),
)

const zone = computed(() => get(result)?.city?.zone || null)

// TODO: filter by locations for performance :geojson-options="{ promoteId: 'name' }",
const geojson = computed(() => {
  const geojson = get(zone)?.geojson

  if (!geojson) {
    return null
  }

  return {
    ...geojson,
    features: geojson.features.filter(
      ({ id }: { id: string }) =>
        props.locations.length === 0 || props.locations.find(l => l.id === id),
    ),
  }
})
</script>

<template>
  <MapLibreSourceGeojson
    v-if="geojson && !loading"
    id="geometry"
    :layer-props="{
      type: 'line',
      beforeId: 'city-map',
      paint: {
        'line-width': 0.8,
        'line-opacity': 0.33,
        'line-color': '#576067',
      },
    }"
    :data="geojson"
  >
    <MapLibreSourceGeojson
      id="geometry-hovered"
      :layer-props="{
        type: 'fill',
        beforeId: 'city-map',
        paint: {
          'fill-opacity': 0.4,
          'fill-outline-color': 'transparent',
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            '#C1CCCD',
            'transparent',
          ],
        },
      }"
      :data="geojson"
      :geojson-options="{
        promoteId: 'name',
      }"
    >
      <slot />
    </MapLibreSourceGeojson>
  </MapLibreSourceGeojson>
</template>
