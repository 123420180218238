<script setup>
import DTooltip from '@/components/ui/DTooltip/DTooltip.vue'

defineProps({
  rows: {
    type: Array,
    default: () => [],
  },
  tooltip: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
</script>

<template>
  <component
    :is="tooltip ? DTooltip : 'p'"
    class="mx-2 my-0 flex flex-1 flex-col justify-center text-xs font-normal"
  >
    <span>{{ rows[0] || '' }}</span>
    <span
      class="text-base font-bold"
      :class="{
        'text-sm': rows[1] === null,
      }"
    >{{ rows[1] === null ? t('N/A') : rows[1] ?? 0 }}</span>
    <span class="h-4 text-xs text-grey-300">
      {{ rows.length === 3 ? rows[2] || '-' : '' }}
    </span>

    <template
      v-if="tooltip"
      #content
    >
      <p class="w-32">
        {{ tooltip }}
      </p>
    </template>
  </component>
</template>
