<script setup>
const { t } = useI18n()
const route = useRoute()
const loginModalsStore = useLoginModalsStore()
const { isLogged, organization } = storeToRefs(useUserStore())
const navigationStore = useNavigationStore()

watch(route, () => {
  navigationStore.hideAll()
})
</script>

<template>
  <div
    class="color-white gradient relative z-navbar flex w-full h-16 md:flex-col items-center shadow-md overflow-y-auto md:z-auto md:h-auto md:w-20 md:bg-gradient-to-b"
    role="navigation"
  >
    <!-- Logo -->
    <RouterLink
      to="/"
      class="px-2 md:py-7"
    >
      <img
        src="/img/logo.svg"
        alt="City Dive"
      >
    </RouterLink>

    <!-- Actions on right side : mobile -->
    <div
      v-if="organization"
      class="md:hidden flex-1 text-right mx-2"
    >
      <DIconButton
        path="search"
        light
        @click="() => navigationStore.toggleSearch()"
      />
      <div class="h-8 mx-2 inline-block border-l border-white/30" />
      <DIconButton
        path="hamburger"
        light
        @click="() => navigationStore.toggleMenu()"
      />

      <!-- Search Bar -->
      <NavigationSearchInput />
    </div>

    <!-- Actions on right side : desktop / hidden on mobile before opened -->
    <div
      class="flex flex-1 flex-col items-center"
      :class="{
        'hidden md:flex': !navigationStore.isOpen,
        'fixed z-navbar gradient md:bg-none bottom-0 w-full top-16 md:relative md:z-auto md:bg-transparent md:h-auto md:w-auto md:top-auto':
          navigationStore.isOpen,
      }"
    >
      <!-- Navigation menu -->
      <NavigationMenu />

      <!-- spacer -->
      <div class="flex-1" />

      <!-- Settings and Contact/TOF -->
      <div
        class="mt-6 flex justify-center border-t border-white/20 px-12 py-6 md:m-0 md:block md:border-0 md:px-0 md:py-2 place-self-stretch"
      >
        <DActions
          light
          class="md:m-auto"
        >
          <DButton
            variant="fill-secondary"
            href="https://docs.google.com/document/d/e/2PACX-1vQZN0Bkm36xEf-Y-7NLSeXbIsEYsAWqhtfhi6KqF9Mx0BXAUzlF-mZE0B0vlREFLHxsL89Pjjk4XOYn/pub"
            target="_blank"
            rel="noopener noreferrer"
            @click="() => navigationStore.hideAll()"
          >
            {{ t('Terms & Conditions') }}
          </DButton>
          <DButton
            variant="fill-secondary"
            href="mailto:contact@fluctuo.com"
          >
            {{
              t('Contact')
            }}
          </DButton>
        </DActions>

        <span
          v-if="!isLogged"
          @click="
            () => {
              loginModalsStore.openSignin()
              navigationStore.hideAll()
            }
          "
        >
          <DIcon
            name="account"
            size="lg"
          />
        </span>

        <RouterLink
          v-else
          :to="{ name: 'SettingsAccount' }"
          class="account-nav"
          @click="() => navigationStore.hideAll()"
        >
          <DIcon
            name="account"
            size="lg"
          />
        </RouterLink>
      </div>
    </div>
  </div>

  <!-- black opacity overlay -->
  <div
    v-if="navigationStore.settingsIsOpen || navigationStore.searchIsOpen"
    class="fixed top-0 z-underall h-screen w-screen bg-black/50 md:hidden"
    @click="() => navigationStore.hideAll()"
  />
</template>
