<script setup>
import { StripeElement, StripeElements } from 'vue-stripe-js'

const props = defineProps({
  subscriptionId: { type: String, default: null },
  plans: { type: Array, default: null },
})

const emit = defineEmits(['updated', 'failed'])

const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY

const stripeLoaded = inject(PROVIDE_STRIPE_LOADED)

const { t } = useI18n()
const { translateExample } = useExamplesTranslation()
const {
  confirmSubscription,
  addPaymentMethod,
  updateSubscription,
  createSetupIntent,
  paySubscription,
} = useSubscription()
const displayError = useDisplayErrors()

const iban = ref(null)
const elms = ref(null)
const completed = ref(false)
const saving = ref(false)
const ibanInfo = reactive({
  name: null,
  email: null,
})
const ibanValidated = ref(false)

const instanceOptions = {}
const ibanOptions = { supportedCountries: ['SEPA'] }
const elementsOptions = {}

async function savePaymentMethod() {
  saving.value = true

  if (props.subscriptionId) {
    await addIban({ doPay: true })
  } else if (props.plans) {
    await payByIban()
  } else {
    await addIban()
  }

  saving.value = false
}

async function addIban({ doPay } = {}) {
  ibanValidated.value = true

  if (!ibanInfo.name || !ibanInfo.email) {
    return
  }

  const ibanElement = iban.value.stripeElement
  const stripe = elms.value.instance

  try {
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'sepa_debit',
      sepa_debit: ibanElement,
      billing_details: ibanInfo,
    })

    const res = await createSetupIntent({
      paymentMethodId: paymentMethod.id,
    })

    const intent = res.data.createSetupIntent
    const clientSecret = intent.secret

    let paymentMethodId

    if (clientSecret) {
      const { error, setupIntent } = await stripe.confirmSepaDebitSetup(
        clientSecret,
        {
          payment_method: {
            sepa_debit: ibanElement,
          },
        },
      )

      if (error) {
        throw error
      }

      paymentMethodId = setupIntent.payment_method
    } else {
      paymentMethodId = paymentMethod.id
    }

    await addPaymentMethod({ paymentMethodId })

    if (doPay) {
      await paySubscription({
        subscriptionId: props.subscriptionId,
        paymentMethodId: paymentMethod.id,
      })
    }

    emit('updated')
  } catch (error) {
    saving.value = false
    console.error(error)
    displayError(error)
  }
}

async function payByIban() {
  ibanValidated.value = true

  if (!ibanInfo.name || !ibanInfo.email) {
    return
  }

  const ibanElement = iban.value.stripeElement
  const stripe = elms.value.instance
  const { paymentMethod } = await stripe.createPaymentMethod({
    type: 'sepa_debit',
    sepa_debit: ibanElement,
    billing_details: ibanInfo,
  })

  try {
    const res = await updateSubscription({
      plans: props.plans,
      paymentMethodId: paymentMethod?.id || null,
    })

    const subscription = res.data.subscription
    const clientSecret = subscription.paymentSecret

    if (clientSecret) {
      const { error, paymentIntent } = await stripe.confirmSepaDebitPayment(
        clientSecret,
        {
          setup_future_usage: 'off_session',
        },
      )

      if (error) {
        throw error
      }

      const status = paymentIntent && paymentIntent.status

      if (status === 'succeeded' || status === 'processing') {
        await confirmSubscription({ subscriptionId: subscription.id })

        emit('updated')
      } else {
        emit('failed')
      }
    }

    emit('updated')
  } catch (error) {
    saving.value = false
    console.error(error)
    displayError(error)
  }
}
</script>

<template>
  <div class="paymentMethodsIban">
    <form @submit.prevent="savePaymentMethod">
      <div class="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
        <DField>
          <template #label>
            {{ t('Name') }}
          </template>
          <DInputText
            v-model="ibanInfo.name"
            required
            :placeholder="translateExample('john_doe')"
          />
        </DField>

        <DField>
          <template #label>
            {{ t('Email') }}
          </template>
          <DInputText
            v-model="ibanInfo.email"
            type="email"
            required
            :placeholder="translateExample('john_doe_email')"
          />
        </DField>
      </div>

      <DField>
        <template #label>
          {{ t('IBAN') }}
        </template>
        <StripeElements
          v-if="stripeLoaded"
          v-slot="{ elements }"
          ref="elms"
          :stripe-key="stripePublicKey"
          :instance-options="instanceOptions"
          :elements-options="elementsOptions"
        >
          <StripeElement
            ref="iban"
            class="text-normal block w-full rounded-md border border-gray-200 bg-white bg-clip-padding p-2 py-2 px-4 text-grey-700 transition-all"
            type="iban"
            :elements="elements"
            :options="ibanOptions"
            @change="completed = $event.complete"
          />
        </StripeElements>
      </DField>

      <div class="small text-grey-300">
        {{
          t('by_providing_your_iban_you_authorize_us_to_send_instructions_to_your_bank')
        }}
      </div>
    </form>

    <div class="flex justify-end pt-4">
      <DButton
        :disabled="!completed || saving"
        variant="success"
        :loading="saving"
        @click="savePaymentMethod"
      >
        {{ t('Use this payment method') }}
      </DButton>
    </div>
  </div>
</template>
