import type { VehicleType, VehicleTypeData } from '@/types/vehicleTypes.types'

export const CDN_PATH = import.meta.env.VITE_CDN_PATH
export const IS_DEV = import.meta.env.DEV

export const LOGIN_DISABLED
  = import.meta.env.VITE_DISABLE_LOGIN
  && import.meta.env.VITE_DISABLE_LOGIN === 'true'

export const CITY = 'city'
export const PROVIDER = 'provider'
export const OPERATOR = 'operator'
export const DEMO = 'demo'

//
// Vehicle Types

export const VEHICLE_TYPE_ALL = 'all'
export const VEHICLE_TYPE_STATION = 'station'
export const VEHICLE_TYPE_BIKE = 'bike'
export const VEHICLE_TYPE_SCOOTER = 'scooter'
export const VEHICLE_TYPE_MOPED = 'moped'
export const VEHICLE_TYPE_CAR = 'car'
export const VEHICLE_TYPE_OTHER = 'other'

export const DATA_VEHICLE_TYPE_ALL = 'ALL'
export const DATA_VEHICLE_TYPE_STATION = 'STATION'
export const DATA_VEHICLE_TYPE_BIKE = 'BIKE'
export const DATA_VEHICLE_TYPE_SCOOTER = 'SCOOTER'
export const DATA_VEHICLE_TYPE_MOPED = 'MOTORSCOOTER'
export const DATA_VEHICLE_TYPE_CAR = 'CAR'
export const DATA_VEHICLE_TYPE_OTHER = 'OTHER'

export const VEHICLE_TYPES: VehicleType[] = [
  VEHICLE_TYPE_BIKE,
  VEHICLE_TYPE_SCOOTER,
  VEHICLE_TYPE_MOPED,
  VEHICLE_TYPE_CAR,
]

export const DATA_VEHICLE_TYPES: VehicleTypeData[] = [
  DATA_VEHICLE_TYPE_BIKE,
  DATA_VEHICLE_TYPE_SCOOTER,
  DATA_VEHICLE_TYPE_MOPED,
  DATA_VEHICLE_TYPE_CAR,
]

export const ENUM_VEHICLE_TYPES: Record<VehicleType, VehicleTypeData> = {
  [VEHICLE_TYPE_ALL]: DATA_VEHICLE_TYPE_ALL,
  [VEHICLE_TYPE_STATION]: DATA_VEHICLE_TYPE_STATION,
  [VEHICLE_TYPE_BIKE]: DATA_VEHICLE_TYPE_BIKE,
  [VEHICLE_TYPE_SCOOTER]: DATA_VEHICLE_TYPE_SCOOTER,
  [VEHICLE_TYPE_MOPED]: DATA_VEHICLE_TYPE_MOPED,
  [VEHICLE_TYPE_CAR]: DATA_VEHICLE_TYPE_CAR,
  [VEHICLE_TYPE_OTHER]: DATA_VEHICLE_TYPE_OTHER,
}

export const ENUM_DATA_VEHICLE_TYPES = Object.entries(
  ENUM_VEHICLE_TYPES,
).reduce((acc, [key, value]) => ({ [value]: key, ...acc }), {} as Record<VehicleTypeData, VehicleType>)

//
// Car specs

export const UNKNOWN = 'UNKNOWN'

export const CAR_PROPULSION_ELECTRIC = 'ELECTRIC'
export const CAR_PROPULSION_COMBUSTION = 'COMBUSTION'
export const CAR_PROPULSION_HYBRID = 'HYBRID'

export const CAR_PROPULSIONS = [
  CAR_PROPULSION_ELECTRIC,
  CAR_PROPULSION_COMBUSTION,
  CAR_PROPULSION_HYBRID,
]

export const ENUM_CAR_PROPULSION = {
  electric: CAR_PROPULSION_ELECTRIC,
  combustion: CAR_PROPULSION_COMBUSTION,
  hybrid: CAR_PROPULSION_HYBRID,
}

export const CAR_SIZE_MICRO = 'MICRO'
export const CAR_SIZE_SMALL = 'SMALL'
export const CAR_SIZE_MEDIUM = 'MEDIUM'
export const CAR_SIZE_LARGE = 'LARGE'
export const CAR_SIZE_XLARGE = 'XLARGE'

export const CAR_SIZES = [
  CAR_SIZE_MICRO,
  CAR_SIZE_SMALL,
  CAR_SIZE_MEDIUM,
  CAR_SIZE_LARGE,
  CAR_SIZE_XLARGE,
]

export const ENUM_CAR_SIZE = {
  micro: CAR_SIZE_MICRO,
  small: CAR_SIZE_SMALL,
  medium: CAR_SIZE_MEDIUM,
  large: CAR_SIZE_LARGE,
  xLarge: CAR_SIZE_XLARGE,
}

//
// Metrics Indicators

export const INDICATOR_VALUE = 'VALUE'
export const INDICATOR_PERCENT = 'PERCENT'

export const AVAILABLE = 'available'
export const USED = 'used'

export const VEHICLES = 'vehicles'
export const TRIPS = 'trips'
export const TVD = 'tvd'

//
// Map Layers

export const SCT = 'sct'
export const PTS = 'pts'
export const GEOMETRY_TYPE_H3_8 = 'h3_8'
export const GEOMETRY_TYPE_H3_9 = 'h3_9'
export const GEOMETRY_TYPE_ADMIN_11 = 'admin_11'
export const GEOMETRY_TYPES = [
  GEOMETRY_TYPE_H3_8,
  GEOMETRY_TYPE_H3_9,
  GEOMETRY_TYPE_ADMIN_11,
]
export const DEFAULT_GEOMETRY_TYPE = GEOMETRY_TYPE_H3_8
export const CRAWL_ZONES_TYPE = [SCT, PTS]
export const LEAFLET_DEFAULT_PANE = 'overlayPane'

export const ENUM_LOCATION_TYPES = {
  city: 'CITY',
  country: 'COUNTRY',
  state: 'STATE',
  continent: 'CONTINENT',
  region: 'REGION',
}

export const PROVIDERS_STATUS = {
  ACTIVE: 'ACTIVE',
  STOPPED: 'STOPPED',
  PAUSED: 'PAUSED',
  UNKNOWN: 'UNKNOWN',
}

//
// Provide symbols

export const PROVIDE_OVERVIEW_CITIES = Symbol('overviewCities')
export const PROVIDE_OVERVIEW_AVAILABLE_VEHICLES = Symbol(
  'overviewAvailableVehicles',
)
export const PROVIDE_OVERVIEW_LOADING = Symbol('overviewLoading')
export const PROVIDE_STRIPE = Symbol('stripe')
export const PROVIDE_STRIPE_LOADED = Symbol('stripeLoaded')
export const PROVIDE_UI_FIELD_LABEL = Symbol('uiFieldLabel')

// MAPs
export const PROVIDE_CITY_MAP_DATA = Symbol('cityMapData')
export const PROVIDE_CITY_MAP_DATA_LOADING = Symbol('cityMapDataLoading')

//
// Regions

export const MAP_CENTER_EU: [number, number] = [10, 50] // lat, lng
export const MAP_CENTER_NA: [number, number] = [-97.2, 39.1] // lat, lng
export const MAP_CENTER_APAC: [number, number] = [145.5, -27.8] // lat, lng

export const REGION_CODE_EU = 'EU'
export const REGION_CODE_NA = 'NA'
export const REGION_CODE_APAC = 'APAC'

export const REGIONS = [
  // {
  //   name: 'Global',
  //   code: null,
  //   center: MAP_CENTER_EU,
  //   zoom: 0,
  // },
  {
    name: 'Europe',
    code: REGION_CODE_EU,
    center: MAP_CENTER_EU,
    zoom: 4,
  },
  {
    name: 'North America',
    code: REGION_CODE_NA,
    center: MAP_CENTER_NA,
    zoom: 3,
  },
  {
    name: 'Asia-Pacific',
    code: REGION_CODE_APAC,
    center: MAP_CENTER_APAC,
    zoom: 3,
  },
]

export const REGIONS_CODES = REGIONS.map(({ code }) => code)

//
// Sorting Table

export const PROVIDE_SORTING_TABLE_OPTIONS = Symbol('sortingTableOption')

//
// Other

export const CHART_WEEKEND_FILL_LABEL = 'weekend-fill'

export const DEFAULT_DEBOUNCE = 500

export enum DATES_FORMATS {
  DEFAULT = 'yyyy-LL-dd',
  SHORT = 'dd/MM/yyyy',
  FULL = 'MMM dd, yyyy',
  MONTH = 'MMM yyyy',
  DAY = 'MMM dd',
  DATA = 'yyyy-MM-dd', // use only to data request (API)
}

export const WAIT_TIMEOUT = 30000
export const HUMAN_DATE_FORMAT = DATES_FORMATS.FULL
export const DATE_FORMAT = DATES_FORMATS.DEFAULT
export const MIN_DATE = '2020-02-18'
export const H24_MILLISECONDS = 24 * 60 * 60 * 1000

export const SORT_BY_AVAILABLE_VEHICLES = 'data.available-vehicles.value'
export const SORT_BY_PER_10K_INHAB = 'data.available-vehicles-per-capita.value'
export const SORT_BY_SERVICES = 'providers.filtered.length'
export const SORT_BY_COUNTRIES = 'country'
export const SORT_BY_TOTAL_CITIES = 'cities.filtered.length'
export const SORT_BY_TOTAL_COUNTRIES = 'countries.filtered.length'
export const DEFAULT_SORT_BY = SORT_BY_AVAILABLE_VEHICLES
export const AVAILABLE_VEHICLES = 'available-vehicles'

//
// Apis

export const PRODUCT = 'citydive'
export const CAPTAIN = 'captain'

//
// Plans

export const PLAN_FREEMIUM_ID = 15
export const PACK_ALL_EUROPE_SLUG = 'explorer-pack-europe'
export const PACK_ALL_NORTH_AMERICA_SLUG = 'explorer-pack-north-america'

export const PLANS_SLUGS = [
  PACK_ALL_EUROPE_SLUG,
  'explorer-pack-northern-europe',
  'explorer-pack-france-benelux',
  'explorer-pack-dach',
  'explorer-pack-southern-europe',
  'explorer-pack-eastern-europe',
  'explorer-pack-aus-nz',
  PACK_ALL_NORTH_AMERICA_SLUG,
  'explorer-pack-united-states',
  'explorer-pack-canada',
]

export const PLANS_OPTIONS_SLUGS = [
  'explorer-pack-app-metrics',
  'explorer-pack-download',
  // 'explorer-pack-seats',
]

//
// User

export const DEFAULT_ORGA_PREFERENCES = {
  onboardingStep: 1,
  alerts: [],
  filters: {},
  visited: [],
  boards: {
    global: {},
    providers: {},
    cities: {},
  },
}

export const GEORGE_LOUTRE = {
  id: 'github|1234567',
  name: 'George Loutre',
  email: 'george.loutre@exemple.com',
  roles: ['user', 'admin'],
  organizations: [
    {
      id: 1,
      name: 'fluctuo',
      company: true,
      address1: '42b rue sedaine',
      address2: null,
      zipCode: '75011',
      city: 'Paris',
      country: 'FR',
      state: 'IDF',
      vatNumber: 'FR123456',
      vatRate: 11,
      disabled: false,
      disabledAt: null,
      disabledReason: null,
      users: [
        {
          userId: 'github|1234567',
          name: 'George Loutre',
          email: 'george.loutre@exemple.com',
          role: 'owner',
          createdAt: new Date(),
          __typename: 'OrganizationUser',
        },
        {
          email: 'emmitt otterton@exemple.com',
          role: 'member',
          createdAt: new Date(),
          __typename: 'OrganizationInvitation',
        },
      ],
      paymentInformation: {},
      subscriptions: [
        {
          end: null,
          plans: [
            {
              id: 16,
              name: 'Great Explorer',
              quantity: 1,
              support: 'premium',
              isOption: false,
              isPack: false,
              requirePack: true,
              seats: 0,
              data: null,
              metadata: null,
            },
            {
              id: 19,
              name: 'All Europe',
              quantity: 1,
              support: 'premium',
              isOption: false,
              isPack: true,
              requirePack: false,
              seats: 100,
              data: {
                cities: [
                  'aachen',
                  'aalborg',
                  'aarhus',
                  'amersfoort',
                  'amsterdam',
                  'angers',
                  'antwerp',
                  'augsburg',
                  'barcelona',
                  'bari',
                  'basel',
                  'bergen',
                  'berlin',
                  'bern',
                  'bilbao',
                  'bologna',
                  'bonn',
                  'bordeaux',
                  'bratislava',
                  'breda',
                  'bremen',
                  'bruxelles',
                  'bucharest',
                  'budapest',
                  'copenhagen',
                  'delft',
                  'den_bosch',
                  'dortmund',
                  'dresden',
                  'dublin',
                  'dusseldorf',
                  'eindhoven',
                  'enschede',
                  'essen',
                  'florence',
                  'frankfurt',
                  'gdansk',
                  'geneva',
                  'gothenburg',
                  'grenoble',
                  'groningen',
                  'haarlem',
                  'hamburg',
                  'hanover',
                  'heilbronn',
                  'helsingborg',
                  'helsinki',
                  'jonkoping',
                  'karlsruhe',
                  'karlstad',
                  'koln',
                  'krakow',
                  'leeuwarden',
                  'leipzig',
                  'lillestrom',
                  'linkoping',
                  'lisbon',
                  'london',
                  'lubeck',
                  'lund',
                  'lyon',
                  'madrid',
                  'malaga',
                  'malmo',
                  'marseille',
                  'milano',
                  'modena',
                  'munchen',
                  'munster',
                  'namur',
                  'naples',
                  'nice',
                  'norrkoping',
                  'nuremberg',
                  'odense',
                  'orebro',
                  'oslo',
                  'palermo',
                  'paris',
                  'pisa',
                  'potsdam',
                  'poznan',
                  'prague',
                  'reggio_emilia',
                  'riga',
                  'rome',
                  'rotterdam',
                  'seville',
                  'stavanger',
                  'stockholm',
                  'stuttgart',
                  'tallinn',
                  'tampere',
                  'the_hague',
                  'tilburg',
                  'torino',
                  'toulouse',
                  'trondheim',
                  'turku',
                  'uppsala',
                  'utrecht',
                  'valencia',
                  'vasteras',
                  'vilnius',
                  'warsaw',
                  'wien',
                  'winterthur',
                  'wroclaw',
                  'zaragoza',
                  'zurich',
                  'zwolle',
                  'bochum',
                  'katowice',
                  'bielefeld',
                  'mannheim',
                  'gelsenkirchen',
                  'wiesbaden',
                  'monchengladbach',
                  'halle',
                  'braunschweig',
                  'kosice',
                  'kiel',
                  'linz',
                  'rostock',
                  'kassel',
                  'parma',
                  'heidelberg',
                  'darmstadt',
                  'tarragona',
                  'lahti',
                  'fredrikstad',
                  'eskilstuna',
                  'drammen',
                  'lodz',
                  'duisburg',
                  'szczecin',
                  'kaunas',
                  'bydgoszcz',
                  'brno',
                  'mainz',
                  'lublin',
                  'iasi',
                  'constanta',
                  'clujnapoca',
                  'ostrava',
                  'almere',
                  'innsbruck',
                  'klagenfurt',
                  'chemnitz',
                  'czestochowa',
                  'liege',
                  'zagreb',
                  'jyvaskyla',
                  'oulu',
                  'vaasa',
                  'vantaa',
                  'oldenburg',
                  'solingen',
                  'genoa',
                  'pesaro',
                  'verona',
                  'alkmaar',
                  'alphenaandenrijn',
                  'apeldoorn',
                  'deventer',
                  'hilversum',
                  'nijmegen',
                  'zoetermeer',
                  'kristiansand',
                  'gliwice',
                  'kielce',
                  'radom',
                  'torun',
                  'braga',
                  'coimbra',
                  'ljubljana',
                  'boras',
                  'kyiv',
                  'bristol',
                  'telaviv',
                  'istanbul',
                  'washington',
                  'erlangen',
                  'gottingen',
                  'hildesheim',
                  'paderborn',
                  'recklinghausen',
                  'reutlingen',
                  'saarbrucken',
                  'salzgitter',
                  'wolfsburg',
                  'catania',
                  'padua',
                  'zabrze',
                  'pitesti',
                  'sibiu',
                  'elpuertodesantamaria',
                  'southampton',
                ],
              },
              metadata: null,
            },
            {
              id: 37,
              name: 'App Statistics',
              quantity: 1,
              support: 'premium',
              isOption: true,
              isPack: false,
              requirePack: false,
              seats: 0,
              data: {
                appMetrics: true,
              },
              metadata: null,
            },
            {
              id: 40,
              name: 'Data Export',
              quantity: 1,
              support: 'premium',
              isOption: true,
              isPack: false,
              requirePack: false,
              seats: 0,
              data: {
                dataExport: true,
              },
              metadata: null,
            },
          ],
          citydive: {
            support: 'premium',
            cities: [
              {
                city: 'alphenaandenrijn',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'apeldoorn',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'bochum',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'boras',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'braga',
                country: 'portugal',
                isPublic: true,
              },
              {
                city: 'braunschweig',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'brno',
                country: 'czech_republic',
                isPublic: true,
              },
              {
                city: 'czestochowa',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'duisburg',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'hilversum',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'iasi',
                country: 'romania',
                isPublic: true,
              },
              {
                city: 'jyvaskyla',
                country: 'finland',
                isPublic: true,
              },
              {
                city: 'kaunas',
                country: 'lithuania',
                isPublic: true,
              },
              {
                city: 'klagenfurt',
                country: 'austria',
                isPublic: true,
              },
              {
                city: 'kristiansand',
                country: 'norway',
                isPublic: true,
              },
              {
                city: 'lublin',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'mainz',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'nijmegen',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'ostrava',
                country: 'czech_republic',
                isPublic: true,
              },
              {
                city: 'vantaa',
                country: 'finland',
                isPublic: true,
              },
              {
                city: 'verona',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'zoetermeer',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'saarbrucken',
                country: 'germany',
                isPublic: false,
              },
              {
                city: 'paderborn',
                country: 'germany',
                isPublic: false,
              },
              {
                city: 'wolfsburg',
                country: 'germany',
                isPublic: false,
              },
              {
                city: 'gottingen',
                country: 'germany',
                isPublic: false,
              },
              {
                city: 'reutlingen',
                country: 'germany',
                isPublic: false,
              },
              {
                city: 'recklinghausen',
                country: 'germany',
                isPublic: false,
              },
              {
                city: 'zabrze',
                country: 'poland',
                isPublic: false,
              },
              {
                city: 'southampton',
                country: 'united_kingdom',
                isPublic: false,
              },
              {
                city: 'sibiu',
                country: 'romania',
                isPublic: false,
              },
              {
                city: 'erlangen',
                country: 'germany',
                isPublic: false,
              },
              {
                city: 'salzgitter',
                country: 'germany',
                isPublic: false,
              },
              {
                city: 'hildesheim',
                country: 'germany',
                isPublic: false,
              },
              {
                city: 'pitesti',
                country: 'romania',
                isPublic: false,
              },
              {
                city: 'bologna',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'modena',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'amersfoort',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'munster',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'bristol',
                country: 'united_kingdom',
                isPublic: false,
              },
              {
                city: 'namur',
                country: 'belgium',
                isPublic: true,
              },
              {
                city: 'potsdam',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'bielefeld',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'chemnitz',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'clujnapoca',
                country: 'romania',
                isPublic: true,
              },
              {
                city: 'coimbra',
                country: 'portugal',
                isPublic: true,
              },
              {
                city: 'constanta',
                country: 'romania',
                isPublic: true,
              },
              {
                city: 'deventer',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'drammen',
                country: 'norway',
                isPublic: true,
              },
              {
                city: 'eskilstuna',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'fredrikstad',
                country: 'norway',
                isPublic: true,
              },
              {
                city: 'gelsenkirchen',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'gliwice',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'halle',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'innsbruck',
                country: 'austria',
                isPublic: true,
              },
              {
                city: 'katowice',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'kielce',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'kosice',
                country: 'slovakia',
                isPublic: true,
              },
              {
                city: 'lodz',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'mannheim',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'monchengladbach',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'oldenburg',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'oulu',
                country: 'finland',
                isPublic: true,
              },
              {
                city: 'solingen',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'torun',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'vaasa',
                country: 'finland',
                isPublic: true,
              },
              {
                city: 'wiesbaden',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'catania',
                country: 'italy',
                isPublic: false,
              },
              {
                city: 'padua',
                country: 'italy',
                isPublic: false,
              },
              {
                city: 'elpuertodesantamaria',
                country: 'spain',
                isPublic: false,
              },
              {
                city: 'genoa',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'kyiv',
                country: 'ukraine',
                isPublic: false,
              },
              {
                city: 'liege',
                country: 'belgium',
                isPublic: true,
              },
              {
                city: 'ljubljana',
                country: 'slovenia',
                isPublic: true,
              },
              {
                city: 'zagreb',
                country: 'croatia',
                isPublic: true,
              },
              {
                city: 'breda',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'geneva',
                country: 'switzerland',
                isPublic: true,
              },
              {
                city: 'bari',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'tampere',
                country: 'finland',
                isPublic: true,
              },
              {
                city: 'toulouse',
                country: 'france',
                isPublic: true,
              },
              {
                city: 'angers',
                country: 'france',
                isPublic: true,
              },
              {
                city: 'antwerp',
                country: 'belgium',
                isPublic: true,
              },
              {
                city: 'berlin',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'bruxelles',
                country: 'belgium',
                isPublic: true,
              },
              {
                city: 'grenoble',
                country: 'france',
                isPublic: true,
              },
              {
                city: 'hamburg',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'helsinki',
                country: 'finland',
                isPublic: true,
              },
              {
                city: 'koln',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'marseille',
                country: 'france',
                isPublic: true,
              },
              {
                city: 'bydgoszcz',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'darmstadt',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'heidelberg',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'kassel',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'kiel',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'lahti',
                country: 'finland',
                isPublic: true,
              },
              {
                city: 'linz',
                country: 'austria',
                isPublic: true,
              },
              {
                city: 'parma',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'radom',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'rostock',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'szczecin',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'tarragona',
                country: 'spain',
                isPublic: true,
              },
              {
                city: 'milano',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'munchen',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'rotterdam',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'heilbronn',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'florence',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'riga',
                country: 'latvia',
                isPublic: true,
              },
              {
                city: 'almere',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'tallinn',
                country: 'estonia',
                isPublic: true,
              },
              {
                city: 'vilnius',
                country: 'lithuania',
                isPublic: true,
              },
              {
                city: 'poznan',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'bucharest',
                country: 'romania',
                isPublic: true,
              },
              {
                city: 'dresden',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'hanover',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'leipzig',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'norrkoping',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'utrecht',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'bratislava',
                country: 'slovakia',
                isPublic: true,
              },
              {
                city: 'karlstad',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'bilbao',
                country: 'spain',
                isPublic: true,
              },
              {
                city: 'haarlem',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'tilburg',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'copenhagen',
                country: 'denmark',
                isPublic: true,
              },
              {
                city: 'dortmund',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'dublin',
                country: 'ireland',
                isPublic: true,
              },
              {
                city: 'dusseldorf',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'frankfurt',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'alkmaar',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'jonkoping',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'linkoping',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'palermo',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'bergen',
                country: 'norway',
                isPublic: true,
              },
              {
                city: 'bonn',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'eindhoven',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'wroclaw',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'lillestrom',
                country: 'norway',
                isPublic: true,
              },
              {
                city: 'bordeaux',
                country: 'france',
                isPublic: true,
              },
              {
                city: 'groningen',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'aalborg',
                country: 'denmark',
                isPublic: true,
              },
              {
                city: 'aarhus',
                country: 'denmark',
                isPublic: true,
              },
              {
                city: 'basel',
                country: 'switzerland',
                isPublic: true,
              },
              {
                city: 'bern',
                country: 'switzerland',
                isPublic: true,
              },
              {
                city: 'gdansk',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'naples',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'torino',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'pisa',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'reggio_emilia',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'budapest',
                country: 'hungary',
                isPublic: true,
              },
              {
                city: 'istanbul',
                country: 'turkey',
                isPublic: true,
              },
              {
                city: 'nice',
                country: 'france',
                isPublic: true,
              },
              {
                city: 'barcelona',
                country: 'spain',
                isPublic: true,
              },
              {
                city: 'gothenburg',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'zwolle',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'london',
                country: 'united_kingdom',
                isPublic: true,
              },
              {
                city: 'helsingborg',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'telaviv',
                country: 'israel',
                isPublic: true,
              },
              {
                city: 'krakow',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'uppsala',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'valencia',
                country: 'spain',
                isPublic: true,
              },
              {
                city: 'warsaw',
                country: 'poland',
                isPublic: true,
              },
              {
                city: 'the_hague',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'washington',
                country: 'usa',
                isPublic: false,
              },
              {
                city: 'lisbon',
                country: 'portugal',
                isPublic: true,
              },
              {
                city: 'lubeck',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'zurich',
                country: 'switzerland',
                isPublic: true,
              },
              {
                city: 'lund',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'malmo',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'odense',
                country: 'denmark',
                isPublic: true,
              },
              {
                city: 'orebro',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'prague',
                country: 'czech_republic',
                isPublic: true,
              },
              {
                city: 'seville',
                country: 'spain',
                isPublic: true,
              },
              {
                city: 'stavanger',
                country: 'norway',
                isPublic: true,
              },
              {
                city: 'stockholm',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'stuttgart',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'vasteras',
                country: 'sweden',
                isPublic: true,
              },
              {
                city: 'karlsruhe',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'delft',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'enschede',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'den_bosch',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'leeuwarden',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'paris',
                country: 'france',
                isPublic: true,
              },
              {
                city: 'trondheim',
                country: 'norway',
                isPublic: true,
              },
              {
                city: 'aachen',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'amsterdam',
                country: 'netherlands',
                isPublic: true,
              },
              {
                city: 'augsburg',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'bremen',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'lyon',
                country: 'france',
                isPublic: true,
              },
              {
                city: 'wien',
                country: 'austria',
                isPublic: true,
              },
              {
                city: 'winterthur',
                country: 'switzerland',
                isPublic: true,
              },
              {
                city: 'zaragoza',
                country: 'spain',
                isPublic: true,
              },
              {
                city: 'pesaro',
                country: 'italy',
                isPublic: false,
              },
              {
                city: 'nuremberg',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'rome',
                country: 'italy',
                isPublic: true,
              },
              {
                city: 'turku',
                country: 'finland',
                isPublic: true,
              },
              {
                city: 'essen',
                country: 'germany',
                isPublic: true,
              },
              {
                city: 'madrid',
                country: 'spain',
                isPublic: true,
              },
              {
                city: 'malaga',
                country: 'spain',
                isPublic: true,
              },
              {
                city: 'oslo',
                country: 'norway',
                isPublic: true,
              },
            ],
            seats: 100,
            freemium: false,
            dataExport: true,
            appMetrics: true,
          },
        },
      ],
    },
  ],
  emailVerified: true,
}

export const EUROPEAN_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'EL',
  'ES',
  'FI',
  'FR',
  'GB',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
]
