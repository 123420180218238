export const useSharedChartColors = createSharedComposable(() => {
  const usedColors = reactive({
    default: {},
  })

  const getColor = computed(() => (name = '', namespace = 'default') => {
    if (namespace && !usedColors[namespace]) {
      usedColors[namespace] = {}
    }

    const colorSpace = usedColors[namespace]

    if (!colorSpace[name]) {
      colorSpace[name] = getColorByIndex(Object.values(colorSpace).length)
    }

    return colorSpace[name]
  })

  return {
    getColor,
  }
})
