<script setup>
defineOptions({
  name: 'CityTableCompare',
})

defineProps({
  kpi: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
const indicator = ref(INDICATOR_VALUE)
</script>

<template>
  <MetricCard
    :kpi="kpi"
    no-padding
    :variables="{
      indicator,
    }"
    :used-filters="['mode', 'compare', 'filterBySpecs']"
  >
    <template #title>
      {{ title || t('Compare') }}
    </template>
    <template #right>
      <FilterIndicator v-model="indicator" />
    </template>

    <template #default="{ data }">
      <TableProvidersCompare
        :data="data"
        :indicator="indicator"
      />
    </template>
  </MetricCard>
</template>
