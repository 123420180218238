<script setup>
defineOptions({
  name: 'ProviderFleetsCarsOverview',
})

const { t } = useI18n()
const { translateFromData } = useLabelTranslation()
</script>

<template>
  <MetricCard
    query="providerFleetsCarsOverview"
    :kpi="[
      'providerFleetsCarSizeOverview',
      'providerFleetsCarPropulsionOverview',
    ]"
    :as-mode="VEHICLE_TYPE_CAR"
    :used-filters="['regions']"
  >
    <template #title>
      {{ t('Cars specs breakdown') }}
    </template>
    <template #subtitle>
      {{ t('AVG Last 30 days') }}
    </template>

    <template #default="{ data }">
      <div class="flex flex-col md:flex-row relative">
        <div class="md:w-1/2 md:pr-8">
          <h4 class="text-lg uppercase text-grey-300 text-center py-4">
            {{ t('Size') }}
          </h4>
          <PieMetric
            class="max-w-[175px] mx-auto"
            :data="
              data?.[0]
                .map(({ carSize, value, percent }) => {
                  return {
                    label: translateFromData('carSize', carSize),
                    percent,
                    value,
                    color: getColorByCarSize(carSize),
                  }
                })
                .filter(d => d.value !== null)
            "
          />
        </div>

        <div class="md:w-1/2 md:pl-8">
          <h4 class="text-lg uppercase text-grey-300 text-center py-4">
            {{ t('Engine Type') }}
          </h4>
          <PieMetric
            class="max-w-[175px] mx-auto"
            :data="
              data?.[1]
                .map(({ carPropulsion, value, percent }) => {
                  return {
                    label: translateFromData('carPropulsion', carPropulsion),
                    percent,
                    value,
                    color: getColorByCarPropulsion(carPropulsion),
                  }
                })
                .filter(d => d.value !== null)
            "
          />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
