<script setup>
const { t } = useI18n()

const { error, loading, freemiumPlan } = useFreemiumPlan()
const { onFreemium } = useFreemiumAddSubscription(freemiumPlan)
</script>

<template>
  <DModal :closable="false">
    <template #title>
      {{ t('you_will_be_plan', { subscriptionPlan: t('data.plans.premium') }) }}<br>
      {{ t('in no time!') }}
    </template>

    <p>
      {{
        t(
          'Thanks for your interest. A pricing request has been sent to our team and we will be in touch soon.',
        )
      }}
    </p>
    <p class="font-bold">
      {{
        t(
          'Whilst you wait, you can continue to explore and use City Dive with your current subscription.',
        )
      }}
    </p>

    <p class="mt-6 text-center">
      <DButton
        :loading="loading"
        :disabled="!freemiumPlan"
        @click="onFreemium"
      >
        {{ t('Back to dashboard') }}
      </DButton>
    </p>

    <div
      v-if="error"
      class="flex-center flex flex-1"
    >
      <DAlert
        variant="alert"
        :title="t('Something went wrong. Please try again later.')"
        :subtitle="error.message"
      />
    </div>
  </DModal>
</template>
