<script setup>
const props = defineProps({
  isOpen: Boolean,
  title: {
    type: String,
    default: null,
  },
  content: {
    type: String,
    default: null,
  },
  target: {
    type: Element,
    default: null,
  },
})

const emit = defineEmits(['onClose'])
const sidebar = ref(null)
const container = document.getElementById('app-content')
container.classList.add('help-sidebar--transition')

watch(
  () => props.isOpen && props.target,
  () => {
    if (props.isOpen && props.target) {
      props.target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  },
)

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) {
      container.classList.add('help-sidebar--is-open')
    } else {
      container.classList.remove('help-sidebar--is-open')
    }
  },
)

onBeforeUnmount(() => {
  container.classList.remove('help-sidebar--is-open')
})
</script>

<template>
  <Teleport to="body">
    <Transition
      name="slideRight"
      duration="300"
    >
      <div
        v-if="isOpen"
        ref="sidebar"
        class="help-sidebar absolute bottom-0 right-0 bg-white w-80 h-full overflow-x-hidden overflow-y-auto z-[2001] transition-transform ease-in-out"
        :class="{
          '-translate-x-80': !isOpen,
        }"
      >
        <div class="p-5">
          <div class="mb-5 flex items-center">
            <div
              v-if="title"
              class="flex-1 font-bold"
            >
              {{ title }}
            </div>

            <DIconButton
              name="close"
              @click="emit('onClose')"
            />
          </div>

          <DMarkdown
            v-if="content"
            :source="content"
            class="prose prose-a:font-bold prose-a:text-blue-500 prose-img:mx-auto"
          />
        </div>
      </div>
    </Transition>

    <Transition
      name="fade"
      duration="300"
    >
      <HelpSidebarOverlay
        v-if="isOpen && target"
        :target="target"
        container-id="app-content"
        @on-action="() => emit('onClose')"
      />
    </Transition>
  </Teleport>
</template>

<style lang="pcss">
.help-sidebar--transition {
  transition: all 0.3s ease-in-out;
}
.help-sidebar--is-open {
  @apply -translate-x-80;
}
</style>
