import { defineStore } from 'pinia'

import cityDiveClient from '@/graphql/cityDive'
import citiesAndProvidersQuery from '@/graphql/queries/citiesAndProviders.gql'

export const useCubeStore = defineStore('cube', () => {
  const fetchCube = createEventHook()
  const { onOrganizationLoaded, onUserLogout, token } = useUserStore()
  const filtersStore = useFiltersStore()
  const citiesStore = useCitiesStore()
  const providersStore = useProvidersStore()

  const loading = ref(false)
  const isReady = ref(false)
  const errors = ref(null)

  const isNeedToBeLoaded = computed(() => !get(isReady) && !get(errors))

  const variables = computed(() => ({
    dateRange: filtersStore.dateRangeStr,
  }))

  const {
    load,
    refetch,
    onResult: onResultCitiesAndProviders,
  } = useLazyQuery(citiesAndProvidersQuery, variables, {
    errorPolicy: 'all',
    returnPartialData: false,
  })

  onResultCitiesAndProviders(async ({ data, errors: newErrors, partial }) => {
    if (partial) {
      return
    }

    const citiesRes = data?.cities || []
    const providersRes = data?.providers || []

    citiesStore.saveCities(citiesRes)
    providersStore.saveProviders(providersRes)

    if (newErrors) {
      set(errors, newErrors)
      errors.value.forEach(e => console.error('cube:', e.message))
    }

    set(loading, false)
    set(isReady, true)

    fetchCube.trigger({
      cities: citiesRes,
      providers: providersRes,
    })
  })

  async function loadOrRefetchCube() {
    set(loading, true)
    try {
      return load() || refetch()
    } catch (e) {
      console.error(e)
      set(loading, false)
    }
  }

  async function waitingCube() {
    if (get(loading)) {
      await until(loading).toBe(false, { timeout: WAIT_TIMEOUT })
      await nextTick()
    }

    if (get(isNeedToBeLoaded)) {
      await until(isNeedToBeLoaded).toBe(false, { timeout: WAIT_TIMEOUT })
      await nextTick()
    }
  }

  function $reset() {
    set(isReady, false)
  }

  watch(
    () => filtersStore.dateRangeStr,
    () => {
      if (get(isReady)) {
        $reset()
        load()
      }
    },
  )

  function resetCubeCache() {
    const { cache } = cityDiveClient

    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'cities',
    })
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'providers',
    })
    cache.gc({
      resetResultCache: true,
      resetResultIdentities: true,
    })
  }

  async function reload() {
    // we need to wait for the cube to be set at first time
    if (get(loading)) {
      await until(loading).toBe(false, { timeout: WAIT_TIMEOUT })
      await nextTick()
    }

    if (get(isReady)) {
      resetCubeCache()
    }

    $reset()
    citiesStore.$reset()
    providersStore.$reset()

    return loadOrRefetchCube()
  }

  onUserLogout(async () => {
    await reload()
  })

  onOrganizationLoaded(async () => {
    await reload()
  })

  // we load the default cube data
  if (!get(token)) {
    loadOrRefetchCube()
  }

  return {
    isLoading: loading,
    isReady,
    isNeedToBeLoaded,
    errors,
    $reset,
    waitingCube,
    onCubeLoaded: fetchCube.on,
    load: loadOrRefetchCube,
  }
})

// make sure to pass the right store definition, `useCubeStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCubeStore, import.meta.hot))
}
