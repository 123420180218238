<script setup>
import citiesQuery from '@/graphql/queries/cities.gql'

const { t } = useI18n()
const filtersStore = useFiltersStore()
const { getCityNameLocalized, getCountryNameLocalized } = useCitiesStore()
const { isFreemium, settings } = storeToRefs(useUserStore())
const { getPref } = usePreferences()

const sortByOptions = [
  { value: 'nameLocalized', text: t('Alphabetical Order') },
  { value: SORT_BY_AVAILABLE_VEHICLES, text: t('Available vehicles') },
  { value: SORT_BY_PER_10K_INHAB, text: t('Vehicles per 10k inhab.') },
  { value: SORT_BY_SERVICES, text: t('Services', 2) },
  { value: SORT_BY_COUNTRIES, text: t('Countries') },
]

const viewType = ref(getPref('boards.global.viewType') || 'list')
const isFiltering = ref(true)
const showUpgradeModal = ref(false)
const citiesShallowed = shallowRef([])

const {
  sortBy,
  vehicleTypesSelected,
  locationsSelected,
  showLocked,
  filterAndSort,
  resetFilters,
  getVariableFromFilter,
} = useCityProviderFilters()

const variables = computed(() => ({
  dateRange: filtersStore.dateRangeStr,
  cities: getVariableFromFilter(
    locationsSelected.value,
    ENUM_LOCATION_TYPES.city,
  ),
  countries: getVariableFromFilter(
    locationsSelected.value,
    ENUM_LOCATION_TYPES.country,
  ),
  continents: getVariableFromFilter(
    locationsSelected.value,
    ENUM_LOCATION_TYPES.continent,
  ),
  regions: getVariableFromFilter(
    locationsSelected.value,
    ENUM_LOCATION_TYPES.region,
  ),
  vehicleTypes: vehicleTypesSelected.value.map(
    vehicleType => ENUM_VEHICLE_TYPES[vehicleType],
  ),
}))

function localizeCity(city) {
  return {
    ...city,
    nameLocalized: getCityNameLocalized(city.name),
    countryLocalized: getCountryNameLocalized(city.countryCode || city.country),
    isMonitored: city.providers.all?.some(
      provider => provider.notMonitored === false,
    ),
  }
}

const { loading, onResult, onError, error } = useQuery(citiesQuery, variables, {
  debounce: 200,
  returnPartialData: false,
})

onResult(async (result) => {
  if (result.partial) {
    return
  }

  const cities = result.data?.cities.map(city => localizeCity(city)) || []
  set(citiesShallowed, cities)
})

onError(() => {
  isFiltering.value = false
})

const isLoading = computed(() => {
  return loading.value || isFiltering.value
})

const favoriteCities = computed(() => {
  if (citiesShallowed.value.length) {
    return (
      get(settings)
        ?.favorites?.cities?.map(favoriteCity =>
          citiesShallowed.value.find(city => city.name === favoriteCity),
        )
        .filter(city => city) || []
    )
  }
  return []
})

const filteredCities = computed(() => {
  let cities = citiesShallowed.value

  if (cities.length > 0) {
    isFiltering.value = true

    cities = filterAndSort(cities)
    isFiltering.value = false
  }

  return cities
})

const monitoredOrNotTotals = computed(() => {
  const count = get(citiesShallowed).reduce((total, city) => {
    if (city.isMonitored) {
      return total + 1
    }

    return total
  }, 0)

  return {
    monitored: count,
    notMonitored: get(citiesShallowed).length - count,
  }
})
</script>

<template>
  <div>
    <SidebarContainer>
      <template #left>
        <Sidebar @reset="() => resetFilters()">
          <template #filters>
            <FilterBy
              v-model:vehicle-types="vehicleTypesSelected"
              v-model:locations="locationsSelected"
            />
            <FilterSortBy
              v-model="sortBy"
              :options="sortByOptions"
            />
            <FilterLocked v-model="showLocked" />
          </template>
        </Sidebar>
      </template>

      <BoardTitle class="md:mt-8">
        {{ t('Explore cities') }}

        <FiltersIcons :filters="['dateRange', 'filterBy', 'sortBy']" />

        <template #right>
          <RowCardViewTypeSelector
            v-model="viewType"
            v-memo="[viewType]"
          />
        </template>
      </BoardTitle>

      <DAlert
        id="cities"
        variant="info"
        closable
      >
        {{ t('Take your City Dive experience to the next level') }}
        <template #subtitle>
          <I18nMd
            keypath="click_to_upgrade"
            tag="p"
          >
            <template #link="{ translation }">
              <DLink
                :to="{ name: 'SubscribePlans' }"
                class="underline"
              >
                {{ translation }}
              </DLink>
            </template>
          </I18nMd>
        </template>
      </DAlert>

      <template v-if="favoriteCities && favoriteCities.length">
        <RowCardTitle>
          <template #header>
            {{ t('starred') }}
          </template>
          <template
            v-if="!isLoading"
            #header-right
          >
            <span class="font-sans font-bold">{{ favoriteCities.length }}</span>
            {{ t('starred') }}
          </template>
        </RowCardTitle>

        <RowCardContainer :view-type="viewType">
          <DLink
            v-for="city in favoriteCities"
            :key="city.name"
            :to="
              city.unlocked
                ? { name: 'City', params: { citySlug: city.name } }
                : isFreemium
                  ? false
                  : { name: 'SubscribePlans' }
            "
            @click="
              () => {
                if (isFreemium && !city.unlocked) {
                  showUpgradeModal = true
                }
              }
            "
          >
            <CityRowCard
              :city="city"
              :selected-vehicles="vehicleTypesSelected"
              :mode="viewType"
            />
          </DLink>
        </RowCardContainer>
      </template>

      <RowCardTitle>
        <template #header>
          {{ t('Cities') }}
          <DLoader
            v-if="isLoading"
            size="sm"
            inline
          />
        </template>
        <template
          v-if="!isLoading"
          #header-right
        >
          <span class="font-bold">{{ filteredCities.length }}</span>
          {{ t('matches', filteredCities.length) }}<br>
          <small class="text-xs text-grey-300">
            <span class="text-blue-500">
              {{ monitoredOrNotTotals.monitored }} {{ t('monitored') }}
            </span>
            / {{ monitoredOrNotTotals.notMonitored }} {{ t('unmonitored') }}
          </small>
        </template>

        <div v-if="isLoading && !filteredCities.length">
          <PlaceHolder>
            <PlaceHolderCard />
          </PlaceHolder>
        </div>

        <DAlert
          v-if="!isLoading && error"
          variant="alert"
          closable
          :title="t('An error occurred !')"
        />

        <DAlert
          v-if="!isLoading && !filteredCities.length"
          class="text-center text-sm text-grey-500"
          variant="warning"
          closable
          :title="t('No cities found')"
        />
      </RowCardTitle>

      <RowCardContainer :view-type="viewType">
        <DLink
          v-for="city in filteredCities"
          :key="city.name"
          :to="
            city.unlocked
              ? { name: 'City', params: { citySlug: city.name } }
              : isFreemium
                ? false
                : { name: 'SubscribePlans' }
          "
          @click="
            () => {
              if (isFreemium && !city.unlocked) {
                showUpgradeModal = true
              }
            }
          "
        >
          <CityRowCard
            :city="city"
            :selected-vehicles="vehicleTypesSelected"
            :mode="viewType"
          />
        </DLink>
      </RowCardContainer>
    </SidebarContainer>

    <FreemiumBecomeGreatExplorerModal
      v-if="showUpgradeModal"
      @close="() => (showUpgradeModal = false)"
    />
  </div>
</template>
