import chroma from 'chroma-js'
import type { MaybeRef } from 'vue'

interface Line {
  mean: number
}

type GeoJSONData = GeoJSON.FeatureCollection | Line[]

export function useClassBreaks(data: MaybeRef<GeoJSONData>, nbClasses: MaybeRef<number> = 5) {
  const breaks = ref<number[]>([])
  const colors = ref<string[]>([])

  if (typeof nbClasses === 'number') {
    nbClasses = ref(nbClasses)
  }

  const series = computed((): number[] => {
    const dataRef = get(data)

    if (!dataRef) {
      return []
    }

    if (Array.isArray(dataRef)) {
      return dataRef.map(l => l.mean)
    } else if (dataRef.type === 'FeatureCollection' && dataRef.features) {
      return dataRef.features
        .map(f => f.properties?.mean || 0)
        .filter(v => v !== null)
    } else {
      return []
    }
  })

  watch(
    [series, nbClasses],
    ([series, nbClasses]: [number[], number]) => {
      if (series.length === 0) {
        set(breaks, [])
        set(colors, [])
      } else if (series.length < nbClasses) {
        set(breaks, jenks(series, series.length - 1))
        set(colors, chroma.scale('YlOrRd').colors(series.length - 1))
      } else {
        set(breaks, jenks(series, nbClasses))
        set(colors, chroma.scale('YlOrRd').colors(nbClasses))
      }
    },
    {
      immediate: true,
    },
  )

  function getColorInRange(value: number): string {
    if (get(breaks).length === 0) {
      return 'rgb(240,59,32)'
    }

    const index = get(breaks).findIndex(b => b >= value) - 1
    return get(colors)[index === -1 ? 0 : index]
  }

  return {
    getColorInRange,
    breaks,
    colors,
  }
}

export default {
  useClassBreaks,
}
