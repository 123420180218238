<script setup>
defineOptions({
  name: 'CityVehicleTypeOverview',
})

const { t } = useI18n()
const { mode } = useMode()
const { modeClasses } = useModeClasses()
const { city } = storeToRefs(useCityStore())
</script>

<template>
  <MetricCard kpi="cityVehicleTypeOverview">
    <template #title>
      {{
        t(`mode_in_city.${mode}`, {
          city: city.nameLocalized,
        })
      }}
    </template>
    <template #subtitle="scoped">
      {{ t('AVG per day over last 15 days') }} -
      {{
        t(
          'data from {n} operators',
          { n: scoped.data?.providersCount ?? 0 },
          scoped.data?.providersCount ?? 0,
        )
      }}
    </template>
    <template #default="scoped">
      <div
        class="grid grid-cols-2 gap-4 md:grid-cols-3"
        :class="modeClasses"
      >
        <TrendMetric
          :data="scoped.data?.vehicles"
          :label="t('Vehicles deployed')"
          class="mb-4"
        />
        <TrendMetric
          :data="scoped.data?.usagePercent"
          :label="t('Vehicles used')"
          class="mb-4"
        >
          <template #default="{ value }">
            <template v-if="value !== null">
              {{ formatPercent(value) }}
            </template>
            <template v-else>
              {{ t('N/A') }}
            </template>
          </template>
        </TrendMetric>
        <TrendMetric
          :data="scoped.data?.tripDuration"
          :label="
            mode === VEHICLE_TYPE_CAR
              ? t('AVG Rental period')
              : t('AVG Trip time')
          "
          class="mb-4"
        >
          <template #default="{ value }">
            <template v-if="value !== null">
              {{ t('{n}min', { n: value }) }}
            </template>
            <template v-else>
              {{ t('N/A') }}
            </template>
          </template>
        </TrendMetric>
        <TrendMetric
          :data="scoped.data?.trips"
          :label="t('Trips')"
          class="mb-4"
        />
        <TrendMetric
          :data="scoped.data?.tripsPerVehicles"
          :label="t('TVD')"
          :help="t('Trips per vehicle per day')"
          class="mb-4"
        />
        <TrendMetric
          v-if="mode !== VEHICLE_TYPE_CAR"
          :data="scoped.data?.tripDistance"
          :label="t('AVG Trip distance')"
          class="mb-4"
        >
          <template #default="{ value }">
            <template v-if="value !== null">
              {{ formatDistance(value, { meters: true }) }}
            </template>
            <template v-else>
              {{ t('N/A') }}
            </template>
          </template>
        </TrendMetric>
      </div>
    </template>
  </MetricCard>
</template>
