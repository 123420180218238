<script setup>
const emit = defineEmits(['close'])
const { t } = useI18n()
const { isFreemium } = storeToRefs(useUserStore())
</script>

<template>
  <DModal @close="() => emit('close')">
    <template #title>
      {{ t('Operator locked') }}
    </template>

    <template #default="{ close }">
      <DMarkdown
        v-if="isFreemium"
        :source="
          t(
            'as_freemium_you_cannot_access_see_demo',
            { subscriptionPlan: t('data.plans.freemium') },
          )
        "
      />
      <p v-else>
        {{
          t(
            'You only have access to the data from operators that are present in at least one city that you have unlocked.',
          )
        }}
      </p>

      <p class="mt-8 flex space-x-4">
        <DButton
          :to="{ name: 'Provider', params: { provider: DEMO } }"
          @click="() => close()"
        >
          {{ t('Discover operator page features') }}
        </DButton>
        <DButton
          variant="outline-primary"
          :to="{ name: 'SubscribePlans' }"
          @click="() => close()"
        >
          {{ isFreemium ? t('upgrade_to_plan', { subscriptionPlan:
            t('data.plans.premium'),
          }) : t('Upgrade my plan') }}
        </DButton>
      </p>
    </template>
  </DModal>
</template>
