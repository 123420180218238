<script setup>
defineOptions({
  name: 'CrawlZones',
})

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  sources: {
    type: Array,
    default: () => CRAWL_ZONES_TYPE,
  },
})

const filteredCrawlZones = computed(() =>
  props.sources.length !== props.data.length
    ? props.data?.filter(zone => props.sources.includes(zone.source))
    : props.data || [],
)
</script>

<template>
  <MapLibreSourceGeojson
    v-for="(zone, index) in filteredCrawlZones"
    :id="`crawl-zone_${index}`"
    :key="index"
    :layer-props="{
      type: 'line',
      paint: {
        'line-width': 2,
        'line-opacity': 0.5,
        'line-color': zone.source === SCT ? '#f080ca' : '#455eef',
      },
    }"
    :data="zone.geojson"
  />
</template>
