<script setup>
const props = defineProps({
  image: {
    type: String,
    default: null,
  },
  tags: {
    type: Array,
    default: () => [],
  },
})

const defaultPicture = computed(() => {
  const type = props.tags?.find(t => VEHICLE_TYPES.includes(t)) || 'paper'
  return `/img/thumbnail-${type}.jpg`
})
</script>

<template>
  <DAsyncImg
    :src="props.image"
    :error-src="defaultPicture"
  >
    <template #loading>
      <div class="flex-center absolute inset-0 flex bg-grey-100">
        <DLoader />
      </div>
    </template>
  </DAsyncImg>
</template>
