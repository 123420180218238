<script setup>
import confirmUpdateUserEmailMutation from '@/graphql/mutations/confirmUpdateUserEmail.gql'

const router = useRouter()
const { t } = useI18n()
const { loadUser } = useUserStore()
const displayError = useDisplayErrors()

const invalidToken = ref(false)
const error = ref(null)

const {
  mutate: updateEmail,
  loading,
  onDone,
  onError,
} = useMutation(confirmUpdateUserEmailMutation, {
  clientId: 'captain',
})

onMounted(() => {
  const token = new URL(document.location).searchParams.get('token')

  if (!token) {
    return router.push({ name: 'SettingsSubscription' })
  }

  updateEmail({ token })
})

onDone(async (result) => {
  if (!result?.data?.error) {
    await loadUser()
    router.push({ name: 'SettingsEdit' })
  } else {
    const error = result?.data?.error

    if (error === 'invalid_token') {
      invalidToken.value = true
    } else if (error === 'email_already_used') {
      displayError(t('This email is already used'))
      error.value = t('This email is already used')
    } else {
      displayError(t('An error occurred, please retry later'))
      error.value = t('An error occurred, please retry later')
    }
  }
})

onError(() => displayError(t('An error occurred, please retry later')))
</script>

<template>
  <LoginHeader />

  <DCard class="mt-16 md:mx-auto">
    <div v-if="!loading">
      <DAlert
        v-if="invalidToken"
        variant="alert"
      >
        {{ t('That link has expired.') }}
      </DAlert>
      <DAlert
        v-if="error"
        variant="alert"
      >
        {{ error }}
      </DAlert>
    </div>
    <p
      v-else
      class="text-center"
    >
      <DLoader /> {{ t('Loading...') }}
    </p>
  </DCard>
</template>
