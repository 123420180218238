interface Region {
  code: string
}

export function getRegionsFromDataFiltered(data: any, regions: Ref<Region[]> | Region[]): Region[] {
  if (!data) {
    return get(regions)
  } else {
    return get(regions).filter(region =>
      data.regions?.filtered?.includes(region.code),
    )
  }
}

export const getRegionIcon: (region: string) => string = (region) => {
  return `world-${(region || REGION_CODE_EU).toLowerCase()}`
}
