<script setup>
const loginModalsStore = useLoginModalsStore()

onMounted(() => {
  loginModalsStore.openSignin()
})
</script>

<template>
  <div />
</template>
