<script setup>
const { t } = useI18n()

const { user, role, organization, subscription, draft, isAdmin } = storeToRefs(
  useUserStore(),
)

const hasBilling = computed(() => {
  return ['owner', 'billing'].includes(role.value)
})

const overwriteduser = computed({
  get() {
    return localStorage.getItem('DEV_USER_OVERWRITED') === 'user'
  },
  set(v) {
    if (v) {
      localStorage.setItem('DEV_USER_OVERWRITED', 'user')
    } else {
      localStorage.removeItem('DEV_USER_OVERWRITED')
    }

    location.reload()
  },
})
</script>

<template>
  <div class="space-y-4">
    <BoardTitle hide-on-mobile>
      {{ t('My account') }}
    </BoardTitle>

    <div class="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
      <DCard class="flex-1">
        <template #title>
          {{ user.name }}
        </template>

        <p class="text-base text-grey-400">
          {{ t('Email') }}
        </p>
        <p>{{ user.email }}</p>
        <DButton
          class="mt-4"
          :to="{ name: 'SettingsEdit' }"
          variant="gradient-primary"
        >
          {{ t('Edit my profile') }}
        </DButton>
      </DCard>

      <DCard class="flex-1">
        <template #title>
          {{ t('My organization') }}
        </template>

        <div class="flex flex-row space-x-4">
          <div class="flex-1">
            <p class="text-base text-grey-400">
              {{ t('Organization') }}
            </p>
            <p>{{ organization.name }}</p>
          </div>
          <div class="flex-1">
            <p class="text-base text-grey-400">
              {{ t('Address') }}
            </p>
            <p>
              {{ organization.address1 }}
              <span v-if="organization.address2">
                <br>
                {{ organization.address2 }}
              </span>
              <br>
              {{ organization.zipCode }} {{ organization.city }}
            </p>
          </div>
        </div>

        <p class="mt-4 text-base text-grey-400">
          {{
            organization.company
              ? t('VAT Number (mandatory for companies registered in the EU)')
              : t('Not a company')
          }}
        </p>

        <p v-if="organization.company && organization.vatNumber">
          <b>{{ t('VAT') }}</b>
          {{ organization.vatNumber }}
        </p>

        <DButton
          v-if="hasBilling"
          variant="gradient-primary"
          :to="{ name: 'SettingsEdit' }"
        >
          {{ t('Edit my organization') }}
        </DButton>
      </DCard>
    </div>

    <PaymentDraft
      v-if="draft && hasBilling"
      :draft="draft"
    />

    <Subscription
      v-if="subscription && hasBilling"
      manage
    />

    <DCard v-if="hasBilling">
      <template #title>
        {{ t('payment.information') }}
      </template>
      <PaymentInformation
        :payment-information="organization.paymentInformation"
      />
    </DCard>

    <DCard v-if="isAdmin">
      <template #title>
        <DIcon
          path="warning"
          size="sm"
        />
        {{ t('Admin') }}
        <DIcon
          path="warning"
          size="sm"
        />
      </template>

      <DInputSwitch
        v-model="overwriteduser"
        name="checkbox-1"
      >
        {{ t('as user') }}
      </DInputSwitch>
    </DCard>
  </div>
</template>
