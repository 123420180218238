import {
  addDays,
  differenceInDays,
  endOfDay,
  isWeekend,
  startOfDay,
} from 'date-fns'

function getWeekendsSeriesData(from = new Date(), to = new Date()) {
  const weekends = []
  const start = startOfDay(from)
  const end = endOfDay(to)

  for (let i = 0; i <= differenceInDays(end, start); i = i + 1) {
    const day = addDays(start, i)

    weekends.push({
      x: formatDate(day, DATE_FORMAT),
      y: isWeekend(day) ? 1 : 0,
    })
  }

  return weekends
}

export default getWeekendsSeriesData
