<script setup>
defineOptions({
  name: 'FlowMap',
})

const props = defineProps({
  zoom: {
    type: Number,
    default: 8,
  },
  center: {
    type: Array,
    default: () => [-0.09, 51.505],
  },
  data: {
    type: Object,
    default: () => ({
      lines: [],
      providers: [],
    }),
  },
  options: {
    type: Object,
    default: () => ({
      dataScale: 5,
      geometryType: 'h3_8',
      providers: [],
    }),
  },
  legendTitle: {
    type: String,
    default: null,
  },
  loading: Boolean,
})

const { dataScale, geometryType } = toRefs(props.options)

const hoverElement = ref(null)

const { t } = useI18n()
const featuresFlagsStore = useFeaturesFlagsStore()
const { selectedArea, onSelectArea, unselectAllAreas, getAreaName }
  = useSelectedAreaFromUrl(geometryType)

const lines = computed(() => props.data?.lines)
const { breaks, getColorInRange } = useClassBreaks(lines, dataScale)

const flowData = computed(
  () =>
    get(lines)?.reduce(
      (acc, { from, to, mean }) => {
        if (!acc.locations.find(({ id }) => id === from.code)) {
          acc.locations.push({
            id: from.code,
            name: `[${from.latlng.join(', ')}]`,
            lat: Number(from.latlng[0]),
            lon: Number(from.latlng[1]),
          })
        }

        if (!acc.locations.find(({ id }) => id === to.code)) {
          acc.locations.push({
            id: to.code,
            name: `[${to.latlng.join(', ')}]`,
            lat: Number(to.latlng[0]),
            lon: Number(to.latlng[1]),
          })
        }

        acc.flows.push({
          origin: `${from.code}`,
          dest: `${to.code}`,
          count: Number(mean),
          color: get(getColorInRange)(Number(mean)),
        })

        return acc
      },
      { locations: [], flows: [] },
    ) || {
      locations: [],
      flows: [],
    },
)

function onFeatureClick(info) {
  if (props.loading) {
    return
  }

  onSelectArea(info?.object?.id)
}

function onFeatureHover(info) {
  if (props.loading) {
    return
  }

  if (info) {
    set(hoverElement, info)
  } else {
    set(hoverElement, null)
  }
}

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
  >
    <MapLibrePopup
      v-if="hoverElement"
      :lng-lat="hoverElement?.coordinate || [0, 0]"
      :offset="[0, -10]"
    >
      <template #element>
        <div class="flex w-28 flex-col whitespace-normal text-xs">
          <template v-if="hoverElement.object.type === 'flow'">
            <p class="font-bold">
              {{ t('origin:') }}
            </p>
            <p class="whitespace-nowrap">
              {{ getAreaName(hoverElement.object.origin.id, ' ') }}
            </p>
            <p class="font-bold">
              {{ t('destination:') }}
            </p>
            <p class="whitespace-nowrap">
              {{ getAreaName(hoverElement.object.dest.id, ' ') }}
            </p>
            <p class="mt-2 font-bold">
              {{
                `${formatNumber(hoverElement.object.count, ' ')} ${t('trips')}`
              }}
            </p>
          </template>

          <template v-else>
            <p class="mb-2 whitespace-nowrap">
              {{ getAreaName(hoverElement.object.id, ' ') }}
            </p>
            <p
              v-for="(count, key) in hoverElement.object.totals"
              :key="key"
              class="whitespace-nowrap font-bold"
            >
              {{
                `${formatNumber(count)} ${t(key.replace('Count', ''))} ${t(
                  'trips',
                  count,
                )}`
              }}
            </p>
          </template>

          <p
            v-if="featuresFlagsStore.get('map_selected_area')"
            class="mt-4 text-grey-300"
          >
            {{ t('Click on zone for more details') }}
          </p>
        </div>
      </template>
    </MapLibrePopup>

    <Geometry
      :geometry-type="geometryType"
      :locations="flowData.locations"
    >
      <template v-if="!loading">
        <FlowMapLayer
          id="city-map"
          :opacity="selectedArea.length === 0 ? 0.7 : 0.01"
          :data="flowData"
          :clustering-level="dataScale"
          :color-scheme="breaks?.length ? breaks.map(getColorInRange) : null"
          @click="event => onFeatureClick(event)"
          @hover="
            event => {
              if (selectedArea.length === 0) {
                onFeatureHover(event)
              }
            }
          "
        />
        <FlowMapLayer
          v-if="selectedArea.length > 0"
          id="city-map-selected"
          :opacity="0.8"
          :data="flowData"
          :clustering-level="dataScale"
          :color-scheme="breaks?.length ? breaks.map(getColorInRange) : null"
          :filter="{
            selectedLocations: selectedArea,
            locationFilterMode: 'ALL',
          }"
          @click="event => onFeatureClick(event)"
          @hover="event => onFeatureHover(event)"
        />
      </template>
    </Geometry>

    <MapLibreControl
      v-if="selectedArea.length !== 0"
      position="top-right"
      no-style
      class="rounded-full p-4"
    >
      <DButton
        variant="fill-primary"
        icon-left="cross"
        @click="unselectAllAreas"
      >
        {{ t('Unselect all') }}
      </DButton>
    </MapLibreControl>

    <DefineTemplate>
      <DLoader v-if="loading" />
      <MapLegend
        v-else-if="breaks"
        :breaks="breaks"
        :get-color="getColorInRange"
      >
        <template
          v-if="legendTitle"
          #title
        >
          {{ legendTitle }}
        </template>
      </MapLegend>
    </DefineTemplate>

    <SidebarMobileControls>
      <template #default="{ isMobile }">
        <MapLibreControl
          v-if="!isMobile && (loading || breaks)"
          position="bottom-left"
        >
          <ReuseTemplate />
        </MapLibreControl>
      </template>

      <template #mobile>
        <ReuseTemplate />
      </template>
    </SidebarMobileControls>
  </MapLibre>
</template>
