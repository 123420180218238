<script setup>
defineOptions({
  name: 'ProviderFootprintsOverview',
})

const { t } = useI18n()
const filtersStore = useFiltersStore()
const { mode } = useMode()
const { modeClasses } = useModeClasses()

const { provider } = storeToRefs(useProviderStore())
const { providerRegions } = useFilterRegion(provider)
</script>

<template>
  <MetricCard
    kpi="providerFootprintsOverview"
    no-padding
  >
    <template #default="{ data }">
      <div class="min-h-[240px] flex-1">
        <WorldmapMetric
          :options="{ color: PALETTE_COLORS_VEHICLES[mode] }"
          :data="data?.countries"
          :region="filtersStore.region || providerRegions[0]?.code"
        />
      </div>

      <div class="flex items-center mx-4 justify-between py-2">
        <p class="font-bold">
          {{ t('Footprint') }} <br>
          <FiltersIcons
            class="gap-2 inline-flex"
            :filters="['mode', 'region']"
          />
        </p>
        <p class="text-sm text-slate-500">
          <I18nMd
            keypath="in_countries_nbr"
            tag="span"
            class="whitespace-nowrap"
            :plural="data?.countries.length ?? 0"
            :variables="{ n: data?.countries.length ?? 0 }"
          >
            <template #n="{ translation }">
              <span
                class="font-bold text-md text-grey-500"
                :class="modeClasses"
              >{{ translation }}</span>
            </template>
          </I18nMd>
          /
          <I18nMd
            keypath="in_cities_nbr"
            tag="span"
            class="whitespace-nowrap"
            :plural="data?.cities.length ?? 0"
            :variables="{ n: data?.cities.length ?? 0 }"
          >
            <template #n="{ translation }">
              <span
                class="font-bold text-md text-grey-500"
                :class="modeClasses"
              >{{ translation }}</span>
            </template>
          </I18nMd>
          <br>
          ({{ t('monitored by City Dive') }})
        </p>

        <DLink
          class="font-bold"
          :class="modeClasses"
          :to="{ name: 'ProviderFootprint' }"
        >
          {{ t('View cities') }}
          <DIcon
            path="chevron-right"
            size="xs"
          />
        </DLink>
      </div>
    </template>
  </MetricCard>
</template>
