<script setup>
defineProps({
  value: Boolean,
})
const emit = defineEmits(['change'])
const { t } = useI18n()
</script>

<template>
  <div
    class="inline-flex w-28 cursor-pointer overflow-hidden whitespace-nowrap rounded-full bg-white text-xs font-bold text-blue-500"
    @click="emit('change')"
  >
    <span
      class="flex-1 p-1 text-center max-w-8 text-ellipsis overflow-hidden whitespace-nowrap"
      :class="{ 'bg-blue-500 text-white': !value }"
      :title="t('All')"
    >{{ t('All') }}</span>
    <span
      class="flex-1 p-1 text-center max-w-8 text-ellipsis overflow-hidden whitespace-nowrap"
      :class="{ 'bg-blue-500 text-white': value }"
      :title="t('Any of')"
    >{{ t('Any of') }}</span>
  </div>
</template>
