<script setup>
const props = defineProps({
  provider: {
    type: Object,
    required: true,
  },
})
const { provider } = toRefs(props)

const { linkAttrs, canSeeBlockedModal, showBlockedModal }
  = useProviderLink(provider)
</script>

<template>
  <component
    :is="linkAttrs.is"
    :to="linkAttrs.to"
    class="block"
    :class="{
      'cursor-pointer': canSeeBlockedModal,
    }"
    @click="() => linkAttrs.action()"
  >
    <slot />
    <template v-if="canSeeBlockedModal && showBlockedModal">
      <slot
        name="modal"
        :close="() => (showBlockedModal = false)"
      >
        <FreemiumOperatorBlockedModal
          @close="() => (showBlockedModal = false)"
        />
      </slot>
    </template>
  </component>
</template>
