import { markRaw } from 'vue'
import { createPinia } from 'pinia'

import i18n from './i18n'

const pinia = createPinia()

pinia.use(({ store }) => {
  store.i18n = markRaw(i18n.global)
})

export default pinia
