<script setup lang="ts">
import type { MetricPercentChart, PieMetricOptions } from '@/types/charts.types'
import type { Color } from '@/utils/colors'

defineOptions({
  name: 'PieMetric',
})

const props = withDefaults(defineProps<PieMetricProps>(), {
  options: () => ({}),
  legend: true,
})

type PieMetricProps = MetricPercentChart<PieMetricOptions>

const series = computed(() => {
  if (!props.data) {
    return []
  }

  return props.data.map((d, dataIndex) => {
    const formatedLabel = props.options.label ? props.options.label(d) : d.label

    return {
      ...d,
      label: formatedLabel,
      name: d.label,
      data: [d.value],
      percent: d.percent,
      color: (props.options.color && props.options.color(d)) || d.color || getColorByIndex(dataIndex),
    }
  })
})

const chartData = computed(() => {
  if (!series.value) {
    return {}
  }

  return {
    labels: series.value.map((d) => {
      return {
        label: d.label,
        estimated: Boolean(d.estimated),
      }
    }),
    datasets: [
      series.value.reduce(
        (dataset, d) => {
          dataset.data.push(d.value)
          dataset.backgroundColor.push(d.color as string)

          return dataset
        },
        {
          backgroundColor: [] as Color[],
          data: [] as number[],
        },
      ),
    ],
  }
})
</script>

<template>
  <div class="pie-metric">
    <div v-if="data.length">
      <PieChart
        :data="chartData"
        :options="options"
      />
      <div
        v-if="legend"
        class="pt-4"
      >
        <ChartLegend
          class="flex-col"
          :series="series"
          :dimension="options.dimension"
          :sub-legend="d => ('percent' in d ? ` ${d.percent}%` : null)"
          :selectable="false"
        />
      </div>
    </div>
  </div>
</template>
