import updateUserLangMutation from '@/graphql/mutations/updateUserLang.gql'
import localesConfig from '@/locales'

export function useLocaleSetting() {
  const { user, isAdmin } = storeToRefs(useUserStore())
  const { t, locale, availableLocales } = useI18n()

  const { mutate: updateUserLang } = useMutation(updateUserLangMutation, {
    clientId: CAPTAIN,
  })

  whenever(
    locale,
    (locale) => {
      if (user?.value?.lang === locale) {
        return
      }

      try {
        updateUserLang({
          lang: locale,
        })
      } catch (e) {
        console.error(e)
      }
    },
  )

  function updateLocale(newLocale: string) {
    return locale.value = newLocale || 'en-GB'
  }

  return {
    updateLocale,
    localesOptions: computed(() => {
      return localesConfig
        .filter((l) => {
          if (l.admin && !isAdmin.value) {
            return false
          }

          return availableLocales.includes(l.key.split('-')[0])
        })
        .map((l) => {
          const isBeta = l.beta ? ` (${t('BETA')})` : ''
          const isAdmin = l.admin ? ` (${t('Admin')})` : ''

          return {
            text: t(`data.locales_select.${l.key}`) + isBeta + isAdmin,
            value: l.key,
          }
        })
    }),
    locale,
  }
}
