<script setup lang="ts">
defineOptions({
  name: 'I18nMd',
})

const props = defineProps<{
  keypath: string
  tag?: string
  variables?: Record<string, string | number>
  plural?: number
}>()

const { variables, plural, keypath } = toRefs(props)
const { t } = useI18n()

const translated = computed(() => {
  const kp = keypath.value
  const v = variables?.value || {}

  return typeof plural?.value !== 'undefined' ? t(kp, plural?.value, v) : t(kp, v)
})
const regexSplit = /(<[a-z0-9]+>.*?<\/[a-z0-9]+>)/ig
const regex = /<([a-z0-9]+)>(.*?)<\/([a-z0-9]+)>/ig

const splited = computed(() => {
  const arr = translated.value.split(regexSplit).filter(s => s !== '')
  return arr.map((str) => {
    const isSlot = str.match(regex)
    const ex = isSlot ? regex.exec(str) : null

    return ex && ex[1] === ex[3]
      ? {
          slot: ex[1],
          translation: ex[2].trim(),
        }
      : str
  })
})
</script>

<template>
  <component :is="tag || 'span'">
    <template
      v-for="str in splited"
      :key="str"
    >
      <template v-if="typeof str === 'object'">
        <slot
          :name="str.slot"
          :translation="str.translation"
        >
          {{ str.translation }}
        </slot>
      </template>
      <template v-else>
        {{ str }}
      </template>
    </template>
  </component>
</template>
