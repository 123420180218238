<script setup>
const { t } = useI18n()
const router = useRouter()
const { error, loading, freemiumPlan } = useFreemiumPlan()
</script>

<template>
  <DModal
    size="lg"
    closable
  >
    <template #title>
      {{ t('upgrade_to_plan', { subscriptionPlan: t('data.plans.premium') }) }}<br>
      {{ t('to view operator pages') }}
    </template>

    <DMarkdown
      class="py-4 text-sm"
      :source="
        t(
          'to_access_more_you_must_become_plan_or_see_demo',
          { subscriptionPlan: t('data.plans.premium') },
        )
      "
    />

    <DLoader v-if="loading" />

    <DAlert
      v-if="error"
      variant="alert"
      :title="t('Something went wrong. Please try again later.')"
      :subtitle="error.message"
    />

    <FreemiumOnboardingPlans
      v-if="freemiumPlan"
      :freemium-plan="freemiumPlan"
      @explore="router.push({ name: 'Provider', params: { provider: DEMO } })"
      @upgrade="router.push({ name: 'SubscribePlans' })"
    >
      <template #upgrade-text>
        {{ t('Upgrade now') }}
      </template>
      <template #explore-text>
        {{ t('Explore demo operator') }}
      </template>
    </FreemiumOnboardingPlans>
  </DModal>
</template>
