import { useDateTranslation } from './translation'
import { DATES_FORMATS } from '@/utils/constants'

export function useChartExtendedOptions(options, datasets) {
  const filtersStore = useFiltersStore()
  const { translateDate } = useDateTranslation()
  const { weathers } = useWeather()

  const showWeather = computedEager(
    () =>
      get(options).weather !== false
      && get(options).scales.x.type === 'time'
      && filtersStore.timeMode === TimeModes.DAY
      && ((get(options).isCompare && !filtersStore.comparativeRange)
      || !get(options).isCompare),
  )

  const showWeekend = computedEager(
    () =>
      get(options).weekend !== false
      && get(options).scales.x.type === 'time'
      && filtersStore.timeMode === TimeModes.DAY
      && ((get(options).isCompare && !filtersStore.comparativeRange)
      || !get(options).isCompare),
  )

  const showStatus = computedEager(() => {
    const datasetsRef = get(datasets)
    return datasetsRef?.length === 1 && Boolean(datasetsRef[0].status)
  })

  const statusScales = computed(() => {
    if (get(showStatus)) {
      return {
        yStatus: {
          position: 'right',
          min: 0,
          max: 1,
          ticks: { display: false },
          grid: { display: false },
        },
        xStatus: {
          type: 'time',
          display: false,
          grid: { display: false },
          time: {
            unit: TimeModes.DAY,
          },
          offset: !get(options).scales.x.stacked,
        },
      }
    }

    return null
  })

  const statusSeries = computed(() => {
    if (get(showStatus)) {
      const { status } = get(datasets)[0]

      return {
        label: 'status',
        type: 'bar',
        backgroundColor: status.map(getDataColorByStatus),
        yAxisID: 'yStatus',
        xAxisID: 'xStatus',
        barThickness: 'flex',
        categoryPercentage: 1,
        barPercentage: 1,
        data: status.map(({ status, day }) => {
          const statusArray = (Array.isArray(status) && status) || [status]

          return {
            x: day,
            y: statusArray.length >= 1 ? 1 : 0,
          }
        }),
        status,
      }
    }

    return null
  })

  const weekendScales = computed(() => {
    return {
      yWeekend: {
        position: 'right',
        min: 0,
        max: 1,
        ticks: { display: false },
        grid: { display: false },
      },
      xWeekend: {
        type: 'time',
        stacked: true,
        display: false,
        time: {
          unit: TimeModes.DAY,
        },
        offset: !get(options).scales.x.stacked,
      },
    }
  })

  const weekendSeries = computed(() => {
    return {
      label: CHART_WEEKEND_FILL_LABEL,
      type: 'bar',
      backgroundColor: 'rgba(5, 16, 43, 0.06)',
      xAxisID: 'xWeekend',
      yAxisID: 'yWeekend',
      categoryPercentage: 1.0,
      barPercentage: 1.0,
      data: getWeekendsSeriesData(
        filtersStore.dateRange.start,
        filtersStore.dateRange.end,
      ),
    }
  })

  const cleanWeather = (v) => {
    const last = Array.isArray(v) ? v[v.length - 1] : v.slice(-1)
    const weathersRef = get(weathers)
    let result = false
    let i = 0

    while (last !== weathersRef[i] && i < weathersRef.length) {
      i++
    }

    result = last === weathersRef[i]

    if (result && Array.isArray(v)) {
      const clean = v.slice(0, -1)
      return clean.length === 1 ? clean[0] : clean
    } else if (result) {
      return v.slice(0, -1)
    }

    return v
  }

  const weatherTicksCallback = computed(() => (value, index) => {
    const w = get(weathers)?.[index]
    return w ? [cleanWeather(value), w] : value
  })

  const getExtendedOptions = computed(() => (scales) => {
    const newScales = { ...scales }

    if ((showWeekend.value || showStatus.value) && scales.y) {
      newScales.y.position = 'left'
    }

    if (showWeekend.value) {
      Object.assign(newScales, weekendScales.value)
    }

    if (showStatus.value) {
      Object.assign(newScales, statusScales.value)
    }

    const tickCallback = scales.x.ticks?.callback

    // Prepare the format of the date in X axis
    if (!tickCallback && scales.x.type === 'time') {
      const timeMode = get(options).scales.x.time.unit || filtersStore.timeMode
      const formatOfDate = timeMode === 'week'
        ? DATES_FORMATS.FULL
        : timeMode === 'month'
          ? DATES_FORMATS.MONTH
          : DATES_FORMATS.DAY

      const formatTickDate = v => translateDate(v, formatOfDate)

      // Add weather to X axis with the format of the date
      if (showWeather.value) {
        newScales.x.ticks = {
          ...newScales.x.ticks,
          callback: (...args) => {
            const value = formatTickDate(...args)
            return weatherTicksCallback.value(value, args[1])
          },
        }
      } else {
        newScales.x.ticks = {
          ...newScales.x.ticks,
          callback: formatTickDate,
        }
      }

      return newScales
    }

    // Add weather to X axis
    if (showWeather.value) {
      newScales.x.ticks = {
        ...newScales.x.ticks,
        callback: (...args) => {
          const value = tickCallback(...args) || args[0]
          return weatherTicksCallback.value(value, args[1])
        },
      }
    }

    return newScales
  })

  return {
    statusScales,
    statusSeries,
    weekendScales,
    weekendSeries,
    showWeather,
    showWeekend,
    showStatus,
    getExtendedOptions,
  }
}
