import plansQuery from '@/graphql/queries/plans.gql'

export function useFreemiumPlan() {
  const { result, error, loading } = useQuery(
    plansQuery,
    { product: PRODUCT },
    { clientId: CAPTAIN },
  )

  const freemiumPlan = computed(
    () => get(result)?.plans.find(plan => plan.data?.freemium === true) || null,
  )

  return {
    result,
    error,
    loading,
    freemiumPlan,
  }
}
