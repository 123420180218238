<script setup>
defineProps({ row: Boolean, inline: Boolean })

const slots = useSlots()
const labelStr = computed(() => slots.label()[0].children)

provide(PROVIDE_UI_FIELD_LABEL, labelStr.value)
</script>

<template>
  <div
    class="d-field"
    :class="{
      'flex items-center': row && !inline,
      'inline-flex items-center': row && inline,
      'inline-block': !row && inline,
      'mb-4': !row || inline,
    }"
  >
    <label
      class="flex flex-wrap justify-between pb-1 font-bold text-xs text-grey-200"
      :class="{
        'font-bold': row,
      }"
    >
      <span
        :class="{
          'mr-2': !row,
          'mr-2': row,
        }"
      >
        <slot name="label" />
      </span>
      <span><slot name="label-right" /></span>
    </label>
    <slot />
  </div>
</template>
