import { resolveValue, tokenizePath } from 'path-value'

export function usePreferences() {
  const userStore = useUserStore()

  return {
    setPref: (path, value) => {
      try {
        const pathTokens = tokenizePath(path)

        pathTokens.reduce((acc, token, index) => {
          if (index === pathTokens.length - 1) {
            acc[token] = value
          } else {
            acc[token] = acc[token] || {}
          }

          return acc[token]
        }, userStore.orgaPreferences)
      } catch (_) {
        console.warn('Error while setting pref', path, value)
        return null
      }
    },
    getPref: (path) => {
      try {
        return toRaw(resolveValue(userStore.orgaPreferences, path))
      } catch (_) {
        return null
      }
    },
  }
}
