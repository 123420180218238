<script setup>
import { usePlaceHoldersClasses } from './placeHoldersClasses'

defineProps({
  lines: {
    type: Number,
    default: 4,
  },
})

const { classes } = usePlaceHoldersClasses()
</script>

<template>
  <div class="mt-4 space-y-4">
    <div
      v-for="n in lines"
      :key="n"
      class="h-4 w-full bg-grey-400/10 odd:w-10/12 even:w-11/12"
      :class="classes"
    />
  </div>
</template>
