<script setup>
const { t } = useI18n()

const route = useRoute()
const loginModalsStore = useLoginModalsStore()
const { isLogged, user } = storeToRefs(useUserStore())
const { logout } = useUserStore()

const textLite = computed(() => route.name === 'Register')
</script>

<template>
  <div
    class="relative z-hop flex items-center justify-between px-4 pt-4 md:px-16 md:pt-10"
  >
    <RouterLink to="/">
      <img
        src="/img/logo.svg"
        alt="City Dive"
        class="w-24 md:hidden"
      >
      <img
        src="/img/logo_colored.svg"
        alt="City Dive"
        class="hidden w-32 md:block"
      >
    </RouterLink>

    <p v-if="isLogged">
      <DMenu
        variant="none"
        :value="user.name"
        :title="t('Logged in as :')"
      >
        <DButton
          variant="fill-secondary"
          @click="logout"
        >
          {{
            t('Logout')
          }}
        </DButton>
      </DMenu>
    </p>
    <p v-else>
      <span
        class="mr-4 hidden md:inline"
        :class="textLite ? 'text-white' : 'text-grey-500'"
      >
        {{ t('Already have an account?') }}
      </span>
      <DButton
        variant="fill-secondary"
        @click="() => loginModalsStore.openSignin()"
      >
        {{ t('Log in') }}
      </DButton>
    </p>
  </div>
</template>
