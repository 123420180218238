<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityIndexMetric',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())

const { writeCache } = useWriteCacheForMetric('cityCyclabilityIndex')
const { dateRange } = storeToRefs(useFiltersStore())

onMounted(() => {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
  }, {
    date: dateRange.value.start.toString(),
    index: random(0, 100) / 10,
    lastMonth: {
      __typename: 'Percent',
      value: random(0, 10),
      trend: {
        percent: random(0, 100) / 10,
        direction: Directions.UP,
        positive: true,
      },
    },
    lastYear: {
      __typename: 'Percent',
      value: random(0, 10),
      trend: {
        percent: random(0, 100) / 10,
        direction: Directions.DOWN,
        positive: true,
      },
    },
  })
})
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityIndex"
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.ciclability_index') }}
    </template>

    <template #default="{ data }">
      <div class="grid grid-cols-2 gap-4 md:grid-cols-3">
        <div>
          <p class="text-sm">
            {{ formatDate(data.date, DATES_FORMATS.MONTH) }}
          </p>
          <TrendMetric :data="{ value: data.index }" />
        </div>
        <div>
          <p>{{ `${t('date_vs_date')} ${t('cyclability.previus_month')}` }}</p>
          <Trend
            :trend="data.lastMonth.trend.percent"
            :direction="data.lastMonth.trend.direction"
          />
        </div>
        <div>
          <p>{{ `${t('date_vs_date')} ${t('cyclability.same_month_last_year')}` }}</p>
          <Trend
            :data="data.lastYear.trend.percent"
            :direction="data.lastYear.trend.direction"
          />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
