import { defineStore } from 'pinia'

// to use feature flag add `?ff=map_selected_area:1` to the url to enable it or `?ff=map_selected_area:0` to disable it

const flagsList = ['map_selected_area', 'screen']

export const useFeaturesFlagsStore = defineStore('featuresFlags', {
  state: () => {
    return {
      flags: useStorage('ff', new Map(), localStorage, { mergeDefaults: true }),
    }
  },
  actions: {
    updateFromQuery(query) {
      const flags = query.split('-').map(flagValue => flagValue.split(':'))

      flags.forEach(([flag, value]) => {
        if (flagsList.includes(flag)) {
          this.flags.set(flag, value === '1')
        }
      })
    },
    get(flag) {
      return this.flags.get(flag)
    },
  },
})

// make sure to pass the right store definition, `useFeaturesFlagsStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useFeaturesFlagsStore, import.meta.hot),
  )
}
