<script setup>
/*
  + [ ] Validation name/email  pour l'iban
  + [ ] tester la creation du token ou source
*/
defineProps({
  subscription: {
    type: Object,
    default: null,
  },
  plans: {
    type: Array,
    default: null,
  },
})

const emit = defineEmits(['close', 'updated', 'failed'])

const { t } = useI18n()

const inputPaymentMethod = ref(0)
</script>

<template>
  <DModal @close="emit('close')">
    <template #title>
      {{ t('Add payment method') }}
    </template>

    <DTabs v-model="inputPaymentMethod">
      <DTab
        :title="t('credit_card')"
        default
      >
        <PaymentMethodCard
          :subscription-id="subscription?.id || null"
          :plans="plans"
          @updated="emit('updated')"
          @failed="emit('failed')"
        />
      </DTab>

      <DTab :title="t('bank_account')">
        <PaymentMethodIban
          :subscription-id="subscription?.id || null"
          :plans="plans"
          @updated="emit('updated')"
          @failed="emit('failed')"
        />
      </DTab>
    </DTabs>
  </DModal>
</template>
