<script setup>
defineProps({
  crawls: {
    type: Array,
    default: () => [],
  },
  geometries: {
    type: Array,
    default: () => [],
  },
  isLoading: Boolean,
  zoom: {
    type: Number,
    default: 8,
  },
  center: {
    type: Array,
    default: () => [51.505, -0.09],
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const featuresFlagsStore = useFeaturesFlagsStore()
const navigationStore = useNavigationStore()
const { translateFromData } = useLabelTranslation()

const displayOptions = [
  { value: USED, text: t('Used Vehicles') },
  { value: AVAILABLE, text: t('Deployed vehicles') },
]

const display = computed({
  get: () => route.params.indicator || AVAILABLE,
  set: (value) => {
    router.push({
      params: {
        ...route.params,
        indicator: value,
      },
    })
  },
})

const kpi = computed(() =>
  get(display) === AVAILABLE
    ? 'availableVehiclesDailyZone'
    : 'usedVehiclesDailyZone',
)

const providers = computed({
  get: () => (route.params.providers ? route.params.providers.split('-') : []),
  set: (value) => {
    router.push({
      params: {
        ...route.params,
        providers: value.join('-'),
      },
    })
  },
})

const options = reactive({
  dataScale: 5,
  geometryType: getGeometryTypeFromUrl(route.params.areas),
  providers,
  display,
})

const variables = computed(() => {
  const vehicleType
    = route.params.mode !== VEHICLE_TYPE_ALL ? route.params.mode : null

  return {
    vehicleType: ENUM_VEHICLE_TYPES[vehicleType],
    zone: options.geometryType,
    providers: options.providers,
  }
})

const { data, loading } = useMetricData(kpi, variables)

onMounted(() => {
  globalEmitter.on('filters:reset', () => {
    options.dataScale = 5
    options.geometryType = getGeometryTypeFromUrl(route.params.areas)
    options.providers = []
    options.display = AVAILABLE
  })
})

onBeforeUnmount(() => {
  globalEmitter.off('filters:reset')
})
</script>

<template>
  <Teleport
    v-if="navigationStore.filtersIsMounted"
    to="#teleport-sidebar"
  >
    <CityMapSettings
      v-model:display="options.display"
      v-model:geometryType="options.geometryType"
      v-model:dataScale="options.dataScale"
      v-model:providers="options.providers"
      :providers-list="data?.providers || []"
      :display-options="displayOptions"
      :geometries-options="
        geometries.map(geometry => ({
          value: geometry.name,
          text: translateFromData('geometry', geometry.name),
        }))
      "
      :geometries-loading="isLoading"
    />
  </Teleport>

  <div class="h-full">
    <AreaMap
      v-if="center"
      :data="data"
      :loading="loading"
      :zoom="zoom"
      :center="center"
      :crawls="crawls"
      :geometries="geometries"
      :options="options"
      :legend-title="displayOptions.find(o => o.value === options.display).text"
    />

    <CityMapSidebar v-if="featuresFlagsStore.get('map_selected_area')">
      <CityMapAreasInfo />
    </CityMapSidebar>
  </div>
</template>
