<script setup>
defineOptions({
  name: 'FavoriteCard',
})

const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: null,
    validator: (value) => {
      return ['city', 'provider'].includes(value)
    },
  },
})

const { isFavorite, setFavorite } = useFavorite()
const { t } = useI18n()

const isStarred = computed(() => {
  return isFavorite(props.slug, props.type)
})

function star() {
  setFavorite(props.slug, props.type)
}
</script>

<template>
  <DLink
    class="bg-white rounded-md text-blue-500 flex items-center"
    @click="() => star()"
  >
    <FavoriteStar :favorite="isStarred" />
    {{ isStarred ? t('Remove as stared') : t('Add as stared') }}
  </DLink>
</template>
