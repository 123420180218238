<script setup>
const props = defineProps({
  provider: {
    type: Object,
    required: true,
  },
  selectedVehicles: {
    type: Array,
    default: () => VEHICLE_TYPES,
  },
  mode: {
    type: String,
    default: 'list',
  },
})

const { t } = useI18n()
const { providerName } = useProviderName()
const { getCountryNameLocalized, getCityNameLocalized } = useCitiesStore()
const { isProviderFavorite, setProviderFavorite } = useFavorite()

const availableVehicles = computed(
  () => (props.provider.data || {})[AVAILABLE_VEHICLES] || {},
)
</script>

<template>
  <RowCard
    :class="{
      '!border-gray-400': !provider.availableInCD,
      '!border-amber-700': provider.availableInCD && !provider.unlocked,
    }"
    :mode="mode"
  >
    <div class="relative">
      <ProviderIcon
        :slug="provider.slug"
        class="ml-2 h-7 w-7 md:h-9 md:w-9"
        :class="{
          'md:ml-0 md:p-0': mode === 'list',
          'md:h-7 md:w-7': mode !== 'list',
        }"
        :size="mode === 'list' ? 'md' : 'sm'"
      />
      <span
        v-if="!provider.unlocked && provider.availableInCD"
        class="pointer-events-none !absolute -top-1 -right-1 -m-1"
      >
        <DIcon
          path="cadenas"
          size="sm"
          class="locked-icon"
        />
      </span>
    </div>

    <p class="my-0 mx-2 flex-1 font-bold leading-3">
      {{ providerName(provider) }}
    </p>

    <FavoriteStar
      class="order-none"
      :class="{ 'md:order-first': mode === 'list' }"
      :favorite="isProviderFavorite(provider.slug)"
      @click.prevent.stop="() => setProviderFavorite(provider.slug)"
    />

    <template #right>
      <VehiclesTypesList
        :vehicle-types="provider.vehicleTypes"
        :selected="selectedVehicles"
        fixed-places
      />
    </template>

    <template
      v-if="!provider.availableInCD"
      #metrics
    >
      <p class="w-full text-xs font-bold text-grey-300">
        <DIcon
          name="warning-colored"
          size="sm"
        />{{
          t('Vehicle data not yet monitored.')
        }}<br>
      </p>
    </template>

    <template
      v-else-if="provider.unlocked"
      #metrics
    >
      <p
        v-if="!provider.data"
        class="m-4 w-48 text-xs text-grey-500"
      >
        {{ t('No vehicles data to show') }}
      </p>

      <RowCardMetrics
        v-else
        :rows="[
          t('Vehicles (AVG)'),
          availableVehicles.value
            ? formatNumber(availableVehicles.value)
            : null,
          availableVehicles.total === availableVehicles.value
            ? null
            : `/ ${formatNumber(availableVehicles.total)}`,
        ]"
        :tooltip="
          t(
            'The top value changes according to the location and vehicle type filters. The bottom value represents the total during the time period choosen.',
          )
        "
      />

      <RowCardMetrics
        :rows="[
          t('Countries'),
          formatNumber(provider.countries.filtered?.length ?? 0),
          provider.countries.filtered?.length === provider.countries.all?.length
            ? null
            : `/ ${formatNumber(provider.countries.all?.length ?? 0)}`,
        ]"
        :tooltip="
          provider.countries.filtered
            && `${provider.countries.filtered
              .slice(0, 10)
              .map(getCountryNameLocalized)
              .join(', ')}${
              provider.countries.filtered?.length > 10 ? '...' : ''
            }`
        "
      />
      <RowCardMetrics
        :rows="[
          t('Cities'),
          formatNumber(provider.cities.filtered?.length ?? 0),
          provider.cities.filtered?.length === provider.cities.all?.length
            ? null
            : `/ ${formatNumber(provider.cities.all?.length ?? 0)}`,
        ]"
        :tooltip="
          provider.cities.filtered
            && `${provider.cities.filtered
              .slice(0, 10)
              .map(getCityNameLocalized)
              .join(', ')}${provider.cities.filtered.length > 10 ? '...' : ''}`
        "
      />
    </template>

    <!-- provider lockded -->
    <template
      v-else
      #metrics
    >
      <p class="w-full text-xs font-bold">
        <I18nMd
          class="w-full text-xs font-bold"
          keypath="upgrade_to_see_data_from"
          tag="p"
          :variables="{
            subject: providerName(provider),
          }"
        >
          <template #link="{ translation }">
            <RouterLink
              :to="{ name: 'SubscribePlans' }"
              class="underline"
            >
              {{ translation }}
            </RouterLink>
          </template>
          <template #bold="{ translation }">
            <span class="font-bold">{{ translation }}</span>
          </template>
        </I18nMd>
      </p>
    </template>
  </RowCard>
</template>

<style lang="pcss">
.locked-icon svg {
  filter: drop-shadow(-3px 3px 4px rgba(0, 0, 0, 0.1));

  path {
    stroke: white;
    stroke-width: 3px;
    paint-order: stroke;
    fill-rule: evenodd !important;
  }
}
</style>
