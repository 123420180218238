<script setup lang="ts">
const isVisible = ref<boolean>(
  import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'development',
)

const mode = ref(import.meta.env.MODE)
</script>

<template>
  <div
    v-if="isVisible"
    class="fixed z-overall top-0 h-0 w-full overflow-visible border-t-2 border-orange-400 justify-center"
  >
    <div class="flex justify-center">
      <p
        class="mx-auto rounded-b-md bg-orange-400 px-2 py-0.5 text-center text-xs font-bold text-white uppercase"
      >
        {{ mode }} environment
      </p>
    </div>
  </div>
</template>
