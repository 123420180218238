export function useWeather() {
  const { weather } = storeToRefs(useCityStore())

  const weathers = computed(
    () =>
      weather.value?.reduce((acc, d) => {
        let text

        switch (d.summary) {
          case 'Rain':
            text = '🌧️'
            break
          case 'Snow':
            text = '🌨️'
            break
        }

        acc.push(text)
        return acc
      }, []) || [],
  )

  return {
    weathers,
  }
}
