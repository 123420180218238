<script setup>
const props = defineProps({
  cityName: {
    type: String,
    required: true,
  },
  showDetails: Boolean,
  vehicleTypes: {
    type: Array,
    default: () => [],
  },
  loading: Boolean,
})

const { t } = useI18n()
const { isLogged } = storeToRefs(useUserStore())

const vehicleTypesSorted = ref([])
const providers = ref([])

watch(
  () => props.vehicleTypes,
  () => {
    vehicleTypesSorted.value = sortVehicleTypes(props.vehicleTypes)

    const providersTemp = {}
    Object.values(props.vehicleTypes).map(type =>
      type.providers.map(provider => (providersTemp[provider.slug] = provider)),
    )

    providers.value = Object.values(providersTemp).sort((a, b) =>
      a.slug.localeCompare(b.slug),
    )
  },
  { immediate: true },
)

const showValues = computed(() => props.vehicleTypes[0]?.value ?? false)
</script>

<template>
  <div class="relative mx-8 mt-8">
    <DCard no-padding>
      <DLoader
        v-if="loading"
        class="text-grey-500"
      />

      <DTable v-else-if="vehicleTypes">
        <thead>
          <tr v-if="showDetails">
            <th>{{ t('mode') }}</th>
            <th v-if="showValues">
              {{ t('vehicles') }}
            </th>
            <th class="w-full">
              {{ t('services', providers.length) }}
            </th>
          </tr>
          <tr v-else>
            <th class="w-full">
              {{ t('services', providers.length) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!showDetails">
            <td class="!p-2.5">
              <ProvidersIconsList
                :providers="providers"
                :no-badges="!isLogged"
              />
            </td>
          </tr>
          <template v-else>
            <tr
              v-for="item in vehicleTypesSorted"
              :key="item"
            >
              <td class="text-center !align-middle">
                <VehicleIcon :type="getVehicleType(item.type)" />
              </td>
              <th
                v-if="showValues"
                class="text-left"
              >
                {{ formatNumber(item.value) }}
              </th>
              <td class="!p-2.5">
                <ProvidersIconsList
                  :providers="item.providers"
                  :no-badges="!isLogged"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </DTable>
    </DCard>
  </div>
</template>
