<script setup>
defineProps({
  cityName: {
    type: String,
    required: true,
  },
  cover: {
    type: Object,
    default: null,
  },
})

const { t } = useI18n()
const router = useRouter()
const { getCityNameLocalized } = useCitiesStore()
</script>

<template>
  <div class="relative bg-gray-700">
    <div class="gradient-transparent absolute h-full w-full">
      <h1 class="px-8 py-6 font-title text-2xl font-bold text-white shadow-black drop-shadow-lg">
        <DIconButton
          path="chevron-left"
          class="-mt-1 align-middle md:hidden"
          @click="router.push({ name: 'Overview' })"
        />

        {{ t('Explore {city}', { city: getCityNameLocalized(cityName) }) }}
      </h1>
    </div>
    <img
      v-if="cover"
      class="aspect-video h-52 w-full object-cover shadow"
      :src="cover.imageUrl"
      :alt="cover.credit ? t('credit: {author}', { author: cover.credit }) : ''"
    >
  </div>
</template>
