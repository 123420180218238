import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { logErrorMessages } from '@vue/apollo-util'

import router from '@/modules/router'

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token')
  const devOverwriteRole = localStorage.getItem('DEV_USER_OVERWRITED')

  const headers = {}

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  if (devOverwriteRole) {
    headers['X-DEV-USER-OVERWRITED'] = devOverwriteRole
  }

  operation.setContext({ headers })

  return forward(operation).map((data) => {
    // Called after server responds
    const context = operation.getContext()
    const apiVersion = context.response.headers.get('X-API-Version')

    if (apiVersion) {
      const { updateApiVersion } = useAppRegisterStore()
      updateApiVersion(apiVersion)
    }

    return data
  })
})

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Number.POSITIVE_INFINITY,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: error => !!error,
  },
})

const batchLink = new BatchHttpLink({
  uri: `${import.meta.env.VITE_CITY_DIVE_SERVER}/graphql`,
  batchInterval: 100,
  batchMax: 1,
})

const errorLink = onError((error) => {
  if (IS_DEV) {
    logErrorMessages(error)
  }

  const { graphQLErrors, networkError } = error

  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message }) => {
      if (extensions && extensions.code) {
        if (
          extensions.code === 'FORBIDDEN'
          && message !== 'You don\'t have the appropriate role'
        ) {
          if (router.currentRoute.name !== 'SettingsSubscription') {
            router.push({ name: 'SettingsSubscription' })
          }
        }

        if (
          extensions.code === 'FORBIDDEN'
          && message !== 'Subscription upgrade required'
        ) {
          if (router.currentRoute.name !== 'SettingsSubscription') {
            router.push({ name: 'SettingsSubscription' })
          }
        }

        if (extensions.code === 'UNAUTHENTICATED') {
          localStorage.removeItem('token')
          localStorage.removeItem('organizationId')
          if (router.currentRoute.name !== 'Login') {
            router.push({ name: 'Login' })
          }
        }
      }
    })
  }

  if (networkError) {
    nextTick(() => {
      globalEmitter.emit(
        'toast',
        {
          title: 'Network error',
          content: 'Network error, try reloding the app',
        },
        {
          variant: 'alert',
        },
      )
    })
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    CityDetail: {
      keyFields: ['name'],
      merge: true,
    },
  },
})

const apolloClient = new ApolloClient({
  link: from([errorLink, retryLink, middlewareLink, batchLink]),
  cache,
})

export default apolloClient
