<script setup>
import { ArcElement, Chart as ChartJS, LinearScale, Tooltip } from 'chart.js'
import { Doughnut } from 'vue-chartjs'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      datasets: [],
    }),
  },
  options: {
    type: Object,
    default: () => ({}),
  },
})

ChartJS.register(Tooltip, ArcElement, LinearScale)

const { options } = toRefs(props)

const datasets = computed(
  () => props.data?.datasets.filter(d => d.data.length > 0) || [],
)

const tooltip = useChartTooltip(options, datasets)

const chartOptions = computed(() => {
  return {
    ...options.value,
    plugins: {
      tooltip: tooltip.value,
    },
  }
})
</script>

<template>
  <Doughnut
    :data="data"
    :options="chartOptions"
    style="position: relative; height: 200px"
  />
</template>
