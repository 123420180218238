<script setup>
defineProps({
  filters: {
    type: Array,
    default: () => [],
  },
})

const filtersNames = [
  'mode',
  'filterBySpecs',
  'dateRange',
  'compare',
  'region',
  'country',
  'timeMode',
  'filterBy',
  'sortBy',
  'language',
]
</script>

<template>
  <p class="mt-2 gap-2 flex">
    <FilterIconButton
      v-for="filterName in filtersNames.filter(name => filters.includes(name))"
      :key="filterName"
      :filter="filterName"
    />
  </p>
</template>
