<script setup>
import addUserToOrganizationMutation from '@/graphql/mutations/addUserToOrganization.gql'
import updateUserInOrganizationMutation from '@/graphql/mutations/updateUserInOrganization.gql'
import removeUserToOrganizationMutation from '@/graphql/mutations/removeUserToOrganization.gql'

/**
 * @typedef {object} IUserMember
 * @property {string} email
 * @property {string} name
 * @property {string} role
 * @property {string} createdAt
 */

/**
 * @typedef {object} IOrganization
 * @property {string} id
 * @property {string} name
 * @property {IUserMember[]} users
 */

/**
 * @typedef {object} IProps
 * @property {IOrganization} organization
 */

/** @type {IProps} */
const props = defineProps({
  organization: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
const { translateFromData } = useLabelTranslation()
const { loadUser } = useUserStore()
const { role } = storeToRefs(useUserStore())

/** @type {Ref<boolean>} */
const updateError = ref(false)

/** @type {import('vue').UnwrapNestedRefs<{email: string, role: string}>} */
const form = reactive({
  email: '',
  role: 'member',
})

const rolesOptions = [
  {
    text: t('data.user_roles.member'),
    value: 'member',
  },
  {
    text: t('data.user_roles.billing'),
    value: 'billing',
  },
]

const { mutate: addUserToOrganization }
/**
       @type {import('@vue/apollo-composable').UseMutationReturn<any, {
      email: string;
      role: string;
      organizationId?: string;
      from?: string;
    }>}
 */ = (
  useMutation(addUserToOrganizationMutation, {
    variables: {
      email: '',
      role: 'member',
      organizationId: props.organization.id,
      from: PRODUCT,
    },
    clientId: CAPTAIN,
  })
)
const { mutate: updateUserInOrganization } = useMutation(
  updateUserInOrganizationMutation,
  {
    clientId: CAPTAIN,
  },
)
const { mutate: removeUserToOrganization } = useMutation(
  removeUserToOrganizationMutation,
  {
    clientId: CAPTAIN,
  },
)

function onEnter({ code }) {
  if (code === 'Enter') {
    addNewUser()
  }
}

/**
 * @async
 * @returns {Promise<void>}
 */
async function addNewUser() {
  // Checks if the email is valid.
  if (validatorEmail(form.email)) {
    updateError.value = null

    try {
      // Adds the user to the organization.
      await addUserToOrganization(toRaw(form))
      // reload the user.
      loadUser()

      // Resets the email field.
      form.email = ''
    } catch (err) {
      // Updates the error message.
      updateError.value = err.message.replace('GraphQL error: ', '')
    }
  }
}

/**
 * @async
 * @param {IUserMember} user - The user to remove.
 * @throws {Error} If there is an error removing the user.
 * @returns {Promise<void>}
 */
async function removeThisUser({ email }) {
  // eslint-disable-next-line no-alert
  if (confirm(t('confirm_remove_user'))) {
    updateError.value = null

    try {
      await removeUserToOrganization({
        email,
        organizationId: props.organization.id,
      })

      loadUser()
    } catch (err) {
      updateError.value = err.message.replace('GraphQL error: ', '')
    }
  }
}

/**
 * @async
 * @param {IUserMember} user - The user object containing the email of the user to update.
 * @param {string} role - The new role of the user.
 * @returns {Promise<void>}
 */
async function updateRole(user, role) {
  updateError.value = null

  try {
    await updateUserInOrganization({
      email: user.email,
      organizationId: props.organization.id,
      role,
    })

    loadUser()
  } catch (err) {
    updateError.value = err.message.replace('GraphQL error: ', '')
  }
}
</script>

<template>
  <div>
    <DTable>
      <thead>
        <tr>
          <th>
            {{ t('name') }}
          </th>
          <th>
            {{ t('email') }}
          </th>
          <th>
            {{ t('position') }}
          </th>
          <th v-if="role === 'owner'">
            {{ t('actions') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="user in organization.users"
          :key="user.email"
        >
          <td>
            <span v-if="user.name">{{ user.name }}</span>
            <span v-else>
              {{ t('invited') }}
              {{ ago(user.createdAt) }}
            </span>
          </td>
          <td>
            {{ user.email }}
          </td>
          <td>
            <DSelect
              v-if="role === 'owner' && user.role !== 'owner'"
              :value="user.role"
              :options="rolesOptions"
              @input="e => updateRole(user, e.target.value)"
            />
            <div v-else>
              {{ translateFromData('user_roles', user.role, capitalize) }}
            </div>
          </td>
          <td v-if="role === 'owner'">
            <DButton
              v-if="user.role !== 'owner'"
              size="sm"
              variant="alert"
              @click="removeThisUser(user)"
            >
              {{ t('remove') }}
            </DButton>
          </td>
        </tr>
        <tr v-if="role === 'owner'">
          <td colspan="2">
            <DInputText
              v-model="form.email"
              type="email"
              :placeholder="t('Enter email to invite a user')"
              @keydown="onEnter"
            />
          </td>
          <td>
            <DSelect
              v-model="form.role"
              :options="rolesOptions"
            />
          </td>
          <td class="text-end">
            <DButton
              size="sm"
              @click="() => addNewUser()"
            >
              {{
                t('invite')
              }}
            </DButton>
          </td>
        </tr>
      </tbody>
    </DTable>

    <DAlert
      v-if="updateError"
      class="mt-2 mb-2"
      variant="alert"
      closable
    >
      {{ updateError }}
    </DAlert>
  </div>
</template>
