<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityKeyFiguresMetric',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())

const { writeCache } = useWriteCacheForMetric('cityCyclabilityKeyFigures')
const { dateRange } = storeToRefs(useFiltersStore())

onMounted(() => {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
  }, {
    date: dateRange.value.start.toString(),
    cycling: {
      __typename: 'Percent',
      value: random(0, 10),
      trend: {
        percent: random(0, 100) / 100,
        direction: 'up',
        positive: true,
      },
    },
    uptake: {
      __typename: 'Percent',
      value: random(0, 10),
      trend: {
        percent: random(0, 100) / 100,
        direction: 'down',
        positive: true,
      },
    },
    vehicleTypes: [
      ENUM_VEHICLE_TYPES[VehicleTypes.BIKE],
      ENUM_VEHICLE_TYPES[VehicleTypes.SCOOTER],
    ],
    genderSplit: [
      {
        gender: 'male',
        value: random(0, 100),
        lastMonth: random(0, 100),
      },
      {
        gender: 'female',
        value: random(0, 10),
        lastMonth: random(0, 100),
      },
    ],
    avgAge: {
      value: random(20, 60),
      lastMonth: random(20, 60),
    },
  })
})
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityKeyFigures"
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.key_figures') }}
    </template>

    <template #subtitle>
      {{ `${t('date_vs_date')} ${t('cyclability.previus_month')}` }}
    </template>

    <template #default="{ data }">
      <div class="grid grid-cols-2 gap-4 md:grid-cols-3">
        <div>
          <p class="text-sm">
            {{ t('cyclability.cycling_popularity') }}
          </p>
          <TrendMetric
            :data="data.cycling"
            is-percent
          />
        </div>
        <div>
          <p>{{ t('cyclability.uptake_in_popularity') }}</p>
          <TrendMetric
            :data="data.uptake"
            is-percent
          />
        </div>
        <div>
          <p>{{ t('cyclability.mode_variety') }}</p>
          <p>
            <VehiclesTypesList :vehicle-types="data.vehicleTypes" />
          </p>
        </div>
        <div>
          <p>{{ t('cyclability.gender_split') }}</p>
          <p class="flex gap-2">
            <span
              v-for="item in data.genderSplit"
              :key="item.gender"
              class="flex gap-1"
            >
              <TrendMetric
                :data="item"
                is-percent
              />
              <DIcon
                :name="`gender-${item.gender}`"
                size="md"
                class="h-6 w-3 !p-0"
              />
            </span>
          </p>
        </div>
        <div>
          <p>{{ t('cyclability.avg_age') }}</p>
          <TrendMetric :data="data.avgAge" />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
