import type { Mode } from '@/types/modes.types'

export function useMode() {
  const route = useRoute()

  const mode = computed((): Mode | null => {
    const routeMode = route.params.mode
    if (routeMode !== VEHICLE_TYPE_ALL) {
      if (Array.isArray(routeMode)) {
        return routeMode[0] as Mode
      } else {
        return routeMode as Mode
      }
    }

    return null
  })

  return {
    mode,
  }
}
