<script setup>
import doResendUserInvitMutation from '@/graphql/mutations/resendUserInvit.gql'
import validateUserEmailMutation from '@/graphql/mutations/validateUserEmail.gql'

const router = useRouter()
const { t } = useI18n()
const toast = useToast()
const { login, loadUser, goToUserMain } = useUserStore()
const { user } = storeToRefs(useUserStore())
const loginModalsStore = useLoginModalsStore()

const waiting = ref(false)
const invalidToken = ref(false)

const { mutate: doResendUserInvit } = useMutation(doResendUserInvitMutation, {
  clientId: CAPTAIN,
})
const { mutate: doConfirmUserEmail } = useMutation(validateUserEmailMutation, {
  clientId: CAPTAIN,
})

onMounted(async () => {
  const token = new URL(document.location).searchParams.get('token')

  if (token) {
    set(waiting, true)

    let error
    let jwt = null
    try {
      const res = await doConfirmUserEmail({ token })

      error = res.data.validateUserEmail?.error
      jwt = res.data.validateUserEmail?.jwt
    } catch (e) {
      error = e
    }

    if (error === 'invalid_token') {
      set(waiting, false)
      set(invalidToken, true)
    } else if (error) {
      set(waiting, false)

      toast.push(
        {
          title: t('Error'),
          message: t('An error occurred, please retry later'),
        },
        { variant: 'alert' },
      )
    } else {
      // email is confirmed, we can init and load the user
      await login(jwt)
      goToUserMain()
    }
  } else {
    const userRef = get(user)
    if (!userRef) {
      router.push({ name: 'Login' })
      return
    }

    if (userRef?.emailVerified) {
      goToUserMain()
    }
  }
})

async function resendInvit() {
  const userRef = get(user)
  if (!userRef) {
    loginModalsStore.openSignin()
    return
  }

  let error
  try {
    const res = await doResendUserInvit()

    error = res.data.resendUserInvit?.error
  } catch (e) {
    error = e
  }

  if (error === 'email_already_verified') {
    await loadUser()

    router.push({ name: 'SettingsSubscription' })
  } else if (error) {
    toast.push(
      {
        title: t('Error'),
        message: t('An error occurred, please retry later'),
      },
      { variant: 'alert' },
    )
  } else {
    toast.push(
      { message: t('An email was sent, please check your mailbox.') },
      { variant: 'info' },
    )
  }
}
</script>

<template>
  <LoginHeader />

  <div class="mt-16 bg-white p-8 text-center shadow md:mx-auto md:max-w-xl">
    <h1 class="font-title text-2xl font-bold text-blue-500">
      {{ t('Welcome to City Dive, {username}!', { username: user?.name || t('new explorer') }) }}
    </h1>

    <DAlert
      v-if="invalidToken"
      variant="alert"
      class="text-center"
    >
      {{ t('confirmEmail.invalidToken') }}
      <a
        class="text-blue-500"
        @click="resendInvit"
      >{{
        t('confirmEmail.resendConfirmationEmail')
      }}</a>
    </DAlert>

    <div v-if="!waiting">
      <p class="text-lg font-normal text-grey-300">
        {{ t('Please confirm your email address') }}
      </p>

      <img
        src="/img/email-notification@2x.png"
        :alt="t('alt_email_notification')"
        class="m-8 mx-auto w-44"
      >

      <DMarkdown :source="t('you_must_confirm_your_email_address_before_you_can_continue')" />

      <DButton
        variant="outline-primary"
        class="mt-8 text-blue-500"
        size="lg"
        @click="resendInvit"
      >
        {{ t('Resend email') }}
      </DButton>
    </div>

    <p
      v-else
      class="text-center"
    >
      <DLoader /> {{ t('Loading...') }}
    </p>
  </div>
</template>
