<script setup>
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue'

const props = defineProps({
  title: { type: String, default: null },
  value: { type: String, default: null },
  icon: { type: String, default: null },
  size: {
    type: String,
    default: 'md',
    validator: value => ['sm', 'md', 'lg'].includes(value),
  },
  variant: {
    type: String,
    default: 'fill-secondary',
    validator: validatorButtonsVariants,
  },
  block: Boolean,
  inlineLink: Boolean,
  noPadding: Boolean,
  forceOpen: Boolean,
  pill: Boolean,
  autoClose: {
    type: Boolean,
    default: true,
  },
  maxHeight: {
    type: String,
    default: 'auto',
  },
})
const emit = defineEmits(['close', 'open'])

const { variant, size, pill } = toRefs(props)

const dropdownOptions = reactive({
  variant,
  size,
  pill,
})

const isOpen = ref(false)
const reference = ref(null)
const floating = ref(null)
const { floatingStyles } = useFloating(reference, floating, {
  whileElementsMounted: autoUpdate,
  placement: 'bottom-start',
  middleware: [offset(0), shift(), flip()],
})

onClickOutside(floating, (event) => {
  const child = event.target
  const parentRef = get(reference)

  if (
    !(
      (parentRef !== child && parentRef.contains(child))
      || parentRef === child
    )
    && (get(isOpen) || props.forceOpen)
  ) {
    close()
  }
})

function close() {
  set(isOpen, false)
  emit('close')
}

function onClickInside() {
  if (props.autoClose) {
    close()
  }
}
</script>

<template>
  <component
    :is="inlineLink ? 'a' : 'vue:d-button'"
    ref="reference"
    v-bind="inlineLink ? {} : dropdownOptions"
    class="w-full relative"
    :class="{
      block,
      'inline-block': !block,
      'hover:underline': inlineLink,
      'underline': inlineLink && isOpen,
    }"
    @click="() => (isOpen = !isOpen)"
  >
    <div
      v-if="icon"
      class="inset-y-0 mr-2 flex h-1/2 items-center border-r border-white/50"
    >
      <DIcon
        :size="size"
        :path="icon"
        class="py-0"
        :class="{
          '-ml-2': size === 'sm',
          '-ml-4': size === 'md',
          '-ml-8': size === 'lg',
        }"
      />
    </div>

    <span class="inline-flex flex-1 justify-between whitespace-normal">
      <slot name="title">
        <span v-if="title">{{ title }}</span>
        <template v-if="value">
          <span class="ml-2 justify-between" />
          <span class="text-blue-500">{{ value }}</span>
        </template>
      </slot>
    </span>
    <DIcon
      :path="isOpen || forceOpen ? 'chevron-up' : 'chevron-down'"
      size="sm"
      :class="{
        'ml-2 -mr-2': size === 'sm',
        'ml-2 -mr-4': size === 'md',
        'ml-2 -mr-8': size === 'lg',
      }"
    />
  </component>

  <div
    v-show="isOpen || forceOpen"
    ref="floating"
    class="relative z-menu overflow-auto bg-white shadow-lg"
    :class="{
      'p-2': !noPadding && size === 'sm',
      'p-3': !noPadding && size === 'md',
      'p-0': noPadding,
    }"
    :style="[{ maxHeight }, floatingStyles]"
    @click="onClickInside"
  >
    <slot :close="close" />
  </div>
</template>
