import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue'

export function useOpenAndFloat(reference, floating, options) {
  const isOpen = ref(false)
  const { floatingStyles, x, y, isPositioned } = useFloating(
    reference,
    floating,
    {
      whileElementsMounted: autoUpdate,
      placement: 'bottom-start',
      middleware: [
        offset(0),
        shift({
          crossAxis: true,
        }),
        flip(),
      ],
      open: isOpen,
    },
  )

  const { width } = useElementSize(reference)
  const { height } = useElementSize(floating)

  const maxHeight = ref(null)
  const scrollHeightState = ref(0)

  whenever(isPositioned, async () => {
    await nextTick()
    updateMaxHeight()
  })

  watch(height, async () => {
    await nextTick()
    updateMaxHeight()
  })

  function updateMaxHeight() {
    const floatingRef = get(floating)

    if (floatingRef) {
      const yRef = get(y)
      const { scrollHeight } = floatingRef.$el || floatingRef

      if (
        scrollHeight > 0
        && yRef < 0
        && scrollHeight !== get(scrollHeightState)
      ) {
        set(scrollHeightState, scrollHeight)
        set(maxHeight, get(y) + scrollHeight)
      }
    }
  }

  if (options) {
    watch(
      () => options?.forceOpen,
      (value) => {
        if (value) {
          set(isOpen, true)
        }
      },
      { immediate: true },
    )
  }

  onClickOutside(floating, (event) => {
    const child = event.target
    const parentRef = get(reference)?.$el || get(reference)

    if (
      !(
        (parentRef !== child && parentRef?.contains(child))
        || parentRef === child
      )
      && get(isOpen)
    ) {
      set(isOpen, false)
    }
  })

  onBeforeUnmount(() => {
    set(maxHeight, null)
  })

  return {
    isOpen,
    width,
    maxHeight,
    x,
    y,
    floatingStyles,
    isPositioned,
  }
}
