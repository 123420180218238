<script setup lang="ts">
useTitles()

const route = useRoute()
const toast = useToast()

useUserStore()
useCubeStore()
useAppRegisterStore()

onMounted(async () => {
  globalEmitter.on('toast', (...payload: [any, any]) => {
    toast.push(...payload)
  })
})
</script>

<template>
  <StagingBar />

  <div class="flex h-full flex-col md:flex-row">
    <Navigation v-if="!route.meta.hideNavigation" />

    <div
      id="app-content"
      class="relative flex-1 overflow-auto"
    >
      <RouterView v-slot="{ Component }">
        <Suspense>
          <component
            :is="Component"
            class="h-full"
          />

          <template #fallback>
            <div class="h-full">
              <AnimatedLoading />
            </div>
          </template>
        </Suspense>
      </RouterView>
    </div>
  </div>

  <AuthModals />
  <AnalyticsTools />

  <DToastContainer />
</template>
