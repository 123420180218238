<script setup>
const { user, organization } = storeToRefs(useUserStore())

const cookieConsent = inject('cookieConsent', null)
const hotjar = inject('hotjar', null)
const crisp = inject('crisp', null)
const gtm = useGtm()

onMounted(() => {
  if (cookieConsent) {
    cookieConsent.setup({
      onAccept(cookie) {
        if (cookie.categories.includes('analytics')) {
          // if the user accepts analytics cookies, we enable all analytics services
          gtm?.enable(true)
          hotjar?.init()
          crisp?.load()
        }
      },
    })
  }
})

watch(
  [user, organization, () => crisp, () => hotjar?.loaded],
  ([user, organization]) => {
    if (!user || !user.email || !organization) {
      return
    }

    // GOOGLE TAG MANAGER
    if (gtm?.enabled()) {
      const isFreemium = organization.subscriptions?.[0]?.citydive?.freemium

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'set-user',
        user_id: user.id,
        name: user.name,
        email: user.email,
        orgnaization_name: organization.name,
        organization_id: organization.id,
        subscription: isDefined(isFreemium)
          ? isFreemium
            ? 'BP'
            : 'GE'
          : 'N/A',
      })
    }

    // HOTJAR
    if (hotjar?.loaded) {
      hotjar.identify(user.id, {
        name: user.name,
        email: user.email,
      })
    }

    // CRISP
    if (crisp) {
      crisp.user.setEmail(user.email)
      crisp.user.setNickname(user.name)
      crisp.session.setData({
        user_id: user.id,
      })
    }
  },
)
</script>

<template>
  <slot />
</template>
