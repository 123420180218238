import { resolveValue } from 'path-value'

export function sortByAttr(attr: string, decorator = (v: any) => v): (a: any, b: any) => number {
  return (a, b) => {
    let aVal
    let bVal = null
    try {
      aVal = resolveValue(a, attr) || ''
    } catch (_) {
      aVal = ''
    }

    try {
      bVal = resolveValue(b, attr) || ''
    } catch (_) {
      bVal = ''
    }

    return Number.isNaN(Number(aVal)) || Number.isNaN(Number(bVal))
      ? decorator(aVal).localeCompare(decorator(bVal))
      : decorator(bVal ?? -1) - decorator(aVal ?? -1)
  }
}

export default {
  sortByAttr,
}
