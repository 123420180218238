<script setup>
defineOptions({
  name: 'ProviderFleetsCarModelOverview',
})

const { t } = useI18n()
const isOpen = ref(false)
const { translateFromData } = useLabelTranslation()
</script>

<template>
  <MetricCard
    kpi="providerFleetsCarModelOverview"
    :as-mode="VEHICLE_TYPE_CAR"
    :used-filters="['regions']"
    no-padding
  >
    <template #title>
      {{ t('Top Car models in fleet') }}
    </template>
    <template #subtitle>
      {{ t('AVG Last 30 days') }}
    </template>

    <template #default="{ data }">
      <DTable>
        <thead>
          <tr>
            <th>{{ t('Models') }}</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in data.slice(0, 5)"
            :key="item.model"
          >
            <td>{{ item.carModel }}</td>
            <td>{{ formatPercent(item.percent) }}</td>
          </tr>
          <tr v-if="data.length > 5">
            <td>
              {{ t('Others') }} -
              <DLink
                class="text-blue-500 underline"
                @click="() => (isOpen = true)"
              >
                {{ t('See all') }}
              </DLink>
            </td>
            <td>
              {{
                formatPercent(
                  data
                    .slice(5)
                    .reduce((total, { percent }) => total + percent, 0) ?? 0,
                )
              }}
            </td>
          </tr>
        </tbody>
      </DTable>

      <DModal
        v-if="isOpen"
        @close="() => (isOpen = false)"
      >
        <template #title>
          {{ t('Details on cars models') }}
        </template>

        <div class="max-h-60 overflow-auto">
          <ul
            v-for="item in data"
            :key="item.model"
          >
            <li>
              {{ translateFromData('carModel', item.carModel) }}
              <span class="font-bold text-grey-400 float-right">{{
                formatPercent(item.percent)
              }}</span>
            </li>
          </ul>
        </div>
      </DModal>
    </template>
  </MetricCard>
</template>
