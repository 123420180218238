<script setup>
defineProps({
  option: {
    type: Object,
    required: true,
  },
  isSelected: Boolean,
})

const emit = defineEmits(['select'])

const { t } = useI18n()

const descriptions = {
  [PLANS_OPTIONS_SLUGS[1]]: t(
    'Export City Dive insights and integrate them with your BI tools (CSV/XLS/Parquet on S3/Google Drive).',
  ),
  [PLANS_OPTIONS_SLUGS[0]]: t(
    'Analyze and compare the performance (downloads, ratings and more) of user apps across the world.',
  ),
}
</script>

<template>
  <div
    class="flex items-center space-x-6 border border-grey-100 bg-teal-100 p-4 shadow-2xl"
  >
    <div>
      <p class="font-title font-bold text-blue-500">
        {{ option.name }}
      </p>
      <p class="text-xs">
        {{ descriptions[option.slug] }}
      </p>
    </div>

    <div>
      <DIconButton
        class="shadow"
        :variant="isSelected ? 'fill-secondary' : 'fill-primary'"
        :path="isSelected ? 'check' : 'plus'"
        icon-size="sm"
        @click="emit('select', option.slug)"
      />
    </div>
  </div>
</template>
