<script setup>
const props = defineProps({
  freemiumPlan: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
const { translateCity } = useLabelTranslation()
const citiesStore = useCitiesStore()

const planCities = computed(() => {
  return (
    props.freemiumPlan.data?.cities
      ?.map((city) => {
        const citydiveCity = citiesStore.getCity(city)

        return {
          name: city,
          nameLocalized: citydiveCity?.nameLocalized || translateCity(city),
          countryCode: citydiveCity?.countryCode,
        }
      })
      .sort(sortByAttr('nameLocalized')) || []
  )
})
</script>

<template>
  <div>
    <p class="text-center md:text-sm">
      {{
        t('as_freemium_you_have_access_to_n_cities', {
          n: planCities?.length ?? 0,
          subscriptionPlan: t('data.plans.freemium'),
        })
      }}
    </p>
    <div
      v-if="planCities"
      class="mt-4 grid grid-cols-2 gap-3"
    >
      <p
        v-for="city in planCities"
        :key="city"
        class="text-lg uppercase"
      >
        <DFlag
          :country-code="city.countryCode"
          class="mr-2 inline-block align-middle"
        />{{ city.nameLocalized }}
      </p>
    </div>
  </div>
</template>
