<script setup>
defineOptions({
  name: 'ProviderTripSummary',
})

const { t } = useI18n()
const { relativeRanges } = useDateRangeFilter()
</script>

<template>
  <MetricCard
    kpi="providerTripSummary"
    :variables="{
      dateRange: {
        start: formatDate(relativeRanges.last30days.period.start, DATE_FORMAT),
        end: formatDate(relativeRanges.last30days.period.end, DATE_FORMAT),
      },
    }"
    :used-filters="['mode', 'country', 'region']"
  >
    <template #title>
      {{ t('Modal split: trips') }}
    </template>
    <template #subtitle>
      {{
        `${t('AVG Last 30 days')} - ${t('Data from unlocked cities')}`
      }}
    </template>

    <template #default="scoped">
      <PieMetric
        class="m-auto max-w-[250px]"
        :data="scoped.data"
        :options="{
          dimension: DimensionTypes.VEHICLETYPE,
          label: d => d.vehicleType,
          color: d => PALETTE_COLORS_VEHICLES[getVehicleType(d.vehicleType)],
        }"
      />
    </template>
  </MetricCard>
</template>
