<script setup>
const props = defineProps({
  plans: {
    type: Array,
    required: true,
  },
  isCurrent: Boolean,
  hideTitle: Boolean,
})

const { t } = useI18n()

const globalPlan = computed(
  () =>
    (props.plans.length === 1 && props.plans[0])
    || props.plans?.find((p) => {
      return p.requirePack
    }),
)

const rows = computed(() => {
  const plans = props.plans.filter(p => p.isPack)
  const options = props.plans.filter(p => p.isOption)

  return { plans, options }
})

const nbrSeats = computed(() => {
  return props.plans.reduce((acc, plan) => {
    return acc + (plan.seats ?? 0)
  }, 0)
})

const nbrCities = computed(() => {
  return props.plans.reduce((acc, plan) => {
    return acc + (plan.data?.cities?.length ?? 0)
  }, 0)
})
</script>

<template>
  <div>
    <p
      v-if="!hideTitle"
      class="font-title"
    >
      {{ isCurrent ? t('Current plan:') : t('New plan:') }}
    </p>

    <p
      v-if="!hideTitle"
      class="font-bold text-blue-500"
    >
      {{ globalPlan?.name }}
    </p>
    <ul class="list-inside list-hyphen text-sm">
      <li v-if="nbrCities > 0">
        {{
          t(
            'Access to {cities} Cities ({plan})',
            {
              cities: nbrCities,
              plan: globalPlan?.name,
            },
            nbrCities,
          )
        }}
      </li>
      <li
        v-for="plan in rows.plans"
        :key="plan.slug"
      >
        {{ t('{plan} cities unlocked', { plan: plan.name }) }}
      </li>
      <li v-if="nbrSeats > 0">
        {{ t('{n} users', { n: nbrSeats }) }}
      </li>
      <li
        v-for="option in rows.options"
        :key="option.slug"
      >
        {{ t('"{name}" extension added', { name: option.name }) }}
      </li>
    </ul>
  </div>
</template>
