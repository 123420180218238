<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])
const value = useVModel(props, 'modelValue', emit)

const { t } = useI18n()
</script>

<template>
  <div
    class="my-6 mx-auto flex max-w-lg flex-col overflow-hidden rounded-3xl bg-blue-300 text-center text-white md:flex-row md:rounded-full"
  >
    <div
      v-for="option in options"
      :key="option.value"
      class="flex-center flex h-12 flex-col rounded-full py-2 transition-all md:flex-1"
      :class="{
        'cursor-pointer hover:bg-blue-400':
          !option.disabled && option.value !== value,
        'bg-blue-500': option.value === value,
        'text-white/50': option.disabled,
      }"
      @click="
        () => {
          if (!option.disabled) {
            value = option.value
          }
        }
      "
    >
      <p class="font-bold leading-3">
        {{ option.text }}
        <span
          v-if="option.subText"
          class="text-xs font-normal uppercase"
        >
          <br>{{ option.subText }}
        </span>
        <span
          v-if="option.isNew"
          class="text-xs font-normal uppercase"
        >
          <br><span class="rounded-full bg-red-500 px-1.5">{{ t('new') }}</span>
        </span>
      </p>
    </div>
  </div>
</template>
