<script setup>
import newslinkTagsQuery from '@/graphql/queries/newslinkTags.gql'

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['update:modelValue'])
const lang = useVModel(props, 'modelValue', emit)

const { t } = useI18n()
const { translateLocale } = useLabelTranslation()
const { result: resultTags, loading } = useQuery(newslinkTagsQuery)

const langsList = computed(() => {
  return resultTags.value?.newslinkTags.langs
    .filter(lang => lang && lang.trim() !== '')
    .map(lang => ({
      text: capitalize(translateLocale(lang) || lang),
      value: lang,
    }))
    .sort(sortByAttr('text'))
},
)
</script>

<template>
  <DSelectPicker
    id="language"
    v-model="lang"
    :options="[
      {
        text: t('Any language'),
        value: null,
        default: true,
      },
      ...(langsList || []),
    ]"
    :label="t('Filter by article languages')"
    :default-text="t('Pick languages')"
    :searchable="true"
    :loading="loading"
    :search-placeholder="t('Type a specific language...')"
    icon="dialog"
  />
</template>
