export function useModeClasses() {
  const { mode } = useMode()

  const modeClasses = computed(() => {
    if (mode.value) {
      return getVehicleTypeClasses(mode.value)
    } else {
      return ['text-blue-500']
    }
  })

  return {
    modeClasses,
  }
}
