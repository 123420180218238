<script setup>
defineOptions({
  name: 'ProviderVehicleFleetOverview',
})

const { t } = useI18n()
const { relativeRanges } = useDateRangeFilter()
</script>

<template>
  <MetricCard
    kpi="providerVehicleTypeFleetsOverviewPerTime"
    :variables="{
      dateRange: {
        start: formatDate(relativeRanges.lastYear.period.start, DATE_FORMAT),
        end: formatDate(relativeRanges.lastYear.period.end, DATE_FORMAT),
      },
      timeMode: 'month',
    }"
    :used-filters="['mode', 'region']"
  >
    <template #title>
      {{ t('Fleet evolution') }}
    </template>
    <template #subtitle="scoped">
      {{ t('AVG Last 12 months') }} - {{
        t(
          'data from {n} cities on City Dive',
          { n: scoped.data?.citiesCount ?? 0 },
          scoped.data?.citiesCount ?? 0,
        )
      }}
    </template>
    <template #default="scoped">
      <BarMetric
        type="time"
        :data="scoped.data"
        :options="{
          dimension: DimensionTypes.VEHICLETYPE,
          afterTitle: 'sum',
          colors: getPaletteVehicleTypeBySeries(scoped.data?.series),
          weekend: false,
          timeMode: 'month',
        }"
      />
    </template>
  </MetricCard>
</template>
