<script setup>
const { t } = useI18n()

const { user, organization, role } = storeToRefs(useUserStore())

const userForm = computed(() => ({
  ...toRaw(unref(user)),
}))
const hasBilling = computed(() => {
  return ['owner', 'billing'].includes(get(role))
})
</script>

<template>
  <div class="space-y-4">
    <BoardTitle hide-on-mobile>
      {{ t('My profile') }}
    </BoardTitle>

    <DCard>
      <template #title>
        {{ t('My profile') }}
      </template>
      <EditUser :user="userForm" />
    </DCard>

    <DCard v-if="hasBilling">
      <template #title>
        {{ t('My organization') }}
      </template>
      <EditOrganization />
    </DCard>

    <DCard no-padding>
      <template #title>
        {{ t("Organization's members") }}
      </template>
      <OrganizationMembers :organization="organization" />
    </DCard>
  </div>
</template>
