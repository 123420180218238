export function useInputShortcut(input) {
  const onKeyPress = (key, callback) => {
    onKeyStroke(
      key,
      (e) => {
        e.preventDefault()
        callback(e)
      },
      { target: input },
    )
  }

  return {
    onDown: callback => onKeyPress('ArrowDown', callback),
    onUp: callback => onKeyPress('ArrowUp', callback),
    onEnter: callback => onKeyPress('Enter', callback),
    onEscape: callback => onKeyPress('Escape', callback),
    onKeyPress,
  }
}
