<script setup>
const props = defineProps({
  selectedPlans: {
    type: Array,
    default: () => [],
  },
  selectedOptions: {
    type: Array,
    default: () => [],
  },
  plans: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['request-pricing'])

const { t } = useI18n()
const { subscription } = storeToRefs(useUserStore())

const newSubscription = computed(() => {
  const { plans, selectedPlans, selectedOptions } = props

  return [
    ...(plans.filter(p => p.slug === 'explorer-pack') || []),
    ...(plans.filter(p => selectedPlans.includes(p.slug)) || []),
    ...(plans.filter(p => selectedOptions.includes(p.slug)) || []),
  ]
})
</script>

<template>
  <Sidebar
    :to="{ name: 'SettingsAccount' }"
    :text="t('Back to your account')"
  >
    <div class="rounded-md bg-white p-4">
      <PlanDetails
        v-if="subscription"
        :plans="subscription.plans"
        is-current
      />

      <PlanDetails
        :class="{ 'mt-6': subscription }"
        :plans="newSubscription"
      />
    </div>

    <p class="mt-4 text-center">
      <DButton
        variant="gradient-primary"
        :disabled="selectedPlans.length === 0"
        @click="() => emit('request-pricing')"
      >
        {{ t('Request pricing') }}
      </DButton>
    </p>
  </Sidebar>
</template>
