<script setup>
import doResetUserPasswordMutation from '@/graphql/mutations/doResetUserPassword.gql'

const router = useRouter()
const { t } = useI18n()

const displayError = useDisplayErrors()
const toast = useToast()

const { mutate: doResetPassword } = useMutation(doResetUserPasswordMutation, {
  clientId: CAPTAIN,
})

const password = ref('')
const score = ref(0)
const loading = ref(false)
const token = ref('')

const validPassword = computed(() => {
  return password.value && score.value > 2
})

onMounted(() => {
  token.value = new URL(document.location).searchParams.get('token')

  if (!token.value) {
    router.replace({ name: 'Login', force: true })
  }
})

async function resetPassword() {
  let error
  try {
    const res = await doResetPassword({
      token: token.value,
      password: password.value,
    })

    error = res.data.doResetUserPassword?.error
  } catch (e) {
    error = e
  }

  if (error === 'invalid_token') {
    displayError(t('resetPasswordModal.invalidToken'))
  } else if (error) {
    displayError(t('An error occurred, please retry later'))
  } else {
    toast.push(
      { message: t('resetPasswordModal.updated') },
      { variant: 'info' },
    )

    setTimeout(() => {
      router.push({ name: 'Login' })
    }, 1000)
  }
}

function setScore(newScore) {
  score.value = newScore
}
</script>

<template>
  <LoginHeader />

  <DCard class="mt-16 md:mx-auto md:w-1/2">
    <template #title>
      {{ t('resetPasswordModal.title') }}
    </template>

    <div class="content flex flex-col items-center">
      <form @submit.prevent="resetPassword">
        <DField>
          <template #label>
            {{
              t('resetPasswordModal.passwordLabel')
            }}
          </template>
          <DInputText
            v-model="password"
            type="password"
            maxlength="72"
            autocomplete="new-password"
            :placeholder="t('Enter password')"
          />
          <PasswordStrengthMeter
            :password="password"
            @score="setScore"
          />
        </DField>

        <p class="mt-4">
          <DButton
            type="submit"
            :loading="loading"
            :disabled="!validPassword || loading"
          >
            {{ t('resetPasswordModal.updatePassword') }}
          </DButton>
        </p>
      </form>
    </div>
  </DCard>
</template>
