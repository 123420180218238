<script setup>
const { t } = useI18n()
const router = useRouter()
const { error, loading, freemiumPlan } = useFreemiumPlan()
</script>

<template>
  <DModal
    size="lg"
    closable
  >
    <template #title>
      {{ t('Time to level up...') }}<br>
      {{ t('become_plan', { subscriptionPlan: t('data.plans.premium') }) }}
    </template>

    <template #default="{ close }">
      <DMarkdown
        class="py-4 text-sm"
        :source="
          t(
            'to_access_more_you_must_become_plan',
            { subscriptionPlan: t('data.plans.premium') },
          )
        "
      />

      <DLoader v-if="loading" />

      <DAlert
        v-if="error"
        variant="alert"
        :title="t('Something went wrong. Please try again later.')"
        :subtitle="error.message"
      />

      <FreemiumOnboardingPlans
        v-if="freemiumPlan"
        :freemium-plan="freemiumPlan"
        :show-explore-btn="false"
        @explore="close"
        @upgrade="router.push({ name: 'SubscribePlans' })"
      >
        <template #upgrade-text>
          {{ t('Upgrade now') }}
        </template>
      </FreemiumOnboardingPlans>
    </template>
  </DModal>
</template>
