<template>
  <div class="board-title flex items-center justify-between my-4 md:my-6">
    <p class="font-title text-2xl font-bold text-blue-700">
      <slot />
      <span
        v-if="$slots.subtitle"
        class="text-base font-normal text-gray-500"
      >
        <br><slot name="subtitle" />
      </span>
    </p>

    <slot name="right" />
  </div>
</template>

<style lang="pcss">
.board-title + .board-sub-title {
  @apply !pt-0;
}
</style>
