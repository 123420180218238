<script setup>
defineOptions({
  name: 'ProviderMetricsSummary',
})

const { t } = useI18n()
const { relativeRanges } = useDateRangeFilter()
const filtersStore = useFiltersStore()
</script>

<template>
  <MetricCard
    kpi="providerMetricsSummary"
    :variables="{
      dateRange: {
        start: formatDate(relativeRanges.last30days.period.start, DATE_FORMAT),
        end: formatDate(relativeRanges.last30days.period.end, DATE_FORMAT),
      },
    }"
    :used-filters="['mode', 'region', 'country', 'filterBySpecs']"
    no-padding
  >
    <template #title>
      {{ t('Market performance') }}
    </template>
    <template #subtitle>
      {{
        `${t('Last 30 days')} - ${t('Data from unlocked cities')}`
      }}
    </template>

    <template #default="{ data }">
      <div
        v-if="data.regions?.length === 0"
        class="flex h-48 items-center justify-center"
      >
        <ErrorNoDataToShow v-if="!error" />
      </div>
      <TableCountriesMetric
        v-else
        :data="data"
        :regions="filtersStore.regions"
      />
    </template>
  </MetricCard>
</template>
