<script setup lang="ts">
import type { AnySerie, DimensionType } from '@/types/charts.types'

defineOptions({
  name: 'ChartLegendItem',
})

const props = withDefaults(defineProps<ChartLegendItemProps>(), {
  dimension: DimensionTypes.UNKNOWN,
  dataIndex: 0,
})

const emit = defineEmits(['select', 'toggle'])

interface ChartLegendItemProps {
  dimension?: DimensionType
  data: AnySerie & {
    cities?: {
      unlocked: number
      total: number
    }
  }
  disabled?: boolean
  dataIndex?: number
}

const { t } = useI18n()
const { translateLabel } = useLabelTranslation()

const isMonitored = computedEager(() => {
  const data = props.data

  if ('value' in data) {
    return data.value === null
  }

  if (data.data) {
    return !data.data.reduce((acc, cur) => {
      if (Array.isArray(cur)) {
        if (cur[1]) {
          acc = false
        }
      } else if (cur) {
        acc = false
      }

      return acc
    }, true)
  }

  return false
})

function getLabeltranslatedFromData(data: AnySerie) {
  const label = data.category || data.label || data.name

  if (label) {
    return translateLabel(label, props.dimension)
  }

  return ''
}
</script>

<template>
  <div
    class="inline-flex items-center gap-2 text-sm font-normal"
    :class="{
      'text-grey-700': !disabled && isMonitored,
      'text-grey-300': disabled || !isMonitored,
      'text-grey-200': data.unlocked === false,
    }"
    @click="
      e =>
        isMonitored
          ? emit(e.ctrlKey || e.metaKey ? 'toggle' : 'select', data.name)
          : false
    "
  >
    <ChartLegendBadge
      :class="{
        'grayscale-[80%]': disabled || !isMonitored,
        'border': data.unlocked === false,
      }"
      :color="(data.unlocked === false && '#fff') || data.color"
      :color-index="dataIndex"
    />

    <span class="leading-3 first-letter:capitalize">
      {{ getLabeltranslatedFromData(data) }}
      <DIcon
        v-if="data.unlocked === false"
        path="cadenas"
        size="sm"
      />
      <span
        v-if="data.cities"
        class="text-xs"
      ><br>
        {{
          `${data.cities.unlocked} / ${data.cities.total} ${t(
            'cit(y|ies)',
            data.cities.total,
          )}`
        }}
      </span>
    </span>
    <slot />
  </div>
</template>
