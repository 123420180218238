<script setup>
defineOptions({
  name: 'CityTripsOverview',
})

const { t } = useI18n()
</script>

<template>
  <MetricCard kpi="cityTripsEvolutionsOverview">
    <template #title>
      {{ t('Trip evolution') }}
    </template>
    <template #subtitle="scoped">
      {{ t('AVG Last 12 months') }} -
      {{
        t(
          'data from {n} operators',
          { n: scoped.data?.providersCount ?? 0 },
          scoped.data?.providersCount ?? 0,
        )
      }}
    </template>
    <template #default="scoped">
      <BarMetric
        type="time"
        :data="scoped.data"
        :options="{
          dimension: DimensionTypes.VEHICLETYPE,
          afterTitle: 'sum',
          colors: getPaletteVehicleTypeBySeries(scoped.data?.series),
          weekend: false,
          timeMode: 'month',
        }"
      />
    </template>
  </MetricCard>
</template>
