<script setup>
import newslinkTagsQuery from '@/graphql/queries/newslinkTags.gql'

defineOptions({
  name: 'FilterByTags',
})

const props = defineProps({
  modes: {
    type: Array,
    default: () => [],
  },
  vehicleTypes: {
    type: Array,
    default: () => VEHICLE_TYPES,
  },
  tags: {
    type: Array,
    default: () => [],
  },
  cities: {
    type: Array,
    default: () => [],
  },
  providers: {
    type: Array,
    default: () => [],
  },
  isAny: {
    type: Object,
    default: () => ({
      tags: true,
      cities: true,
      providers: true,
    }),
  },
})

const emit = defineEmits([
  'update:modes',
  'update:tags',
  'update:cities',
  'update:providers',
  'toggle-any',
])

const { tags, cities, providers, modes } = useVModels(props, emit)

const { t } = useI18n()
const { translateTag, translateLocation } = useLabelTranslation()
const { providerNameBySlug } = useProviderName()
const { result: resultTags, loading } = useQuery(newslinkTagsQuery)

const citiesList = computed(() =>
  get(resultTags)
    ?.newslinkTags.cities.map(cityName => ({
      label: translateLocation(cityName),
      value: cityName.replace(/(^Q\w+):.*$/, '$1'),
    }))
    .sort(sortByAttr('label')),
)

const providersList = computed(() =>
  get(resultTags)
    ?.newslinkTags.providers.map(providerSlug => ({
      label: providerNameBySlug(providerSlug),
      value: providerSlug,
    }))
    .sort(sortByAttr('label')),
)

const categoriesList = computed(() =>
  get(resultTags)
    ?.newslinkTags.tags.map(tag => ({
      label: translateTag(tag),
      value: tag,
    }))
    .sort(sortByAttr('label')),
)
</script>

<template>
  <FilterByContainer
    :name="t('Filter by tags')"
    highlight="filterBy"
  >
    <p class="text-grey-200 text-xs">
      {{ t('Modes') }}
    </p>
    <VehiclesTypesSelector
      v-model="modes"
      class="my-2"
    />

    <div class="mb-2">
      <p class="leading-5 mb-1 align-middle text-grey-200 text-xs">
        {{ t('Location') }}
        <FilterAnyAllToggle
          :value="isAny.cities"
          class="float-right"
          @change="() => emit('toggle-any', 'cities')"
        />
      </p>
      <DInputTags
        v-model="cities"
        :list="citiesList"
        :placeholder="t('Type a country or a city...')"
        :loading="loading"
        variant="secondary"
        hide-input
        multiple
      />
    </div>

    <div class="mb-2">
      <p class="leading-5 mb-1 align-middle text-grey-200 text-xs">
        {{ t('Operators', providersList?.length) }}
        <FilterAnyAllToggle
          class="float-right"
          :value="isAny.providers"
          @change="() => emit('toggle-any', 'providers')"
        />
      </p>
      <DInputTags
        v-model="providers"
        :list="providersList"
        :placeholder="t('Type an operator...')"
        :loading="loading"
        variant="secondary"
        hide-input
        multiple
      />
    </div>

    <div class="mb-2">
      <p class="leading-5 mb-1 align-middle text-grey-200 text-xs">
        {{ t('Categories') }}
        <FilterAnyAllToggle
          class="float-right"
          :value="isAny.tags"
          @change="() => emit('toggle-any', 'tags')"
        />
      </p>
      <DInputTags
        v-model="tags"
        :placeholder="t('Type a category...')"
        :list="categoriesList"
        :loading="loading"
        variant="secondary"
        hide-input
        multiple
      />
    </div>
  </FilterByContainer>
</template>
