<script setup>
const { t } = useI18n()
const { mode } = useMode()

const { city } = storeToRefs(useCityStore())
</script>

<template>
  <div class="overview">
    <BoardTitle hide-on-mobile>
      {{ t('Overview') }}
    </BoardTitle>

    <BoardGrid class="mt-4 lg:grid-cols-2">
      <CityVehicleTypeOverview v-if="mode" />
      <CityFleetsOverview v-else />
      <CityProvidersVehicleTypeOverview v-if="mode" />
      <CityProvidersOverview v-else />
      <CityTripsOverview />
      <NewsfeedWidget
        :city="city"
        :modes="mode ? [mode] : null"
      />
    </BoardGrid>
  </div>
</template>
