const DIconProps = {
  name: {
    type: String,
    default: null,
  },
  src: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    validator: value => ['xs', 'sm', 'md', 'lg'].includes(value),
    default: 'md',
  },
  path: {
    type: String,
    default: null,
  },
  flip: {
    type: String,
    validator: value =>
      ['horizontal', 'vertical', 'both', 'none'].includes(value),
    default: 'none',
  },
  rotate: { type: Number, default: 0 },
}

export default DIconProps
