<script setup>
import requestResetUserPasswordMutation from '@/graphql/mutations/requestResetUserPassword.gql'

const emit = defineEmits(['close'])

const { t } = useI18n()
const toast = useToast()
const loginModalsStore = useLoginModalsStore()

const loading = ref(false)
const resetPasswordError = ref(null)
const inputs = reactive({
  email: loginModalsStore.forgotEmail,
})

const { mutate: requestResetPassword } = useMutation(
  requestResetUserPasswordMutation,
  { clientId: CAPTAIN },
)

async function sendResetPassword() {
  if (!inputs.email || loading.value) {
    return
  }

  loading.value = true

  let error
  try {
    resetPasswordError.value = null
    const res = await requestResetPassword({
      email: inputs.email,
    })

    error = res.data.requestResetUserPassword.error
  } catch (e) {
    error = e
  }

  if (error) {
    const match = error.match(/oauth:(.*)/)

    if (match && match.length && match[1]) {
      // connected with a provider
      loginModalsStore.openOAuth(match[1])
    } else {
      resetPasswordError.value = error
    }
  } else {
    toast.push(
      {
        title: t('Check your mail'),
        message: t(
          'We have sent a password recover instructions to your email',
        ),
      },
      { variant: 'success' },
    )

    loginModalsStore.openSignin()
  }

  loading.value = false
}
</script>

<template>
  <DModal
    @close="
      () => {
        loginModalsStore.closeModal()
        emit('close')
      }
    "
  >
    <template #title>
      {{ t('Reset my password') }}
    </template>

    <p>
      {{
        t(
          "Enter the email associated with your account and we'll send an email with instructions to reset the password.",
        )
      }}
    </p>

    <form
      class="mt-4"
      @submit.prevent="sendResetPassword"
    >
      <DField>
        <template #label>
          {{ t('Your email') }}
        </template>
        <DInputText
          v-model="inputs.email"
          type="email"
          autocomplete="email"
          :placeholder="t('Type your email')"
        />
      </DField>

      <div v-if="resetPasswordError">
        <DAlert
          v-if="resetPasswordError === 'user_not_found'"
          variant="alert"
        >
          {{ t('That email is unknown') }}
        </DAlert>
        <DAlert
          v-else
          variant="alert"
        >
          {{ t('An error occurred, please retry later') }}
        </DAlert>
      </div>

      <p class="ml-1 text-center">
        <DButton
          type="submit"
          :loading="loading"
          :disabled="!inputs.email || loading"
        >
          {{ t('Send reset email') }}
        </DButton>
      </p>

      <div class="text-center">
        <DLink @click="() => loginModalsStore.openSignin">
          {{
            t('Cancel')
          }}
        </DLink>
      </div>
    </form>
  </DModal>
</template>
