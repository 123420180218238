<script setup lang="ts">
import union from '@turf/union'
import bbox from '@turf/bbox'
import center from '@turf/center'
import type { LngLatBoundsLike, LngLatLike } from 'maplibre-gl'

const props = defineProps<{
  area: GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.MultiPolygon>
}>()

const { area } = toRefs(props)
const geojson = ref<GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.MultiPolygon>>()
const bounds = ref<LngLatBoundsLike>()
const areaCenter = ref<LngLatLike>()

whenever(area, (area) => {
  const { features } = area

  if (!features || features.length === 0) {
    return
  }

  geojson.value = {
    ...area,
    features: [features.reduce((acc, feature) => {
      return union(acc, feature) || feature
    }, features[0])],
  }

  // enforce the type of bbox to LngLatBoundsLike because -> https://github.com/Turfjs/turf/issues/1297
  bounds.value = bbox(geojson.value) as LngLatBoundsLike
  // enforce the type of coordinates to LngLatLike because -> https://github.com/Turfjs/turf/issues/1297
  areaCenter.value = center(geojson.value).geometry.coordinates as LngLatLike
}, { immediate: true })
</script>

<template>
  <MapLibre
    :center="areaCenter"
    :bounds="bounds"
    :max-zoom="11"
    :min-zoom="7"
  >
    <MapLibreSourceGeojson
      v-if="geojson"
      id="area"
      :layer-props="{
        type: 'fill',
        paint: {
          'fill-color': '#20757C',
          'fill-outline-color': '#fff',
        },
      }"
      :data="geojson"
    />
  </MapLibre>
</template>
