<script setup lang="ts">
defineProps({
  icon: {
    type: String,
    default: null,
  },
})

const element = ref<HTMLDivElement | undefined>()
const isOpen = ref(false)

onBeforeRouteUpdate(() => {
  set(isOpen, false)
})

onBeforeUnmount(() => {
  set(isOpen, false)
})
</script>

<template>
  <div ref="element">
    <div
      v-if="isOpen"
      class="fixed bottom-36 bg-white rounded-md inset-x-4 p-4"
    >
      <slot />
    </div>
    <div
      class="h-14 w-14 rounded-md flex flex-center hover:bg-blue-200"
      :class="isOpen ? 'bg-blue-200 text-blue-700' : 'bg-white text-grey-300'"
      @click="isOpen = !isOpen"
    >
      <DIcon
        v-if="isOpen"
        path="cross"
        size="sm"
      />
      <DIcon
        v-else
        :path="icon || 'legend'"
        size="lg"
      />
    </div>
  </div>
</template>
