<script setup>
import signinMutation from '@/graphql/mutations/signin.gql'

const emit = defineEmits(['close'])

const { t } = useI18n()
const router = useRouter()

const loginModalsStore = useLoginModalsStore()
const displayError = useDisplayErrors()

const inputs = reactive({
  email: null,
  password: null,
})

const isLoginValid = computed(() => {
  if (!inputs.email || !inputs.password || !validatorEmail(inputs.email)) {
    return false
  }

  return true
})

const {
  mutate: loginEmail,
  onDone: onLoginEmailDone,
  onError,
  loading,
} = useMutation(signinMutation, { clientId: CAPTAIN })

onLoginEmailDone(({ data }) => {
  const { jwt, error } = data?.signin || {}

  if (jwt) {
    loginModalsStore.closeModal()
    router.push({
      name: 'LoginCallback',
      hash: `#jwt=${jwt}`,
    })
  } else {
    errorHandler(error)
  }
})

onError((error) => {
  errorHandler(error)
})

function errorHandler(error) {
  if (error === 'invalid_password') {
    displayError(t('Invalid password'))
  } else if (error === 'oauth:google') {
    loginModalsStore.openOAuth('google')
  } else if (error === 'oauth:microsoft') {
    loginModalsStore.openOAuth('microsoft')
  } else if (error === 'oauth:linkedin') {
    loginModalsStore.openOAuth('linkedin')
  } else if (error === 'oauth:github') {
    loginModalsStore.openOAuth('github')
  } else if (error === 'unknown_user') {
    displayError(t('That email is unknown'))
  } else if (error === 'reset') {
    loginModalsStore.openForgotPassword()
  } else {
    displayError(t('An error occurred, please retry later'))
  }
}

function loginPassword() {
  if (!LOGIN_DISABLED && !isLoginValid.value) {
    return
  }

  if (LOGIN_DISABLED) {
    router.push({ name: 'Overview' })
  }

  loginEmail({
    email: inputs.email,
    password: inputs.password,
  })
}
</script>

<template>
  <DModal
    @close="
      () => {
        loginModalsStore.closeModal()
        emit('close')
      }
    "
  >
    <template #title>
      {{ t('Log in') }}{{ LOGIN_DISABLED ? ' (disabled)' : '' }}
    </template>

    <form @submit.prevent="loginPassword">
      <DField>
        <template #label>
          {{ t('Your email') }}
        </template>
        <DInputText
          v-model="inputs.email"
          type="email"
          autocomplete="email"
          :placeholder="t('Type your email')"
        />
      </DField>
      <DField>
        <template #label>
          {{ t('Your password') }}
        </template>
        <DInputText
          v-model="inputs.password"
          type="password"
          maxlength="72"
          autocomplete="current-password"
          :placeholder="t('Type your password')"
        />
        <div class="text-end">
          <DLink @click="loginModalsStore.openForgotPassword(inputs.email)">
            {{ t('Forgot password?') }}
          </DLink>
        </div>
      </DField>

      <p class="text-center">
        <DButton
          type="submit"
          size="lg"
          :loading="loading"
          :disabled="!LOGIN_DISABLED && (!isLoginValid || loading)"
        >
          {{ t('Log in') }}{{ LOGIN_DISABLED ? ' (disabled)' : '' }}
        </DButton>
      </p>
    </form>

    <p class="mt-4 text-center text-grey-300">
      {{ t('or connect with:') }}
    </p>

    <LoginOAuthIcons class="m-auto my-4" />

    <div class="flex justify-between border-t pt-6">
      <span>{{ t('Not signed up yet?') }}</span>
      <RouterLink
        class="font-bold text-blue-500 hover:underline"
        :to="{ name: 'Register' }"
        @click="loginModalsStore.closeModal"
      >
        {{ t('Register now') }}
      </RouterLink>
    </div>
  </DModal>
</template>
