<script setup>
const props = defineProps({
  size: {
    type: String,
    default: 'md',
  },
  closable: {
    type: Boolean,
    default: true,
  },
  primary: Boolean,
})

const emit = defineEmits(['close'])

const { t } = useI18n()
const modal = ref()
const isOpen = ref(false)

onMounted(() => (isOpen.value = true))
onBeforeUnmount(() => (isOpen.value = false))

function onClose() {
  if (props.closable) {
    emit('close')
  }
}
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-if="isOpen"
        :tabIndex="-1"
        aria-hidden="false"
        class="fixed z-modal !m-0 flex h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden inset-0"
      >
        <div
          class="fixed bg-grey-700/60 inset-0"
          :class="{
            'bg-grey-700/70': !primary,
            'bg-teal-700/70': primary,
          }"
          @click="() => onClose()"
        />
        <div
          ref="modal"
          class="relative mx-auto h-full w-full p-4 text-left md:h-auto md:p-0"
          :class="{
            'max-w-lg': size === 'md',
            'max-w-2xl': size === 'lg',
          }"
        >
          <div class="relative overflow-hidden rounded-lg bg-teal-100 shadow">
            <header
              class="px-6 lg:px-8"
              :class="{
                'bg-gradient-to-r from-teal-500 to-teal-700 py-6 text-white':
                  primary,
                'pt-6 text-blue-500': !primary,
              }"
            >
              <h3
                class="flex items-center justify-between font-title text-2xl font-bold"
              >
                <slot name="title" />

                <DIconButton
                  v-if="closable"
                  path="close"
                  class="text-grey-500"
                  size="lg"
                  data-modal-toggle=""
                  :title="t('Close modal')"
                  @click="() => onClose()"
                />
              </h3>
            </header>
            <section class="px-6 pb-6 pt-4 lg:px-8">
              <slot :close="() => emit('close')" />
            </section>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
