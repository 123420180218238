<script setup>
defineOptions({
  name: 'CityTableSummary',
})

defineProps({
  kpi: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
const indicator = ref(INDICATOR_VALUE)
</script>

<template>
  <MetricCard
    :kpi="kpi"
    no-padding
    :variables="{
      indicator,
    }"
    :middleware="data => data.filter(d => d.provider !== null)"
    :used-filters="['mode', 'filterBySpecs']"
  >
    <template #title>
      {{ title || t('Summary') }}
    </template>
    <template #right>
      <FilterIndicator v-model="indicator" />
    </template>

    <template #default="scoped">
      <TableProvidersSummary
        :data="scoped.data"
        :indicator="indicator"
      />
    </template>
  </MetricCard>
</template>
