<script setup lang="ts">
defineOptions({
  name: 'CityCyclability',
})

const featureFlags = useFeaturesFlagsStore()
if (!featureFlags.get('screen')) {
  throw new Error('Feature not enabled')
}

const { t } = useI18n()
</script>

<template>
  <div class="overview">
    <BoardTitle hide-on-mobile>
      {{ t('Cyclability Report') }}
    </BoardTitle>

    <BoardGrid class="mt-4 lg:grid-cols-3">
      <CityCyclabilityIndexMetric class="lg:col-span-2" />
      <CityCyclabilityKeyFiguresMetric class="lg:col-span-2" />
      <CityCyclabilityAreaMap
        class="min-h-[400px] lg:min-h-full lg:col-span-1 lg:row-span-2 lg:row-start-1 lg:col-end-4"
      />
    </BoardGrid>
  </div>
</template>
