<script setup>
defineOptions({
  name: 'DPanel',
})

defineProps({
  noPadding: Boolean,
  position: {
    type: String,
    default: 'right',
  },
  transition: Boolean,
  isOpen: {
    type: Boolean,
    default: true,
  },
  fixed: Boolean,
})

const positionsClasses = {
  right: {
    open: 'right-0',
    close: '-right-[464px]',
  },
  left: {
    open: 'left-0',
    close: '-left-[464px]',
  },
  top: {
    open: 'top-0',
    close: '-top-[464px]',
  },
  bottom: {
    open: 'bottom-0',
    close: '-bottom-[464px]',
  },
}
</script>

<template>
  <div
    class="z-sidebar min-h-full w-full overflow-y-auto bg-teal-100 md:w-[464px] md:shadow-md"
    :class="[
      {
        'fixed': fixed,
        'relative': !fixed,
        'p-6': !noPadding,
        'md:w-[464px]': position === 'right' || position === 'left',
        'md:h-[464px]': position === 'top' || position === 'bottom',
        [positionsClasses[position].open]: isOpen,
        [positionsClasses[position].close]: !isOpen,
        'transition-all duration-300 ease-in-out': transition,
      },
    ]"
  >
    <slot />
  </div>
</template>
