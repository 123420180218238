<script setup>
const { t } = useI18n()
const { isFreemium } = storeToRefs(useUserStore())
</script>

<template>
  <DButton
    v-if="isFreemium"
    variant="fill-amber"
    :to="{ name: 'SubscribePlans' }"
    class="text-center !py-1.5"
  >
    <span class="text-sm uppercase leading-3">
      <span class="font-title font-bold">
        {{ t('Upgrade') }}
      </span>
      <br>
      <span class="text-xs font-light">
        {{ t('to_plan', { subscriptionPlan: t('data.plans.premium') }) }}
      </span>
    </span>
  </DButton>
</template>
