<script setup>
defineProps({
  name: {
    type: String,
    required: true,
  },
  validation: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
</script>

<template>
  <template v-if="validation[name].$dirty && validation[name].$error === true">
    <DFieldMessageError
      v-for="error in validation[name].$silentErrors"
      :key="error"
    >
      {{ t(error.$message) }}
    </DFieldMessageError>
  </template>
</template>
