<script setup>
import { Bar } from 'vue-chartjs'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  TimeScale,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      datasets: [],
    }),
  },
  options: {
    type: Object,
    default: () => ({}),
  },
})

ChartJS.register(
  Tooltip,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale,
)

const { data, options } = toRefs(props)
const chartData = shallowRef()
const chartOptions = shallowRef()

const datasets = computed(
  () => get(data)?.datasets.filter(d => d.data.length > 0) || [],
)

const extendedOptions = reactive(useChartExtendedOptions(options, datasets))
const tooltip = reactive(useChartTooltip(options, datasets))

watch(
  [data, options, extendedOptions, tooltip],
  ([data, options, extendedOptions, tooltip]) => {
    const dataValue = markRaw(clone(data || {}))

    const newOptions = {
      ...DEFAULT_CHART_OPTIONS,
      ...options,
    }

    if (extendedOptions.showStatus) {
      dataValue.datasets?.push(extendedOptions.statusSeries)
    } else if (extendedOptions.showWeekend) {
      dataValue.datasets?.push(extendedOptions.weekendSeries)
    }

    if (dataValue.datasets.length === 0) {
      dataValue.datasets.push({})
    }

    newOptions.scales = extendedOptions.getExtendedOptions(
      newOptions.scales,
      datasets,
    )
    newOptions.plugins = {
      ...newOptions.plugins,
      tooltip,
    }

    set(chartData, dataValue)
    set(chartOptions, newOptions)
  },
  { immediate: true, deep: true },
)
</script>

<template>
  <Bar
    :key="chartOptions"
    :data="chartData"
    :options="chartOptions"
    style="position: relative; height: 200px"
  />
</template>
