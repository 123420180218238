<script setup>
const emit = defineEmits(['close'])

const { t } = useI18n()
const loginModalsStore = useLoginModalsStore()
const router = useRouter()

function loginOAuth(provider) {
  if (LOGIN_DISABLED) {
    router.push({ name: 'Overview' })
  } else {
    window.location.href = `${
      import.meta.env.VITE_CAPTAIN_API
    }/v1/auth/${provider}?from=citydive&returnTo=${
      import.meta.env.VITE_CITY_BASE_URL
    }/login/callback`
  }
}
</script>

<template>
  <DModal
    id="modal-oauth"
    @close="
      () => {
        loginModalsStore.closeModal()
        emit('close')
      }
    "
  >
    <template #title>
      {{ t('Log in with') }}
    </template>

    <div v-if="loginModalsStore.oAuthProvider">
      <DMarkdown
        :source="
          t(
            'An account is alread associated with a {provider} account.\n Please signup with {provider} to signup.',
            { provider: capitalize(loginModalsStore.oAuthProvider) },
          )
        "
      />

      <div class="mt-4 text-center">
        <button
          class="h-12 w-12 rounded-full bg-white p-2.5 shadow-md transition-all hover:bg-blue-200"
          @click="loginOAuth(loginModalsStore.oAuthProvider)"
        >
          <img
            v-if="loginModalsStore.oAuthProvider === 'google'"
            src="@/assets/svg/signin-platforms/google.svg"
            :alt="t('oauth_providers.google')"
          >
          <img
            v-if="loginModalsStore.oAuthProvider === 'microsoft'"
            src="@/assets/svg/signin-platforms/microsoft.svg"
            :alt="t('oauth_providers.microsoft')"
          >
          <img
            v-if="loginModalsStore.oAuthProvider === 'linkedin'"
            src="@/assets/svg/signin-platforms/linkedin.svg"
            :alt="t('oauth_providers.linkedin')"
          >
          <img
            v-if="loginModalsStore.oAuthProvider === 'github'"
            src="@/assets/svg/signin-platforms/github.svg"
            :alt="t('oauth_providers.github')"
          >
        </button>
      </div>
    </div>
  </DModal>
</template>
