<script setup>
const emit = defineEmits(['close'])

const { t } = useI18n()

const { logout, goToUserMain, switchOrganization } = useUserStore()
const { organizations } = storeToRefs(useUserStore())
const loginModalsStore = useLoginModalsStore()

async function selectOrganization(organizationId) {
  await switchOrganization(organizationId)
  loginModalsStore.closeModal()
  goToUserMain()
}
</script>

<template>
  <DModal
    @close="
      () => {
        loginModalsStore.closeModal()
        emit('close')
      }
    "
  >
    <template #title>
      {{ t('Choose an organization') }}
    </template>

    <template v-if="organizations.length > 0">
      <DMarkdown
        :source="
          t(
            'Select the organization to start diving into the City Dive dashboard.\n You can switch later to another organization in your settings.',
          )
        "
      />
      <p class="flex flex-col items-center">
        <DButton
          v-for="o in organizations"
          :key="o.id"
          class="mt-3"
          @click="() => selectOrganization(o.id)"
        >
          <span>{{ o.name }}</span>
        </DButton>
      </p>
    </template>

    <template v-else>
      <p>
        {{
          t(
            "You don't have any organization linked to your account, it's probably an error. You can try to reconnect later or contact us.",
          )
        }}
      </p>
    </template>

    <p class="mt-8 text-center">
      {{ t('Or') }}<br>
      <DButton
        variant="alert"
        @click="logout"
      >
        {{ t('Logout') }}
      </DButton>
    </p>
  </DModal>
</template>
