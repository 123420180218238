<script setup>
defineOptions({
  name: 'CityMapSidebar',
})

const isOpen = ref(false)
</script>

<template>
  <div
    class="absolute -right-60 top-0 z-sidebar h-full w-64 bg-grey-50 transition-all"
    :class="{
      '!right-0': isOpen,
    }"
  >
    <DIconButton
      class="absolute top-1/2 -left-4 z-hop -mt-4"
      size="sm"
      :path="`chevron-${isOpen ? 'right' : 'left'}`"
      variant="fill-primary"
      @click="isOpen = !isOpen"
    />

    <div class="relative h-full w-64 overflow-y-auto">
      <slot />
    </div>
  </div>
</template>
