<script setup>
defineProps({
  crawls: {
    type: Array,
    default: () => [],
  },
  geometries: {
    type: Array,
    default: () => [],
  },
  isLoading: Boolean,
  zoom: {
    type: Number,
    default: 8,
  },
  center: {
    type: Array,
    default: () => [51.505, -0.09],
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const navigationStore = useNavigationStore()
const { translateFromData } = useLabelTranslation()

const percent = computed({
  get: () => (route.params.percent ? Number(route.params.percent) : 10),
  set: (value) => {
    router.push({
      params: {
        ...route.params,
        percent: value,
      },
    })
  },
})

const options = reactive({
  dataScale: 5,
  geometryType: getGeometryTypeFromUrl(route.params.areas),
  percent,
})

const variables = computed(() => {
  const vehicleType
    = route.params.mode !== VEHICLE_TYPE_ALL ? route.params.mode : null

  return {
    vehicleType: ENUM_VEHICLE_TYPES[vehicleType],
    zone: options.geometryType,
    percent: options.percent,
  }
})

const { data, loading } = useMetricData('tripsZone', variables)

onMounted(() => {
  globalEmitter.on('filters:reset', () => {
    options.dataScale = 5
    options.geometryType = getGeometryTypeFromUrl(route.params.areas)
    options.percent = 10
  })
})

onBeforeUnmount(() => {
  globalEmitter.off('filters:reset')
})
</script>

<template>
  <Teleport
    v-if="navigationStore.filtersIsMounted"
    to="#teleport-sidebar"
  >
    <CityMapSettings
      v-model:geometryType="options.geometryType"
      v-model:dataScale="options.dataScale"
      v-model:percent="options.percent"
      :geometries-options="
        geometries.map(geometry => ({
          value: geometry.name,
          text: translateFromData('geometry', geometry.name),
        }))
      "
      :geometries-loading="isLoading"
    />
  </Teleport>

  <div class="h-full">
    <FlowMap
      v-if="center"
      :data="data"
      :loading="loading"
      :zoom="zoom"
      :center="center"
      :zones="geometries"
      :options="options"
      :legend-title="t('Trips')"
    />
  </div>
</template>
