<script setup>
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import updateUserMutation from '@/graphql/mutations/updateUser.gql'
import doUpdatePasswordMutation from '@/graphql/mutations/updateUserPassword.gql'
import doRequestUpdateEmailMutation from '@/graphql/mutations/requestUpdateUserEmail.gql'

const props = defineProps({
  user: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const toast = useToast()
const { loadUser } = useUserStore()

const { mutate: updateUser } = useMutation(updateUserMutation, {
  clientId: CAPTAIN,
})
const { mutate: doUpdatePassword } = useMutation(doUpdatePasswordMutation, {
  clientId: CAPTAIN,
})
const { mutate: doRequestUpdateEmail } = useMutation(
  doRequestUpdateEmailMutation,
  {
    clientId: CAPTAIN,
  },
)

const formState = reactive({
  name: props.user.name,
  email: props.user.email,
  currentPassword: '',
  newPassword: '',
})

const rules = computed(() => ({
  name: {
    required,
  },
  email: {
    required,
  },
}))

const validation = useVuelidate(rules, formState)

const saving = ref(false)
const updateError = ref(false)
const score = ref(0)

async function onSubmitUserForm() {
  validation.value.$touch()

  if (validation.value.$invalid) {
    return
  }

  saving.value = true

  if (
    formState.currentPassword
    && formState.newPassword
    && formState.currentPassword !== formState.newPassword
  ) {
    const success = await updatePassword()

    if (!success) {
      return
    }
  }

  if (formState.email && formState.email !== props.user.email) {
    const success = await updateEmail()

    if (!success) {
      return
    }
  }

  if (formState.name && formState.name !== props.user.name) {
    await updateName()
  }

  await loadUser()
  saving.value = false
}

async function updateName() {
  try {
    await updateUser({ name: formState.name.trim() })

    return true
  } catch (err) {
    updateError.value = err.message
  }
}

async function updateEmail() {
  try {
    await doRequestUpdateEmail({ email: formState.email.trim() })

    toast.push(
      { message: t('Please check your emails to confirm the update.') },
      { variant: 'info' },
    )

    return true
  } catch (error) {
    if (error.message.match(/Email already used/)) {
      toast.push(
        {
          title: t('Error'),
          message: t('This email is already used'),
        },
        { variant: 'alert' },
      )
    } else {
      toast.push(
        { message: t('An error occurred, please retry later') },
        { variant: 'alert' },
      )
    }

    return false
  }
}

async function updatePassword() {
  let error

  try {
    const res = await doUpdatePassword({
      oldPassword: formState.currentPassword,
      newPassword: formState.newPassword,
    })

    error = res.data.updateUserPassword?.error
  } catch (e) {
    error = e
  }

  if (error === 'invalid_password') {
    toast.push(
      {
        title: t('Error'),
        message: t('Your current password is incorrect.'),
      },
      { variant: 'alert' },
    )
  } else if (error) {
    toast.push(
      {
        title: t('Error'),
        message: t('An error occurred, please retry later'),
      },
      { variant: 'alert' },
    )
  } else {
    toast.push(
      { message: t('Your password has been updated.') },
      { variant: 'info' },
    )

    formState.currentPassword = ''
    formState.newPassword = ''
  }

  return !error
}

function canSave() {
  let hasChanged = false

  if (!formState.name) {
    return false
  }

  hasChanged = formState.name !== props.user.name

  if (formState.newPassword) {
    if (!isValidPassword()) {
      return false
    }

    if (formState.newPassword !== formState.currentPassword) {
      hasChanged = true
    }
  }

  if (!formState.email || !validatorEmail(formState.email)) {
    return false
  }

  if (formState.email !== props.user.email) {
    hasChanged = true
  }

  return hasChanged
}

function isValidPassword() {
  if (!formState.currentPassword) {
    return false
  }

  if (!formState.newPassword) {
    return false
  }

  if (score.value <= 2) {
    return false
  }

  return true
}
</script>

<template>
  <form
    class="pt-3"
    @submit.prevent="onSubmitUserForm"
  >
    <DField>
      <template #label>
        {{ t('Name (first & last)') }}
      </template>
      <DInputText
        v-model="formState.name"
        :placeholder="t('Enter full name')"
        :state="
          validation.name.$dirty && !validation.name.required
            ? !validation.name.$error
            : null
        "
      />
      <p
        v-if="
          validation.name.$dirty
            && !validation.name.required
            && validation.name.$error
        "
        class="text-sm text-red-500"
      >
        {{ t('Please enter your full name.') }}
      </p>
    </DField>

    <DAlert
      v-if="updateError"
      class="mt-2 mb-2"
      variant="alert"
      closable
    >
      {{ updateError }}
    </DAlert>

    <DField>
      <template #label>
        {{ t('Email') }}
      </template>
      <DInputText
        v-model="formState.email"
        :placeholder="t('Enter email')"
      />
      <p
        v-if="validation.email.$error"
        class="text-sm text-red-500"
      >
        {{ t('Please enter your email.') }}
      </p>
    </DField>

    <DField v-if="user.hasPassword">
      <template #label>
        {{ t('Password') }}
      </template>
      <DInputText
        v-model="formState.currentPassword"
        :placeholder="t('Your current password')"
      />
      <DInputText
        v-model="formState.newPassword"
        class="mt-4"
        :placeholder="t('The new password')"
      />
      <PasswordStrengthMeter
        :password="formState.newPassword"
        @score="v => (score = v)"
      />
    </DField>

    <DButton
      type="submit"
      :disabled="!canSave() || saving"
      :loading="saving"
    >
      {{ t('Save changes') }}
    </DButton>
  </form>
</template>
