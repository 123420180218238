<script setup>
const props = defineProps({
  target: {
    type: Element,
    required: true,
  },
  containerId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['onAction'])
const targetBounding = reactive(useElementBounding(props.target))
const container = document.getElementById(props.containerId)

const { pause, resume } = useRafFn(
  () => {
    targetBounding.update()
  },
  { immediate: false },
)

function onTransitionStart(event) {
  if (event.target === container) {
    resume()
  }
}

function onTransitionEnd(event) {
  if (event.target === container) {
    pause()
  }
}

onMounted(() => {
  container.style.pointerEvents = 'none'
  container.addEventListener('transitionstart', onTransitionStart)
  container.addEventListener('transitionend', onTransitionEnd)
})

onUnmounted(() => {
  container.style.pointerEvents = 'auto'
  container.removeEventListener('transitionstart', onTransitionStart)
  container.removeEventListener('transitionend', onTransitionEnd)
})
</script>

<template>
  <div class="absolute w-full h-full top-0 left-0 z-modal">
    <!-- top -->
    <div
      class="absolute bg-black/60"
      :style="{
        top: 0,
        left: `${targetBounding.left}px`,
        height: `${targetBounding.top}px`,
        width: `${targetBounding.width}px`,
      }"
      @click="() => emit('onAction')"
    />

    <!-- right -->
    <div
      class="absolute bg-black/60"
      :style="{
        top: 0,
        left: `${targetBounding.left + targetBounding.width}px`,
        height: '100%',
        width: `calc(100% - ${targetBounding.right}px)`,
      }"
      @click="() => emit('onAction')"
    />

    <!-- bottom -->
    <div
      class="absolute bg-black/60"
      :style="{
        top: `${targetBounding.top + targetBounding.height}px`,
        left: `${targetBounding.left}px`,
        height: `calc(100% - ${targetBounding.bottom}px)`,
        width: `${targetBounding.width}px`,
      }"
      @click="() => emit('onAction')"
    />

    <!-- left -->
    <div
      class="absolute bg-black/60"
      :style="{
        top: 0,
        left: 0,
        height: '100%',
        width: `${targetBounding.left}px`,
      }"
      @click="() => emit('onAction')"
    />
  </div>
</template>
