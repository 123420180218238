<script setup>
import summaryCityQuery from '@/graphql/queries/summaryCity.gql'

const props = defineProps({
  cities: {
    type: Object,
    default: null,
  },
  loading: Boolean,
})

const route = useRoute()

const city = computed(() =>
  props.cities.data.find(c => c.name === route.params.citySlug),
)

const variables = computed(() => ({
  city: route.params.citySlug,
}))

// retrieve city VehicleTypes
const { loading: vehicleTypesLoading, result } = useQuery(
  summaryCityQuery,
  variables,
)
const vehicleTypes = computed(() => {
  return result.value?.summaryCity.data.vehicleTypes
})
</script>

<template>
  <CityInfoSidebar
    class="absolute right-0 top-0 z-sidebar h-full w-full shadow-md md:w-[464px]"
    :city="city"
    :vehicle-types="vehicleTypes"
    :vehicle-types-loading="vehicleTypesLoading"
    :loading="loading"
    show-details
  />
</template>
