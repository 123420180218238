import setFavoriteMutation from '@/graphql/mutations/setFavorite.gql'

export function useFavorite() {
  const { settings } = storeToRefs(useUserStore())

  const isFavorite = (slug: string, type = 'city') => {
    return settings.value?.favorites[
      type === 'city' ? 'cities' : 'providers'
    ]?.includes(slug)
  }

  // TODO: add error handling and
  // import.meta.env.VITE_DISABLE_LOGIN &&
  // import.meta.env.VITE_DISABLE_LOGIN === 'true'
  const { mutate, onDone } = useMutation(setFavoriteMutation, () => ({
    variables: {
      category: 'cities',
      element: '',
      favorite: false,
    },
  }))

  onDone(({ data }) => {
    const { cities, providers } = data.setFavorite
    const settingsRef = get(settings)

    settingsRef.favorites.cities = [...(cities || [])]
    settingsRef.favorites.providers = [...(providers || [])]
    set(settings, settingsRef)
  })

  function setFavorite(slug: string, type = 'city') {
    mutate({
      category: type === 'city' ? 'cities' : 'providers',
      element: slug || '',
      favorite: !isFavorite(slug, type),
    })
  }

  return {
    isFavorite,
    isCityFavorite: (slug: string) => isFavorite(slug, 'city'),
    isProviderFavorite: (slug: string) => isFavorite(slug, 'provider'),
    setFavorite,
    setCityFavorite: (slug: string) => setFavorite(slug, 'city'),
    setProviderFavorite: (slug: string) => setFavorite(slug, 'provider'),
  }
}
