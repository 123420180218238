<script setup>
defineOptions({
  name: 'ProviderFleetsCars',
})

const { t } = useI18n()
const { translateFromData } = useLabelTranslation()
</script>

<template>
  <MetricCard
    query="providerFleetsCars"
    :kpi="[
      'providerFleetsCarSize',
      'providerFleetsCarPropulsion',
      'providerFleetsCarModel',
    ]"
    :as-mode="VEHICLE_TYPE_CAR"
    :used-filters="['dateRange', 'regions', 'country', 'filterBySpecs']"
  >
    <template #title>
      {{ t('Car specs - Fleet breakdown') }}
    </template>

    <template #default="{ data }">
      <div class="flex flex-col md:flex-row relative gap-4 justify-between">
        <div class="w-full max-w-xs">
          <h4 class="text-base uppercase text-grey-300 py-4">
            {{ t('Size') }}
          </h4>
          <PercentBarMetric
            :data="
              data?.[0]
                .map(({ carSize, value, percent }) => {
                  return {
                    label: translateFromData('carSize', carSize),
                    percent,
                    value,
                    color: getColorByCarSize(carSize),
                  }
                })
                .filter(d => d.value !== null)
            "
          />
        </div>
        <div class="w-full max-w-xs">
          <h4 class="text-base uppercase text-grey-300 py-4">
            {{ t('Engine Type') }}
          </h4>
          <PercentBarMetric
            :data="
              data?.[1]
                .map(({ carPropulsion, value, percent }) => {
                  return {
                    label: translateFromData('carPropulsion', carPropulsion),
                    percent,
                    value,
                    color: getColorByCarPropulsion(carPropulsion),
                  }
                })
                .filter(d => d.value !== null)
            "
          />
        </div>
        <div class="w-full max-w-xs">
          <h4 class="text-base uppercase text-grey-300 py-4">
            {{ t('Model') }}
          </h4>
          <PercentBarMetric
            :data="
              data?.[2]
                .map(({ carModel, value, percent }) => {
                  return {
                    label: translateFromData('carModel', carModel),
                    percent,
                    value,
                    color: carModel === UNKNOWN ? '#000000' : null,
                  }
                })
                .filter(d => d.value !== null)
            "
            :limit="5"
          />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
