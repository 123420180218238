<script setup>
import { marked } from 'marked'
import DOMPurify from 'dompurify'

const props = defineProps({
  source: { default: '', type: String },
})

const compiledMarkdown = computed(() =>
  DOMPurify.sanitize(marked(props.source, { breaks: true })),
)
</script>

<template>
  <div v-html="compiledMarkdown" />
</template>
