<script setup>
defineOptions({
  name: 'AreaMap',
})

const props = defineProps({
  zoom: {
    type: Number,
    default: 7,
  },
  center: {
    type: Array,
    default: () => [-0.09, 51.505],
  },
  zones: {
    type: Array,
    default: () => [],
  },
  crawls: {
    type: Array,
    default: () => [],
  },
  data: {
    type: Object,
    default: () => ({
      geojson: {
        features: [],
      },
      providers: [],
    }),
  },
  options: {
    type: Object,
    default: () => ({
      dataScale: 5,
      geometryType: 'h3_8',
      providers: [],
    }),
  },
  legendTitle: {
    type: String,
    default: null,
  },
  loading: Boolean,
})

const { dataScale, geometryType, providers } = toRefs(props.options)

const { t } = useI18n()
const featuresFlagsStore = useFeaturesFlagsStore()
const { selectedArea, onSelectArea, getAreaName, unselectAllAreas }
  = useSelectedAreaFromUrl(geometryType)

const geojson = computed(
  () =>
    props.data?.geojson || {
      type: 'FeatureCollection',
      features: [],
    },
)
const { breaks, getColorInRange } = useClassBreaks(geojson, dataScale)

const areaPaintExpression = computed(() => {
  const breaksRef = get(breaks)

  const fillColor = [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    '#4F74EF',
  ]

  if (breaksRef && breaksRef.length > 0) {
    fillColor.push([
      'step',
      ['get', 'mean'],
      '#20757C',
      ...breaksRef.slice(1).reduce((acc, b, index) => {
        acc.push(breaksRef[index] || 0)
        acc.push(getColorInRange(b || 'transparent'))
        return acc
      }, []),
    ])
  } else {
    fillColor.push('#C1CCCD')
  }

  return {
    'fill-color': fillColor,
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.6,
      0.4,
    ],
    'fill-outline-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#fff',
      'transparent',
    ],
  }
})

function onFeatureClick({ event }) {
  if (!featuresFlagsStore.get('map_selected_area')) {
    return
  }

  const name = event.features[0].properties?.name

  onSelectArea(name)
}

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
  >
    <MapLibrePopup
      :offset="[0, -5]"
      layer-id="city-map"
    >
      <template #element="{ feature }">
        <div class="flex w-28 flex-col whitespace-normal text-xs">
          <p>{{ getAreaName(feature?.properties?.name, ' ') }}</p>
          <p class="font-bold">
            {{ `${formatNumber(feature?.properties?.mean)} ${t('vehicles')}` }}
          </p>
          <p
            v-if="featuresFlagsStore.get('map_selected_area')"
            class="mt-4 text-grey-300"
          >
            {{ t('Click on zone for more details') }}
          </p>
        </div>
      </template>
    </MapLibrePopup>

    <MapLibreSourceGeojson
      v-if="!loading"
      id="city-map"
      :layer-props="{
        type: 'fill',
        paint: areaPaintExpression,
      }"
      :data="geojson"
      :geojson-options="{
        promoteId: 'name',
      }"
      :selected-features="selectedArea || []"
      @click="onFeatureClick"
    />

    <CrawlZones
      :data="crawls"
      :sources="[
        ...new Set(
          data?.providers
            .filter(p => providers.length === 0 || providers.includes(p.name))
            .map(p => p.source) || [],
        ),
      ]"
    />

    <!-- <Zones /> -->

    <MapLibreControl
      v-if="selectedArea.length !== 0"
      position="top-right"
      no-style
      class="rounded-full"
    >
      <DButton
        variant="fill-primary"
        icon-left="cross"
        @click="unselectAllAreas"
      >
        {{ t('Unselect all') }}
      </DButton>
    </MapLibreControl>

    <DefineTemplate>
      <DLoader v-if="loading" />
      <MapLegend
        v-else-if="breaks"
        :breaks="breaks"
        :get-color="getColorInRange"
      >
        <template
          v-if="legendTitle"
          #title
        >
          {{ legendTitle }}
        </template>
      </MapLegend>
    </DefineTemplate>

    <SidebarMobileControls>
      <template #default="{ isMobile }">
        <MapLibreControl
          v-if="!isMobile"
          position="bottom-left"
        >
          <ReuseTemplate />
        </MapLibreControl>
      </template>

      <template #mobile>
        <ReuseTemplate />
      </template>
    </SidebarMobileControls>
  </MapLibre>
</template>
