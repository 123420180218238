<script setup>
defineOptions({
  name: 'NavigationSearchItem',
})

const props = defineProps({
  route: {
    type: [Object, String],
    required: true,
  },
  type: {
    type: String,
    required: true,
    validator: value => ['city', 'provider'].includes(value),
  },
  unlocked: Boolean,
  isSelected: Boolean,
  name: {
    type: String,
    default: null,
  },
  slug: {
    type: String,
    default: null,
  },
  country: {
    type: Object,
    default: () => null,
  },
})

const { t } = useI18n()
const isCity = computedEager(() => props.type === 'city')
const myName = computed(() =>
  props.nameLocalized ? props.nameLocalized : capitalize(props.name || props.slug),
)
</script>

<template>
  <DLink
    :key="slug"
    :disabled="!unlocked"
    class="flex h-14 cursor-pointer items-center py-2 pl-4 hover:bg-grey-100 text-left"
    :class="{
      'bg-grey-100': isSelected,
    }"
    :to="route"
  >
    <DFlag
      v-if="country"
      :country-code="country.countryCode"
      :title="country.countryLocalized"
    />
    <img
      v-else
      class="mr-2 h-6 w-6 overflow-hidden bg-grey-300"
      :src="`${CDN_PATH}/providers/${slug}-2x.jpg`"
      :alt="t('provider_logo', { provider: myName })"
    >
    <div class="flex-1 leading-3">
      <span class="text-sm text-blue-500">{{
        isCity ? t('City') : t('Operator')
      }}</span>
      <br>
      <span class="font-bold">{{ myName }}</span>
      <small
        v-if="country"
        class="capitalize text-grey-500"
      >
        - {{ country.countryLocalized }}
      </small>
    </div>
    <DIcon
      :path="!unlocked ? 'cadenas' : 'chevron-left'"
      class="mr-2 text-blue-500"
      :class="{
        'text-grey-500': !unlocked,
      }"
      flip="horizontal"
      size="sm"
    />
  </DLink>
</template>
