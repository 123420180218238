<script setup>
defineProps({
  data: {
    type: Object,
    required: true,
  },
  indicator: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()

const fields = [
  {
    text: t('Operators', 2),
    value: 'provider',
  },
  {
    text: t('Yesterday'),
    value: 'yesterday',
    sortable: true,
  },
  {
    text: t('Last 7 days (AVG)'),
    value: 'last7d',
    sortable: true,
  },
  {
    text: t('Last 30 days (AVG)'),
    value: 'last30d',
    sortable: true,
  },
]
</script>

<template>
  <DSortingTable
    :fields="fields"
    :rows="
      data
        ?.filter(d => d.provider !== null)
        .map(d => ({
          cols: {
            ...d,
          },
        }))
    "
    :total="
      indicator === INDICATOR_VALUE
        ? {
          cols: {
            ...data?.find(d => d.provider === null),
            provider: t('All Operators'),
          },
        }
        : null
    "
    :find-value="v => v.slug || v.value"
    sortable
  >
    <template #default="{ field, d, isTotal }">
      <div
        class="px-2"
        :class="{
          'py-2 pl-6 text-left': field === 'provider',
          'text-sm uppercase': field === 'provider' && isTotal,
          'py-1': !(field === 'provider' && isTotal),
        }"
      >
        <template v-if="field === 'provider'">
          <template v-if="isTotal">
            {{ d }}
          </template>

          <ProviderIconLink
            v-else
            inline
            :slug="d.slug"
            :status="d.status"
            :not-monitored="d.notMonitored"
            :estimated="d.estimated"
            :locked="d.locked"
            class="whitespace-nowrap"
          />
        </template>

        <template v-else>
          <TrendMetric
            :data="d"
            inline
            :is-percent="indicator === INDICATOR_PERCENT"
          />
        </template>
      </div>
    </template>
  </DSortingTable>
</template>
