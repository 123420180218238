export function useTitles() {
  const route = useRoute()
  const { locale } = useI18n()
  const { translateIfExists } = useLabelTranslation()
  const providersStore = useProvidersStore()
  const citiesStore = useCitiesStore()

  const title = ref('')

  const providerName = computed(
    () =>
      route.params.provider
      && providersStore.providersBySlug[route.params.provider]?.name,
  )

  const cityName = computed(
    () =>
      (route.params.citySlug || route.params.city)
      && citiesStore.citiesByName[route.params.citySlug || route.params.city]
        ?.nameLocalized,
  )

  watch([route, providerName, cityName], ([newRoute, provider, city]) => {
    const meta = newRoute.meta.title
    title.value = translateIfExists(`titles.${meta}`, '...')

    if (provider) {
      title.value = `${provider} - ${translateIfExists(`titles.provider.${meta}`, '...')}`
    } else if (city) {
      title.value = `${city} - ${translateIfExists(`titles.city.${meta}`, '...')}`
    }
  }, { immediate: true })

  useHead({
    title,
    titleTemplate: '%s | City Dive',
    htmlAttrs: {
      lang: locale,
    },
  })
}

export default {
  useTitles,
}
