<script setup>
const route = useRoute()
const router = useRouter()
const loginModalsStore = useLoginModalsStore()

function onClose() {
  if (route.name === 'Login' || route.name === 'LoginCallback') {
    router.push('/')
  }
}
</script>

<template>
  <AuthChangeOrganization
    v-if="loginModalsStore.currentModal === 'change-organization'"
    @close="onClose"
  />
  <AuthSignin
    v-else-if="loginModalsStore.currentModal === 'signin'"
    @close="onClose"
  />
  <AuthForgotPassword
    v-else-if="loginModalsStore.currentModal === 'forgot-password'"
    @close="onClose"
  />
  <AuthOAuth
    v-else-if="loginModalsStore.currentModal === 'oauth'"
    @close="onClose"
  />
</template>
