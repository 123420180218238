<script setup>
const { t } = useI18n()

const filtersStore = useFiltersStore()
const { mode } = useMode()

const { provider } = storeToRefs(useProviderStore())
</script>

<template>
  <div class="overview">
    <BoardTitle hide-on-mobile>
      {{ t('Overview') }}
    </BoardTitle>

    <DAlert
      v-if="provider?.slug === DEMO"
      id="provider-overview-demo"
      variant="warning"
      closable
    >
      {{
        t(
          'This a demo operator. All data is random and should only be used for discovery and test purposes.',
        )
      }}
      <template #subtitle>
        <I18nMd
          keypath="upgrade_to_unlock_operator_pages"
          tag="p"
          :variables="{
            subscriptionPlan: t('data.plans.premium'),
          }"
        >
          <template #link="{ translation }">
            <DLink
              :to="{ name: 'SubscribePlans' }"
              class="underline"
            >
              {{ translation }}
            </DLink>
          </template>
        </I18nMd>
      </template>
    </DAlert>

    <DAlert
      v-else-if="
        filtersStore.region
          && provider
          && !provider.regions?.filtered?.includes(filtersStore.region)
      "
      variant="warning"
    >
      {{ t('This operator is currently not available in the selected region') }}
      <template #subtitle>
        <I18nMd
          keypath="click_to_select_region"
          tag="p"
        >
          <template #link="{ translation }">
            <DLink
              class="underline"
              @click="globalEmitter.emit('open:region')"
            >
              {{ translation }}
            </DLink>
          </template>
        </I18nMd>
      </template>
    </DAlert>

    <DAlert
      v-else
      id="provider-overview"
      variant="info"
      closable
    >
      {{
        t(
          'The data displayed on this page is focused on all cities on City Dive',
        )
      }}
      <template #subtitle>
        {{ t('(Even those not unlocked on your account)') }}
      </template>
    </DAlert>

    <BoardGrid class="mt-4 lg:grid-cols-2">
      <ProviderVehicleOverview v-if="mode" />
      <ProviderFleetsOverview v-else />
      <ProviderFootprintsOverview />
      <ProviderFleetsCarsOverview v-if="mode === VEHICLE_TYPE_CAR" />
      <ProviderFleetsCarModelOverview v-if="mode === VEHICLE_TYPE_CAR" />
      <ProviderVehicleFleetOverview />
      <NewsfeedWidget
        :provider="provider"
        :modes="mode ? [mode] : null"
      />
    </BoardGrid>
  </div>
</template>
