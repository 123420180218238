<script setup>
defineOptions({
  name: 'CityTripsHourly',
})

const { t } = useI18n()
const { mode } = useMode()
</script>

<template>
  <MetricCard
    kpi="tripsHourly"
    :variables="{
      vehicleType: ENUM_VEHICLE_TYPES[mode],
    }"
    :used-filters="['mode', 'dateRange', 'filterBySpecs']"
  >
    <template #title>
      {{ t('Trip distribution per hour') }}
    </template>
    <template #subtitle>
      {{ `${t('weekday')} / ${t('weekend')}` }}
    </template>

    <template #default="{ data }">
      <BarMetric
        :data="data"
        :options="{
          scales: {
            y: {
              title: {
                display: true,
                text: t('percent_trips'),
                padding: 0,
              },
            },
          },
          unstacked: true,
          color: d => PALETTE_COLORS_WEEK[d.name],
        }"
      />
    </template>
  </MetricCard>
</template>
