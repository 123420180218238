<script setup>
import DSvgVue from '../DSvg/DSvg.vue'
import DIconProps from './DIconProps.js'

const props = defineProps(DIconProps)

const styles = computed(() => {
  const { flip, rotate } = props
  return {
    '--sx': ['both', 'horizontal'].includes(flip) ? '-1' : '1',
    '--sy': ['both', 'vertical'].includes(flip) ? '-1' : '1',
    '--r': Number.isNaN(rotate) ? rotate : `${rotate}deg`,
  }
})

const attrs = computed(() => {
  const { name, src, path } = props
  const attrs = {}

  if (!props.path) {
    attrs.alt = name
    attrs.src = src || getIconSvg(name)
  } else {
    attrs.title = name
    attrs.path = ICON_PATHS[path]
  }

  return attrs
})

function getIconSvg(name) {
  return new URL(`../../../assets/svg/icons/${name}.svg`, import.meta.url).href
}
</script>

<template>
  <i
    class="d-icon flex-center relative box-content inline-flex align-middle"
    :class="[
      size,
      {
        'p-2 md:p-3': size === 'lg',
        'p-2': size === 'md',
        'p-1': size === 'sm',
        'p-0.5 mt-[-2px]': size === 'xs',
      },
    ]"
  >
    <component
      :is="path ? DSvgVue : 'img'"
      class="relative"
      :class="{
        'h-8 w-8': size === 'lg',
        'h-6 w-6': size === 'md',
        'h-4 w-4': size === 'sm',
        'h-3 w-3': size === 'xs',
      }"
      :style="styles"
      v-bind="attrs"
    />
  </i>
</template>

<style scoped>
.d-icon {
  line-height: inherit;
}

svg,
symbol,
img {
  transform: rotate(var(--r, 0deg)) scale(var(--sx, 1), var(--sy, 1));
}
</style>
