<script setup>
import { useImage } from '@vueuse/core'

const props = defineProps({
  src: {
    type: String,
    default: null,
  },
  alt: {
    type: String,
    default: null,
  },
  errorSrc: {
    type: String,
    default: null,
  },
})

const { isLoading, error } = useImage({ src: props.src })
const { t } = useI18n()
</script>

<template>
  <slot
    v-if="isLoading"
    name="loading"
  >
    <DLoader />
  </slot>
  <img
    v-else-if="error && errorSrc"
    :src="errorSrc"
    :alt="alt || t('image_not_loaded')"
    class="h-full w-full object-cover"
  >
  <img
    v-else
    :src="props.src"
    class="h-full w-full object-cover"
  >
</template>
