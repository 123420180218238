<script setup>
const { t } = useI18n()
const { subscription, draft, role } = storeToRefs(useUserStore())

const hasBilling = computed(() => {
  return ['owner', 'billing'].includes(role.value)
})
</script>

<template>
  <div class="space-y-4">
    <BoardTitle hide-on-mobile>
      {{ t('My plan') }}
    </BoardTitle>

    <div
      v-if="subscription"
      class="mt-3 mb-3"
    >
      <Subscription :manage="hasBilling" />
    </div>

    <PaymentDraft
      v-if="draft"
      :draft="draft"
    />
  </div>
</template>
