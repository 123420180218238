export function useScrollIntoView({ root = null, targets = [] }) {
  const router = useRouter()
  const route = useRoute()
  const { y, isScrolling } = useScroll(
    root,
    { behavior: 'smooth' },
  )

  const intersectors = []
  const activeHash = ref(null)
  const targetState = reactive({})

  onMounted(() => {
    if (targets && targets.length) {
      targets.forEach((target) => {
        spy(target)
      })
    }

    if (route.hash) {
      nextTick(() => {
        scrolling(route.hash)
      })

      activeHash.value = route.hash
    }
  })

  onBeforeUnmount(() => {
    stopSpyAll()
  })

  watch(
    () => route.hash,
    async (toHash, fromHash) => {
      if (toHash && toHash !== fromHash) {
        scrolling(toHash)
        activeHash.value = toHash
      }
    },
  )

  function spy(target) {
    intersectors.push(
      useIntersectionObserver(
        target,
        ([{ isIntersecting }] /* observerElement */) => {
          const targetRef = get(target)
          const id = targetRef.id || targetRef.$el.id

          if (isIntersecting && id) {
            updateHash(id)
          }

          targetState[id] = isIntersecting
        },
        {
          root,
          threshold: 0.1,
        },
      ),
    )
  }

  function stopSpyAll() {
    intersectors.forEach(intersector => intersector.stop())
  }

  function scrolling(hash) {
    if (get(isScrolling)) {
      return
    }

    let el

    try {
      el = document.querySelector(hash)
    } catch {
      try {
        el = document.querySelector(`[data-tag="${hash.replace('#/', '')}"]`)
      } catch (e) {
        console.error(e)
      }
    }

    if (el) {
      set(y, el.offsetTop)
    }
  }

  function updateHash(itemId) {
    router.replace({
      hash: `#${itemId}`,
    })
  }

  return {
    spy,
    stopSpyAll,
  }
}
