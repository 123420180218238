<script setup>
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
  text: { type: String, default: null },
  icon: { type: String, default: null },
  label: { type: String, default: null },
  align: {
    type: String,
    default: 'left',
    validator: value => ['left', 'right'].includes(value),
  },
  block: Boolean,
  forceOpen: Boolean,
  autoClose: {
    type: Boolean,
    default: true,
  },
  maxHeight: {
    type: Number,
    default: null,
  },
  noStyle: Boolean,
})
const emit = defineEmits(['close', 'open'])

const { forceOpen } = toRefs(props)
const floatOptions = reactive({
  forceOpen,
})

const reference = ref(null)
const floating = ref(null)

const {
  isOpen,
  floatingStyles,
  width: minWidth,
  maxHeight: floatingMaxHeight,
} = useOpenAndFloat(reference, floating, floatOptions)

const { isHighlighted } = useFilterHighlight(props.id, isOpen)

watch(isOpen, (isOpen) => {
  if (isOpen) {
    emit('open')
  } else {
    emit('close')
  }
})

const sizeStyles = computed(() => {
  const floatingMaxHeightRef = get(floatingMaxHeight)
  const fmhOrZero = floatingMaxHeightRef ?? 0

  return {
    maxHeight:
      props.maxHeight && fmhOrZero > props.maxHeight
        ? `${props.maxHeight}px`
        : floatingMaxHeightRef !== null
          ? `${floatingMaxHeightRef}px`
          : null,
    minWidth: `${get(minWidth)}px`,
  }
})

function close() {
  set(isOpen, false)
  emit('close')
}

function onClickInside() {
  if (props.autoClose) {
    close()
  }
}
</script>

<template>
  <div>
    <div
      ref="reference"
      class="w-full cursor-pointer relative transition-colors duration-300"
      :class="{
        'bg-grey-50 hover:bg-blue-100 text-slate-500 rounded-md font-bold min-h-[44px]':
          !noStyle,
        block,
        'inline-block': !block,
        'rounded-b-none': isOpen,
        '!bg-yellow-100': isHighlighted,
      }"
      @click="() => (isOpen = !isOpen)"
    >
      <div
        class="flex flex-col justify-center pr-10 max-w-full"
        :class="{
          'ml-2 my-1': !noStyle,
          'text-right': align === 'right',
        }"
      >
        <!-- Picker Label -->
        <p
          v-if="icon || label"
          class="text-xs text-grey-300 font-bold leading-3 whitespace-nowrap overflow-hidden text-ellipsis"
          :title="label"
        >
          <DIcon
            v-if="icon"
            :path="icon"
            size="xs"
          />
          {{ label }}
        </p>

        <!-- Picker Title/Value -->
        <component
          :is="$slots.text ? 'div' : 'p'"
          :class="{
            'whitespace-nowrap overflow-y-visible overflow-x-hidden text-ellipsis':
              !$slots.text,
          }"
          :title="text"
        >
          <slot name="text">
            {{ text }}
          </slot>
        </component>
      </div>

      <DIcon
        class="!absolute right-2 top-0 h-full py-0"
        :path="isOpen || forceOpen ? 'chevron-up' : 'chevron-down'"
        size="sm"
      />
    </div>

    <Teleport
      v-if="isOpen || forceOpen"
      to="body"
    >
      <div
        ref="floating"
        class="z-popup bg-white shadow"
        :class="{
          'overflow-auto': props.maxHeight || floatingMaxHeight,
        }"
        :style="[floatingStyles, sizeStyles]"
        @click="onClickInside"
      >
        <slot :close="close" />
      </div>
    </Teleport>
  </div>
</template>
