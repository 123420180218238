<script setup>
import { RouterLink } from 'vue-router'

defineProps({
  variant: {
    type: String,
    default: 'fill-primary',
    validator: validatorButtonsVariants,
  },
  size: {
    type: String,
    default: 'md',
    validator: validatorSizes,
  },
  pill: {
    type: Boolean,
    default: true,
  },
  disabled: Boolean,
  active: Boolean,
  loading: Boolean,
  iconLeft: {
    type: String,
    default: null,
  },
  iconRight: {
    type: String,
    default: null,
  },
})

const attrs = useAttrs()
</script>

<template>
  <component
    :is="attrs.to ? RouterLink : attrs.href ? 'a' : 'button'"
    class="d-button inline-flex cursor-pointer items-center rounded-md font-bold transition-all duration-200"
    :class="[variant, size, { pill, disabled, active }]"
    v-bind="attrs"
  >
    <DLoader
      v-if="loading"
      :size="size === 'md' || size === 'sm' ? 'sm' : 'md'"
      color="light"
      class="py-0"
      :class="{
        '-ml-2': size === 'sm',
        '-ml-4': size === 'md',
        '-ml-8': size === 'lg',
      }"
    />
    <DIcon
      v-else-if="iconLeft"
      :path="iconLeft"
      :size="size === 'md' || size === 'sm' ? 'sm' : 'md'"
      class="py-0"
      :class="{
        '-ml-2': size === 'sm',
        '-ml-4': size === 'md',
        '-ml-8': size === 'lg',
      }"
    />

    <span class="inline-flex flex-1 items-center justify-center">
      <slot />
    </span>
    <DIcon
      v-if="iconRight"
      :path="iconRight"
      :size="size === 'md' || size === 'sm' ? 'sm' : 'md'"
      class="py-0"
      :class="{
        '-mr-2': size === 'sm',
        '-mr-4': size === 'md',
        '-mr-8': size === 'lg',
      }"
    />
  </component>
</template>

<style lang="pcss">
.d-button {
  &.success {
    @apply bg-green-500 text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-green-700;
    }
    &.disabled {
      @apply bg-green-300;
    }
  }

  &.alert {
    @apply bg-red-500 text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-red-700;
    }
    &.disabled {
      @apply bg-red-300;
    }
  }

  &.warning {
    @apply bg-amber-500 text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-amber-700;
    }
    &.disabled {
      @apply bg-amber-300;
    }
  }

  &.fill-primary {
    @apply bg-blue-500 text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-blue-700;
    }
    &.disabled {
      @apply bg-blue-300;
    }
  }
  &.fill-secondary {
    @apply bg-white text-blue-500;

    &.active,
    &:not(.disabled):hover {
      @apply bg-grey-100 text-blue-700;
    }
    &.disabled {
      @apply bg-white text-blue-300;
    }
  }

  &.gradient-primary {
    @apply text-white bg-gradient-to-r from-teal-500 to-blue-500;

    &.active,
    &:not(.disabled):hover {
      @apply from-teal-700 to-blue-700;
    }
    &.disabled {
      @apply from-teal-300 to-blue-300;
    }
  }
  &.gradient-secondary {
    @apply bg-white;

    & * {
      @apply bg-gradient-to-r from-teal-500 to-blue-500 bg-clip-text text-transparent;
    }

    &.active,
    &:not(.disabled):hover {
      @apply bg-grey-100;

      & * {
        @apply from-teal-700 to-blue-700;
      }
    }
    &.disabled * {
      @apply from-teal-300 to-blue-300;
    }
  }

  &[class*='outline-'] {
    @apply border-2 bg-transparent;

    &.sm {
      @apply border;
    }
  }

  &.outline-primary {
    @apply border-blue-500 text-blue-500;

    &.active,
    &:not(.disabled):hover {
      @apply bg-blue-500 text-white;
    }
    &.disabled {
      @apply border-blue-300 text-blue-300;
    }
  }
  &.outline-secondary {
    @apply border-white text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-white text-blue-500;
    }
    &.disabled {
      @apply border-white/50 text-white/50;
    }
  }

  &.fill-amber {
    @apply bg-[#B9BB30] text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-amber-700 text-white;
    }
    &.disabled {
      @apply bg-amber-700/50 text-white/50;
    }
  }

  &.none {
    @apply bg-transparent text-current;
  }

  &.disabled {
    @apply cursor-not-allowed text-white/50;
  }

  &.sm:not(.none) {
    @apply px-3.5 py-1.5 text-sm;
  }
  &.md:not(.none) {
    @apply px-6 py-2.5 text-base;
  }
  &.lg:not(.none) {
    @apply px-8 py-4 text-lg;
  }

  &.pill {
    @apply rounded-full;
  }
}
</style>
