<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityAreaMap',
})

const { t } = useI18n()
// const { city } = storeToRefs(useCityStore())
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityDataArea"
    no-padding
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.data_area') }}
    </template>

    <template #subtitle>
      {{ t('cyclability.dive_in_the_cycling_network') }}
    </template>

    <template #default="{ data }">
      <div class="min-h-full flex-1">
        <AreaMetric :area="data.zone.geojson" />
      </div>
    </template>
  </MetricCard>
</template>
