<script setup>
const { t } = useI18n()
const route = useRoute()
const { mode } = useMode()
const filtersStore = useFiltersStore()

const { provider } = storeToRefs(useProviderStore())

const atAGlance = ref()
const fleets = ref()
const trips = ref()

useScrollIntoView({
  root: useParentElement(),
  targets: [atAGlance, fleets, trips],
})
</script>

<template>
  <div>
    <BoardTitle hide-on-mobile>
      {{ t('Fleets & Trips') }}
    </BoardTitle>

    <DAlert
      v-if="route.params.provider === DEMO"
      id="provider-fleet-trips-demo"
      variant="warning"
      closable
    >
      {{
        t(
          'This a demo operator. All data is random and should only be used for discovery and test purposes.',
        )
      }}
      <template #subtitle>
        <I18nMd
          keypath="upgrade_to_unlock_operator_pages"
          tag="p"
          :variables="{
            subscriptionPlan: t('data.plans.premium'),
          }"
        >
          <template #link="{ translation }">
            <DLink
              :to="{ name: 'SubscribePlans' }"
              class="underline"
            >
              {{ translation }}
            </DLink>
          </template>
        </I18nMd>
      </template>
    </DAlert>

    <DAlert
      v-else-if="
        filtersStore.region
          && provider
          && !provider.regions?.filtered?.includes(filtersStore.region)
      "
      variant="warning"
    >
      {{ t('This operator is currently not available in the selected region') }}
      <template #subtitle>
        <I18nMd
          keypath="click_to_select_region"
          tag="p"
        >
          <template #link="{ translation }">
            <DLink
              class="underline"
              @click="globalEmitter.emit('open:region')"
            >
              {{ translation }}
            </DLink>
          </template>
        </I18nMd>
      </template>
    </DAlert>

    <DAlert
      v-else
      id="provider-fleet-trips"
      variant="info"
      closable
    >
      {{
        t(
          'The data displayed on this page is focused on the cities that you have unlocked.',
        )
      }}
      <template #subtitle>
        <I18nMd
          keypath="unlock_to_view_more"
          tag="p"
        >
          <template #link="{ translation }">
            <DLink
              :to="{ name: 'SubscribePlans' }"
              class="underline"
            >
              {{ translation }}
            </DLink>
          </template>
        </I18nMd>
      </template>
    </DAlert>

    <BoardSubTitle
      id="at-a-glance"
      ref="atAGlance"
      icon="glance"
      class="!pt-4"
    >
      {{ t('At a glance') }}
    </BoardSubTitle>

    <BoardGrid class="lg:grid-cols-2">
      <ProviderMetricsSummary class="lg:col-span-2" />
      <ProviderFleetSummary
        v-if="provider?.vehicleTypes?.length > 1"
        class="lg:col-span-1"
      />
      <ProviderTripSummary
        v-if="provider?.vehicleTypes?.length > 1"
        class="lg:col-span-1"
      />
      <ProviderCarsComparison
        v-if="mode === VEHICLE_TYPE_CAR"
        class="lg:col-span-2"
      />
    </BoardGrid>

    <BoardSubTitle
      id="fleets"
      ref="fleets"
      icon="fleet"
    >
      {{ t('Fleets') }}
    </BoardSubTitle>

    <BoardGrid>
      <ProviderFleetsPerTime />
      <ProviderUsedVehiclesPerTime />
      <ProviderFleetsCars v-if="mode === VEHICLE_TYPE_CAR" />
    </BoardGrid>

    <BoardSubTitle
      id="trips"
      ref="trips"
      icon="trips"
    >
      {{ t('Trips') }}
    </BoardSubTitle>

    <BoardGrid>
      <ProviderTripsPerTime />
      <ProviderTPVPerTime />
      <ProviderTripsCars v-if="mode === VEHICLE_TYPE_CAR" />
      <ProviderCarRentalDistribution v-if="mode === VEHICLE_TYPE_CAR" />
    </BoardGrid>
  </div>
</template>
