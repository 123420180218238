<script setup>
import plansQuery from '@/graphql/queries/plans.gql'
import requestPricingQuery from '@/graphql/mutations/requestPricing.gql'

const { t } = useI18n()
const { organization } = storeToRefs(useUserStore())
const displayError = useDisplayErrors()

const { result } = useQuery(
  plansQuery,
  {
    product: PRODUCT,
  },
  {
    clientId: CAPTAIN,
  },
)

const { mutate: requestPricing, loading } = useMutation(requestPricingQuery, {
  variables: {
    organizationId: get(organization).id,
    product: PRODUCT,
    plans: [],
  },
  clientId: CAPTAIN,
})

const showPlan = ref(null)
const showSuccessModal = ref(false)
const region = ref(REGION_CODE_EU)
const selectedPlans = ref([])
const selectedOptions = ref([])

const plans = computed(() => {
  return (
    get(result)?.plans.filter(
      plan =>
        plan.isPack
        && PLANS_SLUGS.includes(plan.slug)
        && plan.data?.regions?.includes(get(region)),
    ) || []
  ).sort((a, b) => PLANS_SLUGS.indexOf(a.slug) - PLANS_SLUGS.indexOf(b.slug))
})

const options = computed(() => {
  return (
    get(result)?.plans.filter(
      plan => plan.isOption && PLANS_OPTIONS_SLUGS.includes(plan.slug),
    ) || []
  ).sort(
    (a, b) =>
      PLANS_OPTIONS_SLUGS.indexOf(a.slug) - PLANS_OPTIONS_SLUGS.indexOf(b.slug),
  )
})

function removePlansFromRegion(plansSlugs = [], region = REGION_CODE_EU) {
  const plansData = get(result)?.plans.filter(p => plansSlugs.includes(p.slug))
  return plansData
    .filter(p => !p.data.regions.includes(region))
    .map(p => p.slug)
}

function isDisabledPlan(plan) {
  const selectedPlansRef = get(selectedPlans)

  // if all europe is selected, disable all other europe plans
  if (
    selectedPlansRef.includes(PACK_ALL_EUROPE_SLUG)
    && plan.slug !== PACK_ALL_EUROPE_SLUG
    && plan.data.regions.includes(REGION_CODE_EU)
  ) {
    return true
  }

  // if all north americ is selected, disable all other north americ plans
  if (
    selectedPlansRef.includes(PACK_ALL_NORTH_AMERICA_SLUG)
    && plan.slug !== PACK_ALL_NORTH_AMERICA_SLUG
    && plan.data.regions.includes(REGION_CODE_NA)
  ) {
    return true
  }

  return false
}

function onSelectPlan(slug) {
  const selectedPlansRef = get(selectedPlans)

  if (!selectedPlansRef.includes(slug)) {
    if (slug === PACK_ALL_EUROPE_SLUG) {
      set(
        selectedPlans,
        removePlansFromRegion(selectedPlansRef, REGION_CODE_EU),
      )
    }

    if (slug === PACK_ALL_NORTH_AMERICA_SLUG) {
      set(
        selectedPlans,
        removePlansFromRegion(selectedPlansRef, REGION_CODE_NA),
      )
    }
    // Add other all package here with bulkRemove function

    get(selectedPlans).push(slug)
  } else {
    set(
      selectedPlans,
      selectedPlansRef.filter(s => s !== slug),
    )
  }
}

function onSelectOption(slug) {
  const selectedOptionsRef = get(selectedOptions)

  if (!selectedOptionsRef.includes(slug)) {
    selectedOptionsRef.push(slug)
  } else {
    set(
      selectedOptions,
      selectedOptionsRef.filter(s => s !== slug),
    )
  }
}

async function onRequestPricing() {
  const selected = [...get(selectedPlans), ...get(selectedOptions)]

  if (get(selectedPlans).length === 0) {
    return
  }

  try {
    const result = await requestPricing({
      plans: selected,
    })

    if (result.data) {
      set(showSuccessModal, true)
    }
  } catch (e) {
    displayError(e.message)
  }
}
</script>

<template>
  <SidebarContainer>
    <template #left>
      <PlansSidebar
        :plans="result?.plans || []"
        :selected-plans="selectedPlans"
        :selected-options="selectedOptions"
        @request-pricing="onRequestPricing"
      />
    </template>

    <BoardTitle>
      {{ t('Upgrade my plan') }}
    </BoardTitle>

    <p class="mt-6 mb-4 font-title text-lg">
      {{ t('Choose the best plan for your team') }}
    </p>

    <DCard>
      <PlansTabs
        v-model="region"
        :options="[
          { text: t('Europe'), value: REGION_CODE_EU },
          { text: t('Asia-Pacific'), value: REGION_CODE_APAC },
          {
            text: t('North America'),
            value: REGION_CODE_NA,
            isNew: true,
          },
        ]"
      />

      <div
        class="mx-auto max-w-[160px] sm:max-w-[360px] md:max-w-[560px] lg:max-w-[760px]"
      >
        <div class="flex flex-wrap content-start gap-10">
          <PlanCard
            v-for="plan in plans"
            :key="plan.slug"
            :plan="plan"
            :region="region"
            :is-selected="selectedPlans.includes(plan.slug)"
            :disabled="isDisabledPlan(plan)"
            class="w-40"
            @show-cities="() => (showPlan = plan)"
            @select="onSelectPlan"
          />
        </div>
      </div>
    </DCard>

    <p class="mt-6 mb-4 font-title text-lg">
      {{ t('Add extensions to suits your needs') }}
    </p>

    <DCard>
      <div class="grid gap-4 md:grid-cols-1 md:gap-8 md:px-8 xl:grid-cols-2">
        <PlanOptionCard
          v-for="option in options"
          :key="option.slug"
          :option="option"
          :is-selected="selectedOptions.includes(option.slug)"
          @select="onSelectOption"
        />
      </div>
    </DCard>

    <p class="m-8 text-center">
      <DButton
        variant="gradient-primary"
        :disabled="selectedPlans.length === 0"
        :loading="loading"
        size="lg"
        @click="onRequestPricing"
      >
        {{ t('Request pricing') }}
      </DButton>
    </p>

    <PlanRequestSentModal
      v-if="showSuccessModal"
      @close="() => (showSuccessModal = false)"
    />

    <PlanCitiesModal
      v-if="showPlan"
      :plan="showPlan"
      @close="() => (showPlan = null)"
    />
  </SidebarContainer>
</template>
