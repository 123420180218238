<script setup>
const { t } = useI18n()
</script>

<template>
  <div class="mt-4 flex justify-around">
    <p class="text-center text-lg">
      <span class="font-title text-4xl font-bold text-current">180+</span>
      <br>{{ t('major cities covered') }}
    </p>
    <p class="text-center text-lg">
      <span class="font-title text-4xl font-bold text-current">250+</span>
      <br>{{ t('operators monitored') }}
    </p>
    <p class="text-center text-lg">
      <span class="font-title text-4xl font-bold text-current">2.6m</span>
      <br>{{ t('trips analysed per day') }}
    </p>
  </div>
</template>
