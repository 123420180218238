import { h } from 'vue'
import { RouterView, createRouter, createWebHistory } from 'vue-router'

import {
  AVAILABLE,
  REGIONS_CODES,
  USED,
  VEHICLE_TYPE_ALL,
} from '@/utils/constants'

// Home
import Homepage from '@/views/Homepage.vue'
import HomeCity from '@/views/city/HomeCity.vue'

// Login
import Login from '@/views/login/Login.vue'
import Register from '@/views/login/Register.vue'
import LoginCallback from '@/views/login/LoginCallback.vue'
import LoginResetPassword from '@/views/login/LoginResetPassword.vue'
import LoginRequireConfirmedEmail from '@/views/login/LoginRequireConfirmedEmail.vue'
import LoginUpdateEmail from '@/views/login/LoginUpdateEmail.vue'

// App Overview
import Overview from '@/views/app/overview/Overview.vue'
import OverviewCity from '@/views/app/overview/OverviewCity.vue'

// Cities
import Cities from '@/views/app/cities/Cities.vue'
import City from '@/views/app/cities/City.vue'
import CityOverview from '@/views/app/cities/CityOverview.vue'
import CityFleetsTrips from '@/views/app/cities/CityFleetsTrips.vue'
import CityMaps from '@/views/app/cities/CityMaps.vue'
import CityMapsDistribution from '@/views/app/cities/CityMapsDistribution.vue'
import CityMapsPatterns from '@/views/app/cities/CityMapsPatterns.vue'

// City Cyclability
import CityCyclability from '@/views/app/cities/CityCyclability.vue'
import CityCyclabilityMap from '@/views/app/cities/CityCyclabilityMap.vue'

// Providers
import Providers from '@/views/app/providers/Providers.vue'
import Provider from '@/views/app/providers/Provider.vue'
import ProviderOverview from '@/views/app/providers/ProviderOverview.vue'
import ProviderFleetsTrips from '@/views/app/providers/ProviderFleetsTrips.vue'
import ProviderFootprint from '@/views/app/providers/ProviderFootprint.vue'

// News center
import NewsfeedView from '@/views/app/newsfeed/NewsfeedView.vue'
import Newsfeed from '@/views/app/newsfeed/Newsfeed.vue'

// Settings
import Settings from '@/views/settings/Settings.vue'
import SettingsAccount from '@/views/settings/SettingsAccount.vue'
import SettingsEdit from '@/views/settings/SettingsEdit.vue'
import SettingsSubscription from '@/views/settings/SettingsSubscription.vue'
import SettingsInvoices from '@/views/settings/SettingsInvoices.vue'
import SettingsPlans from '@/views/settings/plans/Plans.vue'

// FAQ
import FAQ from '@/views/app/FAQ.vue'

// Export
import Export from '@/views/app/Export.vue'

const ChildRoutes = { render: () => h(RouterView) }

const filtersDefaults = {
  mode: true,
  dateRange: false,
  comparativeRange: false,
  timeMode: false,
  region: false,
  country: false,
  filterBySpecs: false,
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  async scrollBehavior() {
    return {
      top: 0,
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Homepage,
      meta: {
        title: 'Take a deep dive into shared mobility',
      },
      children: [
        {
          path: 'city/:citySlug',
          name: 'HomeCity',
          component: HomeCity,
        },
      ],
    },
    {
      path: '/login',
      component: ChildRoutes,
      meta: {
        title: 'Log in',
      },
      children: [
        {
          path: '',
          name: 'Login',
          component: Login,
        },
        {
          path: 'register',
          name: 'Register',
          component: Register,
          meta: {
            hideNavigation: true,
            notConnected: true,
          },
        },
        {
          path: 'callback',
          name: 'LoginCallback',
          component: LoginCallback,
          meta: {
            hideNavigation: true,
          },
        },
        {
          path: 'resetPassword',
          name: 'LoginResetPassword',
          component: LoginResetPassword,
          meta: {
            hideNavigation: true,
          },
        },
        {
          path: 'confirmemail',
          name: 'LoginRequireConfirmedEmail',
          component: LoginRequireConfirmedEmail,
          meta: {
            hideNavigation: true,
          },
        },
        {
          path: 'updateemail',
          name: 'LoginUpdateEmail',
          component: LoginUpdateEmail,
          meta: {
            hideNavigation: true,
          },
        },
      ],
    },
    {
      path: '/app',
      redirect: '/app/overview',
    },
    {
      path: `/app/overview/:regionCode(${REGIONS_CODES.join('|')})?`,
      name: 'Overview',
      component: Overview,
      meta: {
        title: 'Overview',
        auth: true,
        subscription: true,
      },
      children: [
        {
          path: 'city/:citySlug',
          name: 'OverviewCity',
          component: OverviewCity,
          meta: {
            title: 'Overview',
          },
        },
      ],
    },
    {
      path: '/app/cities',
      component: ChildRoutes,
      meta: {
        auth: true,
        subscription: true,
        title: 'Cities',
        needToBeUnlocked: true,
      },
      children: [
        {
          path: '',
          name: 'Cities',
          component: Cities,
          meta: {
            filters: {
              ...filtersDefaults,
              dateRange: true,
            },
          },
        },
        {
          path: ':citySlug',
          component: City,
          meta: {
            type: 'city',
          },
          children: [
            {
              path: '',
              name: 'City',
              redirect: to => ({
                name: 'CityOverview',
                params: {
                  citySlug: to.params.citySlug,
                  mode: VEHICLE_TYPE_ALL,
                },
              }),
            },
            {
              path: 'cyclability',
              name: 'CityCyclability',
              children: [{
                path: 'report',
                name: 'CityCyclabilityReport',
                component: CityCyclability,
                meta: {
                  title: 'Cyclability',
                  filters: {},
                },
              }, {
                path: 'map',
                name: 'CityCyclabilityMap',
                component: CityCyclabilityMap,
                meta: {
                  title: 'Cyclability Map',
                  filters: {},
                  isMaps: true,
                },
              }],
            },
            {
              path: `:mode(${[...VEHICLE_TYPES, VEHICLE_TYPE_ALL].join('|')})`,
              component: ChildRoutes,
              children: [
                {
                  path: '',
                  name: 'CityMode',
                  redirect: to => ({
                    name: 'CityOverview',
                    params: {
                      citySlug: to.params.citySlug,
                      mode: to.params.mode || VEHICLE_TYPE_ALL,
                    },
                  }),
                },
                {
                  path: 'overview',
                  name: 'CityOverview',
                  component: CityOverview,
                  meta: {
                    title: 'Overview',
                    filters: filtersDefaults,
                  },
                },
                {
                  path: 'fleets-trips',
                  name: 'CityFleetsTrips',
                  component: CityFleetsTrips,
                  meta: {
                    title: 'Fleet & Trips',
                    filters: {
                      ...filtersDefaults,
                      dateRange: true,
                      timeMode: true,
                      comparativeRange: true,
                      filterBySpecs: true,
                    },
                  },
                },
                {
                  path: 'maps',
                  name: 'CityMaps',
                  component: CityMaps,
                  redirect: to => ({
                    name: 'CityMapsDistribution',
                    params: {
                      citySlug: to.params.citySlug,
                      mode: to.params.mode || VEHICLE_TYPE_ALL,
                    },
                  }),
                  meta: {
                    isMaps: true,
                    title: 'Maps',
                    filters: {
                      ...filtersDefaults,
                      dateRange: true,
                    },
                  },
                  children: [
                    {
                      path: 'distribution',
                      redirect: to => ({
                        name: 'CityMapsDistribution',
                        params: {
                          citySlug: to.params.citySlug,
                          mode: to.params.mode || VEHICLE_TYPE_ALL,
                          indicator: AVAILABLE,
                        },
                      }),
                    },
                    {
                      path: `distribution/:indicator(${[AVAILABLE, USED].join(
                        '|',
                      )})?/p/:providers?/a/:areas?`,
                      name: 'CityMapsDistribution',
                      component: CityMapsDistribution,
                    },
                    {
                      path: 'patterns',
                      redirect: to => ({
                        name: 'CityMapsPatterns',
                        params: {
                          citySlug: to.params.citySlug,
                          mode: to.params.mode || VEHICLE_TYPE_ALL,
                        },
                      }),
                    },
                    {
                      path: 'patterns/p/:percent?/a/:areas?',
                      name: 'CityMapsPatterns',
                      component: CityMapsPatterns,
                    },
                  ],
                },
                {
                  path: 'newsfeed',
                  name: 'CityNewsfeed',
                  component: NewsfeedView,
                  meta: {
                    title: 'Newsfeed',
                    greatExplorer: true,
                    filters: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/app/providers',
      component: ChildRoutes,
      meta: {
        auth: true,
        subscription: true,
        title: 'Providers',
      },
      children: [
        {
          path: '',
          name: 'Providers',
          component: Providers,
          meta: {
            filters: {
              ...filtersDefaults,
              dateRange: true,
            },
          },
        },
        {
          path: ':provider',
          component: Provider,
          meta: {
            type: 'provider',
          },
          children: [
            {
              path: '',
              name: 'Provider',
              redirect: to => ({
                name: 'ProviderOverview',
                params: {
                  provider: to.params.provider,
                  mode: VEHICLE_TYPE_ALL,
                },
              }),
            },
            {
              path: `:mode(${[...VEHICLE_TYPES, VEHICLE_TYPE_ALL].join('|')})`,
              component: ChildRoutes,
              children: [
                {
                  path: '',
                  name: 'ProviderMode',
                  redirect: to => ({
                    name: 'ProviderOverview',
                    params: {
                      provider: to.params.provider,
                      mode: to.params.mode || VEHICLE_TYPE_ALL,
                    },
                  }),
                },
                {
                  path: 'overview',
                  name: 'ProviderOverview',
                  component: ProviderOverview,
                  meta: {
                    title: 'Overview',
                    greatExplorer: true,
                    needToBeUnlocked: true,
                    filters: {
                      ...filtersDefaults,
                      region: true,
                    },
                  },
                },
                {
                  path: 'fleets-trips',
                  name: 'ProviderFleetsTrips',
                  component: ProviderFleetsTrips,
                  meta: {
                    title: 'Fleet & Trips',
                    greatExplorer: true,
                    needToBeUnlocked: true,
                    filters: {
                      ...filtersDefaults,
                      dateRange: true,
                      timeMode: true,
                      region: true,
                      country: true,
                      filterBySpecs: true,
                    },
                  },
                },
                {
                  path: 'footprint',
                  name: 'ProviderFootprint',
                  component: ProviderFootprint,
                  meta: {
                    title: 'Footprint',
                    greatExplorer: true,
                    filters: {
                      ...filtersDefaults,
                      mode: false,
                      region: true,
                    },
                  },
                },
                {
                  path: 'newsfeed',
                  name: 'ProviderNewsfeed',
                  component: NewsfeedView,
                  meta: {
                    title: 'Newsfeed',
                    greatExplorer: true,
                    filters: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/newsfeed',
      name: 'Newsfeed',
      component: Newsfeed,
      meta: {
        auth: true,
        subscription: true,
        greatExplorer: true,
        filters: true,
        title: 'Newsfeed',
      },
    },
    {
      path: '/app/faq',
      component: FAQ,
      name: 'Faq',
      meta: {
        auth: false,
        title: 'FAQ',
      },
    },
    {
      path: '/app/export',
      component: Export,
      name: 'Export',
      meta: {
        auth: true,
        subscription: true,
        title: 'Export',
      },
    },
    {
      path: '/settings',
      component: Settings,
      meta: {
        auth: true,
        title: 'Settings',
      },
      children: [
        {
          path: '',
          name: 'SettingsAccount',
          component: SettingsAccount,
        },
        {
          path: 'edit',
          name: 'SettingsEdit',
          component: SettingsEdit,
        },
        {
          path: 'subscription',
          name: 'SettingsSubscription',
          component: SettingsSubscription,
        },
        {
          path: 'invoices',
          name: 'Invoices',
          component: SettingsInvoices,
        },
      ],
    },
    {
      path: '/settings/subscribe/plans',
      name: 'SubscribePlans',
      component: SettingsPlans,
      meta: {
        auth: true,
        title: 'Subscribe to a plan',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      redirect: { name: 'Home' },
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const authRequired = to.meta.auth
  const subscriptionRequired = to.meta.subscription
  const greatExplorerRequired = to.meta.greatExplorer

  const loginModalsStore = useLoginModalsStore()
  const { user, organization, subscription, isFreemium, isLogged }
    = storeToRefs(useUserStore())
  const { waitingUser } = useUserStore()

  // waiting for user to be loaded
  await waitingUser()

  if (get(isLogged)) {
    // Not connected required
    if (to.meta.notConnected) {
      // redirect to home if connected
      return next({ name: 'Home' })
    }

    const userRef = get(user)

    if (
      userRef
      && !userRef.emailVerified
      && to.name !== 'LoginRequireConfirmedEmail'
    ) {
      // redirect to email verification if not verified
      return next({ name: 'LoginRequireConfirmedEmail' })
    }
  }

  // Auth required
  if (authRequired) {
    if (!get(organization)) {
      // redirect to organization selection or login if no organization

      if (from.name && !from.meta.auth) {
        loginModalsStore.openSignin()
        return next(false)
      }

      return next({ name: 'Login' })
    }
  }

  // Subscription required
  if (subscriptionRequired && !get(subscription)) {
    // redirect to subscription if no subscription
    return next({ name: 'SubscribePlans' })
  }

  // Great Explorer Plan required
  if (greatExplorerRequired && get(isFreemium) && to.params.provider !== DEMO) {
    // redirect to subscription if no subscription
    return next({ name: 'SubscribePlans' })
  }

  // Features flags
  if (to.query.ff) {
    const featuresFlagsStore = useFeaturesFlagsStore()
    featuresFlagsStore.updateFromQuery(to.query.ff)
  }

  return next()
})

export default router
