<script setup>
import { nanoid } from 'nanoid'

defineProps({
  id: {
    type: String,
    default: nanoid(),
  },
})

const router = useRouter()
const itemOpen = inject('itemOpen', null)

function onBeforeEnter(el) {
  el.style.height = '0'
}
function onEnter(el) {
  el.style.height = `${el.scrollHeight}px`
}
function onBeforeLeave(el) {
  el.style.height = `${el.scrollHeight}px`
}
function onLeave(el) {
  el.style.height = '0'
}
</script>

<template>
  <div
    :id="id"
    class="min-w-80 mb-4 bg-white shadow-md"
  >
    <div
      class="flex cursor-pointer items-center justify-between border-b p-4"
      role="tab"
      @click="
        () => {
          const hash = { hash: `#${id}` }
          if (router.resolve(hash)) {
            router.push(hash)
          }
          itemOpen = id
        }
      "
    >
      <div class="flex-1 text-base font-bold">
        <slot name="title" />
      </div>
      <DIcon
        v-if="itemOpen === id"
        size="sm"
        path="chevron-up"
      />
      <DIcon
        v-else
        size="sm"
        path="chevron-down"
      />
    </div>

    <Transition
      name="nested"
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
    >
      <div
        v-if="itemOpen === id"
        role="tabpanel"
      >
        <div class="p-8">
          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="pcss" scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.nested-enter-from,
.nested-leave-to {
  opacity: 0;
}
</style>
