<script setup>
const props = defineProps({
  menu: {
    type: Object,
    required: true,
  },
  label: {
    type: String,
    default: null,
  },
  city: {
    type: Object,
    default: () => null,
  },
  provider: {
    type: Object,
    default: () => null,
  },
})

const element = ref()

const { t } = useI18n()
const route = useRoute()
const { providerName } = useProviderName()
const { getCityNameLocalized } = useCitiesStore()

const isOpen = IS_MOBILE ? ref(false) : useElementHover(element)

if (IS_MOBILE) {
  onClickOutside(element, () => {
    isOpen.value = false
  })
}

const debounced = refDebounced(isOpen, 300)

const name = computed(() => {
  if (props.label !== null) {
    return props.label
  }

  if (props.city) {
    return getCityNameLocalized(props.city?.name)
  }

  if (props.provider) {
    return providerName(props.provider)
  }

  return ''
})

const activeMenuItem = computed(() => {
  return (
    props.menu.find((item) => {
      let active = null

      if (item.subMenu) {
        active
          = item.subMenu.find((subItem) => {
            return subItem.route?.name === route.name || subItem.isActive
          }) || null
      }

      if (active) {
        return active
      }

      return item.route?.name === route.name || item.isActive
    }) || {}
  )
})

function onBeforeEnter(el) {
  el.style.height = '0'
}
function onEnter(el) {
  el.style.height = `${el.scrollHeight}px`
}
function onBeforeLeave(el) {
  el.style.height = `${el.scrollHeight}px`
}
function onLeave(el) {
  el.style.height = '0'
}
</script>

<template>
  <div
    ref="element"
    @touchend="() => (isOpen = !isOpen)"
  >
    <div
      class="text-white gradient md:rounded-t-md"
      :class="{
        'md:rounded-b-none': isOpen,
        'md:rounded-b-md': !debounced,
      }"
    >
      <div class="md:h-12 h-14 font-bold flex items-center">
        <SidebarNavigationHamburger :open="isOpen" />
        <div class="flex-1 leading-3 font-title font-bold">
          <p class="text-lg">
            {{ name }}
          </p>
          <p class="text-sm text-white/50">
            {{ activeMenuItem.label }}
          </p>
        </div>
        <div class="hidden md:block">
          <DFlag
            v-if="city"
            :country-code="city.countryCode"
          />
          <img
            v-else-if="provider"
            class="m-2 inline-block h-7 w-7"
            :src="`${CDN_PATH}/providers/${provider.slug}.jpg`"
            :alt="t('provider_logo', { provider: name })"
          >
        </div>
      </div>
    </div>

    <Transition
      name="nested"
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
    >
      <div
        v-if="isOpen"
        class="gradient md:rounded-b-md bottom-14 md:bottom-auto absolute w-full z-menu border-t border-white/20"
      >
        <slot />
        <SidebarNavigationMenu :menu="menu" />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

/**
.nested-enter-from,
.nested-leave-to {
  opacity: 0;
}
*/
</style>
