<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: 'md',
    validation: value => ['xs', 'sm', 'md', 'lg'].includes(value),
  },
  type: {
    type: String,
    default: 'text',
  },
  state: {
    type: Boolean,
    default: null,
  },
  inline: Boolean,
})
const emit = defineEmits(['update:modelValue'])
const attrs = useAttrs()
const slots = useSlots()

const data = useVModel(props, 'modelValue', emit)

const label = inject(PROVIDE_UI_FIELD_LABEL, '')
const input = ref()
</script>

<template>
  <div
    class="d-input-text relative"
    :class="{ 'block w-full': !inline, 'inline-block w-auto': inline }"
  >
    <div
      v-if="slots.left"
      class="pointer-events-none absolute inset-y-0 left-0 top-2 flex h-1/2 items-center text-grey-500/75 border-r border-grey-500/50"
    >
      <slot name="left" />
    </div>

    <input
      ref="input"
      v-model="data"
      class="block w-full rounded-md focus:outline-none focus:ring-2 disabled:cursor-not-allowed border bg-white text-grey-500 placeholder:text-grey-200 disabled:bg-grey-100"
      :class="{
        'py-1/2 px-1 text-sm': size === 'xs',
        'py-2 text-sm': size === 'sm',
        'py-2.5 text-base': size === 'md',
        'py-2.5 text-lg': size === 'lg',
        '!pl-6': slots.left && size === 'xs',
        '!pl-10': slots.left && size === 'sm',
        '!pl-12': slots.left && size === 'md',
        '!pl-12': slots.left && size === 'lg',
        'border-grey-100 focus:border-grey-100 focus:ring-blue-200':
          state === null,
        'border-green-500 focus:border-green-500 focus:ring-green-200':
          state === true,
        'border-red-500 focus:border-red-500 focus:ring-red-200':
          state === false,
      }"
      :type="type"
      :aria-label="label"
      v-bind="attrs"
    >
  </div>
</template>

<style lang="pcss">
.d-input-text {
  input[type='search']::-webkit-search-cancel-button {
    @apply appearance-none h-4 w-4 cursor-pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z' /%3E%3C/svg%3E");
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    @apply hidden;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    @apply hidden;
  }
}
</style>
