<script setup>
defineOptions({
  name: 'ProviderFleetsOverview',
})

const { t } = useI18n()
const { providerName } = useProviderName()

const { provider } = storeToRefs(useProviderStore())
</script>

<template>
  <MetricCard
    kpi="providerFleetsOverview"
    :used-filters="['mode', 'region']"
  >
    <template #title>
      {{ providerName(provider) }} - {{ t('modal_breakdown') }}
    </template>
    <template #subtitle="{ data }">
      {{ t('AVG Last 7 days') }} -
      {{
        t(
          'data from {n} cities on City Dive',
          { n: data?.cities ?? 0 },
          data?.cities ?? 0,
        )
      }}
    </template>

    <template #default="scoped">
      <div class="flex justify-between">
        <TrendMetricWithVehiclesTypes
          class="flex-none text-blue-500"
          :label="t('Vehicle Deployed')"
          :data="{ value: scoped.data?.vehicleTypes.TOTAL }"
          :vehicles-types="scoped.data?.vehicleTypes"
        />

        <PieMetric
          class="m-4 h-48 w-48"
          :legend="false"
          :data="
            Object.entries(scoped.data?.vehicleTypes || {})
              .reduce((acc, [type, value]) => {
                if (DATA_VEHICLE_TYPES.includes(type)) {
                  acc.push({
                    label: type,
                    percent: (value / scoped.data?.vehicleTypes.TOTAL) * 100,
                    value,
                    color: PALETTE_COLORS_VEHICLES[getVehicleType(type)],
                  })
                }
                return acc
              }, [])
              .filter(d => d.value !== null)
          "
          :options="{
            dimension: DimensionTypes.VEHICLETYPE,
          }"
        />
      </div>
    </template>
  </MetricCard>
</template>
