<script setup lang="ts">
import type { VueInstance } from '@vueuse/core'

defineOptions({
  name: 'MetricCard',
})

const props = withDefaults(defineProps<{
  kpi?: string | string[] | null
  // use query to enforce query name (useful for multiple kpi in one gql)
  query?: string | null
  queryOptions?: object
  variables?: object
  usedFilters?: string[]
  // Put the metric card in colors of the mode
  asMode?: string | null
  noPadding?: boolean
  middleware?: (d: any) => any
}>(), {
  kpi: null,
  query: null,
  queryOptions: () => ({}),
  variables: () => ({}),
  usedFilters: () => ['mode'],
  asMode: null,
  middleware: (d: any) => d,
})

const { kpi, variables } = toRefs(props)

const card = ref<VueInstance>()
const helpIsOpen = ref(false)

// generate a unique id for each data update (used to get chart colors palettes from variables values)
const lastUpdate = ref(new Date())
const dataUpdateId = ref<string | undefined>()

const queryName = computed((): string | null => {
  if (Array.isArray(props.kpi)) {
    return props.query
  } else {
    return props.query || props.kpi
  }
})

const kpis = computed((): string[] | null => {
  const kpi = props.kpi

  if (Array.isArray(kpi)) {
    return kpi
  } else {
    return null
  }
})

const { t } = useI18n()
const { mode } = useMode()
const filtersStore = useFiltersStore()
const isVisible = useElementVisibility(card)

const {
  loadedOnce,
  data,
  help,
  loading,
  error,
  variables: metricVariables,
} = useMetricData(queryName, variables, {
  kpis,
  middleware: props.middleware,
  queryOptions: {
    enabled: isVisible,
    ...toRefs(reactive(props.queryOptions)),
  },
})

function checkIfNoData(d: { series: [] } | { metric: string, data: [] } | [] | undefined | null): boolean {
  if (d) {
    if (Array.isArray(d)) {
      return d.length === 0
    }

    if ('series' in d && d.series) {
      return d.series?.length === 0
    }

    if ('metric' in d && d.metric) {
      return !d.data
    }

    if (Object.keys(d)) {
      return Object.keys(d).length === 0
    }

    return false
  }

  return true
}

const noData = computed(() => {
  if (get(queryName)) {
    const dataRef = get(data)

    if (Array.isArray(props.kpi)) {
      return props.kpi.every((_, k) => checkIfNoData(dataRef?.[k]))
    }

    return checkIfNoData(dataRef)
  } else {
    return false
  }
})

// TODO better stringify on variables change
whenever(data, async () => {
  await nextTick()
  set(lastUpdate, new Date())
  set(
    dataUpdateId,
    Object.entries(get(metricVariables)).reduce(
      (acc, [k, v]) =>
        `${acc.length === 0 ? '' : `${acc}-`}${k}:${
          (Array.isArray(v) ? v.join('|') : v) || '*'
        }`,
      '',
    ),
  )
})

onBeforeUnmount(() => {
  set(helpIsOpen, false)
})
</script>

<template>
  <BoardCard
    ref="card"
    :no-padding="noPadding"
    :class="{
      'before:content-[\'\'] before:absolute before:top-0 before:left-0 before:border-t-[2rem] before:border-r-transparent before:border-r-[2rem] ':
        asMode && asMode === mode,
      'before:border-scooter': asMode && asMode === VEHICLE_TYPE_SCOOTER,
      'before:border-bike': asMode && asMode === VEHICLE_TYPE_BIKE,
      'before:border-car': asMode && asMode === VEHICLE_TYPE_CAR,
      'before:border-moped': asMode && asMode === VEHICLE_TYPE_MOPED,
    }"
  >
    <template
      v-if="$slots.title"
      #head
    >
      <!-- title -->
      <p>
        <slot name="title" />
        <span v-if="usedFilters.includes('timeMode')">
          ({{ t(`Per ${filtersStore.timeMode}`) }})</span>
      </p>

      <!-- subtitle -->
      <p
        v-if="usedFilters.includes('dateRange') || $slots.subtitle"
        class="text-sm font-normal text-grey-400 mt-1 first-letter:capitalize"
      >
        <slot
          name="range-subtitle"
          :data="data"
          :range="filtersStore.dateRangeHumanFormatted"
        >
          <template v-if="usedFilters.includes('dateRange')">
            {{ filtersStore.dateRangeHumanFormatted }}
            <template v-if="$slots.subtitle">
              -
            </template>
          </template>
          <slot
            name="subtitle"
            :data="data"
          />
        </slot>
      </p>

      <!-- Filters icons -->
      <FiltersIcons
        class="mt-2 gap-2 flex"
        :filters="usedFilters"
      />
    </template>

    <template #head-right>
      <slot
        v-if="$slots.right"
        name="right"
      />

      <template v-if="help">
        <DIconButton
          path="help"
          size="sm"
          icon-size="sm"
          variant="fill-secondary"
          class="flex-none border border-grey-100"
          @click="() => (helpIsOpen = true)"
        />
        <HelpSidebar
          :is-open="helpIsOpen"
          :title="help.title"
          :content="help.content"
          :target="card?.$el"
          @on-close="() => (helpIsOpen = false)"
        />
      </template>
    </template>

    <div
      v-if="loading"
      class="flex h-48 items-center justify-center"
    >
      <DLoader class="text-grey-400" />
    </div>

    <div
      v-else-if="(loadedOnce || error) && noData"
      class="flex h-48 items-center justify-center"
    >
      <ErrorNoDataToShow v-if="!error" />
      <p v-else>
        <DIcon
          name="warning-colored"
          size="sm"
        />{{ t('An error occured') }}
      </p>
    </div>

    <div
      v-show="!loading && !noData"
      v-else-if="(kpi && data) || !kpi"
      class="flex-1 flex flex-col"
    >
      <slot
        :data="data"
        :loading="loading"
        :last-update="lastUpdate"
        :data-update-id="dataUpdateId"
      />
    </div>
    <div
      v-else
      class="flex h-48 items-center justify-center"
    >
      <!-- No errors, no data -->
      <!-- The component is charging data from cache -->
      <DLoader class="text-grey-400" />
    </div>

    <div
      v-if="IS_DEV"
      class="absolute left-2 bottom-0 z-hop p-1 text-xs font-bold text-grey-200 opacity-30 hover:bg-white/90 hover:opacity-100"
    >
      KPI: {{ kpi }}
      <template v-if="error">
        <br>
        <span class="text-red-500">Error: {{ error }}</span>
      </template>
    </div>
  </BoardCard>
</template>
