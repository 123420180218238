import { endOfDay, startOfDay, subDays } from 'date-fns'

export const now = startOfDay(
  new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000),
)

export function defaultDateRange() {
  return {
    start: startOfDay(subDays(now, 7)),
    end: endOfDay(subDays(now, 1)),
  }
}
