<script setup>
const { t } = useI18n()
</script>

<template>
  <div>
    <p class="text-center md:text-sm">
      {{ t('Here are some of the things that you have access to:') }}
    </p>
    <div class="mt-4 grid grid-cols-2 gap-2 text-grey-400">
      <div>
        <p><DIcon path="linechart" />{{ t('Trip evolution') }}</p>
        <p><DIcon path="barchart" />{{ t('Fleet evolution') }}</p>
        <p><DIcon path="donutchart" />{{ t('Breakdowns per mode') }}</p>
        <p><DIcon path="piechart" />{{ t('Market share') }}</p>
      </div>
      <div>
        <p><DIcon path="heatmap" />{{ t('Demand heatmap') }}</p>
        <p><DIcon path="newspaper" />{{ t('Newsfeed') }}</p>
      </div>
    </div>
  </div>
</template>
