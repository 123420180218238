<script setup>
defineOptions({
  name: 'CityBarMetricPerTime',
})

defineProps({
  kpi: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  isCompare: Boolean,
  isStacked: {
    type: Boolean,
    default: true,
  },
})

const filtersStore = useFiltersStore()
const { getColor } = useSharedChartColors()
</script>

<template>
  <MetricCard
    :kpi="kpi"
    :used-filters="[
      'mode',
      isCompare ? 'compare' : 'dateRange',
      'timeMode',
      'filterBySpecs',
    ]"
    :variables="
      isCompare
        ? {}
        : {
          comparativeDateRange: null,
        }
    "
  >
    <template #title>
      {{ title }}
    </template>

    <template #default="{ data, dataUpdateId }">
      <BarMetric
        :data="{
          ...data,
          labels: isCompare
            ? [filtersStore.dateRangeFormated, filtersStore.comparativeFormated]
            : null,
        }"
        type="time"
        :options="{
          dimension: DimensionTypes.VEHICLETYPE,
          afterTitle: !isCompare && isStacked && 'sum',
          isCompare,
          unstacked: !isStacked,
          color: d => getColor(d.name, dataUpdateId),
        }"
      />
    </template>
  </MetricCard>
</template>
