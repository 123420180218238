<script>
const DATA_VEHICLE_TYPE_TOTAL = 'TOTAL'
const dataTypesList = [DATA_VEHICLE_TYPE_TOTAL, ...DATA_VEHICLE_TYPES]

const enumFields = {
  provider: 'provider',
  modes: 'modes',
  vehicles: 'vehicles',
  trips: 'trips',
  tripPerVehicle: 'tripPerVehicle',
}
</script>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
const { providersBySlug } = useProvidersStore()

const fields = [
  {
    text: t('Operators', 2),
    value: enumFields.provider,
  },
  {
    text: t('Modes'),
    value: enumFields.modes,
  },
  {
    text: t('Vehicles'),
    value: enumFields.vehicles,
    sortable: true,
  },
  {
    text: t('Trips'),
    value: enumFields.trips,
    sortable: true,
  },
  {
    text: t('TVD'),
    value: enumFields.tripPerVehicle,
    help: t('Trips per vehicle per day'),
    sortable: true,
  },
]

function formatData(d) {
  if (d.unlocked === false) {
    // the provider is not unlocked so we collapse the columns and we show a message
    return [
      {
        attrs: { colspan: fields.length - 1 },
        locked: true,
      },
    ]
  }

  return (
    Object.entries(d)
      // We format the data only if is a known data vehicle type
      .filter(([key]) => dataTypesList.includes(key))
      .reduce(
        (acc, [key, data]) => {
          // next line is to prevent a bug on hmr with auto-import

          if (data) {
            fields.forEach((field) => {
              const indexOfType = getKeyFromType(key)
              const fieldValue = field.value
              const value
                = field.value === enumFields.modes ? key : data[fieldValue]

              if (isDefined(value) || value === null) {
                if (!acc[fieldValue]) {
                  acc[fieldValue] = {}
                }

                acc[fieldValue][indexOfType] = value
              }
            })
          }

          return acc
        },
        Object.values(enumFields).reduce((acc, field) => {
          acc[field] = null
          return acc
        }, {}),
      )
  )
}

const rowsAndTotal = computed(() => {
  const rows
    = props.data
      .filter(d => d.provider !== DATA_VEHICLE_TYPE_TOTAL)
      .map((d) => {
        return {
          cols: {
            ...formatData(d),
            provider: (d.provider && providersBySlug[d.provider]) || {
              slug: d.provider,
            },
          },
          data: d,
        }
      }) || []

  if (props.data.length > 1) {
    // Do not show the total if there is only one provider

    const totalCols = formatData(
      props.data.find(d => d.provider === DATA_VEHICLE_TYPE_TOTAL),
    )

    return {
      rows,
      total: {
        cols: {
          ...totalCols,
          provider: t('All Operators'),
        },
        isTotal: true,
      },
    }
  } else {
    return {
      rows,
    }
  }
})

function findValue(d) {
  // get the TOTAL or the first type found in the data
  const key
    = getKeyFromType(DATA_VEHICLE_TYPE_TOTAL) || (d && Object.keys(d)[0])

  return d?.[key]
}

function getKeyFromType(type) {
  return dataTypesList.indexOf(type)
}
function getTypeFromKey(key) {
  return dataTypesList[key] || null
}
</script>

<template>
  <DSortingTable
    sortable
    :total="rowsAndTotal.total"
    :rows="rowsAndTotal.rows"
    :fields="fields"
    :find-value="findValue"
  >
    <template #default="{ field, d, isTotal, rowData }">
      <div
        v-if="field === enumFields.provider"
        class="px-2"
        :class="{
          'py-2 pl-6 text-left': field === 'provider',
          'text-sm uppercase': field === 'provider' && isTotal,
          'py-1': !(field === 'provider' && isTotal),
        }"
      >
        <p v-if="isTotal">
          {{ d }}
        </p>
        <ProviderIconLink
          v-else
          inline
          class="pt-0.5"
          :slug="d.slug"
          :locked="d.locked"
          :status="d.status"
          :not-monitored="d.notMonitored"
          :estimated="d.estimated"
        />
      </div>

      <template v-else-if="field === enumFields.modes">
        <p
          v-for="vehicleType in d"
          :key="vehicleType"
          class="flex h-8 items-center justify-center border-b border-grey-100 last:border-none"
        >
          <span
            v-if="vehicleType === DATA_VEHICLE_TYPE_TOTAL"
            class="text-xs font-bold uppercase"
          >
            {{ t('All') }}
          </span>

          <VehicleIcon
            v-else
            size="sm"
            :type="getVehicleType(vehicleType)"
          />
        </p>
      </template>

      <template v-else>
        <p
          v-for="(v, key) in d"
          :key="key"
          class="flex h-8 items-center justify-end border-b border-grey-100 px-2 py-0 last:border-none"
        >
          <span
            :class="{
              'font-bold':
                getTypeFromKey(key) === DATA_VEHICLE_TYPE_TOTAL
                || Object.values(d).length === 1,
              'text-sm':
                getTypeFromKey(key) !== DATA_VEHICLE_TYPE_TOTAL
                && Object.values(d).length > 1,
            }"
          >
            <template v-if="v || v === 0">
              {{ rowData?.provider?.estimated ? '≈' : '' }}
              <template v-if="field === enumFields.usage">{{
                formatPercent(v)
              }}</template>
              <template v-else-if="field === enumFields.tripPerVehicle">{{
                formatAverage(v)
              }}</template>
              <template v-else>{{ formatNumber(v) }}</template>
            </template>
            <template v-else>
              <span class="text-xs">{{ t('N/A') }}</span>
            </template>
          </span>
        </p>
      </template>
    </template>
  </DSortingTable>
</template>
