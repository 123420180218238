<script setup>
import svgHome from '@/assets/svg/menu/home.svg'
import svgCities from '@/assets/svg/menu/cities.svg'
import svgProvider from '@/assets/svg/menu/provider.svg'
import svgNews from '@/assets/svg/menu/news.svg'
import svgQuestion from '@/assets/svg/menu/question.svg'
import svgExport from '@/assets/svg/menu/export.svg'
import svgCrisp from '@/assets/svg/menu/crisp.svg'

const { t } = useI18n()
const navigationStore = useNavigationStore()

const crisp = inject('crisp', null)

const menu = computed(() => [
  {
    to: { name: 'Overview' },
    icon: svgHome,
    text: t('Overview'),
    authNeeded: true,
  },
  {
    to: { name: 'Cities' },
    icon: svgCities,
    text: t('Cities'),
    authNeeded: true,
  },
  {
    to: { name: 'Providers' },
    icon: svgProvider,
    text: t('Operators', 2),
    authNeeded: true,
  },
  {
    to: { name: 'Newsfeed' },
    icon: svgNews,
    text: t('Newsfeed'),
    authNeeded: true,
  },
  {
    to: { name: 'Faq' },
    icon: svgQuestion,
    text: t('FAQ'),
  },
  {
    to: { name: 'Export' },
    icon: svgExport,
    text: t('Export'),
  },
  {
    action() {
      crisp?.chat.show()
      crisp?.chat.open()
    },
    icon: svgCrisp,
    text: t('Customer chat'),
    class: 'md:hidden',
  },
])
</script>

<template>
  <nav
    class="flex flex-col space-y-4 pt-4"
    role="menu"
  >
    <NavigationMenuItem
      v-for="link in menu"
      :key="link.to"
      v-bind="link"
      @click="() => (navigationStore.isOpen = false)"
    />
  </nav>
</template>

<style lang="pcss" scoped>
.account-nav.active {
  @apply opacity-100;

  .d-icon {
    @apply bg-black/20 md:bg-black/30 rounded-full;
  }
}
</style>
