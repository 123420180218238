<script setup>
import { StripeElement, StripeElements } from 'vue-stripe-js'

const props = defineProps({
  subscriptionId: { type: String, default: null },
  plans: { type: Array, default: null },
})

const emit = defineEmits(['updated', 'failed'])

const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY

const { t } = useI18n()
const {
  confirmSubscription,
  addPaymentMethod,
  updateSubscription,
  createSetupIntent,
  paySubscription,
} = useSubscription()
const displayError = useDisplayErrors()

const stripeLoaded = inject(PROVIDE_STRIPE_LOADED)

const elms = ref(null)
const card = ref(null)
const completed = ref(false)
const saving = ref(false)

const instanceOptions = {}
const cardOptions = {}
const elementsOptions = {}

async function savePaymentMethod() {
  saving.value = true

  if (props.subscriptionId) {
    await subscriptionPaymentByCard()
  } else if (props.plans) {
    await payByCard()
  } else {
    await addCard()
  }

  saving.value = false
}

async function subscriptionPaymentByCard() {
  const cardElement = card.value.stripeElement
  const stripe = elms.value.instance
  const { paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
  })

  try {
    const res = await paySubscription({
      subscriptionId: props.subscriptionId,
      paymentMethodId: paymentMethod.id,
    })

    const subscription = res.data.subscription
    const clientSecret = subscription.paymentSecret

    if (clientSecret) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          setup_future_usage: 'off_session',
        },
      )

      if (error) {
        throw error
      }

      const status = paymentIntent && paymentIntent.status

      if (status === 'succeeded' || status === 'processing') {
        await confirmSubscription({ subscriptionId: subscription.id })

        emit('updated')
      } else {
        emit('failed')
      }
    }

    emit('updated')
  } catch (error) {
    saving.value = false
    console.error(error)

    displayError(error)
  }
}

async function addCard() {
  const cardElement = card.value.stripeElement
  const stripe = elms.value.instance

  try {
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    const res = await createSetupIntent({
      paymentMethodId: paymentMethod.id,
    })

    const intent = res.data.createSetupIntent
    const clientSecret = intent.secret

    let paymentMethodId

    if (clientSecret) {
      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        },
      )

      if (error) {
        throw error
      }

      paymentMethodId = setupIntent.payment_method
    } else {
      paymentMethodId = paymentMethod.id
    }

    await addPaymentMethod({ paymentMethodId })

    emit('updated')
  } catch (error) {
    saving.value = false
    console.error({ error })
    displayError(error)
  }
}

async function payByCard() {
  const cardElement = card.value.stripeElement
  const stripe = elms.value.instance
  const { paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
  })

  try {
    const res = await updateSubscription({
      plans: props.plans,
      paymentMethodId: paymentMethod?.id || null,
    })

    const subscription = res.data.subscription
    const clientSecret = subscription.paymentSecret

    if (clientSecret) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          setup_future_usage: 'off_session',
        },
      )

      if (error) {
        throw error
      }

      if (paymentIntent && paymentIntent.status === 'succeeded') {
        await confirmSubscription({ subscriptionId: subscription.id })

        emit('updated')
      } else {
        emit('failed')
      }
    }

    emit('updated')
  } catch (error) {
    saving.value = false
    console.error(error)

    displayError(error)
  }
}
</script>

<template>
  <div class="paymentMethods">
    <StripeElements
      v-if="stripeLoaded"
      v-slot="{ elements }"
      ref="elms"
      :stripe-key="stripePublicKey"
      :instance-options="instanceOptions"
      :elements-options="elementsOptions"
    >
      <StripeElement
        ref="card"
        class="text-normal block w-full rounded-md border border-gray-200 bg-white bg-clip-padding p-2 py-2 px-4 text-grey-700 transition-all"
        :elements="elements"
        :options="cardOptions"
        @change="completed = $event.complete"
      />
    </StripeElements>

    <div class="flex justify-end pt-3">
      <DButton
        :disabled="!completed || saving"
        variant="success"
        :loading="saving"
        @click="savePaymentMethod"
      >
        {{ t('Use this payment method') }}
      </DButton>
    </div>
  </div>
</template>
