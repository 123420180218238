<script setup>
defineOptions({
  name: 'FilterBySpecs',
})

const props = defineProps({
  type: {
    type: String,
    default: 'city',
  },
})

const { t } = useI18n()
const filtersStore = useFiltersStore()
const { translateFromData } = useLabelTranslation()

const kpi = computed(() =>
  props.type === 'city'
    ? 'cityCarFiltersPossibleValues'
    : 'providerCarFiltersPossibleValues',
)

const { data, loading, error } = useMetricData(kpi)
</script>

<template>
  <FilterByContainer
    :name="t('Filter by specs')"
    class="relative after:border-l-4 after:border-car after:inset-y-0 after:absolute after:left-[-1px] overflow-hidden"
  >
    <div class="mt-2">
      <p class="mb-1 text-grey-200 text-xs">
        {{ t('Size') }}
      </p>
      <DInputTags
        v-model="filtersStore.carSize"
        :list="
          data?.carSize?.map(v => ({
            value: v,
            label: translateFromData('carSize', v),
          })) || []
        "
        :loading="loading"
        variant="secondary"
        hide-input
        multiple
      />
    </div>

    <div class="mt-2">
      <p class="mb-1 text-grey-200 text-xs">
        {{ t('Engine type') }}
      </p>
      <DInputTags
        v-model="filtersStore.carPropulsion"
        :list="
          data?.carPropulsion?.map(v => ({
            value: v,
            label: translateFromData('carPropulsion', v),
          })) || []
        "
        :loading="loading"
        variant="secondary"
        hide-input
        multiple
      />
    </div>

    <div class="mt-2">
      <p class="mb-1 text-grey-200 text-xs">
        {{ t('Car model') }}
      </p>
      <DInputTags
        v-model="filtersStore.carModel"
        :list="
          data?.carModel?.map(v => ({
            value: v,
            label: translateFromData('carModel', v),
          })) || []
        "
        :loading="loading"
        variant="secondary"
        hide-input
        multiple
      />
    </div>

    <DAlert
      v-if="error"
      variant="alert"
      size="sm"
      class="mt-4"
    >
      <template #left>
        <DIcon
          path="cross"
          size="sm"
        />
      </template>
      <p class="text-sm">
        {{ error }}
      </p>
    </DAlert>
  </FilterByContainer>
</template>
