<script setup>
import DFlagVue from '@/components/ui/DFlag/DFlag.vue'

const { t } = useI18n()
const { mode } = useMode()
const filtersStore = useFiltersStore()
const providersStore = useProvidersStore()
const citiesStore = useCitiesStore()
const { provider } = storeToRefs(useProviderStore())

const providerCountries = computed(() => {
  const modeRef = get(mode)
  const providerRef = get(provider)
  const providerCitiesByCountries
    = providersStore.getProviderCitiesByCountriesInRegion(
      providerRef?.slug,
      filtersStore.region,
    )

  return (
    providerRef?.countries.all?.reduce((acc, country) => {
      // get all cities from a provider grouped by country and filter by mode
      const citiesInCountry = providerCitiesByCountries?.[country]?.filter(
        c =>
          (modeRef
          && c.vehicleTypes.some(v => v.type === ENUM_VEHICLE_TYPES[modeRef]))
          || !modeRef,
      )

      if (citiesInCountry && citiesInCountry.length > 0) {
        const nbrUnlocked = citiesInCountry.filter(city => city.unlocked).length

        acc.push({
          name: country,
          countryCode: citiesInCountry?.[0]?.countryCode,
          cities: citiesInCountry,
          nbrUnlocked,
          nbrLocked: citiesInCountry.length - nbrUnlocked,
        })
      }

      return acc
    }, []) || []
  )
})

const nbrCitiesLocked = computed(() =>
  providerCountries.value.reduce((acc, c) => (acc += c.cities.length), 0),
)
</script>

<template>
  <DSelectPicker
    id="country"
    v-model="filtersStore.country"
    searchable
    :search-placeholder="t('Type a specific country...')"
    :label="t('Country')"
    icon="flag"
    :is-loading="citiesStore.cities.length === 0"
    :options="[
      {
        text: t('All countries'),
        subtext: `${providerCountries.reduce(
          (acc, c) => (acc += c.nbrUnlocked),
          0,
        )} / ${nbrCitiesLocked} ${t('cit(y|ies)', nbrCitiesLocked)}`,
        value: null,
        icon: 'circle',
      },
      ...providerCountries.map(country => ({
        text: citiesStore.getCountryNameLocalized(country.name),
        subtext: `${country.nbrUnlocked} / ${country.cities.length} ${t(
          'cit(y|ies)',
          country.cities.length,
        )}`,
        value: country.name,
        locked: country.nbrUnlocked === 0,
        component: {
          is: DFlagVue,
          props: {
            class: 'inline-block',
            size: 'sm',
            countryCode: country.countryCode,
          },
        },
      })),
    ]"
  />
</template>
