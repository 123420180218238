<script setup>
defineOptions({
  name: 'ProviderFleetsPerTime',
})

const { t } = useI18n()
const { getColor } = useSharedChartColors()
</script>

<template>
  <MetricCard
    kpi="providerFleetsPerTime"
    :used-filters="[
      'mode',
      'dateRange',
      'timeMode',
      'country',
      'region',
      'filterBySpecs',
    ]"
  >
    <template #title>
      {{ t('Fleet evolution') }}
    </template>
    <template #subtitle>
      {{ t('Data from unlocked cities') }}
    </template>

    <template #default="{ data, dataUpdateId }">
      <BarMetric
        :data="data"
        :options="{
          dimension: DimensionTypes.LOCATION,
          afterTitle: 'sum',
          color: d => getColor(d.name, dataUpdateId),
        }"
        type="time"
      />
    </template>
  </MetricCard>
</template>
