<script setup lang="ts">
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { isFreemium } = storeToRefs(useUserStore())
const cityStore = useCityStore()
const { city, isLoading } = storeToRefs(cityStore)
const { mode } = useMode()

// set filters from user preferences
useFiltersFromPreferences('cities')

const featureFlags = useFeaturesFlagsStore()

const menu = computed(() => {
  const params = {
    mode: mode.value || VEHICLE_TYPE_ALL,
  }

  return [
    {
      label: t('Overview'),
      route: { name: 'CityOverview', params },
    },
    ...(featureFlags.get('screen')
      ? [
          {
            label: t('Cyclability Report'),
            subMenuLabel: t('Focus on'),
            subMenuIcon: 'target',
            subMenu: [
              {
                label: t('Region/City'),
                route: { name: 'CityCyclabilityReport' },
              },
              {
                label: t('Cycling network & routes'),
                route: { name: 'CityCyclabilityMap' },
              },
            ],
          },
        ]
      : []),
    {
      label: t('Fleets & Trips'),
      subMenu: [
        {
          route: { name: 'CityFleetsTrips', params, hash: '#at-a-glance' },
          label: t('At a glance'),
          isActive: route.hash === '#at-a-glance',
        },
        {
          route: { name: 'CityFleetsTrips', params, hash: '#fleets' },
          label: t('Fleets'),
          isActive: route.hash === '#fleets',
        },
        {
          route: { name: 'CityFleetsTrips', params, hash: '#trips' },
          label: t('Trips'),
          isActive: route.hash === '#trips',
        },
      ],
    },
    {
      label: t('Maps'),
      subMenuLabel: t('Selected map'),
      subMenuIcon: 'paper-map',
      subMenu: [
        {
          label: t('Fleet distribution'),
          route: {
            name: 'CityMapsDistribution',
            params,
          },
          isActive: route.name?.toString().includes('CityMapsDistribution'),
        },
        {
          label: t('Travel patterns'),
          route: {
            name: 'CityMapsPatterns',
            params,
          },
          isActive: route.name?.toString().includes('CityMapsPatterns'),
        },
      ],
    },
    {
      label: t('Newsfeed'),
      route: (!get(isFreemium) && { name: 'CityNewsfeed', params }) || null,
      locked: get(isFreemium) && t('upgrade_to_unlock'),
    },
  ]
})

watch(city, (city) => {
  if (city) {
    const vehicleTypes
      = city.vehicleTypes
        ?.map(v => v.type)
        .filter(type => DATA_VEHICLE_TYPES.includes(type)) || []

    const vehicleTypesFiltered = vehicleTypes?.filter(type =>
      DATA_VEHICLE_TYPES.includes(type),
    )

    const modeRef = mode.value

    if (vehicleTypesFiltered && modeRef) {
      if (
        vehicleTypesFiltered?.length > 1
        && !vehicleTypesFiltered.includes(ENUM_VEHICLE_TYPES[modeRef])
      ) {
        router.replace({
          params: {
            ...route.params,
            citySlug: city.name,
            mode: VEHICLE_TYPE_ALL,
          },
        })
      } else if (
        vehicleTypesFiltered?.length === 1
        && ENUM_VEHICLE_TYPES[modeRef] !== vehicleTypesFiltered[0]
      ) {
        router.replace({
          params: {
            ...route.params,
            citySlug: city.name,
            mode: getVehicleType(vehicleTypesFiltered[0]),
          },
        })
      }
    }
  }
})

onMounted(async () => {
  if (route.params.citySlug) {
    await cityStore.load(route.params.citySlug as string)
  }
})

onBeforeUnmount(() => {
  cityStore.unload()
})

watch(
  () => route.params.citySlug,
  async (nextCity, lastCity) => {
    if (nextCity && nextCity !== lastCity) {
      await cityStore.load(nextCity as string)
    }
  },
)
</script>

<template>
  <div>
    <SidebarContainer
      v-if="!isLoading && city"
      class="flex"
      :no-padding="Boolean(route.meta.isMaps)"
      have-menu
    >
      <template #left>
        <Sidebar
          :to="{ name: 'Cities' }"
          :text="t('Back to cities')"
          :menu="menu"
        >
          <FavoriteCard
            type="city"
            :slug="route.params.citySlug as string"
            class="mb-4 hidden md:block"
          />
        </Sidebar>
      </template>

      <template #default="{ container }">
        <router-view :infinite-target="container" />
      </template>
    </SidebarContainer>

    <div
      v-else
      class="flex h-full items-center justify-center"
    >
      <AnimatedLoading />
    </div>
  </div>
</template>
