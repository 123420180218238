<script setup lang="ts">
import type { Direction } from '@/types/index.types'

const props = defineProps<{
  data?: {
    value: number | null
    trend?: {
      percent: number
      direction: Direction
    }
  }
  label?: string
  help?: string
  isPercent?: boolean
  inline?: boolean
}>()

const value = computed(() => {
  const format = props.isPercent ? formatPercent : formatNumber
  return props.data?.value === null ? '-' : format(props.data?.value ?? 0)
})

const trend = computed(() => props.data?.trend)
</script>

<template>
  <p :class="{ 'flex flex-col': !inline }">
    <span
      class="font-bold"
      :class="{
        'flex flex-col text-2xl md:flex-row': !inline,
        'text-base': inline,
      }"
    >
      <slot
        :formated="value"
        :value="data?.value || null"
        :data="data"
      >
        {{ value }}
      </slot>
      <Trend
        v-if="trend?.percent || trend?.percent === 0"
        :trend="trend.percent"
        :direction="trend.direction"
        :type="isPercent ? 'point' : 'percent'"
        class="text-base font-normal md:text-sm"
        :class="{
          'inline-block w-12 text-right align-text-bottom': inline,
          'pl-1 align-text-top': !inline,
        }"
      />
    </span>
    <span
      v-if="label"
      class="text-sm text-grey-400"
    >
      <DHelpTooltip
        v-if="help"
        :help="help"
      >
        {{ label }}
      </DHelpTooltip>
      <template v-else>
        {{ label }}
      </template>
    </span>
  </p>
</template>
