<script setup>
const { t } = useI18n()

const loginModalsStore = useLoginModalsStore()
const { isLogged, organization, loading } = storeToRefs(useUserStore())

const showLogin = computed(
  () => !get(isLogged) || (!get(organization) && !get(loading)),
)
</script>

<template>
  <div
    class="gradient relative flex h-16 items-center justify-center space-x-2 px-4 md:justify-end"
    role="toolbar"
  >
    <template v-if="showLogin">
      <DButton
        v-if="isLogged && !organization"
        variant="outline-secondary"
        class="hidden md:block"
        @click="() => loginModalsStore.openChangeOrganization()"
      >
        {{ t('Select an organization') }}
      </DButton>

      <template v-else-if="!isLogged">
        <DButton
          variant="fill-secondary"
          @click="() => loginModalsStore.openSignin()"
        >
          {{ t('Log in') }}
        </DButton>&nbsp;<DButton
          variant="outline-secondary"
          :to="{ name: 'Register' }"
        >
          {{ t('Sign up') }}
        </DButton>
      </template>
    </template>

    <!-- Inputs on right side : Teleport -->
    <template v-else>
      <DButton
        variant="fill-secondary"
        size="md"
        :to="{ name: 'Overview' }"
      >
        {{ t('Start exploring') }}
      </DButton>
    </template>

    <FreemiumUpgradeBtn />
  </div>
</template>
