<script setup>
defineProps({
  manage: Boolean,
})

const { t } = useI18n()
const { subscription, isFreemium } = storeToRefs(useUserStore())

const currentPlan = computed(() => {
  return subscription.value.plans.find(p => p.isOption === false) || {}
})

const name = computed(() =>
  [4, 5].includes(currentPlan.value.id)
    ? t('City Dweller')
    : [6, 7].includes(currentPlan.value.id)
        ? t('data.plans.premium')
        : currentPlan.value.name,
)
</script>

<template>
  <DCard>
    <div class="flex flex-col md:flex-row">
      <p class="flex-1 font-bold">
        {{ t('Current plan:') }}
      </p>
      <p class="text-xl font-bold text-blue-500">
        {{ name }} {{ isFreemium ? t('(Free Version)') : '' }}
      </p>
    </div>

    <hr class="my-4">

    <div class="flex flex-col md:flex-row">
      <div class="flex-1">
        <PlanDetails
          :plans="subscription.plans"
          hide-title
        />
      </div>
      <div
        v-if="manage"
        class="text-center"
      >
        <DButton
          variant="gradient-primary"
          :to="{ name: 'SubscribePlans' }"
          class="mb-3"
        >
          {{ t('Upgrade my plan') }}
        </DButton>
        <br>
        <DButton
          variant="fill-secondary"
          :to="{ name: 'SettingsEdit' }"
        >
          {{
            t("Organization's members")
          }}
        </DButton>
      </div>
    </div>
  </DCard>
</template>
