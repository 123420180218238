<script setup>
import { usePlaceHoldersClasses } from './placeHoldersClasses'

defineProps({
  cards: {
    type: Number,
    default: 4,
  },
})

const { classes } = usePlaceHoldersClasses()
</script>

<template>
  <div class="mt-4 space-y-4">
    <div
      v-for="n in cards"
      :key="n"
      class="flex h-16 w-full items-center bg-grey-500/10"
      :class="classes"
    >
      <div class="m-3 h-10 w-10 bg-grey-500/10" />
      <div>
        <div class="h-4 w-20 bg-grey-500/10" />
        <div class="mt-2 h-4 w-24 bg-grey-500/10" />
      </div>
    </div>
  </div>
</template>
