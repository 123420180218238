<script setup>
import { Line } from 'vue-chartjs'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      datasets: [],
    }),
  },
  options: {
    type: Object,
    default: () => ({}),
  },
})

ChartJS.register(
  Tooltip,
  BarElement,
  BarController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
)

const { options } = toRefs(props)

const datasets = computed(
  () => props.data?.datasets.filter(d => d.data.length > 0) || [],
)
const tooltip = reactive(useChartTooltip(options, datasets))
const extendedOptions = reactive(useChartExtendedOptions(options))

const chartOptions = computed(() => {
  const newOptions = {
    ...DEFAULT_CHART_OPTIONS,
    ...props.options,
  }

  if (extendedOptions.showWeekend && newOptions.scales.y) {
    newOptions.scales.y.position = 'left'
  }

  if (extendedOptions.showWeekend) {
    newOptions.scales = {
      ...newOptions.scales,
      ...extendedOptions.weekendScales,
    }
  }

  newOptions.plugins = {
    ...newOptions.plugins,
    tooltip: tooltip.value,
  }

  return newOptions
})

const chartData = computed(() => {
  const data = { ...props.data }

  if (extendedOptions.showWeekend) {
    data.datasets?.push(extendedOptions.weekendSeries)
  }

  if (data.datasets.length === 0) {
    data.datasets.push({})
  }

  return data
})
</script>

<template>
  <Line
    class="line-chart"
    :data="chartData"
    :options="chartOptions"
    style="position: relative; height: 200px"
  />
</template>
