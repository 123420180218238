import { createTemplatePromise } from '@vueuse/core'

interface ToastOptions {
  variant?: string
  autoClose?: number
  buttons?: boolean
}
interface ToastData {
  title: string
  message?: string
}

export const useToast = createSharedComposable(() => {
  const ToastPromise = createTemplatePromise<any, [ToastData, ToastOptions]>({
    transition: {
      name: 'fade',
      appear: true,
    },
  })

  async function push(toast: ToastData, options: ToastOptions = {}) {
    const result = await ToastPromise.start(toast, {
      autoClose: 5000,
      ...options,
    })

    return result
  }

  return {
    ToastPromise,
    push,
  }
})
