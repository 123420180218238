<script setup>
const props = defineProps({
  plan: {
    type: Object,
    required: true,
  },
  isSelected: Boolean,
  disabled: Boolean,
  region: {
    type: String,
    default: REGION_CODE_EU,
  },
})

const emit = defineEmits(['select', 'showCities'])

const { t } = useI18n()
const citiesStore = useCitiesStore()

const nbrCities = computed(() => props.plan?.data?.cities.length)

const countries = computed(() => {
  return props.plan?.data?.cities.reduce((acc, city) => {
    const country = citiesStore.getCity(city)?.countryCode

    if (country && !acc.includes(country)) {
      acc.push(country)
    }

    return acc
  }, [])
})
</script>

<template>
  <div class="border border-grey-100 bg-teal-100 text-center shadow-2xl">
    <div
      class="relative h-40 w-full overflow-hidden before:absolute before:bottom-0 before:left-0 before:h-1/3 before:w-full before:bg-gradient-to-b before:from-transparent before:to-[rgba(235,248,248,1)]"
    >
      <PlanWorldMap
        :countries="countries"
        :region="region"
      />
    </div>

    <div class="relative z-hop -mt-4 px-2.5 pb-4">
      <p class="font-title font-bold text-blue-500">
        {{ plan.name }}
      </p>
      <p class="mt-2 whitespace-nowrap text-sm">
        <span class="text-blue-500">{{ countries.length }}</span>
        {{ t('countries', countries.length) }}
        / <span class="text-blue-500">{{ nbrCities }}</span>
        {{ t('cit(y|ies)', nbrCities) }}
      </p>
      <p class="text-xs">
        <DLink
          class="font-normal text-blue-500 underline"
          @click="() => emit('showCities')"
        >
          {{ t('More details') }}
        </DLink>
      </p>
      <p class="mt-4 text-sm text-grey-300">
        {{ t('includes {n} users', { n: plan.seats }, plan.seats) }}
      </p>
      <DButton
        class="mt-2 w-full shadow"
        size="sm"
        :variant="isSelected ? 'fill-secondary' : 'fill-primary'"
        :disabled="disabled"
        @click="
          () => {
            if (disabled) return
            emit('select', plan.slug)
          }
        "
      >
        {{ isSelected ? t('Added!') : t('Add to Bundle') }}
      </DButton>
    </div>
  </div>
</template>
