<script setup>
defineOptions({
  name: 'CityMapAreasInfo',
})

const { t } = useI18n()
</script>

<template>
  <div class="p-4">
    <h3 class="px-8 text-center text-base font-bold">
      {{ t('Top 3 - Hightest zones') }}<br>
      <small class="text-center text-xs text-grey-300">{{
        t('Hover zones below to highlight them on map.')
      }}</small>
    </h3>

    <div class="mt-4 flex flex-col gap-2">
      <div class="flex justify-between px-2 text-xs font-normal uppercase">
        <p>{{ t('zone') }}</p>
        <p>{{ t('Avail. vehicles') }}</p>
      </div>

      <div
        v-for="i in 3"
        :key="i"
        class="flex justify-between bg-white p-2 shadow"
      >
        <p class="text-sm font-bold">
          HEX<span class="text-xs text-grey-300">#0142</span>
        </p>
        <p class="text-sm font-bold">
          {{ formatNumber(random(100, 1000)) }}
        </p>
      </div>
    </div>

    <h3 class="mt-8 text-center text-base font-bold">
      {{ t('Operator Breakdown') }}
    </h3>
    <!-- <PieMetric /> -->

    <h3 class="mt-8 text-center text-base font-bold">
      {{ t('Modal Breakdown') }}
    </h3>
    <!-- <PieMetric /> -->
  </div>
</template>
