export function useProviderLink(provider) {
  const { isFreemium } = storeToRefs(useUserStore())

  const isLocked = ref(true)
  const canSeeFootprint = ref(false)
  const canSeeBlockedModal = ref(false)
  const showBlockedModal = ref(false)

  function setup(provider) {
    if (!provider) {
      return
    }

    const notMonitored = isDefined(provider.availableInCD)
      ? !provider.availableInCD
      : provider.notMonitored || false
    const isFreemiumRef = get(isFreemium)

    const isLockedTmp = notMonitored !== true && provider.unlocked === false
    const canSeeFootprintTmp
      = ((notMonitored === true || isLockedTmp) && !isFreemiumRef)
      || (isFreemiumRef && notMonitored === true)
    const canSeeBlockedModalTmp
      = isFreemiumRef && (isLockedTmp || notMonitored === true)

    set(isLocked, isLockedTmp)
    set(canSeeFootprint, canSeeFootprintTmp)
    set(canSeeBlockedModal, canSeeBlockedModalTmp)
  }

  if (isRef(provider)) {
    watch(provider, p => setup(p), { immediate: true })
  } else {
    setup(provider)
  }

  const linkAttrs = computed(() =>
    get(provider)
      ? {
          is: get(canSeeBlockedModal) ? 'div' : 'router-link',
          to: get(canSeeBlockedModal)
            ? null
            : {
                name: get(canSeeFootprint)
                  ? 'ProviderFootprint'
                  : 'ProviderOverview',
                params: {
                  provider: get(provider).slug,
                  mode: VEHICLE_TYPE_ALL,
                },
              },
          action: get(canSeeBlockedModal)
            ? () => set(showBlockedModal, true)
            : () => {},
        }
      : {
          is: 'div',
          to: null,
          action: null,
        },
  )

  return {
    linkAttrs,
    isLocked,
    canSeeFootprint,
    canSeeBlockedModal,
    showBlockedModal,
  }
}
