<script setup>
import { subDays } from 'date-fns'
import { DatePicker } from 'v-calendar'

const props = defineProps({
  modelValue: { type: Object, default: null },
  disabledDates: {
    type: Array,
    default: () => [
      /* new Date(2023, 10, 10), new Date(2023, 10, 8) */
    ],
  },
  minDate: {
    type: Date,
    default: new Date(2020, 2, 18),
  },
})

const emit = defineEmits(['update:modelValue'])

const { locale } = useI18n()

const datePickerAttrs = computed(() => ({
  attributes: [
    {
      key: 'today',
      highlight: true,
    },
    {
      content: {
        class: 'text-grey-200',
      },
      customData: {
        isDisabled: true,
      },
      dates: props.disabledDates,
    },
  ],
  theme: {
    container: {
      light: '',
    },
  },
  minDate: props.minDate,
  maxDate: subDays(now, 1),
  firstDayOfWeek: 2,
  isInline: true,
}))

const dates = useVModel(props, 'modelValue', emit)
</script>

<template>
  <DatePicker
    v-bind="datePickerAttrs"
    v-model.range="dates"
    :columns="2"
    :locale="locale"
    class="date-picker"
  />
</template>

<style lang="pcss">
.date-picker {
  &.vc-container {
    --blue-200: #e9ecfd;
    --blue-600: #455eef;

    @apply border-0;
  }

  .vc-text {
    &-sm {
      @apply text-sm;
    }
    &-lg {
      @apply text-base;
    }
    &-gray-800 {
      @apply text-grey-500;
    }
  }

  .vc-arrows-container {
    @apply h-14;
  }

  .vc-header {
    @apply border-b border-grey-100 h-14 p-0;
  }

  .vc-weeks {
    @apply p-3;
  }
}
</style>
