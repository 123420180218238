<script>
const DATA_VEHICLE_TYPE_TOTAL = 'TOTAL'
const dataTypesList = [DATA_VEHICLE_TYPE_TOTAL, ...DATA_VEHICLE_TYPES]

/** @enum {string} enumFields */
const enumFields = {
  modes: 'modes',
  vehicles: 'vehicles',
  trips: 'trips',
  tripPerVehicle: 'tripPerVehicle',
}
</script>

<script setup>
defineOptions({
  name: 'TableCountriesMetric',
})

/**
 * @typedef {object} IFields
 * @property {number} vehicles
 * @property {number} trips
 * @property {number} tripPerVehicle
 */

/**
 * @typedef {object} IModeData
 * @property {IFields | null} BIKE
 * @property {IFields | null} SCOOTER
 * @property {IFields | null} MOTORSCOOTER
 * @property {IFields | null} CAR
 * @property {IFields | null} TOTAL
 */

/**
 * @typedef {object} ICity
 * @property {string} city
 * @property {boolean} isCDCity
 * @property {boolean} unlocked
 * @typedef {IModeData & ICity} ICityData
 */

/**
 * @typedef {object} ICountries
 * @property {string} country
 * @property {ICityData[]} cities
 * @typedef {IModeData & ICountries} ICountriesData
 */

/**
 * @typedef {object} IRegion
 * @property {string} region
 * @property {ICountriesData[]} countries
 * @typedef {IModeData & IRegion} IRegionData
 */

/**
 * @typedef {object} IGlobal
 * @property {IRegionData[]} regions
 * @typedef {IModeData & IGlobal} IData
 */

/**
 * @typedef {object} IRow
 * @property {string} dimension
 * @property {ICols[]} cols
 * @property {IRow[]} [rows]
 * @property {ICityData | ICountriesData | IRegionData} [data]
 * @property {boolean} [isRegion]
 * @property {boolean} [isCountry]
 * @property {boolean} [isCity]
 * @property {boolean} [isTotal]
 * @property {{ all: number, unlocked: number }} [citiesTotal]
 */

/**
 * @typedef {object} ICols
 * @property {number} [vehicles]
 * @property {number} [trips]
 * @property {number} [tripPerVehicle]
 * @property {string[]} [modes]
 */

/**
 * @typedef {object} IProps
 * @property {object} data
 * @property {string[]} regions
 */

/** @type {IProps} */
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  regions: {
    type: Array,
    default: () => null,
    validator: v => Array.isArray(v) || v === null,
  },
})

const { t } = useI18n()
const { translateLocation } = useLabelTranslation()
const { getCountryNameLocalized, getCityNameLocalized } = useCitiesStore()
const filtersStore = useFiltersStore()

/**
   @type {{
  text: string
  value?: enumFields
  sortable?: boolean
}[]} */
const fields = [
  {
    text: t('Countries / Cities'),
  },
  {
    text: t('Modes'),
    value: enumFields.modes,
  },
  {
    text: t('Vehicles'),
    value: enumFields.vehicles,
    sortable: true,
  },
  {
    text: t('Trips'),
    value: enumFields.trips,
    sortable: true,
  },
  {
    text: t('TVD'),
    value: enumFields.tripPerVehicle,
    help: t('Trips per vehicle per day'),
    sortable: true,
  },
]

/** @type {Ref<IRow | undefined>} */
const total = ref()
/** @type {Ref<IRow[]>} */
const rows = ref([])
/** @type {Ref<string | null>} */
const lastCountrySelected = ref(null)

// watch the data and regions props to update the data in the table
watch(
  [() => props.data, () => props.regions],
  ([data, regions]) => {
    const { total: regionsTotal, rows: regionsRows } = prepareRegionsRows(
      data,
      regions,
    )

    set(total, regionsTotal || null)
    set(rows, regionsRows)
  },
  { immediate: true, deep: true },
)

watch(
  () => filtersStore.country,
  (country) => {
    if (country) {
      set(lastCountrySelected, country)
    }
  },
)

/** @param {ICityData | ICountriesData | IRegionData | IData} d */
function formatData(d) {
  if (d.unlocked === false) {
    // the city is not unlocked so we collapse the columns and we show a message
    return [
      {
        attrs: { colspan: fields.length - 1 },
        locked: true,
      },
    ]
  }

  return (
    Object.entries(d)
      // We format the data only if is a known data vehicle type
      .filter(([key]) => dataTypesList.includes(key))
      .reduce(
        (acc, [key, data]) => {
          if (data) {
            fields.forEach((field) => {
              const indexOfType = getKeyFromType(key)
              const fieldValue = field.value
              const value
                = fieldValue === enumFields.modes ? key : data[fieldValue]

              if (isDefined(value) || value === null) {
                if (!acc[fieldValue]) {
                  acc[fieldValue] = {}
                }

                acc[fieldValue][indexOfType] = value
              }
            })
          }

          return acc
        },
        Object.values(enumFields).reduce((acc, field) => {
          acc[field] = null
          return acc
        }, {}),
      )
  )
}

/**
 * @param {IRegionData} regionData
 * @returns {{ rows: IRow[], cityCounter: number, cityUnlockedCounter: number }}
 */
function getCountriesFromRegion(regionData) {
  if (!regionData) {
    return {
      rows: [],
      cityCounter: 0,
      cityUnlockedCounter: 0,
    }
  }

  let cityCounter = 0
  let cityUnlockedCounter = 0

  const rows = regionData.countries?.map((d) => {
    const countriesData = formatData(d)

    return {
      dimension: getCountryNameLocalized(d.country),
      isCountry: true,
      cols: countriesData,
      data: d,
      rows: d.cities.map((c) => {
        cityCounter = cityCounter + 1

        if (c.unlocked === true) {
          cityUnlockedCounter = cityUnlockedCounter + 1
        }

        return {
          dimension: getCityNameLocalized(c.city),
          isCity: true,
          data: c,
          cols: formatData(c),
        }
      }),
    }
  })

  return {
    rows,
    cityCounter,
    cityUnlockedCounter,
  }
}

/**
 * @param {IData} data
 * @param {string[] | null | undefined} regionsSelected
 * @returns {{ total: IRow, rows: IRow[] }}
 */
function prepareRegionsRows(data, regionsSelected) {
  const regionsData = data.regions
  const rows = []

  if (regionsSelected !== null && Array.isArray(regionsSelected)) {
    // one or more regions are selected
    regionsSelected.forEach((region) => {
      const regionData = regionsData?.find(d => d.region === region)

      if (regionData) {
        rows.push({
          dimension: translateLocation(
            regionData.region,
            ENUM_LOCATION_TYPES.region,
          ),
          isRegion: true,
          data: regionData,
          cols: formatData(regionData),
          ...getCountriesFromRegion(regionData),
        })
      }
    })
  } else {
    // no region selected, we show all the regions
    regionsData.forEach((regionData) => {
      rows.push({
        dimension: translateLocation(
          regionData.region,
          ENUM_LOCATION_TYPES.region,
        ),
        isRegion: true,
        data: regionData,
        cols: formatData(regionData),
        ...getCountriesFromRegion(regionData),
      })
    })
  }

  let totalCols = null

  if (rows?.length === 1) {
    totalCols = rows[0].cols
  } else {
    totalCols = formatData(data)
  }

  const hasOneRegion = rows?.length === 1

  if (hasOneRegion) {
    const regionData = rows[0]

    const hasOneCountry = regionData.rows?.length === 1

    if (hasOneCountry) {
      return regionData
    }
  }

  return {
    rows: hasOneRegion ? rows[0].rows : rows,
    total: {
      dimension: hasOneRegion ? rows[0].dimension : t('All Regions'),
      citiesTotal: {
        all: rows.reduce((acc, d) => {
          acc += d.cityCounter
          return acc
        }, 0),
        unlocked: rows.reduce((acc, d) => {
          acc += d.cityUnlockedCounter
          return acc
        }, 0),
      },
      cols: totalCols,
      isTotal: true,
    },
  }
}

/**
 * @param {ICityData[]} cities
 * @returns {number}
 */
function getNumberOfCitiesUnlocked(cities = []) {
  return cities.reduce((acc, city) => {
    if (city.unlocked) {
      acc = acc + 1
    }

    return acc
  }, 0)
}

/**
 * @param {IRow} row
 * @returns {boolean}
 */
function findOpenRows(row) {
  const selected = get(lastCountrySelected)
  return Boolean(row.isCountry && row.data.country === selected && !filtersStore.country)
}

/**
 * @param {object} d
 * @returns {IFields}
 */
function findValue(d) {
  // get the TOTAL or the first type found in the data
  const key
    = getKeyFromType(DATA_VEHICLE_TYPE_TOTAL) || (d && Object.keys(d)[0])

  return d?.[key]
}

/** @param {string} type */
function getKeyFromType(type) {
  return dataTypesList.indexOf(type)
}

/** @param {number} key */
function getTypeFromKey(key) {
  return dataTypesList[key] || null
}
</script>

<template>
  <DSortingTable
    sortable
    closable-rows
    multi-dimensional
    :total="total || null"
    :rows="rows || []"
    :fields="fields"
    :find-value="findValue"
    :find-open-rows="findOpenRows"
  >
    <template #dimension="{ d, label }">
      <!-- city row -->
      <p v-if="d.isCity">
        <template v-if="d.data?.unlocked && d.data.isCDCity">
          <DLink
            :to="{
              name: 'CityOverview',
              params: {
                citySlug: d.data?.city,
                mode: VEHICLE_TYPE_ALL,
              },
            }"
            class="font-normal hover:underline"
          >
            {{ label }}
          </DLink>
        </template>

        <template v-else>
          {{ label }}
          <DIcon
            v-if="!d.data?.unlocked"
            size="xs"
            path="cadenas"
          />
        </template>
      </p>

      <!-- total row -->
      <template v-else-if="d.isTotal">
        <p>{{ label }}</p>
        <p
          v-if="d.isTotal"
          class="text-xs font-bold normal-case text-blue-500"
        >
          {{
            `${t('Data from')} ${d.citiesTotal.unlocked} / ${
              d.citiesTotal.all
            } ${t('cit(y|ies)', d.citiesTotal.all)}`
          }}
        </p>
      </template>

      <!-- country row or region -->
      <template v-else-if="d.isRegion || d.isCountry">
        <p
          class="pr-4"
          :class="{ 'font-bold': d.isRegion }"
        >
          <!-- <DIcon v-if="d.data.region" :path="getRegionIcon(d.data.region)" size="sm" /> -->
          {{ label }}
        </p>
        <p
          v-if="d.data?.countries"
          class="text-xs font-bold normal-case text-blue-500"
        >
          {{
            `${t('Data from')} ${d.cityUnlockedCounter} / ${d.cityCounter} ${t(
              'cit(y|ies)',
              d.cityCounter,
            )}`
          }}
        </p>
        <p
          v-else-if="d.data?.cities"
          class="text-xs font-bold normal-case text-blue-500"
        >
          {{
            `${t('Data from')} ${getNumberOfCitiesUnlocked(d.data.cities)} / ${
              d.data.cities.length
            } ${t('cit(y|ies)', d.data.cities.length)}`
          }}
        </p>
      </template>

      <p v-else>
        {{ label }}
      </p>
    </template>

    <template #default="{ d, field }">
      <template v-if="d.locked">
        <p class="px-4 align-middle text-sm">
          <RouterLink
            :to="{ name: 'SubscribePlans' }"
            class="text-amber-600 underline"
          >
            {{ t('Unlock this city to access data') }}
          </RouterLink>
        </p>
      </template>

      <template v-else-if="field === enumFields.modes">
        <p
          v-for="vehicleType in d"
          :key="vehicleType"
          class="flex h-8 items-center justify-center border-b border-grey-100 last:border-none"
        >
          <span
            v-if="vehicleType === DATA_VEHICLE_TYPE_TOTAL"
            class="text-xs font-bold uppercase"
          >
            {{ t('All') }}
          </span>

          <VehicleIcon
            v-else
            size="sm"
            :type="getVehicleType(vehicleType)"
          />
        </p>
      </template>

      <template v-else>
        <p
          v-for="(v, key) in d"
          :key="key"
          class="flex h-8 items-center justify-end border-b border-grey-100 px-2 py-0 last:border-none"
        >
          <span
            :class="{
              'font-bold':
                getTypeFromKey(key) === DATA_VEHICLE_TYPE_TOTAL
                || Object.values(d).length === 1,
              'text-sm':
                getTypeFromKey(key) !== DATA_VEHICLE_TYPE_TOTAL
                && Object.values(d).length > 1,
            }"
          >
            <template v-if="v || v === 0">
              {{
                field === enumFields.tripPerVehicle
                  ? formatAverage(v)
                  : formatNumber(v)
              }}
            </template>
            <template v-else>
              <span class="text-xs">{{ t('N/A') }}</span>
            </template>
          </span>
        </p>
      </template>
    </template>
  </DSortingTable>
</template>
