import { createApolloProvider } from '@vue/apollo-option'

import cityDiveClient from '@/graphql/cityDive'
import captainClient from '@/graphql/captain'

export const clients = {
  default: cityDiveClient,
  captain: captainClient,
}

const apolloProvider = createApolloProvider({
  clients,
  defaultClient: cityDiveClient,
})

export default apolloProvider

export async function resetCityDive() {
  await cityDiveClient.cache.reset()
  await cityDiveClient.clearStore()
}

export async function resetCaptain() {
  await captainClient.cache.reset()
  await captainClient.clearStore()
}

export async function resetCache() {
  await resetCityDive()
  await resetCaptain()
}
