<script setup>
defineOptions({
  name: 'VehicleIcon',
})

const props = defineProps({
  type: {
    type: String,
    required: true,
    validation: value =>
      VEHICLE_TYPES.includes(value) || DATA_VEHICLE_TYPES.includes(value),
  },
  disabled: Boolean,
  size: {
    type: String,
    validator: value => ['xs', 'sm', 'md', 'lg'].includes(value),
    default: 'sm',
  },
})

const { t } = useI18n()
const typeStr = computed(() => getVehicleType(props.type))
const classes = computed(() => getVehicleTypeClasses(get(typeStr)))
</script>

<template>
  <DTooltip
    :content="capitalize(t(`${typeStr}s`))"
    class="inline-flex"
  >
    <DIcon
      :path="typeStr"
      :size="size"
      class="!mt-0 flex-none rounded-full border"
      :class="[classes, { 'opacity-20': disabled }]"
    />
  </DTooltip>
</template>
