<script setup>
const props = defineProps({
  modelValue: { type: String, default: 'alpha' },
  options: { type: Array, default: () => [] },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const sortBy = useVModel(props, 'modelValue', emit)
</script>

<template>
  <DSelectPicker
    id="sortBy"
    v-model="sortBy"
    :options="options"
    :label="t('Sort by')"
    icon="sort"
  />
</template>
