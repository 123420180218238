<script setup>
defineOptions({
  name: 'CityProvidersOverview',
})

const { t } = useI18n()
const providersStore = useProvidersStore()

const { city } = storeToRefs(useCityStore())

const providers = computed(() => Object.entries(city.value?.providers) || [])
const providersByType = computed(() => {
  return Object.fromEntries(
    Object.entries(
      providers.value.reduce((acc, [slug, types]) => {
        types?.forEach((type) => {
          if (DATA_VEHICLE_TYPES.includes(type)) {
            if (!acc[type]) {
              acc[type] = []
            }

            if (!acc[type].find(p => p.slug === slug)) {
              const provider = providersStore.providersBySlug[slug]

              acc[type].push(provider || { slug })
            }
          }
        })

        return acc
      }, {}),
    ).sort(
      (a, b) =>
        DATA_VEHICLE_TYPES.indexOf(a[0]) - DATA_VEHICLE_TYPES.indexOf(b[0]),
    ),
  )
})
</script>

<template>
  <MetricCard>
    <template #title>
      {{
        t('{n} active operators in {city}', {
          n: providers.length,
          city: city.nameLocalized,
        })
      }}
    </template>
    <template #subtitle>
      {{ t('Refreshed daily') }}
    </template>

    <div class="flex flex-col divide-y">
      <div
        v-for="(providersList, type) in providersByType"
        :key="type"
        class="flex-center flex py-3.5 first:pt-0 last:pb-0"
      >
        <div class="mr-4">
          <VehicleIcon :type="getVehicleType(type)" />
        </div>
        <ProvidersIconsList
          :providers="providersList"
          class="flex-1"
        />
      </div>
    </div>
  </MetricCard>
</template>
