<script setup>
const props = defineProps({
  plan: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
const { translateCountry } = useLabelTranslation()
const citiesStore = useCitiesStore()

const search = ref('')
const debounced = refDebounced(search, DEFAULT_DEBOUNCE)

const citiesFormated = computed(() => {
  return (
    props.plan?.data?.cities?.reduce((countries, cityName) => {
      const countryCode = citiesStore.getCity(cityName)?.countryCode || ''

      if (!countries.find(c => c.code === countryCode)) {
        countries.push({
          code: countryCode,
          name: translateCountry(countryCode),
          cities: [],
        })
      }

      countries
        .find(c => c.code === countryCode)
        .cities.push({
          name: cityName,
          nameLocalized: citiesStore.getCityNameLocalized(cityName),
        })

      return countries
    }, []) || []
  )
})

const citiesFiltered = computed(() => {
  const sortByName = sortByAttr('name')

  return get(citiesFormated)
    .sort((a, b) => {
      return a.name === null ? 1 : b.name === null ? -1 : sortByName(a, b)
    })
    .map((country) => {
      return {
        ...country,
        cities: country.cities
          .filter(city =>
            city.nameLocalized
              .toLowerCase()
              .includes(get(debounced).toLowerCase()),
          )
          .sort(sortByAttr('nameLocalized')),
      }
    })
})
</script>

<template>
  <DModal size="lg">
    <template #title>
      {{
        `${plan.name} - ${props.plan?.data?.cities.length}
      ${t('cit(y|ies)', props.plan?.data?.cities.length)}`
      }}
    </template>

    <DField>
      <template #label>
        {{ t('Find a specific city in this pack') }}
      </template>
      <DInputText
        v-model="search"
        type="search"
        :placeholder="t('e.g Aachen, Aalborg, Aarhus, ...')"
      />
    </DField>

    <h2 class="my-4 font-bold">
      {{ t('Cities Unlocked') }}
    </h2>

    <div class="max-h-52 overflow-y-auto md:max-h-96">
      <div
        v-for="(country, key) in citiesFiltered"
        :key="key"
      >
        <template v-if="country.cities.length">
          <h3 class="font-bold">
            <DFlag
              size="sm"
              :country-code="country.code"
              class="inline-block align-middle"
            />{{ country.name }}
          </h3>
          <div class="flex flex-wrap">
            <div
              v-for="list in country.cities.reduce((acc, city, index) => {
                // group cities three by three
                if (index % 3 === 0) {
                  acc.push([])
                }
                acc[acc.length - 1].push(city)
                return acc
              }, [])"
              :key="list"
              class="mb-4 basis-1/3"
            >
              <p
                v-for="city in list"
                :key="city"
              >
                {{ city.nameLocalized }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </DModal>
</template>
