<script setup>
const props = defineProps({
  modelValue: Boolean,
})
const emit = defineEmits(['update:modelValue'])

const value = useVModel(props, 'modelValue', emit)

const route = useRoute()
const { t } = useI18n()

const { isHighlighted } = useFilterHighlight('locked')

const page = computed(() => {
  if (route.name === 'Cities') {
    return CITY
  } else if (route.name === 'Providers') {
    return OPERATOR
  } else {
    return 'name'
  }
})
</script>

<template>
  <div
    class="bg-grey-50 hover:bg-blue-100 text-slate-500 rounded-md font-normal text-sm w-full min-h-[44px] px-2 py-1 transition-colors duration-300"
    :class="{
      '!bg-yellow-100': isHighlighted,
    }"
  >
    <DInputSwitch v-model="value">
      <template v-if="page === CITY">
        {{ t('Display locked and unmonitored cities') }}
      </template>
      <template v-else>
        {{ t('Display locked and unmonitored operators') }}
      </template>
    </DInputSwitch>
  </div>
</template>
