<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityMap',
})

const featureFlags = useFeaturesFlagsStore()
if (!featureFlags.get('screen')) {
  throw new Error('Feature not enabled')
}
const { t } = useI18n()
const navigationStore = useNavigationStore()
const cityStore = useCityStore()
const { city, isLoading } = storeToRefs(cityStore)

const data = ref<{ geojson: GeoJSON.FeatureCollection }>({
  geojson: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          name: 'Barcelona 02',
          mean: 200,
        },
        geometry: {
          coordinates: [
            [
              2.1313038104671875,
              41.38954352003594,
            ],
            [
              2.1225495596854387,
              41.387371546129145,
            ],
            [
              2.112030745420185,
              41.384415858227214,
            ],
            [
              2.1016008806915067,
              41.38197695436733,
            ],
            [
              2.0998243125399085,
              41.38186313098501,
            ],
            [
              2.098117876871413,
              41.38153765924375,
            ],
            [
              2.0959652076991233,
              41.380283382654085,
            ],
            [
              2.0937468503897207,
              41.37893048393411,
            ],
            [
              2.0928834241445884,
              41.37846344557323,
            ],
            [
              2.0906483900585613,
              41.37724960864659,
            ],
          ],
          type: 'LineString',
        },
      },
      {
        type: 'Feature',
        properties: {
          name: 'Barcelona 01',
          mean: 500,
        },
        geometry: {
          coordinates: [
            [
              2.134966551186153,
              41.365004748139796,
            ],
            [
              2.134766797405291,
              41.36432178396453,
            ],
            [
              2.1256713860759078,
              41.35715884267131,
            ],
            [
              2.1167033085660307,
              41.35112304718925,
            ],
            [
              2.1154169174171784,
              41.351034348704786,
            ],
          ],
          type: 'LineString',
        },
      },
    ],
  },
})
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="relative h-full flex-1 overflow-hidden">
      <Teleport
        v-if="navigationStore.filtersIsMounted"
        to="#teleport-sidebar"
      >
        Filters
      </Teleport>

      <CyclabilityMap
        :data="data"
        :is-loading="isLoading"
        :center="city?.center ? [city?.center.lng, city?.center.lat] : undefined"
        :zoom="city?.default_zoom"
        :legend-title="t('Cyclability Map')"
      />

      <div class="h-full">
        <CityMapSidebar v-if="featureFlags.get('map_selected_area')">
          Sidebar
        </CityMapSidebar>
      </div>
    </div>
  </div>
</template>
